<template>
  <v-card>
    <v-card outlined>
      <!-- Header      -->
      <v-expansion-panels>
      <v-expansion-panel>
      <v-expansion-panel-header class="pa-0" hide-actions>
      <v-row
        justify="space-between"
        class="ma-4"
      > 
      <!-- Avatar -->
        <v-col
          class="ma-3 pa-0"
          cols="12"
          sm="3"
          md="3"
          lg="3"
        >
          <v-img
            overflow="hiden"
            class="align-self-center"
            max-width="238px"
            max-height="80px"
            contain
            :src="getImageLogo(proposition.logo)"
          ></v-img>
        </v-col>
        <v-row  align="center">
          <div class="d-flex flex-row mr-4">
            <p class="text-body-1 grey--text font-weight-light  mb-2 mr-2">
              Offre: 
            </p>
            <p style="color:#103A5E" class="text-body-1 font-weight-bold mb-2 mr-2">
              {{ proposition.type }}
            </p>
          </div>
          <div class="d-flex flex-row">
            <p class="text-body-1 grey--text font-weight-light  mb-2 mr-2">
              Budget: 
            </p>
            <p style="color:#103A5E" class=" text-body-1 font-weight-bold mb-2 mr-2">
              {{ proposition.budget.toLocaleString() }} €/an
            </p>
          </div>
        </v-row>
        <!-- Bouton Souscrire -->
        <v-col class="d-flex" cols="12" lg="2" md="3" sm="2">
          <v-btn
            @click="onButtonClick(proposition.link)"
            depressed
            class="ma-auto text-capitalize text-white"
            :color="$colors[0]"
            width="70%"
          >
            <span class="Poppins-Bold">Souscrire</span>
          </v-btn
          >
        </v-col>
      </v-row>
      </v-expansion-panel-header>

      <!-- Body -->
      <v-expansion-panel-content class="mt-4">
      <v-divider></v-divider>
      <v-row>
        <!-- Prix du KWh -->
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="3"
          class="d-flex flex-column"
        >
          <div class="text-body-1 grey--text font-weight-light  mb-2">
            Prix du KWh
          </div>
          <div v-if="fta === 'MU'">
            <div style="color:#103A5E" class="text-body-1 font-weight-bold">
              <span> HP: </span>
              {{ parseFloat(proposition.unitairemwhp).toLocaleString() }} €/kWh
            </div>
            <div style="color:#103A5E" class="text-body-1 font-weight-bold">
              <span> HC: </span>
              {{ parseFloat(proposition.unitairemwhc).toLocaleString() }} €/kWh
            </div>
          </div>
          <div v-if="fta === 'CU'">
            <div style="color:#103A5E" class="text-body-1 font-weight-bold">
              {{ parseFloat(proposition.unitaire).toLocaleString() }} €/kWh
            </div>
          </div>
        </v-col>

        <!-- Abonement -->
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="3"
          class="d-flex flex-column"
        >
          <div class="text-body-1 grey--text font-weight-light  mb-2">
            Abonnement
          </div>
          <div style="color:#103A5E" class="text-body-1 font-weight-bold">
            {{ parseFloat(proposition.mensuel).toLocaleString() }} €/mois
          </div>
        </v-col>

        <!-- Description -->
        <v-col
          v-if="proposition.description"
          cols="12"
          sm="3"
          md="3"
          lg="3"
          class="d-flex flex-column"
        >
          <div class="text-body-1 grey--text  mb-2">
            Description:
          </div>
          <div class="text-justify text-h6 font-weight-regular">
            {{proposition.description}}
          </div>
        </v-col>

      </v-row>
      </v-expansion-panel-content>
      </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    proposition: null,
    fta: null,
  },
  methods: {
    getImageLogo(logo) {
        return require('@/assets/logoParticulier/' + logo)
    },
    onButtonClick(link) {
      this.openLink(link)
    },
    openLink(link) {
      window.open('https://' + link)
    },
  },
}
</script>

<style scoped>
</style>
