<template style="margin: auto">
  <div class="login">
    <div class="test">
      <img @click="$router.push('/')" src="../assets/gemc.png" :width="235" alt="Logo" />
    </div>
    <div class="login-area">
      <h2 class="textprimary">Se Connecter</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email"><strong class="textprimary">ADRESSE MAIL</strong></label>
          <input
            type="email"
            placeholder="ADRESSE MAIL"
            v-model="user.email"
            class="input-field"
            required
          />
        </div>
        <div class="form-group">
          <label for="password"><strong class="textprimary">MOT DE PASSE</strong></label>
          <input
            type="password"
            placeholder="MOT DE PASSE"
            v-model="user.password"
            class="input-field"
            required
          />
        </div>

        <div class="form-group">
          <button class="login-btn" type="submit" :disabled="loading">
            {{ loading ? "Patientez ..." : "Login" }}
          </button>
        </div>
        <div class="form-group">
          <p style="color: red">
            {{ errorData ? "Email et/ou mot de passe incorrect(s)" : "" }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import io from "socket.io-client";
export default {
  name: "Login",

  data() {
    // test si la session contient des valeur
    if (this.getToken("token") != null) {
      if (localStorage.getItem("lastLink") != "notFound") {
        this.$router.push(localStorage.getItem("lastLink"));
      } else {
        this.$router.push("/OldDashBoard");
      }
    } else {
      this.$router.push("/login");
    }
    return {
      loading: false,
      errorData: false,
      socketconnection: null,

      user: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    test3() {
      var formData = new FormData();
      formData.append("siret", "83018285300010");
      formData.append("numCompteur", "50073185673648");
      formData.append("nom", "test");
      formData.append("prenom", "test");
      formData.append("email", "test@test.com");
      formData.append("numTele", "0755412885");
      formData.append("fonction", "test");
      formData.append(
        "tokenApi",
        "$2y$10$7ohWUBKYj/QiVggiCv.0H.Omjsjq2sN.wh0cnozxhcyKKRyOPoTaOTREDF"
      );
      axios({
        url: "https://calculedecotation.volto.fr/api/infos/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          console.log("connected");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async socketConnect() {
      /*
        const socket = await io.connect("http://localhost:8002/test",{auth:{email:"chrif@gaz-elec-moinscher.fr", id:"1122111"}})
        socket.emit("my_event",{data:{email:"chrif@gaz-elec-moinscher.fr",}});
       socket.on("my_response",data=>{
              console.log(data)
            })*/
    },
    async login() {
      //this.socketConnect()
      this.errorData = false;
      this.loading = true;
      axios
        .post(process.env.VUE_APP_URL_API_CLIENT + "logIn/", {
          email: this.user.email,
          password: this.user.password,

          isAuthenticated: true,
        })
        .then(async (res) => {
          if (res.data.login == true) {
            //set the vueAfficher to pro or particulier to know wich view we will open first
            //type of user for the header
            localStorage.setItem("email", this.user.email);
            localStorage.setItem("typeUser", res.data.type);
            localStorage.setItem("Telephone", res.data.Telephone);

            /*this.setSocket(await io.connect("http://localhost:8002/test",{auth:{email:this.user.email, id:res.data.token}}))
            this.$socket.emit("my_event",{data:{email:this.user.email,}});
            this.$socket.on("my_response",data=>{
              console.log(data)
            })*/
            //localStorage.setItem('token', res.data.token)
            this.setToken("token", res.data.token, 8 * 60 * 60);
            var whoIam = res.data.type;
            localStorage.setItem("vendeurName", res.data.nom + " " + res.data.prenom);
            if (
              localStorage.getItem("lastLink") != "notFound" &&
              localStorage.getItem("lastLink") != "login" &&
              localStorage.getItem("lastLink") != "/"
            ) {
              if (whoIam == "fournisseur") {
                if (
                  localStorage.getItem("lastLink").indexOf("ajouterNouvelleOffre") != -1
                ) {
                  this.$router.push(localStorage.getItem("lastLink"));
                } else {
                  this.$router.push("/mesOffre");
                }
              } else {
                this.$router.push("/OldDashBoard");
              }
            } else {
              this.$router.push("/OldDashBoard");
            }
            this.loading = false;
          } else {
            this.errorData = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
//fab627
///48eec7
</script>

<style scoped>
.login {
  top: 50%;
  max-width: 1180px;
  margin: 120px auto;
}
.test {
  text-align: center;
  width: 400px;
  margin: 0 auto;
  border-radius: 6px;
  padding: 20px 30px 30px 20px;
}
.login-area {
  text-align: left;
  width: 400px;
  margin: 0 auto;
  border: 2px solid #fab627;
  border-radius: 6px;
  padding: 20px 30px 30px 20px;
}

.textprimary {
  color: #fab627;
}

h2 {
  text-align: center;
}

.form-group {
  margin-top: 20px;
}

.input-field {
  width: 100%;
  height: 30px;
  outline: none;
  border: 1px solid #fab627;
  border-radius: 4px;
  margin-top: 5px;
}

.login-btn {
  width: 100%;
  height: 35px;
  border: none;
  border: 1px solid #fab627;
  color: white;
  background: #fab627;
  border-radius: 4px;
}

.login-btn:hover {
  cursor: pointer;
}
</style>
