<template>
  <div class="ma-3">
    <v-card class="mt-6 pa-0" elevation="10">
      <v-toolbar class="mb-6" :color="$colors[0]" dark>
        <span class="Poppins-Bold pl-0">Gaz professionnel</span>
      </v-toolbar>
      <FourmulaireDeCotationGaz :isHistorique="true"
        class="pa-4"
        @sendDataToParent="replireDataFromCotation"
      />
      <ListePropositionsGaz
        class="pa-4"
        v-if="Propositions.length > 0"
        :Propositions="Propositions"
        :SocieteData="SocieteData"
        :numCompteur="numCompteur"
        :typeCompteur="typeCompteur"
        :panel="panel"
        :fournisseurActuelle="fournisseurActuelle"
        :selected="selected"
        :listCompteur="listCompteur"
        :donnerCompteur="donnerCompteur"
      />
    </v-card>
  </div>
</template>
<script>
import FourmulaireDeCotationGaz from "../../components/proposition-professionnels-gaz/newGazFormulaireProposition/newFormulaireGazPro.vue";
import ListePropositionsGaz from "../../components/proposition-professionnels-gaz/newGazFormulaireProposition/newListePropositionGazPro.vue";
import "./css/cotationPro.css";

import axios from "axios";
import dialogeModal from "../../components/ui/dialogeModal.vue";
export default {
  name: "demandeDeCotationGazPro",
  components: {
    FourmulaireDeCotationGaz,
    ListePropositionsGaz,
    dialogeModal,
  },
  data() {
    return {
      Propositions: [],
      idHistorique:"",
      SocieteData: [],
      numCompteur: null,
      typeCompteur: null,
      panel: [],
      fournisseurActuelle: null,
      selected: [],
      listCompteur: [],
      donnerCompteur: {},
    };
  },
  async created() {
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
  },
  methods: {
    replireDataFromCotation({
      typeCompteur,
      Propositions,
      SocieteData,
      numCompteur,
      fournisseurActuelle,
      selected,
      listCompteur,
      profilCompteur,
      consommationGlobal,
      dateDebut,
    }) {
      this.Propositions = Propositions;
      this.SocieteData = SocieteData;
      this.numCompteur = numCompteur;
      this.typeCompteur = typeCompteur;
      this.fournisseurActuelle = fournisseurActuelle;
      this.selected = selected;
      this.listCompteur = listCompteur;
      this.donnerCompteur = {
        dateDebut: dateDebut,
        typeCompteur: typeCompteur,
        profilCompteur: profilCompteur,
        consomationGlobale: consommationGlobal,
      };
    },
  },
};
</script>
