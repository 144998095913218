<template style="background-color: #eee">
  <div>
    <!-- pop up demande offre -->
    <v-dialog v-model="ajouterVenteElecModal" persistent max-width="70%">
      <v-card style="overflow: hidden">
        <v-toolbar :color="$colors[0]" dark>
          <span class="text-h6 text-white">
            Ajouter une vente {{ venteTypeEnergie }}</span
          >
        </v-toolbar>
        <v-card-text class="my-6">
          <ajouterVenteElecComposent
            :dataVente="dataPourVente"
            :societe="societe"
            :typeVente="venteTypeEnergie"
            :pourcentage="pourcentage"
            :listCompteur="listeCompteurVente"
            @closeDialog="ajouterVenteElecModalClose"
          >
          </ajouterVenteElecComposent>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
        <loadingComponent
          @closeDialog="dialog = $event"
          :messageDialogeProps="messageDialogeError"
          :dialogProps="dialog"
          :loadingProps="loading"
          :errorProps="error"
        />
      </v-col>
    </v-row>
    <section style="background-color: #eee" v-if="societe != null">
      <div class="container-fluid py-5" style="height: 100%">
        <div class="row">
          <div class="col-lg-4 px-2">
            <div class="card mb-0">
              <div class="card-body text-center">
                <h5 class="my-3">{{ societe.Raison }}</h5>
                <img
                  src="../../assets/companies-icon.png"
                  alt="avatar"
                  class="rounded-circle img-fluid"
                  style="width: 150px"
                />
                <p class="text-muted mb-1">{{ societe.siret }}</p>
                <small class="text-muted mb-4">{{
                  societe.AdressePostal
                }}</small>
                <div class="d-flex justify-content-center mb-2 mt-2">
                  <v-btn :color="$colors[0]" v-if="urlStatus != ''">
                    <a
                      :href="urlStatus"
                      target="_blank"
                      style="text-decoration: none"
                    >
                      <span class="white--text Poppins-Bold"
                        >EXTRAIT PAPPERS
                        <v-icon dense>mdi-download</v-icon></span
                      >
                    </a>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="card mb-4 mb-lg-0 mt-2">
              <div class="card-body p-0">
                <ul class="list-group list-group-flush rounded-3">
                  <li
                    @click="afficherInfoPersonnelle"
                    class="btn list-group-item d-flex align-items-center p-3"
                  >
                    <v-icon :color="$colors[2]">mdi-account-outline</v-icon>
                    <p class="mb-0 ml-5">Information du client</p>
                  </li>
                  <li
                    @click="DemandeDeCotation"
                    class="btn list-group-item d-flex align-items-center p-3"
                  >
                    <v-icon :color="$colors[2]"
                      >mdi-clipboard-text-clock-outline</v-icon
                    >
                    <p class="mb-0 ml-5">Historique de cotation</p>
                  </li>
                  <li
                    @click="getAllVenteProBySociete"
                    class="btn list-group-item d-flex align-items-center p-3"
                  >
                    <v-icon :color="$colors[2]"
                      >mdi-clipboard-list-outline</v-icon
                    >
                    <p class="mb-0 ml-5">Liste des ventes</p>
                  </li>
                  <li
                    @click="GenerationPDF"
                    class="btn list-group-item d-flex align-items-center p-3"
                  >
                    <v-icon :color="$colors[2]">mdi-download-outline</v-icon>
                    <p class="mb-0 ml-5">Génération Document(s)</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card mb-4 mb-lg-0 mt-2">
              <div class="card-body p-0">
                <ul class="list-group list-group-flush rounded-3">
                  <li class="list-group-item d-flex align-items-center p-3">
                    <div>
                      <v-card-title class="justify-start">
                        <v-avatar class="mr-2" size="36" rounded>
                          <img
                            alt="credit safe score"
                            src="../../assets/images/logos/logo-credit-safe.png"
                          />
                        </v-avatar>
                        creditsafe
                      </v-card-title>

                      <v-card-text class="text-left text-body-1 pr-6"
                        >Score:
                        <span class="ml-2 font-weight-black">{{
                          societe.scoreCreditSafe
                        }}</span>
                        /100
                      </v-card-text>
                    </div>
                  </li>
                  <li class="list-group-item d-flex align-items-center p-3">
                    <div>
                      <v-card-title class="justify-start">
                        <v-avatar class="mr-2" size="36" rounded>
                          <img
                            alt="credit safe score"
                            src="../../assets/logoElliPro.png"
                          />
                        </v-avatar>
                        Elli Pro
                      </v-card-title>

                      <v-card-text class="text-left text-body-1 pr-6"
                        >Score:
                        <span class="ml-2 font-weight-black">
                          {{ societe.scoreEllipro }} </span
                        >/10
                      </v-card-text>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 pl-0" v-if="vueAfficher == 'client'">
            <div class="card mb-2">
              <div class="card-body">
                <p class="mb-8">
                  <b
                    ><span style="color: #1e6ca4" class="font-italic me-1"
                      >Informations</span
                    ></b
                  >personnelles du client
                </p>
                <div class="d-flex justify-content-end mb-4">
                  <div v-if="editMode == false">
                    <v-btn
                      :color="$colors[0]"
                      class="white--text font-weight-bold"
                      @click="activeEdit"
                      ><v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </div>
                  <div v-if="editMode == true">
                    <v-btn
                      :color="$colors[0]"
                      class="mr-4 white--text font-weight-bold"
                      @click="cancelEdit"
                      ><v-icon>mdi-pencil-off-outline</v-icon></v-btn
                    >
                    <v-btn
                      :color="$colors[0]"
                      class="white--text font-weight-bold"
                      @click="saveChange"
                      ><v-icon>mdi-check-all</v-icon></v-btn
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Nom</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0" v-if="editMode == false">
                      {{ societe.nom }}
                    </p>
                    <input
                      type="text "
                      v-if="editMode == true"
                      class="form-control form-control-sm"
                      v-model="societe.nom"
                    />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Prénom</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0" v-if="editMode == false">
                      {{ societe.prenom }}
                    </p>
                    <input
                      type="text "
                      v-if="editMode == true"
                      class="form-control form-control-sm"
                      v-model="societe.prenom"
                    />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Adresse e-mail</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0" v-if="editMode == false">
                      {{ societe.AdresseEmail }}
                    </p>
                    <input
                      type="text "
                      v-if="editMode == true"
                      class="form-control form-control-sm"
                      v-model="societe.AdresseEmail"
                    />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Numéro de téléphone</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0" v-if="editMode == false">
                      {{ formatPhoneNumber(societe.numTele) }}
                    </p>
                    <input
                      type="text "
                      v-if="editMode == true"
                      class="form-control form-control-sm"
                      v-model="societe.numTele"
                    />
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-4">
                    <p class="mb-0">Poste</p>
                  </div>
                  <div class="col-sm-8">
                    <p class="text-muted mb-0" v-if="editMode == false">
                      {{ societe.fonction }}
                    </p>
                    <input
                      type="text "
                      v-if="editMode == true"
                      class="form-control form-control-sm"
                      v-model="societe.fonction"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pr-0">
                <div style="height: 100%" class="card mb-4 mb-md-0">
                  <div class="card-body">
                    <p class="mb-4">
                      <b
                        ><span style="color: #1e6ca4" class="font-italic me-1"
                          >Informations</span
                        ></b
                      >de la société
                    </p>

                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Raison sociale</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.Raison }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Siren</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.siren }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Siret</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.siret }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Adresse</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.AdressePostal }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Code postal</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.codePostal }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Commune</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.commune }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Voie</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.Voie }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
              <div class="col-md-6 pl-2">
                <div class="card mb-4 mb-md-0">
                  <div class="card-body">
                    <p class="mb-4">
                      <b
                        ><span style="color: #1e6ca4" class="font-italic me-1"
                          >Informations</span
                        ></b
                      >de la société
                    </p>

                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Capitaux propres</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em>
                            {{
                              isNaN(societe.capi)
                                ? societe.capi
                                : Number(societe.capi).toLocaleString() + " €"
                            }}</em
                          ></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Code Naf</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.codeNaf }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Code insee</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.codeInsee }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Capital social</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em>
                            {{
                              isNaN(societe.capitalSocial)
                                ? societe.capitalSocial
                                : Number(societe.capitalSocial).toLocaleString(
                                    "fr-FR"
                                  )
                            }}</em
                          ></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Statut</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.statusSociete }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">Type</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.typeEtablissement }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">n° RCS</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.RCS }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-4">
                        <small class="mb-0">n° TVA</small>
                      </div>
                      <div class="col-sm-8">
                        <small class="text-muted mb-0"
                          ><em> {{ societe.TVA }}</em></small
                        >
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-8 pl-lg-0"
            v-if="vueAfficher == 'historiqueDeCotation'"
          >
            <div class="card mb-2">
              <div class="card-body mb-2" style="overflow: overlay">
                <p class="mb-">
                  <b
                    ><span style="color: #1e6ca4" class="font-italic me-1"
                      >Les demandes de cotations
                    </span></b
                  >associées à l'entreprise
                </p>
                <b-table
                  :busy="loadingTableCotation"
                  show-empty
                  bordered
                  responsive
                  :fields="fieldDemandeDeCotation"
                  hover
                  :items="demandeDeCotationItems"
                  :per-page="perPageDC"
                  :current-page="currentPageDC"
                  id="demandeDeCotation"
                >
                  <template #empty="scope">
                    <noDataTable />
                  </template>
                  <template #table-busy>
                    <div style="color: #fbc02d" class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Chargement...</strong>
                    </div>
                  </template>

                  <template #cell(date)="data">
                    {{ data.item.date }}
                  </template>

                  <template #cell(typeDemande)="data">
                    {{ data.item.typeDemande }}
                  </template>
                  <template #cell(numCompteur)="data">
                    <div style="max-width: 200px">
                      <small>{{ spliteList(data.item.numCompteur)[0] }}</small>
                      <v-icon
                        v-if="spliteListSlice(data.item.numCompteur).length > 0"
                        large
                        data-toggle="collapse"
                        :data-target="'#multiCollapseCompteur' + index"
                        aria-expanded="false"
                        :aria-controls="'multiCollapseCompteur' + index"
                        right
                      >
                        mdi-menu-down
                      </v-icon>
                      <div
                        class="collapse multi-collapse"
                        :id="'multiCollapseCompteur' + index"
                      >
                        <div>
                          <small
                            v-for="c in spliteListSlice(data.item.numCompteur)"
                          >
                            {{ c }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(Echeance)="data">
                    {{ data.item.Echeance }}
                  </template>
                  <template #cell(finContrat)="data">
                    {{ data.item.finContrat }}
                  </template>
                  <template #cell(statut)="data">
                    <small v-if="data.item.valide == '2'"
                      ><v-icon style="color: red"
                        >mdi-close-circle</v-icon
                      ></small
                    >
                    <small v-if="data.item.valide == '1'"
                      ><v-icon style="color: #00e676"
                        >mdi-check-circle</v-icon
                      ></small
                    >
                    <small v-if="data.item.valide == '0'"
                      ><v-icon style="color: gray"
                        >mdi-timer-refresh-outline</v-icon
                      ></small
                    >
                  </template>
                </b-table>
                <b-pagination
                  v-if="demandeDeCotationItems?.length > 0"
                  v-model="currentPageDC"
                  :total-rows="demandeDeCotationItems?.length"
                  :per-page="perPageDC"
                  aria-controls="demandeDeCotation"
                >
                </b-pagination>
              </div>
            </div>
            <!-- historique gaz -->
            <div class="row">
              <div class="col-md-12 pb-0">
                <div class="card mb-4 mb-md-0">
                  <div class="card-body">
                    <p class="mb-4">
                      <b
                        ><span style="color: #1e6ca4" class="font-italic me-1"
                          >Historique de cotation GAZ</span
                        ></b
                      >
                      par compteur
                    </p>
                    <div class="col-md-12 text-right pr-0">
                      <router-link
                        style="text-decoration: none"
                        :to="{
                          path: '/demande-cotation-gaz-pro',
                          query: { siret: this.societe.siret },
                        }"
                      >
                        <!--<v-btn :color="$colors[0]">Nouvelle cotation </v-btn>-->
                        <v-btn
                          :color="$colors[0]"
                          class="white--text font-weight-bold"
                          >Nouvelle cotation GAZ</v-btn
                        >
                      </router-link>
                    </div>
                    <b-table
                      :busy="loadingTableHistoriqueGaz"
                      show-empty
                      :fields="fieldhistoriqueDeCotationGaz"
                      :items="historiqueCotationGazItems"
                      responsive
                      bordered
                      :per-page="perPageHisoriqueGaz"
                      :current-page="currentPageHistoriqueGaz"
                      id="historiqueCotationGaz"
                    >
                      <template #empty="scope">
                        <noDataTable />
                      </template>
                      <template #table-busy>
                        <div style="color: #fbc02d" class="text-center my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong class="pl-2">Chargement...</strong>
                        </div>
                      </template>
                      <template #cell(Action)="data">
                        <v-btn
                          color="#FBC02D"
                          icon
                          plain
                          @click="ajouterVenteGazModalOpen(data.item)"
                        >
                          <v-icon>mdi-plus-thick</v-icon>
                        </v-btn>
                      </template>
                      <template #cell(date)="data">
                        <small>{{ data.item.date }}</small>
                      </template>

                      <template #cell(numCompteur)="data">
                        <router-link
                          :to="{
                            path: '/new-historiqueCotationGaz',
                            query: { id: data.item.id },
                          }"
                          target="_blank"
                          v-if="data.item.status == 'new'"
                          ><small
                            >Historique de Cotation {{ data.item.id }}</small
                          ></router-link
                        >
                        <router-link
                          :to="{
                            path: '/historiqueCotationGaz',
                            query: { id: data.item.id },
                          }"
                          target="_blank"
                          v-else
                          ><small>{{
                            data.item.numCompteur
                          }}</small></router-link
                        >
                      </template>
                      <template #cell(dateDebut)="data">
                        <small>{{ data.item.dateDebut }}</small>
                      </template>

                      <template #cell(dateFin)="data">
                        <small>{{ data.item.dateFin }}</small>
                      </template>

                      <template #cell(marge)="data">
                        <small>{{ data.item.marge }}</small>
                      </template>
                    </b-table>
                    <b-pagination
                      v-if="historiqueCotationGazItems?.length > 0"
                      v-model="currentPageHistoriqueGaz"
                      :total-rows="historiqueCotationGazItems?.length"
                      :per-page="perPageHisoriqueGaz"
                      aria-controls="historiqueCotationGaz"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
            <!-- historique Elec-->
            <div class="row">
              <div class="col-md-12 pt-2">
                <div class="card mb-4 mb-md-0">
                  <div class="card-body" style="overflow: overlay">
                    <p class="mb-4">
                      <b
                        ><span style="color: #1e6ca4" class="font-italic me-1"
                          >Historique de cotation ELEC</span
                        ></b
                      >
                      par compteur
                    </p>
                    <div class="col-md-12 text-right pr-0">
                      <router-link
                        style="text-decoration: none"
                        :to="{
                          path: '/demande-cotation-elec-pro',
                          query: { siret: this.societe.siret },
                        }"
                      >
                        <v-btn
                          :color="$colors[0]"
                          class="white--text font-weight-bold"
                          >Nouvelle cotation ELEC</v-btn
                        >
                      </router-link>
                    </div>
                    <b-table
                      :busy="loadingTableHistorique"
                      show-empty
                      :fields="fieldhistoriqueDeCotation"
                      :items="historiqueCotationItems"
                      responsive
                      bordered
                      :per-page="perPageHistorique"
                      :current-page="currentPageHistorique"
                      id="historiqueCotation"
                    >
                      <template #empty="scope">
                        <noDataTable />
                      </template>
                      <template #table-busy>
                        <div style="color: #fbc02d" class="text-center my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong class="pl-2">Chargement...</strong>
                        </div>
                      </template>
                      <template #cell(Action)="data">
                        <v-btn
                          color="#FBC02D"
                          icon
                          plain
                          @click="ajouterVenteElecModalOpen(data.item)"
                        >
                          <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                      </template>
                      <template #cell(date)="data">
                        <small>{{ data.item.date }}</small>
                      </template>

                      <template #cell(numCompteur)="data">
                        <router-link
                          :to="{
                            path: '/new-historiqueCotation',
                            query: { id: data.item.id },
                          }"
                          target="_blank"
                          v-if="data.item.status == 'new'"
                          ><small
                            >Historique de Cotation {{ data.item.id }}</small
                          ></router-link
                        >
                        <router-link
                          :to="{
                            path: '/historiqueCotation',
                            query: { id: data.item.id },
                          }"
                          target="_blank"
                          v-else
                          ><small>{{
                            data.item.numCompteur
                          }}</small></router-link
                        >
                      </template>

                      <template #cell(optionTarifaire)="data">
                        <small>{{ data.item.optionTarifaire }}</small>
                      </template>

                      <template #cell(dateDebut)="data">
                        <small>{{ data.item.dateDebut }}</small>
                      </template>

                      <template #cell(dateFin)="data">
                        <small>{{ data.item.dateFin }}</small>
                      </template>

                      <template #cell(marge)="data">
                        <small>{{ data.item.marge }}</small>
                      </template>
                    </b-table>
                    <b-pagination
                      v-if="historiqueCotationItems?.length > 1"
                      v-model="currentPageHistorique"
                      :total-rows="historiqueCotationItems?.length"
                      :per-page="perPageHistorique"
                      aria-controls="historiqueCotation"
                    >
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8 px-0" v-if="vueAfficher == 'listeDesVentePro'">
            <div class="card mb-4">
              <div class="card-body">
                <p class="mb-4">
                  <b
                    ><span style="color: #1e6ca4" class="font-italic me-1"
                      >Les ventes professionnelles</span
                    ></b
                  >
                </p>
                <!-- <div class="col-md-12 bg-light text-right">
                                        <button @click="ajouterUneVentePro" type="button" class="btn btn-primary">Ajouter une vente</button>
                                </div>-->
                <b-table
                  :busy="loadingTableVentePro"
                  show-empty
                  :fields="fieldVentePro"
                  :items="venteProSocieteItems"
                  responsive
                  bordered
                  :per-page="perPageVentePro"
                  :current-page="currentPageVentePro"
                  id="venteProSociete"
                >
                  <template #empty="scope">
                    <noDataTable />
                  </template>
                  <template #table-busy>
                    <div style="color: #fbc02d" class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="pl-2">Chargement...</strong>
                    </div>
                  </template>
                  <template #cell(date)="data">
                    <small>{{ data.item.date }}</small>
                  </template>

                  <template #cell(Vendeur)="data">
                    <small>{{ data.item.Vendeur }}</small>
                  </template>

                  <template #cell(numCompteur)="data">
                    <small>{{ data.item.numCompteur }}</small>
                  </template>

                  <template #cell(type)="data">
                    <small>{{ data.item.type }}</small>
                  </template>

                  <template #cell(valeur)="data">
                    <small>{{
                      isNaN(data.item.valeur)
                        ? data.item.valeur
                        : Number(data.item.valeur).toLocaleString("fr-FR")
                    }}</small>
                  </template>

                  <template #cell(status)="data">
                    <div class="btn-group">
                      <small
                        v-if="data.item.status == 'Refuser'"
                        style="color: red"
                        ><b>refusée</b></small
                      >
                      <small
                        v-if="data.item.status == 'Accepter'"
                        style="color: green"
                        ><b>acceptée</b></small
                      >
                      <small
                        v-if="data.item.status == 'en Attend'"
                        style="color: gray"
                        ><b>en attente</b></small
                      >
                      <small
                        v-if="data.item.status == 'en attente de validation'"
                        style="color: blue"
                        ><b>Phase de validation</b></small
                      >
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-if="venteProSocieteItems?.length > 0"
                  v-model="currentPageVentePro"
                  :total-rows="venteProSocieteItems?.length"
                  :per-page="perPageVentePro"
                  aria-controls="venteProSociete"
                >
                </b-pagination>
              </div>
            </div>
          </div>
          <div
            class="col-lg-8 pl-lg-0 pl-2 pr-2"
            v-if="vueAfficher == 'GenerationPDF'"
          >
            <div class="card mb-3">
              <div class="card-body pb-9">
                <p class="mb-8">
                  <b
                    ><span style="color: #1e6ca4" class="font-italic me-1"
                      >Les Documents
                    </span></b
                  >disponibles
                </p>
                <div class="d-flex justify-content-left mb-2 mt-2">
                  <v-select
                    required
                    outlined
                    v-model="listCompteurSelected"
                    :items="customItems"
                    @change="changeClick"
                    label="Document"
                    item-value="value"
                    item-text="text"
                    return-object
                    hide-details
                  >
                    <!-- <template v-slot:item="data">
                                            <v-btn :color="data.item.color" @click="data.item.action" class="ma-2">
                                            <span class="white--text font-weight-bold">
                                                {{ data.item.text }}
                                                <v-icon class="ml-1" dense>{{ data.item.icon }}</v-icon>
                                            </span>
                                            </v-btn>
                                        </template> -->
                  </v-select>
                </div>
              </div>
            </div>
            <div>
              <lettreDeRisiliation
                v-if="openResiliationModal"
                :societe="societe"
                :listCompteur="listCompteur"
                @closeResiliationModal="openResiliationModal = false"
              />
              <CreationAcdEngie
                v-if="openAcdEngie"
                :societe="societe"
                :listCompteur="listCompteur"
                @closeAcdEngieModal="openAcdEngie = false"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import loadingComponent from "../../components/ui/loading-component.vue";
import ajouterVenteElecComposent from "./ajouterVenteElecComposent.vue";

import lettreDeRisiliation from "./componentDocs/resiliation/resiliation.vue";
import CreationAcdEngie from "./componentDocs/acdEngie/CreationAcdEngie.vue";
import noDataTable from "../../components/no-data-table.vue";
export default {
  components: {
    loadingComponent,
    ajouterVenteElecComposent,
    noDataTable,
    lettreDeRisiliation,
    CreationAcdEngie,
  },
  name: "monProfileSociete",
  data() {
    return {
      listCompteurSelected: "",

      customItems: [
        {
          text: "Creation ACD Engie",
          value: "acdEngie",
          color: "primary",
          icon: "mdi-download",
          action: this.ACDEngie,
        },
        {
          text: "Lettre de résiliation",
          value: "resiliation",
          color: "primary",
          icon: "mdi-download",
          action: this.openResiliation,
        },
      ],
      openResiliationModal: false,
      openAcdEngie: false,
      pourcentage: 0,
      listeCompteurVente: [],
      dataPourVente: null,
      ajouterVenteElecModal: false,
      currentPageHistorique: 1,
      perPageHistorique: 4,
      currentPageHistoriqueGaz: 1,
      perPageHisoriqueGaz: 4,
      currentPageVentePro: 1,
      perPageVentePro: 10,
      currentPageDC: 1,
      perPageDC: 4,
      urlStatus: "",
      historiqueCotationItems: [],
      historiqueCotationGazItems: [],
      demandeDeCotationItems: [],
      venteProSocieteItems: [],

      loadingTableHistorique: false,
      loadingTableHistoriqueGaz: false,
      loadingTableCotation: false,
      loadingTableVentePro: false,
      fieldhistoriqueDeCotationGaz: [
        {
          key: "Action",
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "date",
          label: "Date d'ajout",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "numCompteur",
          label: "Compteur",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "dateDebut",
          label: "Début de contrat",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "dateFin",
          label: "Fin de contrat",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "marge",
          label: "Marge",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      fieldDemandeDeCotation: [
        { key: "date", label: "Date d'ajout" },
        { key: "typeDemande", label: "Énergie" },
        { key: "numCompteur", label: "Numéro de compteur" },
        { key: "Echeance", label: "Début de contrat" },
        { key: "finContrat", label: "Fin de contrat" },
        { key: "statut", label: "Statut" },
      ],

      fieldhistoriqueDeCotation: [
        {
          key: "Action",
          label: "Action",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "date",
          label: "Date d'ajout",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "numCompteur",
          label: "Compteur",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "optionTarifaire",
          label: "Option tarifaire",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "dateDebut",
          label: "Début de contrat",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "dateFin",
          label: "Fin de contrat",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "marge",
          label: "Marge",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      fieldVentePro: [
        {
          key: "date",
          label: "Date d'ajout",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "Vendeur",
          label: "Commercial",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "type",
          label: "Énergie",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "valeur",
          label: "Valeur",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          label: "Statut",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      venteTypeEnergie: "ELEC",
      dialog: false,
      loading: false,
      vueAfficher: "client",
      societe: null,
      historiqueDeCotation: null,
      demandeDeCotationDeSociete: null,
      venteProSociete: null,
      compteVentePro: null,
      editMode: false,
    };
  },
  watch: {
    async vueAfficher(newVal) {
      if (newVal == "GenerationPDF") {
        this.listCompteurSelected = this.customItems[0];
        await this.listCompteurSelected.action();
      }
    },
  },
  async created() {
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    await this.getSocieteById();
    await this.getAllHistoryCotationBySociete(); // ajout afin d'obtenir la liste des compteurs rattaché a une cotation
    await this.getAllHistoryCotationGazBySociete();
  },
  methods: {
    async changeClick() {
      await this.listCompteurSelected.action();
    },
    formatPhoneNumber(phoneNumber) {
      // Supprimer tous les caractères non numériques
      phoneNumber = phoneNumber.replace(/\D/g, "");

      // Insérer un espace après chaque paire de chiffres
      phoneNumber = phoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ");

      return phoneNumber;
    },
    async ACDEngie() {
      this.listCompteur = [];

      for (var i = 0; i < this.demandeDeCotationItems.length; i++) {
        var lesNumCompteur =
          this.demandeDeCotationItems[i].numCompteur.split(",");
        var typeDemande = this.demandeDeCotationItems[i].typeDemande;
        for (var j = 0; j < lesNumCompteur.length; j++) {
          this.listCompteur.push({
            numCompteur: lesNumCompteur[j],
            typeDemande: typeDemande,
          });
        }
      }
      this.openResiliationModal = false;
      this.openAcdEngie = true;
      //this.openModalAcdEngie = true
    },
    async creationAcdEngie() {
      this.$refs.formAcd.validate();
      if (this.$refs.formAcd.validate()) {
        //var pdfDoc = await PDFDocument.create()

        const doc = new jsPDF("p", "pt", "a4");

        //draw image
        /*var img = new Image()
                    img.src = "/logos/engieAcdLog.png"
                    doc.addImage(img, 'png', 10, 78, 12, 15)*/
        var tableColumn = [
          "ELECTRICITE (Point De Livraison) PDL",
          "GAZ (Point de Consommation Estimé) PCE",
        ];
        var tableRows = [];
        var lesCompteurGaz = [];
        var lesCompteurElec = [];

        //drawTable
        for (var i = 0; i < this.selectedCompteur.length; i++) {
          if (this.selectedCompteur[i]["typeDemande"] == "ELEC") {
            lesCompteurElec.push(this.selectedCompteur[i]["numCompteur"]);
          } else {
            lesCompteurGaz.push(this.selectedCompteur[i]["numCompteur"]);
          }
        }
        if (lesCompteurElec.length >= lesCompteurGaz.length) {
          for (var j = 0; j < lesCompteurElec.length; j++) {
            if (j < lesCompteurGaz.length) {
              tableRows.push([lesCompteurElec[j], lesCompteurGaz[j]]);
            } else {
              tableRows.push([lesCompteurElec[j], ""]);
            }
          }
        } else {
          for (var j = 0; j < lesCompteurGaz.length; j++) {
            if (j < lesCompteurElec.length) {
              tableRows.push([lesCompteurElec[j], lesCompteurGaz[j]]);
            } else {
              tableRows.push(["", lesCompteurGaz[j]]);
            }
          }
        }
        var columnStyle = {
          0: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 220,
            minCellHeight: 30,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            valign: "middle",
            fillColor: [255, 255, 255],
            textColor: 0,
            lineColor: 0,
            fontSize: 9,
            lineWidth: 1,
            cellWidth: 220,
            minCellHeight: 30,
            fontStyle: "normal",
          },
        };
        doc.autoTable(tableColumn, tableRows, {
          startX: 50,
          startY: 585,
          margin: { left: 60 },
          columnStyles: columnStyle,
          headStyles: {
            fillColor: [210, 210, 210],
            textColor: 0,
            halign: "center",
            lineWidth: 1,
            valign: "middle",
          },
        });

        let finalY = doc.lastAutoTable.finalY;
        let blob = doc.output("arraybuffer");
        let pdfDoc = await PDFDocument.load(blob);
        let tablePageDoc = pdfDoc;
        var pages = tablePageDoc.getPages();
        var page = pages[0];

        const ImageLogo = await fetch("/logos/engieAcdLog.png").then((res) =>
          res.arrayBuffer()
        );

        var pngImage = await pdfDoc.embedPng(ImageLogo);
        //var page = pdfDoc.addPage()
        page.drawImage(pngImage, { x: 40, y: 720, width: 260, height: 100 });
        const form = pdfDoc.getForm();
        var xBegin = 40;
        var yBegin = 680;
        var width = 520;
        var height = 20;
        var fieldName = "para1";
        //societe.commune
        var txt =
          "AUTORISATION POUR LA RECUPERATION DES DONNEES AUPRES DES GESTIONNAIRES DE RESEAUX";

        await this.createTextField(
          10,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Helvetica-Bold"
        );
        var txt =
          "Je soussigné(e) " +
          this.societe.prenom +
          " " +
          this.societe.nom +
          " agissant en qualité de " +
          this.societe.fonction +
          " de la société " +
          this.societe.Raison +
          " immatriculée au RCS sous le numéro de SIRET" +
          this.societe.siret;
        yBegin = yBegin - 95;
        height = 85;
        width = 490;
        var fieldName = "para2";
        await this.createTextField(
          11,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Courier"
        );
        var txt =
          "Le signataire du présent formulaire déclare être dûment habilité par le client pour la signature du présent document." +
          "Par la signature de ce document, le Client autorise expressément ENERGYPLUS ainsi que les fournisseurs partenaires" +
          " dûment habilité(s) à cet effet (liste des fournisseurs sur simple demande), à demander et à recevoir communication" +
          " auprès des gestionnaires de réseaux assurant l’accès au réseau d’électricité et de gaz du(des) site(s) du client, des" +
          " données cochées ci-dessous, sous réserve de disponibilité, en vue de la préparation d’une offre de fourniture de gaz" +
          " et/ou d’électricité :\n" +
          "   • L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance)\n" +
          "   • L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site;\n" +
          "   • L’historique de courbe de charge, au pas restitué par Enedis, du site;\n" +
          "   • Les données techniques et contractuelles disponibles du site.\n\n" +
          "La présente autorisation ne peut être cédée et pourra être retirée à tout moment. Elle est consentie pour toute la" +
          " période d’exécution du contrat ou à défaut pour la durée de 12 mois, à compter de la date de signature." +
          "Le Client accepte expressément que ses données personnelles soient conservées par ENERGYPLUS et/ou les" +
          " gestionnaires de réseaux à des fins de gestion et de traçabilité. Conformément à la loi n°78-17 du 6 janvier 1978" +
          " modifiée et au règlement (UE) n°2016/679 du 27 avril 2016, les informations recueillies sont enregistrées dans un fichier" +
          " informatisé par ENERGYPLUS en sa qualité de responsable de traitement à des fins de gestion et de traçabilité " +
          "des demandes. Vous disposez d’un droit d'accès à vos données, de rectification, d’opposition et d’effacement pour motifs" +
          " légitimes. Vous disposez, également, d’un droit à la limitation du traitement et à la portabilité des données à caractère" +
          " personnel vous concernant. Vous pouvez exercer vos droits à l’adresse suivante Enedis, 34 place des Corolles, 92079" +
          " Paris La Défense Cedex Conformément à la loi « informatique et libertés », vous disposez de la faculté d’introduire une" +
          " réclamation auprès de la CNIL.";
        yBegin = yBegin - 320;
        height = 340;
        var fieldName = "para3";
        await this.createTextField(
          11,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Courier"
        );
        // les table
        yBegin = yBegin - 10;
        /*for (var i=0; i<this.selectedCompteur.length; i++){
                        txt = "* "+this.selectedCompteur[i]
                        yBegin = yBegin - 25
                        height = 20
                        var fieldName = "compteur"+i
                        await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                        if(yBegin<60){
                            page = pdfDoc.addPage()
                            yBegin = 700
                        }
                    }*/
        page = pages[pages.length - 1];
        var txt =
          "En vue de la préparation d’une offre de fourniture de gaz et/ou d’électricité. ";
        yBegin = 842 - finalY - 40;
        height = 20;
        var fieldName = "para4";
        await this.createTextField(
          11,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Courier"
        );
        if (yBegin < 120) {
          page = pdfDoc.addPage();
          yBegin = 700;
        }
        var txt =
          "La présente autorisation ne peut être cédée et pourra être retirée à tout moment. Elle est consentie pour toute la" +
          " période d’exécution du contrat ou à défaut pour la durée de 12 mois, à compter de la date de signature." +
          "\nLes données ainsi acquises sont détruites dès la fin de validité de la présente autorisation";
        yBegin = yBegin - 40;
        height = 60;
        var fieldName = "para5";
        await this.createTextField(
          11,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Courier"
        );
        var dateAjourdhui =
          new Date().getDate() +
          "/" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getFullYear();
        var txt = "Fait à " + this.societe.commune + " le " + dateAjourdhui;
        yBegin = yBegin - 25;
        height = 20;
        var fieldName = "para6";
        await this.createTextField(
          11,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Courier"
        );
        var txt =
          "Signature du gérant:                                                                                    Cachet";
        yBegin = yBegin - 25;
        height = 20;
        var fieldName = "para7";
        await this.createTextField(
          11,
          true,
          form,
          page,
          xBegin,
          yBegin,
          width,
          height,
          txt,
          "title" + fieldName,
          [1, 1, 1],
          "Courier"
        );
        const pdfBytes = await pdfDoc.save();
        this.generatedPdf = pdfBytes;
        download(
          pdfBytes,
          "AcdEngie" + this.societe.siret + ".pdf",
          "application/pdf"
        );
      }
    },
    async GenerationPDF() {
      // await this.DemandeDeCotation();
      this.vueAfficher = "GenerationPDF";
    },
    async openResiliation() {
      this.listCompteur = [];
      for (var i = 0; i < this.demandeDeCotationItems.length; i++) {
        var lesNumCompteur =
          this.demandeDeCotationItems[i].numCompteur.split(",");
        var typeDemande = this.demandeDeCotationItems[i].typeDemande;
        for (var j = 0; j < lesNumCompteur.length; j++) {
          this.listCompteur.push({
            numCompteur: lesNumCompteur[j],
            typeDemande: typeDemande,
          });
        }
      }
      this.openResiliationModal = true;
      this.openAcdEngie = false;
    },
    async openMandat() {
      this.listCompteur = [];
      for (var i = 0; i < this.demandeDeCotationItems.length; i++) {
        var lesNumCompteur =
          this.demandeDeCotationItems[i].numCompteur.split(",");
        var typeDemande = this.demandeDeCotationItems[i].typeDemande;
        for (var j = 0; j < lesNumCompteur.length; j++) {
          this.listCompteur.push({
            numCompteur: lesNumCompteur[j],
            typeDemande: typeDemande,
          });
        }
      }
      this.openResiliationModal = false;
      this.openAcdEngie = false;
    },

    async getPourcentage(numCompteur) {
      var formData = new FormData();
      formData.append("numCompteur", numCompteur);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getPourcentageCompte/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == "permission") {
            this.pourcentage = 0;
          } else {
            this.pourcentage = res.data.result;
          }
        })
        .catch((err) => {
          this.pourcentage = 0;
        });
    },
    async ajouterVenteElecModalOpen(item) {
      await this.getPourcentage(item.numCompteur);
      await this.getListeCompteurByDC(item.numCompteur);
      this.dataPourVente = item;
      this.venteTypeEnergie = "ELEC";
      this.ajouterVenteElecModal = true;
    },
    async getListeCompteurByDC(numCompteur) {
      var formData = new FormData();
      formData.append("numCompteur", numCompteur);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompteurParDC/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.listeCompteurVente = res.data.result;
        })
        .catch((err) => {
          this.listeCompteurVente = [];
        });
    },
    async ajouterVenteGazModalOpen(item) {
      this.dataPourVente = item;
      this.venteTypeEnergie = "GAZ";
      this.ajouterVenteElecModal = true;
    },
    async ajouterVenteElecModalClose() {
      this.ajouterVenteElecModal = false;
    },
    async DemandeDeCotation() {
      this.loadingTableHistorique = true;
      this.loadingTableCotation = true;
      this.loadingTableHistoriqueGaz = true;
      //await this.getAllVenteProBySociete()
      await this.getAllHistoryCotationBySociete();
      await this.getAllHistoryCotationGazBySociete();
      this.loadingTableHistorique = false;
      this.loadingTableCotation = false;
      this.loadingTableHistoriqueGaz = false;
    },
    activeEdit() {
      this.editMode = true;
    },

    cancelEdit() {
      this.editMode = false;
    },
    async saveChange() {
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("id", this.societe.id);
      formData.append("nom", this.societe.nom);
      formData.append("prenom", this.societe.prenom);
      formData.append("AdresseEmail", this.societe.AdresseEmail);
      formData.append("numTele", this.societe.numTele);
      formData.append("fonction", this.societe.fonction);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "saveChangeSociete/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            alert("données mises à jour");
            this.editMode = false;
          } else if (res.data.result == false) {
            alert("Une erreur est survenue ! Veuillez réessayer");
            this.editMode = false;
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert("permission refusée");
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          localStorage.removeItem("token");
          this.$router.push("/login");
        });
    },
    afficherInfoPersonnelle() {
      this.vueAfficher = "client";
      this.demandeDeCotationDeSociete = null;
      this.historiqueDeCotation = null;
      this.venteProSociete = null;
      this.compteVentePro = null;
    },
    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },

    dateFormatSlach(date) {
      return date.split("-").reverse().join("/");
    },
    async getAllVenteProBySociete() {
      this.loadingTableVentePro = true;
      this.demandeDeCotationItems = [];
      this.historiqueCotationItems = [];
      this.historiqueCotationGazItems = [];
      this.vueAfficher = "listeDesVentePro";
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("idSociete", this.$route.query.id);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllVenteProBySociete/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.venteProSocieteItems = res.data.ventes;
            this.compteVentePro = res.data.comptes;
          } else if (res.data.result == false) {
            alert("Une erreur est survenue ! Veuillez réessayer");
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert("permission refusée");
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          localStorage.removeItem("token");
          this.$router.push("/login");
        });
      this.loadingTableVentePro = false;
    },
    async getAllHistoryCotationGazBySociete() {
      this.venteProSociete = null;
      this.compteVentePro = null;
      this.vueAfficher = "historiqueDeCotation";
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("idSociete", this.$route.query.id);
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "getAllHistoryCotationGazBySociete/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.vueAfficher = "historiqueDeCotation";
            this.historiqueCotationGazItems = res.data.histoDeCotation;
            this.demandeDeCotationItems = res.data.DC;
          } else if (res.data.result == false) {
            alert("Une erreur est survenue");
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert("permission refusée");
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          localStorage.removeItem("token");
          this.$router.push("/login");
        });
    },
    async getAllHistoryCotationBySociete() {
      this.venteProSociete = null;
      this.compteVentePro = null;
      this.vueAfficher = "historiqueDeCotation";
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("idSociete", this.$route.query.id);
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "getAllHistoryCotationBySociete/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.vueAfficher = "historiqueDeCotation";
            this.historiqueCotationItems = res.data.histoDeCotation;
            this.demandeDeCotationItems = res.data.DC;
          } else if (res.data.result == false) {
            alert("Une erreur est survenue ! Veuillez réessayer");
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert("permission refusée");
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          localStorage.removeItem("token");
          this.$router.push("/login");
        });
    },
    async getSocieteById() {
      this.dialog = true;
      this.loading = true;
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("id", this.$route.query.id);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getSocieteById/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          if (res.data.result == true) {
            this.urlStatus = res.data.status;
            this.societe = res.data.societe;
          } else if (res.data.result == false) {
            alert("Une erreur est survenue ! Veuillez réessayer");
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          this.dialog = false;
          this.loading = false;
          alert("une erreur est survenue");
        });
    },
  },
};
</script>
<style>
#demandeDeCotation th,
#venteProSociete th,
#historiqueCotationGaz th,
#historiqueCotation th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
</style>
