<style>
.td-valeur {
  align-items: center;
  flex-direction: column;
}

.td-statut {
  align-items: center;
  flex-direction: row;
}
.td-table,
.td-valeur,
.td-statut {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.d1 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
</style>
<template>
  <div>
    <v-dialog v-model="dialogComment" max-width="600px">
      <v-card>
        <v-toolbar :color="$colors[0]" dark>
          <v-card-title
            class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
            >{{
              ObjectComment?.commentaire
                ? `Commentaire général de la vente`
                : `Commentaire de refus de vente`
            }}</v-card-title
          >
        </v-toolbar>
        <v-card-text class="mt-8">
          <v-textarea
            class="text-body-2"
            readonly
            auto-grow
            outlined
            :value="
              ObjectComment?.commentaire
                ? ObjectComment.commentaire
                : ObjectComment?.commentaire_refus
            "
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-end">
          <v-btn text color="error" @click="dialogComment = false"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ouvrireDialogeSupprimerMulti" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de vouloir supprimer toutes les ventes sélectionnées
          ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="ouvrireDialogeSupprimerMulti = false"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="supprimerVenteMulti">Confirmer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ouvrireDialogeSupprimer" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de bien vouloir supprimer tout les ventes selectionné
          ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="fermerDialogeSupprimerVente"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="supprimerVente">Confirmer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAccepterVente" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >données pour le rapport de vente</v-card-title
        >
        <v-card-content>
          <v-form ref="acceptVente" lazy-validation>
            <v-row class="ml-2 mr-2">
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <v-text-field
                  outlined
                  type="number"
                  step="1"
                  v-model="rapportDeVente.consommation"
                  label="CAR"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <v-text-field
                  outlined
                  :rules="[rules.margeMaxMin, required('une durre valide')]"
                  type="number"
                  step="1"
                  v-model="rapportDeVente.marge"
                  label="Marge"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <v-text-field
                  type="date"
                  outlined
                  :rules="[required('une date de début du fourniture valide')]"
                  label="Début de Fourniture"
                  v-model="rapportDeVente.dateDF"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <v-text-field
                  outlined
                  :rules="[rules.value]"
                  type="number"
                  step="1"
                  v-model="rapportDeVente.duree"
                  label="durée (mois)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <v-text-field
                  outlined
                  type="number"
                  step="0.01"
                  v-model="rapportDeVente.pourcentage"
                  :rules="[rules.pourcentageMaxMin, required('une pourcentage valide')]"
                  label="Pourcentage (%)"
                  :min="0"
                  :max="100"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAccepterVente = false"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="accepterVente">Confirmer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAccepterVenteMany" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >données pour le rapport de vente</v-card-title
        >
        <v-card-content>
          <v-form ref="acceptVenteMulti" lazy-validation>
            <v-row class="ml-2 mr-2" v-for="(vente, idx) in dataMultiVente" :key="idx">
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  outlined
                  type="number"
                  step="1"
                  v-model="vente.consommation"
                  label="CAR"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  outlined
                  :rules="[rules.margeMaxMin, required('une durre valide')]"
                  type="number"
                  step="1"
                  v-model="vente.marge"
                  label="Marge"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                <v-text-field
                  type="date"
                  outlined
                  :rules="[required('une date de début du fourniture valide')]"
                  label="Début de Fourniture"
                  v-model="vente.dateDF"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  outlined
                  :rules="[rules.value]"
                  type="number"
                  step="1"
                  v-model="vente.duree"
                  label="durée (mois)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="2" xl="2">
                <v-text-field
                  outlined
                  type="number"
                  step="0.01"
                  v-model="vente.pourcentage"
                  :rules="[rules.pourcentageMaxMin, required('une pourcentage valide')]"
                  label="Pourcentage (%)"
                  :min="0"
                  :max="100"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-content>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAccepterVenteMany = false"
            >Annuler</v-btn
          >
          <v-btn color="blue darken-1" text @click="accepterVenteMulti">Confirmer</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ouvrireDialogeRefuser" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de bien vouloir refuser cette vente ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea
                  :rules="[rules.required('un commentaire.')]"
                  auto-grow
                  placeholder="Laissez un commentaire"
                  rows="2"
                  row-height="20"
                  prepend-inner-icon="mdi-comment"
                  v-model="commentRefuser"
                >
                </v-textarea>
              </v-col>
              <v-col class="d-flex justify-content-center" cols="12" sm="12">
                <v-btn color="blue darken-1" text @click="fermerDialogeRefuserVente"
                  >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="refuserVente">Confirmer</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ouvrireDialogeMultiRefuser" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de bien vouloir refuser cette vente ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-form ref="formRefusMulti" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea
                  :rules="[rules.required('un commentaire.')]"
                  auto-grow
                  placeholder="Laissez un commentaire"
                  rows="2"
                  row-height="20"
                  prepend-inner-icon="mdi-comment"
                  v-model="commentRefuser"
                >
                </v-textarea>
              </v-col>
              <v-col class="d-flex justify-content-center" cols="12" sm="12">
                <v-btn
                  color="blue darken-1"
                  text
                  @click="ouvrireDialogeMultiRefuser = false"
                  >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="refuserVenteMulti"
                  >Confirmer</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Controle des ventes pro </v-card-title>
        <v-card-text>
          <v-alert v-if="error == true && loading == false" type="error">
            {{ messageDialoge }}
          </v-alert>
          <v-alert v-if="error == false && loading == false" type="success">
            {{ messageDialoge }}
          </v-alert>
          <v-flex class="text-center">
            <v-progress-circular
              v-if="loading == true"
              :size="150"
              :width="7"
              color="primary"
              indeterminate
            >
              Patientez ...
            </v-progress-circular>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="loading == false"
            color="green darken-1"
            text
            @click="dialog = false"
          >
            FERMER
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <datatable
      id="table-mes-ventes-pro"
      class="ma-8 text-center"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
    >
      <template v-slot:body>
        <tbody
          v-if="projects.length > 0 && loadingTable == false"
          v-for="(project, index) in projects"
          :key="project.id"
        >
          <tr>
            <td>
              <div class="td-table">
                {{ project.date }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ project.Commercial }}
              </div>
            </td>
            <td>
              <div class="td-table">
                <v-tooltip v-if="project.Raison.length >= 20" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <small class="td-table" v-bind="attrs" v-on="on">
                      <router-link
                        :to="{
                          path: '/mes-profils-societes',
                          query: { id: project.idSociete },
                        }"
                        >{{ project.Raison.substring(0, 20) + "..." }}</router-link
                      >
                    </small>
                    <small class="Poppins-Bold">{{ project.siret }}</small>
                  </template>
                  <small>{{ project.Raison }}</small>
                </v-tooltip>
                <small class="td-table" v-else>
                  <router-link
                    :to="{
                      path: '/mes-profils-societes',
                      query: { id: project.idSociete },
                    }"
                    >{{ project.Raison }}</router-link
                  >
                  <small class="Poppins-Bold">{{ project.siret }}</small>
                </small>
              </div>
            </td>
            <td>
              <div v-if="project.type == 'ELEC'" class="td-table">
                <v-icon large color="orange" class="mr-2"
                  >mdi-lightning-bolt-circle</v-icon
                >
                <span class="Poppins-Bold">{{ project.type }}</span>
              </div>
              <div v-else class="td-table">
                <v-icon large color="blue darken-4" class="mr-2">mdi-fire-circle</v-icon>
                <span class="Poppins-Bold">{{ project.type }}</span>
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ project.fournisseur }}
              </div>
            </td>
            <td>
              <div style="max-width: 200px">
                <div
                  v-if="
                    project.ventes[0].Contrat != '' &&
                    project.ventes[0].Contrat != 'undefined' &&
                    project.ventes[0].Contrat != null
                  "
                >
                  <small>
                    <a :href="project.ventes[0].Contrat" target="_blank"
                      ><span class="text-body-4 blue--text">Contrat</span></a
                    >
                  </small>
                  <v-icon
                    v-if="
                      (project.ventes[0].RIB != '' &&
                        project.ventes[0].RIB != 'undefined' &&
                        project.ventes[0].RIB != null) ||
                      (project.ventes[0].Factures != '' &&
                        project.ventes[0].Factures != 'undefined' &&
                        project.ventes[0].Factures != null) ||
                      (project.ventes[0].SEPA != '' &&
                        project.ventes[0].SEPA != 'undefined' &&
                        project.ventes[0].SEPA != null) ||
                      (project.ventes[0].ACD != '' &&
                        project.ventes[0].ACD != 'undefined' &&
                        project.ventes[0].ACD != null) ||
                      (project.ventes[0].CGV != '' &&
                        project.ventes[0].CGV != 'undefined' &&
                        project.ventes[0].CGV != null) ||
                      (project.ventes[0].IBAN != '' &&
                        project.ventes[0].IBAN != 'undefined' &&
                        project.ventes[0].IBAN != null)
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseCompteur' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseCompteur' + index"
                    right
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div
                  v-else-if="
                    project.ventes[0].RIB != '' &&
                    project.ventes[0].RIB != 'undefined' &&
                    project.ventes[0].RIB != null
                  "
                >
                  <small>
                    <a :href="project.ventes[0].RIB" target="_blank"
                      ><span class="text-body-4 blue--text">RIB</span></a
                    >
                  </small>
                  <v-icon
                    v-if="
                      (project.ventes[0].Contrat != '' &&
                        project.ventes[0].Contrat != 'undefined' &&
                        project.ventes[0].Contrat != null) ||
                      (project.ventes[0].Factures != '' &&
                        project.ventes[0].Factures != 'undefined' &&
                        project.ventes[0].Factures != null) ||
                      (project.ventes[0].SEPA != '' &&
                        project.ventes[0].SEPA != 'undefined' &&
                        project.ventes[0].SEPA != null) ||
                      (project.ventes[0].ACD != '' &&
                        project.ventes[0].ACD != 'undefined' &&
                        project.ventes[0].ACD != null) ||
                      (project.ventes[0].CGV != '' &&
                        project.ventes[0].CGV != 'undefined' &&
                        project.ventes[0].CGV != null) ||
                      (project.ventes[0].IBAN != '' &&
                        project.ventes[0].IBAN != 'undefined' &&
                        project.ventes[0].IBAN != null)
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseCompteur' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseCompteur' + index"
                    right
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div
                  v-else-if="
                    project.ventes[0].SEPA != '' &&
                    project.ventes[0].SEPA != 'undefined' &&
                    project.ventes[0].SEPA != null
                  "
                >
                  <small>
                    <a :href="project.ventes[0].SEPA" target="_blank"
                      ><span class="text-body-4 blue--text">SEPA</span></a
                    >
                  </small>
                  <v-icon
                    v-if="
                      (project.ventes[0].Contrat != '' &&
                        project.ventes[0].Contrat != 'undefined' &&
                        project.ventes[0].Contrat != null) ||
                      (project.ventes[0].Factures != '' &&
                        project.ventes[0].Factures != 'undefined' &&
                        project.ventes[0].Factures != null) ||
                      (project.ventes[0].RIB != '' &&
                        project.ventes[0].RIB != 'undefined' &&
                        project.ventes[0].RIB != null) ||
                      (project.ventes[0].ACD != '' &&
                        project.ventes[0].ACD != 'undefined' &&
                        project.ventes[0].ACD != null) ||
                      (project.ventes[0].CGV != '' &&
                        project.ventes[0].CGV != 'undefined' &&
                        project.ventes[0].CGV != null) ||
                      (project.ventes[0].IBAN != '' &&
                        project.ventes[0].IBAN != 'undefined' &&
                        project.ventes[0].IBAN != null)
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseCompteur' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseCompteur' + index"
                    right
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div
                  class="collapse multi-collapse"
                  :id="'multiCollapseCompteur' + index"
                >
                  <div
                    v-if="
                      project.ventes[0].Contrat != '' &&
                      project.ventes[0].Contrat != 'undefined' &&
                      project.ventes[0].Contrat != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].Contrat" target="_blank"
                        ><span class="text-body-4 blue--text">Contrat</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].RIB != '' &&
                      project.ventes[0].RIB != 'undefined' &&
                      project.ventes[0].RIB != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].RIB" target="_blank"
                        ><span class="text-body-4 blue--text">RIB</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].Factures != '' &&
                      project.ventes[0].Factures != 'undefined' &&
                      project.ventes[0].Factures != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].Factures" target="_blank"
                        ><span class="text-body-4 blue--text">Facture</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].SEPA != '' &&
                      project.ventes[0].SEPA != 'undefined' &&
                      project.ventes[0].SEPA != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].SEPA" target="_blank"
                        ><span class="text-body-4 blue--text">SEPA</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].ACD != '' &&
                      project.ventes[0].ACD != 'undefined' &&
                      project.ventes[0].ACD != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].ACD" target="_blank"
                        ><span class="text-body-4 blue--text">ACD</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].CGV != '' &&
                      project.ventes[0].CGV != 'undefined' &&
                      project.ventes[0].CGV != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].CGV" target="_blank"
                        ><span class="text-body-4 blue--text">CGV</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].IBAN != '' &&
                      project.ventes[0].IBAN != 'undefined' &&
                      project.ventes[0].IBAN != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].IBAN" target="_blank"
                        ><span class="text-body-4 blue--text">IBAN</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].Annexe5Ekwateur != '' &&
                      project.ventes[0].Annexe5Ekwateur != 'undefined' &&
                      project.ventes[0].Annexe5Ekwateur != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].Annexe5Ekwateur" target="_blank"
                        ><span class="text-body-4 blue--text">Annexe 5</span></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.ventes[0].EXTRAITPAPPERS != '' &&
                      project.ventes[0].EXTRAITPAPPERS != 'undefined' &&
                      project.ventes[0].EXTRAITPAPPERS != null
                    "
                  >
                    <small>
                      <a :href="project.ventes[0].EXTRAITPAPPERS" target="_blank"
                        ><span class="text-body-4 blue--text">EXTRAIT PAPPERS</span></a
                      >
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <v-btn
                icon
                color="blue lighten-4"
                @click="openDialogComment(project)"
                v-if="project.commentaire?.length > 0"
              >
                <v-badge
                  color="blue lighten-4"
                  bordered
                  class="mr-4"
                  offset-x="15"
                  offset-y="15"
                  :content="'1'"
                >
                  <v-icon>mdi-comment-eye</v-icon>
                </v-badge>
              </v-btn>
              <v-badge
                color="red"
                bordered
                bottom
                class="mr-4"
                v-if="countStatus(project)[0] > 0"
                offset-x="10"
                offset-y="10"
                :content="'' + countStatus(project)[0]"
              >
                <v-icon dense style="color: red">mdi-close-circle</v-icon>
              </v-badge>
              <v-badge
                color="#06B24E"
                bordered
                bottom
                class="mr-4"
                v-if="countStatus(project)[1] > 0"
                offset-x="10"
                offset-y="10"
                :content="'' + countStatus(project)[1]"
              >
                <v-icon dense style="color: #06b24e">mdi-check-circle</v-icon>
              </v-badge>
              <v-badge
                color="grey"
                bordered
                bottom
                class="mr-4"
                v-if="countStatus(project)[2] > 0"
                offset-x="10"
                offset-y="10"
                :content="'' + countStatus(project)[2]"
              >
                <v-icon dense style="color: grey">mdi-timer-refresh-outline</v-icon>
              </v-badge>
              <v-badge
                color="blue"
                bordered
                bottom
                class="mr-4"
                v-if="countStatus(project)[3] > 0"
                offset-x="10"
                offset-y="10"
                :content="'' + countStatus(project)[3]"
              >
                <v-icon dense style="color: blue">mdi-send-check</v-icon>
              </v-badge>
            </td>
            <td class="text-center">
              <div class="d-flex justify-content-center align-items-center">
                <v-btn
                  style="position: sticky"
                  text
                  color="blue darken-4"
                  data-toggle="collapse"
                  :data-target="'#multiCollapseVente' + index"
                  aria-expanded="false"
                  :aria-controls="'multiCollapseVente' + index"
                >
                  <span>{{
                    project.selected.length == 1
                      ? "1 site"
                      : `${project.selected.length} sites`
                  }}</span>
                  <v-icon class="ml-2" size="17"> mdi-plus </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          <tr class="collapse multi-collapse" :id="'multiCollapseVente' + index">
            <td colspan="8">
              <table width="100%">
                <thead>
                  <tr>
                    <th style="text-align: center">Site</th>
                    <th style="text-align: center">Compteur</th>
                    <th style="text-align: center">Début de contrat</th>
                    <th style="text-align: center">Fin de contrat</th>
                    <th style="text-align: center">Commentaire de refus</th>
                    <th style="text-align: center">Valeur</th>
                    <th style="text-align: center">Statut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, ind) in project.ventes">
                    <td>
                      <v-tooltip v-if="v?.Site?.length >= 20" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <small class="td-table" v-bind="attrs" v-on="on">
                            {{ v?.Site?.substring(0, 20) + "..." }}
                          </small>
                        </template>
                        <span>{{ v?.Site }}</span>
                      </v-tooltip>
                      <small class="td-table" v-else>
                        {{ v?.Site }}
                      </small>
                    </td>
                    <td>
                      <div class="td-table">
                        {{ v.numCompteur }}
                      </div>
                    </td>
                    <td>
                      <div class="td-table">
                        {{ v.dateDebutFourniture }}
                      </div>
                    </td>
                    <td>
                      <div class="td-table">
                        {{ v.dateFinFourniture }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-row justify-center">
                        <v-btn
                          icon
                          color="red"
                          @click="openDialogComment(v)"
                          v-if="v.status == 'Refuser'"
                        >
                          <v-icon>mdi-comment-eye</v-icon>
                        </v-btn>
                        <div v-else><p>----</p></div>
                      </div>
                    </td>
                    <td>
                      <div class="td-valeur">
                        <span>
                          {{
                            Number.isNaN(v.valeurGEMC)
                              ? "0"
                              : Number(v.valeurGEMC).toLocaleString("fr-FR")
                          }}
                          €
                        </span>
                      </div>
                    </td>
                    <td>
                      <div
                        class="d-flex flex-column justify-content-center align-items-center btn-group"
                      >
                        <div
                          class="d-flex flex-row justify-content-center align-items-center"
                        >
                          <small class="d-flex" v-if="v.status == 'Refuser'"
                            ><v-icon dense style="color: red">mdi-close-circle</v-icon
                            ><b class="ml-2 Poppins-Bold">refusée</b></small
                          >
                          <small class="d-flex" v-if="v.status == 'Accepter'"
                            ><v-icon dense style="color: #00e676">mdi-check-circle</v-icon
                            ><b class="ml-2 Poppins-Bold">acceptée</b></small
                          >
                          <small
                            class="d-flex justify-content-end"
                            style="width: 8rem"
                            v-if="v.status == 'en Attend'"
                            ><v-icon dense style="color: gray"
                              >mdi-timer-refresh-outline</v-icon
                            ><b class="ml-2 Poppins-Bold">en attente</b></small
                          >
                          <small
                            class="d-flex justify-content-end"
                            style="width: 8rem"
                            v-if="v.status == 'en attente de validation'"
                            ><v-icon dense style="color: blue">mdi-send-check</v-icon
                            ><b class="ml-2 Poppins-Bold">en validation</b></small
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
        <noDataTable class="ma-4" v-if="projects.length == 0 && loadingTable == false" />
        <progressCircular class="ma-8" v-if="loadingTable == true" />
      </template>
    </datatable>
    <v-row>
      <v-col>
        <pagination
          v-if="projects.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import jQuery from "jquery";
import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/no-data-table.vue";
import progressCircular from "../../components/progress-circular.vue";
import axios from "axios";
export default {
  name: "mesVenteProMulti",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "13vw", label: "Date d'ajout", name: "date", sortable: true },
      { width: "13vw", label: "Commercial", name: "nom", sortable: true },
      { width: "13vw", label: "Raison Sociale", name: "raison", sortable: true },
      { width: "13vw", label: "Energie", name: "type", sortable: false },
      { width: "13vw", label: "Fournisseur", name: "fournisseur", sortable: false },
      { width: "13vw", label: "Fichiers", name: "Contrat", sortable: true },
      { width: "13vw", label: "Commentaire", name: "commentaire", sortable: false },
      { width: "13vw", label: "Multi-site", name: "status", sortable: false },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      dialogComment: false,
      ObjectComment: null,
      items: [
        { state: "Veuillez sélectionner une action", abbr: "" },
        { state: "Supprimer Tous", abbr: "supprimer" },
        { state: "Accepter tous", abbr: "accepter" },
        { state: "Envoyer pour validation tous", abbr: "validation" },
        { state: "Refuser tous", abbr: "refuse" },
      ],
      ouvrireDialogeSupprimerMulti: false,
      ouvrireDialogeMultiRefuser: false,
      dataMultiVente: [],
      loadingTable: false,
      dialogAccepterVenteMany: false,
      dialogAccepterVente: false,
      rapportDeVente: {
        idVente: "",
        daetDF: "",
        duree: "",
        consommation: "",
        marge: "",
        pourcentage: "",
      },
      dialog: false,
      loading: false,
      error: false,
      commentRefuser: "",
      messageDialoge: "",
      ouvrireDialogeRefuser: false,
      ouvrireDialogeSupprimer: false,
      projects: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "15", "30"],
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 0,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      TryParseInt(str, defaultValue) {
        var retValue = defaultValue;
        if (str !== null) {
          if (str.length > 0) {
            if (!isNaN(str)) {
              retValue = parseInt(str);
            }
          }
        }
        return retValue;
      },
      required(fieldName) {
        return (value) =>
          ("" + value).length > 0 ||
          (("" + value).length > 0 && value >= 0) ||
          `Veuillez saisir ${fieldName}`;
      },
      rules: {
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",
        pourcentageMaxMin: (v) =>
          (v <= 100 && v >= 0) || "La pourcentage doit être comprise entre 0 et 100",
        required(finPhrase) {
          return (value) => value != "" || value >= 0 || `Veuillez saisir ${finPhrase}`;
        },
      },
    };
  },
  async created() {
    this.loadingTable = true;
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    await this.getData();
    this.loadingTable = false;
  },
  methods: {
    countStatus(item) {
      var nbrEnAttend = 0;
      var nbrAccepter = 0;
      var nbrRefuser = 0;
      var nbrEnValidation = 0;
      for (var i = 0; i < item.ventes.length; i++) {
        if (item.ventes[i].status == "Refuser") {
          nbrRefuser = nbrRefuser + 1;
        } else if (item.ventes[i].status == "en Attend") {
          nbrEnAttend = nbrEnAttend + 1;
        } else if (item.ventes[i].status == "en attente de validation") {
          nbrEnValidation = nbrEnValidation + 1;
        } else if (item.ventes[i].status == "Accepter") {
          nbrAccepter = nbrAccepter + 1;
        }
      }
      return [nbrRefuser, nbrAccepter, nbrEnAttend, nbrEnValidation];
    },
    openDialogComment(item) {
      console.log(item);
      (this.dialogComment = true), (this.ObjectComment = item);
    },
    changeSelect(project) {
      if (project.multiAction != "") {
        if (project.multiAction == "accepter") {
          this.openAccepterVenteModalMany(project.ventes, project.selected);
        } else if (project.multiAction == "validation") {
          this.envoyerPourValiderMultiVentePro(project.ventes, project.selected);
        } else if (project.multiAction == "refuse") {
          this.ouvrireDialogeRefuserMultiVente(project.ventes, project.selected);
        } else if (project.multiAction == "supprimer") {
          this.ouvrireDialogeSupprimerMultiVente(project.ventes, project.selected);
        }
      }
    },
    async envoyerNotification(message) {
      var formData = new FormData();
      formData.append("msg", message);
      axios({
        url: "https://app.volto.fr/slackBotSendMessage/",
        method: "POST",
        data: formData,
      }).then((res) => {});
    },
    tryUpdateValue(id) {
      $("#buttonTryEdit" + id).css("display", "none");
      $("#buttonAnnulerEdit" + id).css("display", "inline-block");
      $("#buttonValideEdit" + id).css("display", "inline-block");

      $("#Value" + id).css("display", "none");
      $("#inputValue" + id).css("display", "inline-block");
    },
    annulerUpdateValue(id) {
      $("#buttonTryEdit" + id).css("display", "inline-block");
      $("#buttonAnnulerEdit" + id).css("display", "none");
      $("#buttonValideEdit" + id).css("display", "none");

      $("#Value" + id).css("display", "inline-block");
      $("#inputValue" + id).css("display", "none");
    },
    modifierValue(id) {
      var val = $("#inputValue" + id).val();

      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("val", val);
      formData.append("idVente", id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "updateValeurVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            alert("la valeur de cette vente a été mise à jour");
            this.getData(this.pagination.currentPage);
            this.annulerUpdateValue(id);
          } else if (res.data.result == false) {
            alert("vérifiez que votre valeur soit valide");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          alert("permission refusée");
        });
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      var pageNumber = 0;
      this.tableData.search = key;
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getMesVentesProServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    dateFormatSlach(date) {
      return date.split("-").reverse().join("/");
    },
    dateFormatMinus(date) {
      return date.split("/").reverse().join("-");
    },
    openAccepterVenteModalMany(ventes, selected) {
      this.dialogAccepterVenteMany = true;
      this.dataMultiVente = [];
      for (var i = 0; i < ventes.length; i++) {
        if (selected.indexOf(ventes[i].id) != -1) {
          var d = {
            vente: ventes[i],
            idVente: ventes[i].id,
            dateDF: this.dateFormatMinus(ventes[i].dateDebutFourniture),
            duree: ventes[i].duree,
            consommation: ventes[i].consommation,
            marge: ventes[i].marge,
            pourcentage: ventes[i].pourcentage,
          };
          this.dataMultiVente.push(d);
        }
      }
    },
    openAccepterVenteModal(item) {
      this.dialogAccepterVente = true;
      this.rapportDeVente.item = item;
      this.rapportDeVente.idVente = item.id;
      this.rapportDeVente.dateDF = this.dateFormatMinus(item.dateDebutFourniture);
      this.rapportDeVente.duree = item.duree;
      this.rapportDeVente.consommation = item.consommation;
      this.rapportDeVente.marge = item.marge;
      this.rapportDeVente.pourcentage = item.pourcentage;
    },
    accepterVenteMulti() {
      this.$refs.acceptVenteMulti.validate();
      if (this.$refs.acceptVenteMulti.validate()) {
        this.dialogAccepterVenteMany = false;
        var item = this.dataMultiVente[0]["vente"];
        //open modal loading
        this.dialog = true;
        this.loading = true;
        this.error = false;
        this.messageDialoge = "";
        let self = this;
        var formData = new FormData();
        formData.append("lesVente", JSON.stringify(this.dataMultiVente));
        formData.append("token", this.getToken("token"));
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "accepterMultiVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              this.getData(this.pagination.currentPage);
              var message =
                "----------------------------------------\nL'administrateur " +
                localStorage.getItem("email") +
                " a validé la vente professionnelle" +
                "\n Raison Sociale: " +
                item.Raison +
                "\n vendeur: " +
                item.Commercial +
                "\n----------------------------------------";
              //this.envoyerNotification(message)
              self.loading = false;
              self.error = false;
              self.messageDialoge =
                "Vente acceptée ! Un email vient d'être envoyé au vendeur";
            } else if (res.data.result == false) {
              self.loading = false;
              self.error = true;
              self.messageDialoge = "La vente n'existe plus";
            } else if (res.data.result == "permission") {
              self.loading = false;
              self.error = true;
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            console.log(err);
            self.dialog = false;
            self.loading = false;
            self.error = true;
          });
      }
    },
    accepterVente() {
      this.$refs.acceptVente.validate();
      if (this.$refs.acceptVente.validate()) {
        this.dialogAccepterVente = false;
        var item = this.rapportDeVente.item;
        //open modal loading
        this.dialog = true;
        this.loading = true;
        this.error = false;
        this.messageDialoge = "";
        let self = this;
        var formData = new FormData();
        formData.append("id", this.rapportDeVente.idVente);
        formData.append("dateDF", this.dateFormatSlach(this.rapportDeVente.dateDF));
        formData.append("duree", this.rapportDeVente.duree);
        formData.append("consommation", this.rapportDeVente.consommation);
        formData.append("marge", this.rapportDeVente.marge);
        formData.append("pourcentage", this.rapportDeVente.pourcentage);
        formData.append("token", this.getToken("token"));
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "accepterUneVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              this.getData(this.pagination.currentPage);

              var message =
                "----------------------------------------\nL'administrateur " +
                localStorage.getItem("email") +
                " a validé la vente professionnelle \n au compteur numéro :" +
                item.numCompteur +
                "\n Raison Sociale: " +
                item.Raison +
                "\n vendeur: " +
                item.Commercial +
                "\n----------------------------------------";
              //this.envoyerNotification(message)
              self.loading = false;
              self.error = false;
              self.messageDialoge =
                "Vente acceptée ! Un email vient d'être envoyé au vendeur";
            } else if (res.data.result == false) {
              self.loading = false;
              self.error = true;
              self.messageDialoge = "La vente n'existe plus";
            } else if (res.data.result == "permission") {
              self.loading = false;
              self.error = true;
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            console.log(err);
            self.dialog = false;
            self.loading = false;
            self.error = true;
          });
      }
    },
    envoyerPourValiderMultiVentePro(ventes, selected) {
      this.dataMultiVente = [];
      for (var i = 0; i < ventes.length; i++) {
        if (selected.indexOf(ventes[i].id) != -1) {
          var d = {
            vente: ventes[i],
            idVente: ventes[i].id,
            dateDF: this.dateFormatMinus(ventes[i].dateDebutFourniture),
            duree: ventes[i].duree,
            consommation: ventes[i].consommation,
            marge: ventes[i].marge,
            pourcentage: ventes[i].pourcentage,
          };
          this.dataMultiVente.push(d);
        }
      }
      var item = this.dataMultiVente[0]["vente"];
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";
      let self = this;
      var formData = new FormData();
      formData.append("lesVente", JSON.stringify(this.dataMultiVente));
      formData.append("token", this.getToken("token"));
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "envoyerPourValiderMultiVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getData(this.pagination.currentPage);

            var message =
              "----------------------------------------\nL'administrateur " +
              localStorage.getItem("email") +
              " a changé le statut de la vente professionnelle en <<Phase de validation>>\n Fournisseur: " +
              item.fournisseur +
              "\n Raison Sociale: " +
              item.Raison +
              "\n vendeur: " +
              item.Commercial +
              "\n----------------------------------------";
            //this.envoyerNotification(message)
            self.loading = false;
            self.error = false;
            self.messageDialoge = "Un email vient d'être envoyé au vendeur";
          } else if (res.data.result == false) {
            self.loading = false;
            self.error = true;
            self.messageDialoge = "La vente n'existe plus";
          } else if (res.data.result == "permission") {
            self.loading = false;
            self.error = true;
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          self.dialog = false;
          self.loading = false;
          self.error = true;
        });
    },
    envoyerPourValider(item) {
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";
      let self = this;
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("id", item.id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "envoyerPourValiderUneVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getData(this.pagination.currentPage);
            var message =
              "----------------------------------------\nL'administrateur " +
              localStorage.getItem("email") +
              " a changé le statut de la vente professionnelle en <<Phase de validation>>\n Fournisseur: " +
              item.fournisseur +
              "\n N°Compteur :" +
              item.numCompteur +
              "\n Raison Sociale: " +
              item.Raison +
              "\n vendeur: " +
              item.Commercial +
              "\n----------------------------------------";
            //this.envoyerNotification(message)
            self.loading = false;
            self.error = false;
            self.messageDialoge = "Un email vient d'être envoyé au vendeur";
          } else if (res.data.result == false) {
            self.loading = false;
            self.error = true;
            self.messageDialoge = "La vente n'existe pas";
          } else if (res.data.result == "permission") {
            self.loading = false;
            self.error = true;
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          self.dialog = false;
          self.loading = false;
          self.error = true;
        });
    },
    ouvrireDialogeRefuserMultiVente(ventes, selected) {
      this.ouvrireDialogeMultiRefuser = true;
      this.dataMultiVente = [];
      for (var i = 0; i < ventes.length; i++) {
        if (selected.indexOf(ventes[i].id) != -1) {
          var d = {
            vente: ventes[i],
            idVente: ventes[i].id,
            dateDF: this.dateFormatMinus(ventes[i].dateDebutFourniture),
            duree: ventes[i].duree,
            consommation: ventes[i].consommation,
            marge: ventes[i].marge,
            pourcentage: ventes[i].pourcentage,
          };
          this.dataMultiVente.push(d);
        }
      }
    },
    ouvrireDialogeRefuserVente(item) {
      this.VenteRefuser = item;
      this.ouvrireDialogeRefuser = true;
    },
    fermerDialogeRefuserVente() {
      this.VenteRefuser = "";
      this.ouvrireDialogeRefuser = false;
    },
    ouvrireDialogeSupprimerVente(item) {
      this.VenteSupprimer = item;
      this.ouvrireDialogeSupprimer = true;
    },
    fermerDialogeSupprimerVente() {
      this.VenteSupprimer = "";
      this.ouvrireDialogeSupprimer = false;
    },
    refuserVente() {
      if (!this.$refs.form.validate()) return;
      //fermer modal comment
      this.ouvrireDialogeRefuser = false;
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";

      let self = this;
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("comment", this.commentRefuser);
      formData.append("id", this.VenteRefuser.id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "refuserUneVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            var message =
              "----------------------------------------\nL'administrateur " +
              localStorage.getItem("email") +
              " a refusé la vente professionnelle \n au compteur numéro :" +
              this.VenteRefuser.numCompteur +
              "\n raison Sociale: " +
              this.VenteRefuser.Raison +
              "\n vendeur: " +
              this.VenteRefuser.Commercial +
              "\n Commentaire: " +
              self.commentRefuser +
              "\n----------------------------------------";
            //this.envoyerNotification(message)
            this.getData(this.pagination.currentPage);
            self.loading = false;
            self.error = false;
            self.commentRefuser = "";
            this.messageDialoge =
              "Vente refusée ! Un email vient d'être envoyé au vendeur";
          } else if (res.data.result == false) {
            self.loading = false;
            self.error = false;
            self.messageDialoge = "La vente n'existe pas";
          } else if (res.data.result == "permission") {
            self.loading = false;
            self.error = false;
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          self.dialog = false;
          self.loading = false;
          self.error = true;
        });
    },
    refuserVenteMulti() {
      this.$refs.formRefusMulti.validate();
      if (this.$refs.formRefusMulti.validate()) {
        this.ouvrireDialogeMultiRefuser = false;
        var item = this.dataMultiVente[0]["vente"];
        //open modal loading
        this.dialog = true;
        this.loading = true;
        this.error = false;
        this.messageDialoge = "";
        let self = this;
        var formData = new FormData();
        formData.append("lesVente", JSON.stringify(this.dataMultiVente));
        formData.append("comment", self.commentRefuser);
        formData.append("token", this.getToken("token"));
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "refuserMultiVentePro/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              var message =
                "----------------------------------------\nL'administrateur " +
                localStorage.getItem("email") +
                " a refusé la vente professionnelle " +
                "\n raison Sociale: " +
                item.Raison +
                "\n vendeur: " +
                item.Commercial +
                "\n Commentaire: " +
                self.commentRefuser +
                "\n----------------------------------------";
              //this.envoyerNotification(message)
              this.getData(this.pagination.currentPage);
              self.loading = false;
              self.error = false;
              self.commentRefuser = "";
              this.messageDialoge =
                "Vente refusée ! Un email vient d'être envoyé au vendeur";
            } else if (res.data.result == false) {
              self.loading = false;
              self.error = true;
              self.messageDialoge = "La vente n'existe plus";
            } else if (res.data.result == "permission") {
              self.loading = false;
              self.error = true;
              alert("permission refusée");
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            console.log(err);
            self.dialog = false;
            self.loading = false;
            self.error = true;
          });
      }
    },
    supprimerVente() {
      this.ouvrireDialogeSupprimer = false;
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";
      let self = this;
      var formData = new FormData();

      formData.append("token", this.getToken("token"));
      formData.append("id", this.VenteSupprimer.id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "supprimerUneVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            var message =
              "----------------------------------------\nL'administrateur " +
              localStorage.getItem("email") +
              " a supprimé la vente professionnnelle \n au compteur numéro :" +
              this.VenteSupprimer.numCompteur +
              "\n raison sociale: " +
              this.VenteSupprimer.Raison +
              "\n vendeur: " +
              this.VenteSupprimer.Commercial +
              "\n----------------------------------------";
            //this.envoyerNotification(message)
            this.getData(this.pagination.currentPage);
            self.loading = false;
            self.error = false;
            self.VenteSupprimer = "";
            self.messageDialoge =
              "Vente supprimée ! Un email vient d'être envoyé au vendeur";
          } else if (res.data.result == false) {
            self.loading = false;
            self.error = false;
            self.messageDialoge = "La vente n'existe pas";
          } else if (res.data.result == "permission") {
            self.loading = false;
            self.error = false;
            self.messageDialoge = "permission refusée";
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          self.dialog = false;
          self.loading = false;
          self.error = true;
        });
    },
    ouvrireDialogeSupprimerMultiVente(ventes, selected) {
      this.ouvrireDialogeSupprimerMulti = true;
      this.dataMultiVente = [];
      for (var i = 0; i < ventes.length; i++) {
        if (selected.indexOf(ventes[i].id) != -1) {
          var d = {
            vente: ventes[i],
            idVente: ventes[i].id,
            dateDF: this.dateFormatMinus(ventes[i].dateDebutFourniture),
            duree: ventes[i].duree,
            consommation: ventes[i].consommation,
            marge: ventes[i].marge,
            pourcentage: ventes[i].pourcentage,
          };
          this.dataMultiVente.push(d);
        }
      }
    },
    supprimerVenteMulti() {
      this.ouvrireDialogeSupprimerMulti = false;
      var item = this.dataMultiVente[0]["vente"];
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";
      let self = this;
      var formData = new FormData();
      formData.append("lesVente", JSON.stringify(this.dataMultiVente));
      formData.append("token", this.getToken("token"));
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "supprimerMultiVentePro/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            var message =
              "----------------------------------------\nL'administrateur " +
              localStorage.getItem("email") +
              " a supprimé des ventes professionnnelle " +
              "\n raison sociale: " +
              item.Raison +
              "\n vendeur: " +
              item.Commercial +
              "\n----------------------------------------";
            //this.envoyerNotification(message)
            this.getData(this.pagination.currentPage);
            self.loading = false;
            self.error = false;
            self.commentRefuser = "";
            self.messageDialoge =
              "Vente supprimée ! Un email vient d'être envoyé au vendeur";
          } else if (res.data.result == false) {
            self.loading = false;
            self.error = true;
            self.messageDialoge = "La vente n'existe plus";
          } else if (res.data.result == "permission") {
            self.loading = false;
            self.error = true;
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          self.dialog = false;
          self.loading = false;
          self.error = true;
        });
    },
    async getData(pageNumber = 0) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getMesVentesProServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          console.log(response.data);
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style>
#table-mes-ventes-pro th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
</style>
