<style scoped>
    .width-text-field {
        width: 30%
    }

    .width-button-responsive {
        width: 25%
    }

    @media only screen and (max-width:500px) {
        .width-text-field {
            width: 90%
        }

        .width-button-responsive {
            width: 75%,
        }
    }
</style>
<template>
    <div class="ma-3">
        <!-- la form pricipale -->
        <v-card elevation="5">
            <v-toolbar :color="$colors[0]" dark>
                <span class="Poppins-Bold pl-0">Autorisation de Collecte de Données</span>
            </v-toolbar>
            <v-row>
                <div>
                    <loadingComponent :dialogProps="dialog" :messageDialogeProps="messageDialoge"
                        :loadingProps='loading' :errorProps="error" @closeDialog="dialog = $event" />
                </div>
            </v-row>
            <div style='d-flex' class="ma-0">
                <v-col class="pa-0" cols="12" sm="10" md="10" lg="10" xl="12">
                    <span class="Poppins-Bold ml-4">Informations Entreprise :</span>
                    <v-form class="ma-4 pt-2" ref="formUpload" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense :rules="[rules.required]" v-model="societe.siret"
                                    label="Raison sociale">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense :rules="[rules.required]" v-model="societe.Raison"
                                    label="Raison sociale">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense
                                    :rules="[rules.required, rules.isString]" v-model="societe.nom"
                                    label="Nom">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense
                                    :rules="[rules.required, rules.isString]" v-model="societe.prenom"
                                    label="Prénom">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense
                                    :rules="[rules.required, rules.isString]" v-model="societe.fonction"
                                    label="Poste">
                                </v-text-field>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-autocomplete
                                    dense
                                    v-model="listCompteurSelected"
                                    :items="listCompteur"
                                    hide-no-data
                                    :rules="[rules.required]"
                                    label="Compteur(s)"
                                    placeholder="Ajouter un compteur"
                                    prepend-icon="mdi-account-search-outline"
                                    chips
                                    item-text="numCompteur"
                                    deletable-chips
                                    multiple
                                    return-object
                                  >
                                    <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index === 0">
                                        <span>{{ item.numCompteur }}</span>
                                    </v-chip>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ listCompteurSelected.length - 1 }}
                                        {{
                                        listCompteurSelected.length - 1 == 1
                                            ? "compteur"
                                            : "compteurs"
                                        }})
                                    </span>
                                    </template>
                                    <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                        <v-list-item-title>{{
                                            data.item.numCompteur
                                        }}</v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            data.item.typeDemande
                                        }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    </template>
                                    <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                        <v-list-item-content>
                                        <v-list-item-title
                                            >Séléctionner tous les compteurs</v-list-item-title
                                        >
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div class="flex-col align-items-center justify-start pa-4">
                        <v-btn style="width-button-responsive"
                            @click="generatePdf" depressed elevation="1" :color='$colors[0]' class="text-white">
                            Examiner le document
                        </v-btn>
                    </div>
                </v-col>
            </div>
        </v-card>

        <!-- entete du pdf -->
        <div style="position:relative; left:-400%; width:600px;">
            <p id="para" style="margin-left:50px; margin-top:105px;font-size:10px; max-width: 500px;">
                Je soussigné {{societe.nom }} {{ societe.prenom }} autorise gaz-elec-moinscher.fr a
                acceder aux données mentionnées ci-dessous, pour la société
                {{ societe.Raison }} &nbsp;au siren {{ societe.siret }} et
                pour le/les point(s) de mesure suivant:
            </p>
        </div>
    </div>
    </div>
</template>

<script>
import loadingComponent from "../../../../components/ui/loading-component.vue"
import axios from 'axios'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'


import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText } from 'pdf-lib'
import download from 'downloadjs'
import 'jspdf-autotable'

export default {
    name: 'acdPowerSmart',
    props: {listCompteur:[], societe: "", typeFournisseur:""},
    components: {
        loadingComponent
    },
    data() {
        return {
            listCompteurSelected: [],
            isPowerConseil: "",
            isSmartElectricite: "",
            hasGeneratePDF: false,
            search: "",
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "L'e-mail a été envoyé avec succès",
            //enedis data
            enedisRecherchePointResult: null,
            missedCompteur: null,
            //radio button data for add compteur
            num_comp_gaz: [],
            num_comp_elec: [],
            //data for test exist siret and siren
            siretFound: null,
            siretExist: [],
            //insee response data
            inseeResponse: null,
            //data for pdf 
            regie: false,
            traitemantEnedisRegie: 'non',
            //form data for upload file
            formData: null,
            //l'etat deximination de fichier
            examine_file: true,
            isSend: 0,
            //les rules du formulaire
            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
                isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
                sirenLength: v => !!v == 0 || v?.length == 9 || 'Le siren doit comporter 9 chiffres',
                siretLength: v => !!v == 0 || v?.length == 14 || v?.length == 9 || 'Ce champ doit contenir soit 9 chiffres, soit 14 chiffres.',
                isNumber: v => v?.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre',
                compteurElecLength: v => v?.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                isGazCompteur: v => (v?.match(/^(GI)[0-9]{6}$/) || (v?.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                phoneLength: v => v?.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                phoneisNumber: v => v?.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
                margeMaxMin: v => (v <= 10 && v >= 2) || 'La marge doit être comprise entre 2 et 10',

                emailRules: v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
            },
            verifyLength() {
                return this.siret?.length == 14 || 'Le siret doit comporter 14 chiffres.'
            },
            siretAcceptNumber() {
                return this.siret.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre'
            },
            siretInccorect: null,
        }
    },
    created() {
        this.typeUser = localStorage.getItem('typeUser')
    },
    watch: {
        async typeFournisseur(newVal){
            this.listCompteurSelected = []
            this.num_comp_elec = []
            this.num_comp_gaz = []
        }
    },
    methods: {
        
        toggle() {
            this.num_comp_elec = [];
            this.num_comp_gaz = [];
            this.listCompteurSelected = [...this.listCompteur];
            /*for (let i = 0; i < this.selectedCompteur.length; i++) {
                if (this.selectedCompteur[i].typeDemande == "ELEC") {
                this.selectedCompteursELEC.push(this.selectedCompteur[i]);
                } else {
                this.selectedCompteursGAZ.push(this.selectedCompteur[i]);
                }
            }*/
        },
        verifPageEnd(pageHeight, doc, currentPage, currentY) {

            if (currentY < 50) {
                var page = doc.addPage()
                var startingPositon = 750
            } else {
                var page = currentPage
                var startingPositon = currentY
            }
            return { page, startingPositon };
        },
        //la fonction appelle l'une des fonctions précédentes qui génèrent le type de leur pdf sellon
        
        async generatePdf() {
            this.$refs.formUpload.validate()
            if (this.$refs.formUpload.validate()) {
                let self = this
                self.dialog = true
                self.loading = true
                self.error = false
                for(var i=0; i<this.listCompteurSelected.length; i++){
                    if(this.listCompteurSelected[i]["typeDemande"] == "ELEC"){
                        this.num_comp_elec.push(this.listCompteurSelected[i]["numCompteur"])              
                    }else{
                        this.num_comp_gaz.push(this.listCompteurSelected[i]["numCompteur"])         
                    }
                }
                if (this.typeFournisseur.text=== 'ACD Smart Electricité') {
                    if(this.num_comp_elec.length>0){
                        await this.generateSmartACDELEC()
                    }
                    if(this.num_comp_gaz.length>0){
                        await this.generateSmartACDGAZ()
                    }
                }
                if (this.typeFournisseur.text=== 'ACD Power Conseils') {
                    if(this.num_comp_elec.length>0){
                        await this.generatePowerConseACD('ELEC')
                    }
                    if(this.num_comp_gaz.length>0){
                        await this.generatePowerConseACD('GAZ')
                    }
                }
                if (this.typeFournisseur.text=== 'ACD GEMC') {
                    if(this.num_comp_elec.length>0){
                        await this.generateACDGAZ('ELEC')
                    }
                    if(this.num_comp_gaz.length>0){
                        await this.generateACDGAZ('GAZ')
                    }
                }
            }
            self.loading = false
            self.dialog = false
        },
        async generateSmartACDELEC() {
            var raison = this.societe.Raison
            var nom = this.societe.nom
            var prenom = this.societe.prenom
            var email = this.societe.AdresseEmail
            var fonction = this.societe.fonction
            var address = this.societe.AdressePostal
            var siret = this.societe.siret
            var url0 = '/pdf/acd/acdSmart.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingPositon = 725
            firstPage.drawText("Je soussigné(e) " + nom + " " + prenom, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("en ma qualité de " + fonction, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            var lineRaison = "dûment habilité(e) à représenter la société " + raison
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 25
            firstPage.drawText("immatriculé(e) au RCS de " + this.RCS, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :" + siret, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à" +
                " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            startingPositon = startingPositon - 25
            for (let i = 0; i < multiText.lines.length; i++) {
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 30
            firstPage.drawText('N° PDL(s)', {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            console.log(this.num_comp_elec)
            startingPositon = startingPositon - 20
            for (var i = 0; i < this.num_comp_elec.length; i++) {
                firstPage.drawText('-' + this.num_comp_elec[i], {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 15
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }
            startingPositon = startingPositon - 25
            lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie " +
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("le " + DateAujourdhui + " Signature + Tampon : ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 50
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("SMART-ELECTRICITÉ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("(06.27.79.34.93)", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            /*firstPage.drawText("Cette autorisation reproduit celle du partenaire", {
                x: 100,
                y: startingPositon,
                size: 6,
                color: rgb(0, 0, 0),
            })*/
            //fin contact
            
            const pdfBytes = await pageZeroDoc.save()
            var file = download(pdfBytes, 'ACD_smart_ELEC_' + raison + '_.pdf', 'application/pdf')
            //create form data
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generateSmartACDGAZ() {

            var raison = this.societe.Raison
            var nom = this.societe.nom
            var prenom = this.societe.prenom
            var email = this.societe.AdresseEmail
            var fonction = this.societe.fonction
            var address = this.societe.AdressePostal
            var siret = this.societe.siret
            var url0 = '/pdf/acd/acdSmart.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingPositon = 725
            firstPage.drawText("Je soussignée " + nom + " " + prenom, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("en ma qualité de " + fonction, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            var lineRaison = "dûment habilité(e) à représenter la société " + raison
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 25
            firstPage.drawText("immatriculé(e) au RCS de " + this.RCS, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :" + siret, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à" +
                " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            startingPositon = startingPositon - 25
            for (let i = 0; i < multiText.lines.length; i++) {
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 30
            firstPage.drawText('N° PCE(s)', {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 20
            for (var i = 0; i < this.num_comp_gaz.length; i++) {
                firstPage.drawText('-' + this.num_comp_gaz[i], {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 15
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }
            startingPositon = startingPositon - 25
            lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie " +
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("le " + DateAujourdhui + " Signature + Tampon : ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 50
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("SMART-ELECTRICITÉ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("(06.27.79.34.93)", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon

            //fin contact
            const pdfBytes = await pageZeroDoc.save()
            download(pdfBytes, 'ACD_smart_GAZ_' + raison + '_.pdf', 'application/pdf')
            //create form data
            this.isSend = 1
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generatePowerConseACD(Energie) {
            var raison = this.societe.Raison
            var nom = this.societe.nom
            var prenom = this.societe.prenom
            var email = this.societe.AdresseEmail
            var fonction = this.societe.fonction
            var address = this.societe.AdressePostal
            var siret = this.societe.siret

            var url0 = '/pdf/acd/powerConseille.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingXPositon = 70;
            var startingPositon = pageHeight - 225
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            const fontSize = 12;
            var lineRaison = "Je soussigné "
                + nom
                + " "
                + prenom
                + " dûment habilité à représenter la société "
                + raison
                + ", dont le siège social se situe au "
                + address +
                ", immatriculée sous le numéro SIRET"
                + siret
                + ", donne l’autorisation à la société gestionnaires de réseaux d’électricité et / ou de gaz aux données techniques (courbe de charge,consommations et profils, capacité journalière en gaz, puissance en électricité et tarif d’acheminement sur les 36 derniers mois), associées aux points de livraison ci-après, dans le but de préparer une offre de fourniture d’électricité et / ou de gaz :"
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: fontSize,
                font: timesRomanFont,
                lineHeight: 28,
                bounds: { width: width - (startingXPositon*2), height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: startingXPositon,
                    y: startingPositon,
                    size: fontSize,
                    maxWidth: width - (startingXPositon*2),
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 28
            }

            var xPDLs = 100;
            var xPCEs = 250;
            // ELEC PDLs
            startingPositon = startingPositon - 10
            if (Energie == 'ELEC' || Energie == 'DUAL') {
                firstPage.drawText('N° PDL(s)', {
                    x: xPDLs,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })  
            } else {
                xPCEs = xPDLs
            }
            if (Energie == 'GAZ' || Energie == 'DUAL') {
                firstPage.drawText('N° PCE(s)', {
                    x: xPCEs,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
            }
            startingPositon = startingPositon - 25
            var lengthCompteur = 0
            if (this.num_comp_gaz.length > this.num_comp_elec.length) {
                lengthCompteur = this.num_comp_gaz.length
            } else {
                lengthCompteur = this.num_comp_elec.length
            }
            for (var i = 0; i < lengthCompteur; i++) {
                if (i < this.num_comp_gaz.length && (Energie == 'GAZ' || Energie == 'DUAL')) {
                    firstPage.drawText('-' + this.num_comp_gaz[i], {
                        x: xPCEs,
                        y: startingPositon,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    })
                }
                if (i < this.num_comp_elec.length && (Energie == 'ELEC' || Energie == 'DUAL')) {
                    firstPage.drawText('-' + this.num_comp_elec[i], {
                        x: xPDLs,
                        y: startingPositon,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    })
                }
                startingPositon = startingPositon - 25
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }

            startingPositon = startingPositon - 25
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation reste valable pour une durée de 12 mois à compter de la date de signature.", {
                x: startingXPositon,
                y: startingPositon,
                size: fontSize,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("Fait à " + "_________________ " + " le " + DateAujourdhui, {
                x: startingXPositon,
                y: startingPositon,
                size: fontSize,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("Signature :", {
                x: startingXPositon,
                y: startingPositon,
                size: fontSize,
                color: rgb(0, 0, 0),
            })
          
            //fin contact
            const pdfBytes = await pageZeroDoc.save()
            if(Energie == "ELEC"){
                var file = download(pdfBytes, 'ACD_Power_ELEC_' + raison + '_.pdf', 'application/pdf')
            }else{
                var file = download(pdfBytes, 'ACD_Power_GAZ_' + raison + '_.pdf', 'application/pdf')
            }
            
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generateACDGAZ(Energie) {
            var raison = this.societe.Raison
            var nom = this.societe.nom
            var prenom = this.societe.prenom
            var email = this.societe.AdresseEmail
            var fonction = this.societe.fonction
            var address = this.societe.AdressePostal
            var siret = this.societe.siret
            let self = this
            var tableDoc = new jsPDF('p', 'pt', 'a4')
            if (Energie == "GAZ") {

                var tableRows = [["NOM, Prénom", nom + " " + prenom], ["Société", raison], ["N° SIREN", siret.substring(0, 9)],
                ["Point de Mesure(GAZ)", self.num_comp_gaz]]
            } else if (Energie == "ELEC") {

                var tableRows = [["NOM, Prénom", nom + " " + prenom], ["Société", raison], ["N° SIREN", siret.substring(0, 9)],
                ["Point de Mesure(ELEC)", self.num_comp_elec + ""]]
            } else if (Energie == "DUAL") {
                var tableRows = [["NOM, Prénom", nom + " " + prenom], ["Société", raison], ["N° SIREN", siret.substring(0, 9)],
                ["Point de Mesure(ELEC)", self.num_comp_elec + ""], ["Point de Mesure(GAZ)", self.num_comp_gaz]]

            }
            var columnStyle = {
                0: { halign: 'center', valign: 'middle', fillColor: [255, 255, 255], textColor: 0, lineColor: 0, fontSize: 9, lineWidth: 1, cellWidth: 120, minCellHeight: 25, fontStyle: 'bold' },
                1: { halign: 'center', valign: 'middle', fillColor: [255, 255, 255], textColor: 0, lineColor: 0, fontSize: 9, lineWidth: 1, cellWidth: 340, minCellHeight: 25, fontStyle: 'normal' },
            }
            tableDoc.autoTable(['', ''], tableRows,
                {
                    startX: 40,
                    margin: 70,
                    startY: 150,
                    columnStyles: columnStyle,
                    headStyles: { fillColor: [255, 255, 255], halign: 'center', valign: 'middle' }
                })
            let finalY = tableDoc.lastAutoTable.finalY; // The y position on the page
            let blob = tableDoc.output("arraybuffer");
            const tableSiteDoc = await PDFDocument.load(blob)
            var pages = tableSiteDoc.getPages()
            var firstPage = pages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var HelveticaBold = await tableSiteDoc.embedFont(StandardFonts.HelveticaBold)

            var urlImage = `/pdf/gemc.png`
            const ImageLogo = await fetch(urlImage).then((res) =>
                res.arrayBuffer()
            )
            var pngImage = await tableSiteDoc.embedPng(ImageLogo)
            var pngDims = pngImage.scale(1)
            firstPage.drawImage(pngImage, {
                x: 430,
                y: 745,
                width: 100,
                height: 50,
            })

            var startingPositon = 720
            var title = "Autorisation collecte de données pour demander des offres"
            var lineRaison = "Je soussigné :"
            firstPage.drawText(`${title}`, {
                x: 70,
                y: startingPositon,
                font: HelveticaBold,
                size: 15,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon -= 40
            firstPage.drawText(`${lineRaison}`, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon = 820 - finalY
            if (820 - finalY <= 160) {
                firstPage = tableSiteDoc.addPage()
                startingPositon = 720
            }
            var lines = "Autorisant Gaz-Elec Moins Cher à accéder aux données mentionnées ci-dessus.\n" +
                "Cette autorisation permet à  Gaz-Elec Moins Cher et aux fournisseurs d’énergie (au sens de l’article L333-1 et L443-1 du code de l’énergie)" +
                " partenaires avec lesquels celle-ci est liée par contrat de demander et consulter les données suivantes : "
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 70,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon - 7
            var txt = "ELECTRICITÉ"
            firstPage.drawText(txt, {
                x: 70,
                y: startingPositon,
                size: 13,
                color: rgb(0, 0, 0),
            })
            var lesTextsElec = ["*      L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance) ;",
                "*      L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site ;",
                "*      L’historique de courbe de charge, au pas restitué par Enedis, du site] ;",
                "*      Les données techniques et contractuelles disponibles du site."
            ]
            var lesTextsGaz = ["*       Les données publiées (données transmises à mon fournisseur d’énergie pour la facturation)",
                "*       Les données informatives journalières (si disponibles)",
                "*       Les données contractuelles (données relatives au contrat avec mon fournisseur)",
                "*       Les données techniques (données relatives aux détails techniques de mon compteur)"
            ]
            for (var i = 0; i < lesTextsElec.length; i++) {
                startingPositon = startingPositon - 17
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(lesTextsElec[i], {
                    x: 80,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
            }
            startingPositon = startingPositon - 25
            var txt = "GAZ"
            firstPage.drawText(txt, {
                x: 70,
                y: startingPositon,
                size: 13,
                color: rgb(0, 0, 0),
            })
            for (var i = 0; i < lesTextsGaz.length; i++) {
                startingPositon = startingPositon - 17
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(lesTextsGaz[i], {
                    x: 80,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
            }

            startingPositon = startingPositon - 22
            var lines = "Solliciter l’ensemble des fournisseurs d’énergie en votre nom afin qu’ils élaborent des offres de fourniture."
            firstPage.drawText(lines, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 20
            var lines = "Vous donnez l’autorisation à la société gaz-elec-moinscher de demander et recevoir des différents fournisseurs d’énergie" +
                " des offres de fourniture de gaz naturel et d'électricité pour nos compteurs mentionnés ci-dessus."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 70,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 5
            var lines = "Vous autorisez expressément gaz-elec-moinscher, ainsi que et l’ensemble des fournisseurs d’énergie partenaires" +
                " avec lesquels celle-ci est liée par contrat, à demander aux gestionnaires de réseau de distribution de gaz naturel et " +
                "d’électricité (Enedis, GRDF…) et au(x) fournisseur(s) actuel(s).\nNous autorisons expressément gaz-elec-moinscher à :"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 70,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 5
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var lines = "*      Demander à tout fournisseur des offres de fourniture de gaz naturel et/ou d’électricité pour nos compteurs mentionnés ci-dessus ;"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 85,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 5
            var lines = "*      Recevoir lesdites offres et poster toutes questions utiles au(x) fournisseur(s) sur les conditions techniques et financières de la fourniture d’énergie."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 85,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }

            startingPositon = startingPositon - 5
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var lines = "gaz-elec-moinscher transmettra la présente autorisation au(x) fournisseur(s) interrogé(s) dans le cadre de sa mission."
            firstPage.drawText(lines, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 15
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var lines = "Cette autorisation est valable pour une durée de 12 mois à compter de sa signature et n’engage aucun frais de votre part."
            firstPage.drawText(lines, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
                htmlTags: true,
            })
            startingPositon = startingPositon - 20
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("Date : " + DateAujourdhui + "                                         Signature avec pour mention “Bon pour accord” : ", {
                x: 100,
                y: startingPositon,
                font: HelveticaBold,
                size: 9,
                color: rgb(0, 0, 0),
            })

            //fin contact
            //create form data
            var formData = new FormData()

            const pdfBytes = await tableSiteDoc.save()
            
            if(Energie == "ELEC"){
                var file = download(pdfBytes, 'ACD_GEMC_ELEC_' + raison + '_.pdf', 'application/pdf')
            }else{
                var file = download(pdfBytes, 'ACD_GEMC_GAZ_' + raison + '_.pdf', 'application/pdf')
            }
            self.dialog = false
            self.loading = false
            self.error = false
        },
        async urltoFile(url, filename, mimeType) {
            return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
        },
        // fonction qui crée la date du jour pour la mettre à la fin du fichier pdf "fait le"
        currentDateTime() {
            const current = new Date()
            const date =
                current.getDate() +
                '/' +
                (current.getMonth() + 1) +
                '/' +
                current.getFullYear()
            return date
        },
    }
}

</script>
<style scoped>
::v-deep .v-label {
    margin: 0px;
}
</style>
<style scoped>
@media only screen (max-width:350px) {
    ::v-deep .v-application .ml-2 {
        margin-left: 0px !important;
    }
}
</style>
<style>
@media only screen and (max-width:400px) {
    .title {
        font-size: small,
    }
}
</style>
