export const leads =
 [
    {
        "id": 18146679,
        "title": "Scierie parquet chêne bezier",
        "pipeline": null,
        "step": "Pré-Closing",
        "step_id": 450473,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-27",
        "remind_time": null,
        "created_at": "2021-12-16T08:46:08.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:30:05.000Z",
        "description": "Prénom: \nNom: \nTéléphone:03 25 40 30 87 \nE-mail:scieriebezieraline@orange.fr \nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r\nAttente de facture, relancé le 13/12/21",
        "html_description": "Prénom: <br>Nom: <br>Téléphone:03 25 40 30 87 <br>E-mail:<span>scieriebezieraline@orange.fr</span> <br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p>Attente de facture, relancé le 13/12/21</p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18435292,
        "title": "AGJA Bordeaux Caudéran",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "15:00",
        "created_at": "2022-01-18T14:26:12.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:26:43.000Z",
        "description": "Prénom : \nDate de création : 31 décembre 1986\nAdresse : 8 r Gambetta\nC.P. : 33200\nVille : BORDEAUX\nTéléphone : 0556086779\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.agja.org\nSIREN : 34044943800019\nCode Naf : 9312Z",
        "html_description": "Prénom : <br>Date de création : 31 décembre 1986<br>Adresse : 8 r Gambetta<br>C.P. : 33200<br>Ville : BORDEAUX<br>Téléphone : 0556086779<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.agja.org<br>SIREN : 34044943800019<br>Code Naf : 9312Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18389572,
        "title": "Nicaez Alsace Fermetures",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "11:30",
        "created_at": "2022-01-13T15:58:25.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:18:30.000Z",
        "description": "Prénom : \nAdresse : 7 r Flora Tristan\nC.P. : 67200\nVille : STRASBOURG\nTéléphone : 0388280792\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.nicaez.fr\nSIREN : 88080010700026\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 7 r Flora Tristan<br>C.P. : 67200<br>Ville : STRASBOURG<br>Téléphone : 0388280792<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.nicaez.fr<br>SIREN : 88080010700026<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18434312,
        "title": "Bella Coiffure & Bien-Etre",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-26",
        "remind_time": null,
        "created_at": "2022-01-18T13:35:52.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:15:16.000Z",
        "description": "Prénom : Manuela\nNom: BONNEREAU\nDate de création : 1 octobre 1993\nAdresse : 3 av Paris\nC.P. : 86490\nVille : BEAUMONT\nTéléphone : 0549852988\nTél. n°2 : \nFax : \nMobile : \nE-mail : manou.eclypse@gmail.com\nSite Internet : http://www.salon-coiffure-beaumont.fr\nSIREN : 39248270900046\nCode Naf : 9602A",
        "html_description": "Prénom : Manuela<br>Nom: BONNEREAU<br>Date de création : 1 octobre 1993<br>Adresse : 3 av Paris<br>C.P. : 86490<br>Ville : BEAUMONT<br>Téléphone : 0549852988<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : manou.eclypse@gmail.com<br>Site Internet : http://www.salon-coiffure-beaumont.fr<br>SIREN : 39248270900046<br>Code Naf : 9602A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18387210,
        "title": "M.A.W",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-25",
        "remind_time": "10:00",
        "created_at": "2022-01-13T13:45:26.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:15:01.000Z",
        "description": "Prénom :\nNom: Mme NOGUES\nAdresse : 115 r Martyrs\nC.P. : 60280\nVille : VENETTE\nTéléphone : 0344832454\nTél. n°2 : \nFax : 0344835150\nMobile : 0607666774\nE-mail : maw.secretariat@wanadoo.fr\nSite Internet : \nSIREN : 39198523100011\nCode Naf : 4332B",
        "html_description": "Prénom :<br> Nom: Mme NOGUES<br>Adresse : 115 r Martyrs<br>C.P. : 60280<br>Ville : VENETTE<br>Téléphone : 0344832454<br>Tél. n°2 : <br>Fax : 0344835150<br>Mobile : 0607666774<br>E-mail : maw.secretariat@wanadoo.fr<br>Site Internet : <br>SIREN : 39198523100011<br>Code Naf : 4332B",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18434653,
        "title": "josephine baker",
        "pipeline": null,
        "step": "Closing",
        "step_id": 450474,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2022-01-18T13:49:52.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:04:44.000Z",
        "description": "Prénom: ANA \nNom: LIBORIO \nTéléphone: \nE-mail:gestionnaire.0720797a@ac-nantes.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \nNo Compteur: \n---",
        "html_description": "Prénom: ANA <br>Nom: LIBORIO <br>Téléphone: <br>E-mail:gestionnaire.0720797a@ac-nantes.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>No Compteur: <br><br>---<p><br></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 1,
        "created_by_id": 185047,
        "user_id": 186131,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18292545,
        "title": "BREUIL MATERIAUX",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "16:00",
        "created_at": "2022-01-04T13:37:38.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:00:09.000Z",
        "description": "Prénom : Corinne\nNom: JUMEL\nAdresse : 4 r Abel Maroux\nC.P. : 60840\nVille : BREUIL LE SEC\nTéléphone : 0344503876\nTél. n°2 : \nFax : 0344501766\nMobile : \nE-mail : corinnebreuil.materiaux@orange.fr\nSite Internet : http://www.breuil-materiaux.fr\nSIREN : 32642014800024\nCode Naf : 4673A",
        "html_description": "Prénom : Corinne<br>Nom: JUMEL<br>Adresse : 4 r Abel Maroux<br>C.P. : 60840<br>Ville : BREUIL LE SEC<br>Téléphone : 0344503876<br>Tél. n°2 : <br>Fax : 0344501766<br>Mobile : <br>E-mail : corinnebreuil.materiaux@orange.fr<br>Site Internet : http://www.breuil-materiaux.fr<br>SIREN : 32642014800024<br>Code Naf : 4673A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18201169,
        "title": "PHENIX RETRO CONCEPT",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "16:00",
        "created_at": "2021-12-22T09:23:24.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T14:00:09.000Z",
        "description": "Prénom: François\nNom: DECLA\nTéléphone: 05 59 67 13 56\nE-mail: contact@phenix-retroconcept.com\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: 40290\nVille: Pouillon\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: François<br>Nom: DECLA<br>Téléphone: 05 59 67 13 56<br>E-mail: contact@phenix-retroconcept.com<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: 40290<br>Ville: Pouillon<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18434711,
        "title": "Mj Horticulture EARL",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-02",
        "remind_time": null,
        "created_at": "2022-01-18T13:53:06.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:53:44.000Z",
        "description": "Prénom : \nAdresse : 5 chem Petit Plan\nC.P. : 01250\nVille : SAINT JUST\nTéléphone : 0474451805\nTél. n°2 : \nFax : \nMobile : \nE-mail : earl.mj@bbox.fr\nSite Internet : http://www.saintjust01.fr/horticulture/mj-horticulture\nSIREN : 41473846800019\nCode Naf : 0119Z",
        "html_description": "Prénom : <br>Adresse : 5 chem Petit Plan<br>C.P. : 01250<br>Ville : SAINT JUST<br>Téléphone : 0474451805<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : earl.mj@bbox.fr<br>Site Internet : http://www.saintjust01.fr/horticulture/mj-horticulture<br>SIREN : 41473846800019<br>Code Naf : 0119Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18344935,
        "title": "Actilife",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "11:30",
        "created_at": "2022-01-10T11:22:49.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:51:17.000Z",
        "description": "Prénom : Emannuel\nNom: GERWILL\nDate de création : 1 janvier 2009\nAdresse : bât Actilife 6 r Clement Ader\nC.P. : 68730\nVille : BLOTZHEIM\nTéléphone : 0389890428\nTél. n°2 : \nFax : \nMobile : \nE-mail : info@actilife.fr\nSite Internet : http://www.weiderfrance.fr\nSIREN : 51093036500034\nCode Naf : 9311Z",
        "html_description": "Prénom : Emannuel<br>Nom: GERWILL<br>Date de création : 1 janvier 2009<br>Adresse : bât Actilife 6 r Clement Ader<br>C.P. : 68730<br>Ville : BLOTZHEIM<br>Téléphone : 0389890428<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : info@actilife.fr<br>Site Internet : http://www.weiderfrance.fr<br>SIREN : 51093036500034<br>Code Naf : 9311Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18395902,
        "title": "Carrosserie Viala Saint Georges",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-26",
        "remind_time": "10:30",
        "created_at": "2022-01-14T09:02:38.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:50:04.000Z",
        "description": "Nom : \nAdresse : 13 r Four A Chaux\nC.P. : 34680\nVille : SAINT GEORGES D'ORQUES\nTéléphone : 0499771770\nTél. n°2 : \nFax : 0467102189\nMobile : \nE-mail : contactstg@carrosserieviala.com\nSite Internet : http://www.carrosserie-viala.fr/fr/accueil.php\nSIREN : 51506223000013\nCode Naf : 4520A",
        "html_description": "Nom : <br>Adresse : 13 r Four A Chaux<br>C.P. : 34680<br>Ville : SAINT GEORGES D'ORQUES<br>Téléphone : 0499771770<br>Tél. n°2 : <br>Fax : 0467102189<br>Mobile : <br>E-mail : contactstg@carrosserieviala.com<br>Site Internet : http://www.carrosserie-viala.fr/fr/accueil.php<br>SIREN : 51506223000013<br>Code Naf : 4520A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18430460,
        "title": "Terrasse Design",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "11:30",
        "created_at": "2022-01-18T08:24:51.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:46:21.000Z",
        "description": "Prénom : \nAdresse : 8 r Industrie\nC.P. : 67720\nVille : HOERDT\nTéléphone : 0975798236\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : https://www.terrasse-design.fr/\nSIREN : 89884919500018\nCode Naf : 2511Z",
        "html_description": "Prénom : <br>Adresse : 8 r Industrie<br>C.P. : 67720<br>Ville : HOERDT<br>Téléphone : 0975798236<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : https://www.terrasse-design.fr/<br>SIREN : 89884919500018<br>Code Naf : 2511Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18396930,
        "title": "PLATRELEC",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "15:45",
        "created_at": "2022-01-14T10:48:54.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:45:05.000Z",
        "description": "Prénom : \nAdresse : 54 r Ernest Renan\nC.P. : 02300\nVille : CHAUNY\nTéléphone : 0323398252\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://platrelec.site-solocal.com\nSIREN : 75313493100041\nCode Naf : 4321A",
        "html_description": "Prénom : <br>Adresse : 54 r Ernest Renan<br>C.P. : 02300<br>Ville : CHAUNY<br>Téléphone : 0323398252<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://platrelec.site-solocal.com<br>SIREN : 75313493100041<br>Code Naf : 4321A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18314294,
        "title": "Duthoit Menuiserie",
        "pipeline": null,
        "step": "Pré-Closing",
        "step_id": 450473,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "14:45",
        "created_at": "2022-01-06T09:17:28.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:45:01.000Z",
        "description": "Prénom : Geneviève\nNom : DROLEZ\nAdresse : 57 r Haie Plouvier\nC.P. : 59810\nVille : LESQUIN\nTéléphone : 0320901025\nTél. n°2 : \nFax : \nMobile : \nE-mail : contact@duthoit-alu.fr\nSite Internet : http://duthoitmenuiseries.site-solocal.com\nSIREN : 50434655200020\nCode Naf : 2511Z",
        "html_description": "Prénom : Geneviève<br>Nom : DROLEZ<br>Adresse : 57 r Haie Plouvier<br>C.P. : 59810<br>Ville : LESQUIN<br>Téléphone : 0320901025<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : contact@duthoit-alu.fr<br>Site Internet : http://duthoitmenuiseries.site-solocal.com<br>SIREN : 50434655200020<br>Code Naf : 2511Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18434201,
        "title": "Magic Form",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-10",
        "remind_time": "10:00",
        "created_at": "2022-01-18T13:27:10.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:28:08.000Z",
        "description": "Prénom :\nNom: LOTTON\nDate de création : 1 septembre 2013\nAdresse : 6 r Fourneries\nC.P. : 87100\nVille : LIMOGES\nTéléphone : 0555697513\nTél. n°2 : \nFax : \nMobile : \nE-mail : info@magicform-limoges.fr\nSite Internet : http://www.facebook.com\nSIREN : 79506394000014\nCode Naf : 9311Z",
        "html_description": "Prénom :<br> Nom: LOTTON<br>Date de création : 1 septembre 2013<br>Adresse : 6 r Fourneries<br>C.P. : 87100<br>Ville : LIMOGES<br>Téléphone : 0555697513<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : info@magicform-limoges.fr<br>Site Internet : http://www.facebook.com<br>SIREN : 79506394000014<br>Code Naf : 9311Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18434169,
        "title": "Horticulture Vernet Var'Fleurs",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-09-08",
        "remind_time": null,
        "created_at": "2022-01-18T13:25:28.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:27:09.000Z",
        "description": "Prénom : \nAdresse : imp Blanqui\nC.P. : 42650\nVille : SAINT JEAN BONNEFONDS\nTéléphone : 0477950196\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : \nSIREN : 33106659700013\nCode Naf : 0119Z\nRappel en septembre 2022 , c'est le moment où il s'en occupe !!",
        "html_description": "Prénom : <br>Adresse : imp Blanqui<br>C.P. : 42650<br>Ville : SAINT JEAN BONNEFONDS<br>Téléphone : 0477950196<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : <br>SIREN : 33106659700013<br>Code Naf : 0119Z<br><br>Rappel en septembre 2022 , c'est le moment où il s'en occupe !!",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18384965,
        "title": "Aquaslim Studio",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "15:15",
        "created_at": "2022-01-13T10:50:30.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:15:04.000Z",
        "description": "Prénom : \nDate de création : 2 février 2015\nAdresse : 18 av Gustave Eiffel\nC.P. : 33510\nVille : ANDERNOS LES BAINS\nTéléphone : 0556606475\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.aquabiking-andernos.com\nSIREN : 80931303400013\nCode Naf : 9604Z",
        "html_description": "Prénom : <br>Date de création : 2 février 2015<br>Adresse : 18 av Gustave Eiffel<br>C.P. : 33510<br>Ville : ANDERNOS LES BAINS<br>Téléphone : 0556606475<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.aquabiking-andernos.com<br>SIREN : 80931303400013<br>Code Naf : 9604Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18421806,
        "title": "Sélect Fermetures",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-21",
        "remind_time": "14:15",
        "created_at": "2022-01-17T15:47:35.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:14:17.000Z",
        "description": "Prénom : \nAdresse : 213 r Nationale\nC.P. : 62200\nVille : BOULOGNE SUR MER\nTéléphone : 0321325495\nTél. n°2 : \nFax : 0321992736\nMobile : \nE-mail : \nSite Internet : http://www.selectfermetures.com\nSIREN : 43269481800068\nCode Naf : 4752B",
        "html_description": "Prénom : <br>Adresse : 213 r Nationale<br>C.P. : 62200<br>Ville : BOULOGNE SUR MER<br>Téléphone : 0321325495<br>Tél. n°2 : <br>Fax : 0321992736<br>Mobile : <br>E-mail : <br>Site Internet : http://www.selectfermetures.com<br>SIREN : 43269481800068<br>Code Naf : 4752B<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18433965,
        "title": "Lycée horticole et animalier de La Tour-du-Pin",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": null,
        "created_at": "2022-01-18T13:12:13.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:12:25.000Z",
        "description": "Prénom : \nAdresse : 164 all Louis Clerget\nC.P. : 38110\nVille : LA TOUR DU PIN\nTéléphone : 0474832070\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://lyceehorticole.fr\nSIREN : 19381819200042\nCode Naf : 8532Z",
        "html_description": "Prénom : <br>Adresse : 164 all Louis Clerget<br>C.P. : 38110<br>Ville : LA TOUR DU PIN<br>Téléphone : 0474832070<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://lyceehorticole.fr<br>SIREN : 19381819200042<br>Code Naf : 8532Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18430658,
        "title": "Tillieux Menuiserie",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-02-06",
        "remind_time": "09:30",
        "created_at": "2022-01-18T08:44:51.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:11:52.000Z",
        "description": "Prénom : \nAdresse : 400 r Gand\nC.P. : 59200\nVille : TOURCOING\nTéléphone : 0320947005\nTél. n°2 : \nFax : \nMobile : 0676305863\nE-mail : \nSite Internet : http://www.tillieux-menuiserie.fr\nSIREN : 83075652400013\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 400 r Gand<br>C.P. : 59200<br>Ville : TOURCOING<br>Téléphone : 0320947005<br>Tél. n°2 : <br>Fax : <br>Mobile : 0676305863<br>E-mail : <br>Site Internet : http://www.tillieux-menuiserie.fr<br>SIREN : 83075652400013<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18325379,
        "title": "Le Métamorphose",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "15:00",
        "created_at": "2022-01-07T11:06:00.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T13:00:09.000Z",
        "description": "Prénom :\nNom: Mr GAUTIER\nDate de création : 19 août 2009\nAdresse : 17 pl Hôtel de Ville\nC.P. : 67120\nVille : MOLSHEIM\nTéléphone : 0388388210\nTél. n°2 : \nFax : \nMobile : 06 86 32 18 86\nE-mail : lemetamorphose@orange.fr\nSite Internet : http://lemetamorphose.fr\nSIREN : 51789650200013\nCode Naf : 9313Z",
        "html_description": "Prénom :<br>Nom: Mr GAUTIER<br>Date de création : 19 août 2009<br>Adresse : 17 pl Hôtel de Ville<br>C.P. : 67120<br>Ville : MOLSHEIM<br>Téléphone : 0388388210<br>Tél. n°2 : <br>Fax : <br>Mobile : 06 86 32 18 86<br>E-mail : lemetamorphose@orange.fr<br>Site Internet : http://lemetamorphose.fr<br>SIREN : 51789650200013<br>Code Naf : 9313Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18277942,
        "title": "A . D . M Artésienne de Menuiserie",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2024-01-02",
        "remind_time": "10:00",
        "created_at": "2022-01-03T09:49:07.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T12:59:23.000Z",
        "description": "Prénom :\nNom: M. VOISEUX\nAdresse : Zone Industrielle Est av Immercourt\nC.P. : 62217\nVille : TILLOY LES MOFFLAINES\nTéléphone : 0321714444\nTél. n°2 : \nFax : 0321232399\nMobile : \nE-mail : arte.de.menuiserie@free.fr\nSite Internet : http://www.artesienne-de-menuiserie-62.fr\nSIREN : 39056441700025\nCode Naf : 4332A",
        "html_description": "Prénom :<br>Nom: M. VOISEUX<br>Adresse : Zone Industrielle Est av Immercourt<br>C.P. : 62217<br>Ville : TILLOY LES MOFFLAINES<br>Téléphone : 0321714444<br>Tél. n°2 : <br>Fax : 0321232399<br>Mobile : <br>E-mail : arte.de.menuiserie@free.fr<br>Site Internet : http://www.artesienne-de-menuiserie-62.fr<br>SIREN : 39056441700025<br>Code Naf : 4332A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18421674,
        "title": "Stempfler Meyer Fermetures",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "14:45",
        "created_at": "2022-01-17T15:40:53.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T12:45:05.000Z",
        "description": "Prénom : \nAdresse : Zone B r Industrie\nC.P. : 68720\nVille : TAGOLSHEIM\nTéléphone : 0389070078\nTél. n°2 : 0389075351\nFax : \nMobile : \nE-mail : \nSite Internet : http://stempflermeyerfermetures.site-solocal.com\nSIREN : 49030236100020\nCode Naf : 4332B",
        "html_description": "Prénom : <br>Adresse : Zone B r Industrie<br>C.P. : 68720<br>Ville : TAGOLSHEIM<br>Téléphone : 0389070078<br>Tél. n°2 : 0389075351<br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://stempflermeyerfermetures.site-solocal.com<br>SIREN : 49030236100020<br>Code Naf : 4332B<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18200718,
        "title": "Peugeot Gradignan Automobiles Services Agent",
        "pipeline": null,
        "step": "Attente ACD",
        "step_id": 450479,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "11:30",
        "created_at": "2021-12-22T09:05:56.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T11:36:03.000Z",
        "description": "Prénom: Magali\nNom: DUSSAUX\nTéléphone: 05 56 89 25 04\nE-mail: mdussaux@groupe-am.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---",
        "html_description": "Prénom: Magali<br>Nom: DUSSAUX<br>Téléphone: 05 56 89 25 04<br>E-mail: mdussaux@groupe-am.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br><br>---<p><br></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18200842,
        "title": "Make My Van",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-02",
        "remind_time": "10:15",
        "created_at": "2021-12-22T09:10:09.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T11:25:25.000Z",
        "description": "Prénom: \nNom: DE VILLEDON\nTéléphone: 05 56 57 90 66\nE-mail: contact@makemyvan.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: 44360\nVille: Vigneux-de-Bretagne\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---",
        "html_description": "Prénom: <br>Nom: DE VILLEDON<br>Téléphone: 05 56 57 90 66<br>E-mail: contact@makemyvan.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: 44360<br>Ville: Vigneux-de-Bretagne<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br><br>---<p><br></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18148468,
        "title": "Carrosserie LANDRON",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-01",
        "remind_time": "14:15",
        "created_at": "2021-12-16T11:34:12.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T11:23:17.000Z",
        "description": "Prénom: Thierry\nNom: LANDRON\nTéléphone: 02 40 75 50 89\nE-mail: landron.sarl@bbox.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: Thierry<br>Nom: LANDRON<br>Téléphone: 02 40 75 50 89<br>E-mail: landron.sarl@bbox.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18186907,
        "title": "Garage Tessier SARL",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-25",
        "remind_time": "11:00",
        "created_at": "2021-12-21T09:08:02.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T11:22:46.000Z",
        "description": "Prénom: Frédéric\nNom: TESSIER\nTéléphone: 02 47 26 13 21\nE-mail: garagetessierfrederic@orange.fr\nFournisseur: EDF\nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---",
        "html_description": "Prénom: Frédéric<br>Nom: TESSIER<br>Téléphone: 0<span>2 47 26 13 21</span><br>E-mail: garagetessierfrederic@orange.fr<br>Fournisseur: EDF<br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br><br>---<p><br></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18278124,
        "title": "A2P TUQUET",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-21",
        "remind_time": "14:30",
        "created_at": "2022-01-03T10:08:11.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T11:20:55.000Z",
        "description": "Prénom :\nNom: M et Mme TUQUET\nAdresse : Zone D'angean 100 r Osier\nC.P. : 60240\nVille : CHAUMONT EN VEXIN\nTéléphone : 0360172703\nTél. n°2 : \nFax : \nMobile : 0678484258\nE-mail : contact@a2p-tuquet.fr\nSite Internet : http://a2p-tuquet.fr\nSIREN : 50043454300038\nCode Naf : 4321A",
        "html_description": "Prénom :<br>Nom: M et Mme TUQUET<br>Adresse : Zone D'angean 100 r Osier<br>C.P. : 60240<br>Ville : CHAUMONT EN VEXIN<br>Téléphone : 0360172703<br>Tél. n°2 : <br>Fax : <br>Mobile : 0678484258<br>E-mail : contact@a2p-tuquet.fr<br>Site Internet : http://a2p-tuquet.fr<br>SIREN : 50043454300038<br>Code Naf : 4321A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18162164,
        "title": "Peugeot Jourdan (SARL) Agent",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-01",
        "remind_time": "11:15",
        "created_at": "2021-12-17T14:19:26.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T11:19:13.000Z",
        "description": "Nom : M JOURDAN JACQUES JEAN ROBERT\nAdresse : 1 av Vigan\nC.P. : 34190\nVille : GANGES\nTéléphone : 0467738165\nTél. n°2 : \nFax : 0467735337\nMobile : \nE-mail : jourdan.peugeot@orange.fr\nSite Internet : \nSIREN : 39428894800018\nCode Naf : 4511Z",
        "html_description": "Nom : M JOURDAN JACQUES JEAN ROBERT<br>Adresse : 1 av Vigan<br>C.P. : 34190<br>Ville : GANGES<br>Téléphone : 0467738165<br>Tél. n°2 : <br>Fax : 0467735337<br>Mobile : <br>E-mail : jourdan.peugeot@orange.fr<br>Site Internet : <br>SIREN : 39428894800018<br>Code Naf : 4511Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18388518,
        "title": "Menuiserie Services",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-01-10",
        "remind_time": "09:45",
        "created_at": "2022-01-13T15:03:54.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T10:28:04.000Z",
        "description": "Prénom : \nAdresse : Zone Industrielle r Etangs\nC.P. : 76340\nVille : BLANGY SUR BRESLE\nTéléphone : 0235937283\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://aluminiertechnalmenuiserieservices.site-solocal.com\nSIREN : 48039991400047\nCode Naf : 4673A",
        "html_description": "Prénom : <br>Adresse : Zone Industrielle r Etangs<br>C.P. : 76340<br>Ville : BLANGY SUR BRESLE<br>Téléphone : 0235937283<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://aluminiertechnalmenuiserieservices.site-solocal.com<br>SIREN : 48039991400047<br>Code Naf : 4673A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18431018,
        "title": "Van Elsuwe",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "09:00",
        "created_at": "2022-01-18T09:17:38.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T10:19:21.000Z",
        "description": "Prénom : \nAdresse : 4 r Mouy\nC.P. : 60290\nVille : RANTIGNY\nTéléphone : 0374097817\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.van-elsuwealain.fr\nSIREN : 31533199100010\nCode Naf : 4332B",
        "html_description": "Prénom : <br>Adresse : 4 r Mouy<br>C.P. : 60290<br>Ville : RANTIGNY<br>Téléphone : 0374097817<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.van-elsuwealain.fr<br>SIREN : 31533199100010<br>Code Naf : 4332B<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18163418,
        "title": "Volkswagen Cerf Automobile DBF Concessionnaire",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": "12:00",
        "created_at": "2021-12-17T15:45:11.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T10:00:18.000Z",
        "description": "Nom : \nAdresse : 3160 av Maurin\nC.P. : 34070\nVille : MONTPELLIER\nTéléphone : 0467078383\nTél. n°2 : \nFax : 0467428436\nMobile : \nE-mail : \nSite Internet : http://volkswagen-montpellier.fr\nSIREN : 33423838300056\nCode Naf : 4511Z",
        "html_description": "Nom : <br>Adresse : 3160 av Maurin<br>C.P. : 34070<br>Ville : MONTPELLIER<br>Téléphone : 0467078383<br>Tél. n°2 : <br>Fax : 0467428436<br>Mobile : <br>E-mail : <br>Site Internet : http://volkswagen-montpellier.fr<br>SIREN : 33423838300056<br>Code Naf : 4511Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18431524,
        "title": "SINACORP",
        "pipeline": null,
        "step": "Closing",
        "step_id": 450474,
        "status": "won",
        "amount": 5169.89,
        "probability": 100,
        "currency": "EUR",
        "starred": false,
        "remind_date": null,
        "remind_time": null,
        "created_at": "2022-01-18T09:52:08.000Z",
        "estimated_closing_date": "2022-01-14",
        "updated_at": "2022-01-18T09:52:45.000Z",
        "description": "Prénom: Nathalie\nNom: Bettenfiel\nTéléphone:0383203770 \nE-mail:Procanis@wanadoo.fr \nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: 2 RUE DE TARBES\nPROLONGEE LA PORTE VERTE\nC.P.: 54425 \nVille: PULNOY\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: 4776Z\nNo Compteur: 30000510831860\n---\r",
        "html_description": "Prénom: Nathalie<br>Nom: Bettenfiel<br>Téléphone:0383203770 <br>E-mail:Procanis@wanadoo.fr <br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: 2 RUE DE TARBES<br>PROLONGEE LA PORTE VERTE<br>C.P.: 54425 <br>Ville: PULNOY<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: 4776Z<br>No Compteur: 30000510831860<br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": "2022-01-18",
        "attachment_count": 0,
        "created_by_id": 185047,
        "user_id": 186131,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18431513,
        "title": "Visioferm",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-21",
        "remind_time": "11:15",
        "created_at": "2022-01-18T09:50:57.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:51:11.000Z",
        "description": "Prénom : \nAdresse : 37 r Reiningue\nC.P. : 68310\nVille : WITTELSHEIM\nTéléphone : 0389552580\nTél. n°2 : \nFax : 0389571136\nMobile : \nE-mail : \nSite Internet : http://visioferm.site-solocal.com\nSIREN : 44221563800044\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 37 r Reiningue<br>C.P. : 68310<br>Ville : WITTELSHEIM<br>Téléphone : 0389552580<br>Tél. n°2 : <br>Fax : 0389571136<br>Mobile : <br>E-mail : <br>Site Internet : http://visioferm.site-solocal.com<br>SIREN : 44221563800044<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18431506,
        "title": "Aux Serres Fleuries",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-11-29",
        "remind_time": null,
        "created_at": "2022-01-18T09:50:28.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:51:06.000Z",
        "description": "Prénom : \nAdresse : 18 r Romainville\nC.P. : 03300\nVille : CUSSET\nTéléphone : 0470986490\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : \nSIREN : \nCode Naf :\nEngie 2 ans depuis le 27 novembre",
        "html_description": "Prénom : <br>Adresse : 18 r Romainville<br>C.P. : 03300<br>Ville : CUSSET<br>Téléphone : 0470986490<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : <br>SIREN : <br>Code Naf :<br><br>Engie 2 ans depuis le 27 novembre",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18431456,
        "title": "EARL FLAGEUL",
        "pipeline": null,
        "step": "Closing",
        "step_id": 450474,
        "status": "won",
        "amount": 410.0,
        "probability": 100,
        "currency": "EUR",
        "starred": false,
        "remind_date": null,
        "remind_time": null,
        "created_at": "2022-01-18T09:46:12.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:49:50.000Z",
        "description": "Prénom: Olivier\nNom: FLAGEUL \nTéléphone: 0603297069\nE-mail: gaecdelavillemorvan@orange.fr\nFournisseur: ENGIE PRO\nECHEANCE: 31/01/2024\nSIREN: 75051246900016\nAdresse: 7 LA VILLE MORVAN\nC.P.: 56490 \nVille: EVRIGUET\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: 0141Z \nNo Compteur: 50016746610000\n---\r",
        "html_description": "Prénom: Olivier<br>Nom: FLAGEUL <br>Téléphone: 0603297069<br>E-mail: gaecdelavillemorvan@orange.fr<br>Fournisseur: ENGIE PRO<br>ECHEANCE: 31/01/2024<br>SIREN: 75051246900016<br>Adresse: 7 LA VILLE MORVAN<br>C.P.: 56490 <br>Ville: EVRIGUET<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: 0141Z <br>No Compteur: 50016746610000<br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": "2022-01-18",
        "attachment_count": 0,
        "created_by_id": 185047,
        "user_id": 186131,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18431345,
        "title": "MVT LEMAN",
        "pipeline": null,
        "step": "Closing",
        "step_id": 450474,
        "status": "won",
        "amount": 1931.48,
        "probability": 100,
        "currency": "EUR",
        "starred": false,
        "remind_date": null,
        "remind_time": null,
        "created_at": "2022-01-18T09:36:00.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:42:27.000Z",
        "description": "Prénom: Dorine\nNom: RIVIERE\nTéléphone: 04 50 23 70 35\nE-mail: contact@mvtleman.com \nFournisseur: GEG \nECHEANCE: 31/12/2024\nSIREN: 824 222 830\nAdresse: 214 ROUTE DE LA TOUFFIERE\nC.P.: 74570\nVille: FILIERE\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: 4334Z\nNo Compteur: 9990000000015155\n---\r\nKVA 50 \r",
        "html_description": "Prénom: Dorine<br>Nom: RIVIERE<br>Téléphone: 04 50 23 70 35<br>E-mail: <a href=\"mailto:contact@mvtleman.com\">contact@mvtleman.com</a> <br>Fournisseur: GEG <br>ECHEANCE: 31/12/2024<br>SIREN: 824 222 830<br>Adresse: 214 ROUTE DE LA TOUFFIERE<br>C.P.: 74570<br>Ville: FILIERE<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: 4334Z<br>No Compteur: 9990000000015155<br>\r\n---\r\n<p>KVA 50 </p>\r\n<p></p>",
        "tags": [
            "C4"
        ],
        "created_from": null,
        "closed_at": "2022-01-18",
        "attachment_count": 0,
        "created_by_id": 185047,
        "user_id": 185047,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18200084,
        "title": "Passion carrosserie",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-01-05",
        "remind_time": null,
        "created_at": "2021-12-22T08:22:15.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:36:36.000Z",
        "description": "Prénom: Julien\nNom: GUIN\nTéléphone: 05 53 59 25 14\nE-mail: passion-carrosserie@orange.fr\nFournisseur: EDF Pro depuis Octobre 2021\nECHEANCE: \nSIREN: \nAdresse: \nC.P.: 24200\nVille: Sarlat la Canéda\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: Julien<br>Nom: GUIN<br>Téléphone: 05 53 59 25 14<br>E-mail: passion-carrosserie@orange.fr<br>Fournisseur: EDF Pro depuis Octobre 2021<br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: 24200<br>Ville: Sarlat la Canéda<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18430963,
        "title": "Serres Du Grand Lan",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-26",
        "remind_time": null,
        "created_at": "2022-01-18T09:13:57.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:16:32.000Z",
        "description": "Prénom : \nAdresse : r Lac Jacob\nC.P. : 38150\nVille : SALAISE SUR SANNE\nTéléphone : 0474296086\nTél. n°2 : \nFax : 0474294233\nMobile : \nE-mail :grandlan@wanadoo.fr\nSite Internet : http://www.legrandlan.fr\nSIREN : 34766375900012\nCode Naf : 0130Z",
        "html_description": "Prénom : <br>Adresse : r Lac Jacob<br>C.P. : 38150<br>Ville : SALAISE SUR SANNE<br>Téléphone : 0474296086<br>Tél. n°2 : <br>Fax : 0474294233<br>Mobile : <br>E-mail :grandlan@wanadoo.fr<br>Site Internet : http://www.legrandlan.fr<br>SIREN : 34766375900012<br>Code Naf : 0130Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18151944,
        "title": "Garage Bernardini",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-02-01",
        "remind_time": "09:30",
        "created_at": "2021-12-16T15:50:14.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:09:47.000Z",
        "description": "Nom : BERNARDINI\nAdresse : 2 av Vignerons\nC.P. : 34110\nVille : FRONTIGNAN\nTéléphone : 0467481192\nTél. n°2 : 0467800902\nFax : \nMobile : \nE-mail : \nSite Internet : http://www.nb-automobiles.fr/\nSIREN : 49249808400028\nCode Naf : 4519Z",
        "html_description": "Nom : BERNARDINI<br>Adresse : 2 av Vignerons<br>C.P. : 34110<br>Ville : FRONTIGNAN<br>Téléphone : 0467481192<br>Tél. n°2 : 0467800902<br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.nb-automobiles.fr/<br>SIREN : 49249808400028<br>Code Naf : 4519Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18430812,
        "title": "Fernandez Françoise",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-27",
        "remind_time": null,
        "created_at": "2022-01-18T08:59:10.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:03:24.000Z",
        "description": "Prénom : \nAdresse : 4950 Rte Nationale 7 4950 rte Nationale 7\nC.P. : 26250\nVille : LIVRON SUR DROME\nTéléphone : 0475612559\nTél. n°2 : No Prospect\nFax : \nMobile : \nE-mail :f.francoise26@hotamail.fr\nSite Internet : \nSIREN : 41037291600024\nCode Naf : 0130Z\nDirect energie depuis 2 ans minimum",
        "html_description": "Prénom : <br>Adresse : 4950 Rte Nationale 7 4950 rte Nationale 7<br>C.P. : 26250<br>Ville : LIVRON SUR DROME<br>Téléphone : 0475612559<br>Tél. n°2 : No Prospect<br>Fax : <br>Mobile : <br>E-mail :f.francoise26@hotamail.fr<br>Site Internet : <br>SIREN : 41037291600024<br>Code Naf : 0130Z<br><br>Direct energie depuis 2 ans minimum",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18420191,
        "title": "Serge Menuiserie SARL",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-06-06",
        "remind_time": "09:45",
        "created_at": "2022-01-17T14:39:56.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T09:03:12.000Z",
        "description": "Prénom :\nNom: Mr DULOT\nAdresse : 64 r 3 Hameaux\nC.P. : 62240\nVille : COURSET\nTéléphone : 0321917571\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://sarlsergemenuiserie.site-solocal.com\nSIREN : 83101737100012\nCode Naf : 4332A",
        "html_description": "Prénom :<br> Nom: Mr DULOT<br>Adresse : 64 r 3 Hameaux<br>C.P. : 62240<br>Ville : COURSET<br>Téléphone : 0321917571<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://sarlsergemenuiserie.site-solocal.com<br>SIREN : 83101737100012<br>Code Naf : 4332A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18430757,
        "title": "V S Diffusion EURL",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-25",
        "remind_time": "10:45",
        "created_at": "2022-01-18T08:53:01.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T08:53:15.000Z",
        "description": "Prénom : \nAdresse : 24 av Gérard de Nerval\nC.P. : 60800\nVille : CREPY EN VALOIS\nTéléphone : 0344593224\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.verandas-oise.com\nSIREN : 44435985500012\nCode Naf : 4339Z",
        "html_description": "Prénom : <br>Adresse : 24 av Gérard de Nerval<br>C.P. : 60800<br>Ville : CREPY EN VALOIS<br>Téléphone : 0344593224<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.verandas-oise.com<br>SIREN : 44435985500012<br>Code Naf : 4339Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18317884,
        "title": "Fauquet Menuiserie",
        "pipeline": null,
        "step": "Attente ACD",
        "step_id": 450479,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2022-01-06T14:51:38.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T08:35:31.000Z",
        "description": "Prénom : Sylvie\nAdresse : 8 Bis rte Flandres\nC.P. : 60700\nVille : SAINT MARTIN LONGUEAU\nTéléphone : 0344290462\nTél. n°2 : \nFax : 0344290490\nMobile : \nE-mail : \nSite Internet : http://www.fauquet.fr\nSIREN : 42225092800013\nCode Naf : 4332A",
        "html_description": "Prénom : Sylvie<br>Adresse : 8 Bis rte Flandres<br>C.P. : 60700<br>Ville : SAINT MARTIN LONGUEAU<br>Téléphone : 0344290462<br>Tél. n°2 : <br>Fax : 0344290490<br>Mobile : <br>E-mail : <br>Site Internet : http://www.fauquet.fr<br>SIREN : 42225092800013<br>Code Naf : 4332A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18388114,
        "title": "Jardinerie Teyssier",
        "pipeline": null,
        "step": "Attente ACD",
        "step_id": 450479,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2022-01-13T14:38:30.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T08:34:44.000Z",
        "description": "Prénom : \nAdresse : av Clermont\nC.P. : 63800\nVille : COURNON D'AUVERGNE\nTéléphone : 0473848121\nTél. n°2 : \nFax : \nMobile : \nE-mail : horticultureteyssier@orange.fr \nSite Internet : https://www.jardinerie-teyssier.com/\nSIREN : 81228716700017\nCode Naf : 4776Z\n25€ la molécule sur son contrat actuel (gaz)\nDemande ACD et 25-75 le risque d'attendre",
        "html_description": "Prénom : <br>Adresse : av Clermont<br>C.P. : 63800<br>Ville : COURNON D'AUVERGNE<br>Téléphone : 0473848121<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <a href=\"mailto:horticultureteyssier@orange.fr\" data-mce-href=\"mailto:horticultureteyssier@orange.fr\">horticultureteyssier@orange.fr</a> <br>Site Internet : https://www.jardinerie-teyssier.com/<br>SIREN : 81228716700017<br>Code Naf : 4776Z<br><br>25€ la molécule sur son contrat actuel (gaz)<br><br>Demande ACD et 25-75 le risque d'attendre",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18146716,
        "title": "Scierie du Marthuret",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-17",
        "remind_time": null,
        "created_at": "2021-12-16T08:49:28.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T08:28:34.000Z",
        "description": "Prénom: Bernard\nNom:Buisson\nTéléphone:04 73 51 53 73 \nE-mail:bernardbuisson63@orange.fr \nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r\nAttente de facture , relancé le 13/12/21",
        "html_description": "Prénom: Bernard<br>Nom:Buisson<br>Téléphone:04 73 51 53 73 <br>E-mail:<span>bernardbuisson63@orange.fr</span> <br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p>Attente de facture , relancé le 13/12/21</p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18278165,
        "title": "Horticulture Guyot SARL",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-27",
        "remind_time": null,
        "created_at": "2022-01-03T10:11:06.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T08:21:36.000Z",
        "description": "Prénom : Didier\nNom : Guyot\nAdresse : 55 r Bale\nC.P. : 68490\nVille : BANTZENHEIM\nTéléphone : 0389260525\nTél. n°2 : \nFax : 0389260802\nMobile : \nE-mail : horticulture.guyot@orange.fr\nSite Internet : http://www.facebook.com\nSIREN : 38889222600019\nCode Naf : 0119Z\nGaz\nFournisseur : Gazprom Reconduction tacite 2 ans\nMail envoyé le 03/01/2022",
        "html_description": "Prénom : Didier<br>Nom : Guyot<br>Adresse : 55 r Bale<br>C.P. : 68490<br>Ville : BANTZENHEIM<br>Téléphone : 0389260525<br>Tél. n°2 : <br>Fax : 0389260802<br>Mobile : <br>E-mail : horticulture.guyot@orange.fr<br>Site Internet : http://www.facebook.com<br>SIREN : 38889222600019<br>Code Naf : 0119Z<br><br><br>Gaz<br>Fournisseur : Gazprom Reconduction tacite 2 ans<br>Mail envoyé le 03/01/2022",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18278596,
        "title": "Horticulture Blind",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-27",
        "remind_time": null,
        "created_at": "2022-01-03T10:51:47.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T08:20:07.000Z",
        "description": "Prénom : Fabien\nNom: Blind\nAdresse : 11 r Eglise\nC.P. : 67700\nVille : MAENNOLSHEIM\nTéléphone : 0388891194\nTél. n°2 : \nFax : \nMobile : \nE-mail : fabien.blind@gmail.com\nSite Internet : \nSIREN : 47942418600019\nCode Naf : 0111Z\nFournisseur: ES \nFin d'engagement: ",
        "html_description": "Prénom : Fabien<br>Nom: Blind<br>Adresse : 11 r Eglise<br>C.P. : 67700<br>Ville : MAENNOLSHEIM<br>Téléphone : 0388891194<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : fabien.blind@gmail.com<br>Site Internet : <br>SIREN : 47942418600019<br>Code Naf : 0111Z<br><br>Fournisseur: ES <br>Fin d'engagement: ",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18301976,
        "title": "Maison Nardeux",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2022-01-05T08:49:13.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T02:03:27.000Z",
        "description": "Prénom: Christian\nNom:Nardeux\nTéléphone: 0663098205\nE-mail: nardeux.christ@orange.fr\nFournisseur: Engie\nECHEANCE: fin 2022\nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---",
        "html_description": "Prénom: Christian<br>Nom:Nardeux<br>Téléphone: 0663098205<br>E-mail: nardeux.christ@orange.fr<br>Fournisseur: Engie<br>ECHEANCE: fin 2022<br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br><br>---<p><br></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18292392,
        "title": "Boucard Horticulture",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2022-01-04T13:26:43.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T02:03:25.000Z",
        "description": "Prénom : \nAdresse : av Clavier\nC.P. : 17140\nVille : LAGORD\nTéléphone : 0546676599\nTél. n°2 : \nFax : \nMobile : \nE-mail : boucard.horticulture@orange.fr\nSite Internet : http://boucardjacques.site-solocal.com\nSIREN : 39942541200018\nCode Naf : 0119Z\nGaz et Elec renégocié en Mars/Avril 2021 pour 3ans ",
        "html_description": "Prénom : <br>Adresse : av Clavier<br>C.P. : 17140<br>Ville : LAGORD<br>Téléphone : 0546676599<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : boucard.horticulture@orange.fr<br>Site Internet : http://boucardjacques.site-solocal.com<br>SIREN : 39942541200018<br>Code Naf : 0119Z<br><br>Gaz et Elec renégocié en Mars/Avril 2021 pour 3ans ",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18187244,
        "title": "Patte et fils solutions",
        "pipeline": null,
        "step": "Pré-Closing",
        "step_id": 450473,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2021-12-21T09:33:04.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T02:03:07.000Z",
        "description": "Prénom: Jean François\nNom: Patte \nTéléphone:03 22 77 73 22 \nE-mail:jf.patte@wanadoo.fr\nFournisseur: EDF\nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: Jean François<br>Nom: Patte <br>Téléphone:03 22 77 73 22 <br>E-mail:jf.patte@wanadoo.fr<br>Fournisseur: EDF<br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18180516,
        "title": "Scierie des Champs",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-18",
        "remind_time": null,
        "created_at": "2021-12-20T15:23:48.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-18T02:03:06.000Z",
        "description": "Prénom: Samuel\nNom: Dujardin\nTéléphone: 02 35 56 53 16\nE-mail: samuel@bois-service-normandie.fr\nFournisseur: \nECHEANCE: Fin 2022\nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r\nmail envoyé le 20/12/2021\nen attente de facture",
        "html_description": "Prénom: Samuel<br>Nom: Dujardin<br>Téléphone: 02 35 56 53 16<br>E-mail: samuel@bois-service-normandie.fr<br>Fournisseur: <br>ECHEANCE: Fin 2022<br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p>mail envoyé le 20/12/2021<br>en attente de facture</p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18421106,
        "title": "SOBRIMA",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-27",
        "remind_time": "10:30",
        "created_at": "2022-01-17T15:20:40.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T16:54:20.000Z",
        "description": "Prénom :\nNom: Mr BECLE\nAdresse : 3 r Energie\nC.P. : 67720\nVille : HOERDT\nTéléphone : 0388692569\nTél. n°2 : \nFax : 0388692565\nMobile : \nE-mail : info@sobrima.fr\nSite Internet : http://www.sobrima.fr\nSIREN : 54850101400037\nCode Naf : 4332B",
        "html_description": "Prénom :<br> Nom: Mr BECLE<br>Adresse : 3 r Energie<br>C.P. : 67720<br>Ville : HOERDT<br>Téléphone : 0388692569<br>Tél. n°2 : <br>Fax : 0388692565<br>Mobile : <br>E-mail : info@sobrima.fr<br>Site Internet : http://www.sobrima.fr<br>SIREN : 54850101400037<br>Code Naf : 4332B",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18417745,
        "title": "SABIAUX DUBRECQ",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-24",
        "remind_time": "14:30",
        "created_at": "2022-01-17T11:44:45.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T15:58:00.000Z",
        "description": "Prénom :\nNom : DUBRECQ\nAdresse : 6 r Jean Marie Frouin\nC.P. : 59121\nVille : PROUVY\nTéléphone : 0327289485\nTél. n°2 : \nFax : \nMobile : 0666611011\nE-mail : sabiaux.dubrecq@orange.fr\nSite Internet : https://www.sabiaux-dubrecq.fr\nSIREN : 52421090300018\nCode Naf : 4321A",
        "html_description": "Prénom :<br> Nom : DUBRECQ<br>Adresse : 6 r Jean Marie Frouin<br>C.P. : 59121<br>Ville : PROUVY<br>Téléphone : 0327289485<br>Tél. n°2 : <br>Fax : <br>Mobile : 0666611011<br>E-mail : sabiaux.dubrecq@orange.fr<br>Site Internet : https://www.sabiaux-dubrecq.fr<br>SIREN : 52421090300018<br>Code Naf : 4321A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18416495,
        "title": "S . D . A . Société de Distribution d'Automatismes",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-21",
        "remind_time": "10:45",
        "created_at": "2022-01-17T10:10:33.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T15:57:47.000Z",
        "description": "Prénom : Christophe\nNom: DELAHAIE\nAdresse : Crt N 1 696 bd Petit Quinquin\nC.P. : 59273\nVille : FRETIN\nTéléphone : 0320904080\nTél. n°2 : \nFax : 0320904081\nMobile : \nE-mail : contact@sda-bft.com\nSite Internet : http://www.sda-bft.com\nSIREN : 39007710500059\nCode Naf : 4652Z",
        "html_description": "Prénom : Christophe<br>Nom: DELAHAIE<br>Adresse : Crt N 1 696 bd Petit Quinquin<br>C.P. : 59273<br>Ville : FRETIN<br>Téléphone : 0320904080<br>Tél. n°2 : <br>Fax : 0320904081<br>Mobile : <br>E-mail : contact@sda-bft.com<br>Site Internet : http://www.sda-bft.com<br>SIREN : 39007710500059<br>Code Naf : 4652Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18421973,
        "title": "Gattus Sylvain",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-25",
        "remind_time": null,
        "created_at": "2022-01-17T15:53:16.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T15:56:19.000Z",
        "description": "Prénom : \nAdresse : Cours la Ville 916 rte Buche\nC.P. : 69470\nVille : COURS\nTéléphone : 0474647508\nTél. n°2 : No Prospect\nFax : \nMobile : \nE-mail : serres@santille.fr\nSite Internet : \nSIREN : 41768753000010\nCode Naf : 6820B",
        "html_description": "Prénom : <br>Adresse : Cours la Ville 916 rte Buche<br>C.P. : 69470<br>Ville : COURS<br>Téléphone : 0474647508<br>Tél. n°2 : No Prospect<br>Fax : <br>Mobile : <br>E-mail : serres@santille.fr<br>Site Internet : <br>SIREN : 41768753000010<br>Code Naf : 6820B",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18420086,
        "title": "Seloflex",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-01-06",
        "remind_time": null,
        "created_at": "2022-01-17T14:34:42.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:35:42.000Z",
        "description": "Prénom : \nAdresse : 6 r Lll\nC.P. : 68350\nVille : BRUNSTATT DIDENHEIM\nTéléphone : 0389062499\nTél. n°2 : \nFax : 0389062623\nMobile : \nE-mail : contact@seloflex.fr\nSite Internet : http://www.seloflex-brunstatt.fr\nSIREN : 38903755700029\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 6 r Lll<br>C.P. : 68350<br>Ville : BRUNSTATT DIDENHEIM<br>Téléphone : 0389062499<br>Tél. n°2 : <br>Fax : 0389062623<br>Mobile : <br>E-mail : contact@seloflex.fr<br>Site Internet : http://www.seloflex-brunstatt.fr<br>SIREN : 38903755700029<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419925,
        "title": "Schweyer SARL",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-01",
        "remind_time": "11:00",
        "created_at": "2022-01-17T14:25:20.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:25:33.000Z",
        "description": "Prénom : \nAdresse : 6 r Artisanat\nC.P. : 67700\nVille : SAVERNE\nTéléphone : 0388031303\nTél. n°2 : \nFax : \nMobile : \nE-mail : info@schweyer.fr\nSite Internet : http://www.schweyer.fr\nSIREN : 30544525600046\nCode Naf : 4669B",
        "html_description": "Prénom : <br>Adresse : 6 r Artisanat<br>C.P. : 67700<br>Ville : SAVERNE<br>Téléphone : 0388031303<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : info@schweyer.fr<br>Site Internet : http://www.schweyer.fr<br>SIREN : 30544525600046<br>Code Naf : 4669B<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419886,
        "title": "Scherer Ets",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "08:30",
        "created_at": "2022-01-17T14:22:56.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:23:10.000Z",
        "description": "Prénom : \nAdresse : 2 r Hêtres\nC.P. : 67670\nVille : MOMMENHEIM\nTéléphone : 0388224225\nTél. n°2 : No Prospect\nFax : 0388755355\nMobile : \nE-mail : \nSite Internet : https://ets-scherer.business.site\nSIREN : 50741157700020\nCode Naf : 4332B",
        "html_description": "Prénom : <br>Adresse : 2 r Hêtres<br>C.P. : 67670<br>Ville : MOMMENHEIM<br>Téléphone : 0388224225<br>Tél. n°2 : No Prospect<br>Fax : 0388755355<br>Mobile : <br>E-mail : <br>Site Internet : https://ets-scherer.business.site<br>SIREN : 50741157700020<br>Code Naf : 4332B<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419836,
        "title": "SAS Sinetech",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "15:15",
        "created_at": "2022-01-17T14:19:48.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:20:08.000Z",
        "description": "Prénom : \nAdresse : 1 r Brunehaut\nC.P. : 60130\nVille : SAINT JUST EN CHAUSSEE\nTéléphone : 0344220218\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : https://www.sinetech.fr/site-web/\nSIREN : 88129449000027\nCode Naf : 4321A",
        "html_description": "Prénom : <br>Adresse : 1 r Brunehaut<br>C.P. : 60130<br>Ville : SAINT JUST EN CHAUSSEE<br>Téléphone : 0344220218<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : https://www.sinetech.fr/site-web/<br>SIREN : 88129449000027<br>Code Naf : 4321A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419744,
        "title": "Burdin Fleurs",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-12-15",
        "remind_time": null,
        "created_at": "2022-01-17T14:16:51.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:17:12.000Z",
        "description": "Prénom : \nAdresse : 14 r Commerce\nC.P. : 74100\nVille : ANNEMASSE\nTéléphone : 0450921180\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : https://www.burdin-fleurs.com/\nSIREN : 79658069400019\nCode Naf : 4776Z",
        "html_description": "Prénom : <br>Adresse : 14 r Commerce<br>C.P. : 74100<br>Ville : ANNEMASSE<br>Téléphone : 0450921180<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : https://www.burdin-fleurs.com/<br>SIREN : 79658069400019<br>Code Naf : 4776Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419694,
        "title": "SARL Caux",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-07",
        "remind_time": "10:30",
        "created_at": "2022-01-17T14:14:25.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:14:41.000Z",
        "description": "Prénom : \nAdresse : 33 r Paris\nC.P. : 60410\nVille : VERBERIE\nTéléphone : 0344409155\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.sarlcaux-menuiseries.fr\nSIREN : 69618002500012\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 33 r Paris<br>C.P. : 60410<br>Ville : VERBERIE<br>Téléphone : 0344409155<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.sarlcaux-menuiseries.fr<br>SIREN : 69618002500012<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419653,
        "title": "Sambre Menuiserie Sté",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "11:45",
        "created_at": "2022-01-17T14:12:14.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:12:47.000Z",
        "description": "Prénom : \nAdresse : 33 r Pierre de Coubertin\nC.P. : 59600\nVille : MAUBEUGE\nTéléphone : 0327678567\nTél. n°2 : \nFax : 0327678082\nMobile : \nE-mail : \nSite Internet : http://www.facebook.com\nSIREN : 34482889200044\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 33 r Pierre de Coubertin<br>C.P. : 59600<br>Ville : MAUBEUGE<br>Téléphone : 0327678567<br>Tél. n°2 : <br>Fax : 0327678082<br>Mobile : <br>E-mail : <br>Site Internet : http://www.facebook.com<br>SIREN : 34482889200044<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18148623,
        "title": "Carrosserie Du Gaes",
        "pipeline": null,
        "step": "Attente ACD",
        "step_id": 450479,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "15:00",
        "created_at": "2021-12-16T11:51:31.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T14:06:27.000Z",
        "description": "Prénom: Delphine\nNom: \nTéléphone: 05 56 77 56 37\nE-mail: carrosseriedugaes@orange.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: 33440\nVille: Ambarès-et-Lagrave\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---",
        "html_description": "Prénom: Delphine<br>Nom: <br>Téléphone: 05 56 77 56 37<br>E-mail: carrosseriedugaes@orange.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: 33440<br>Ville: Ambarès-et-Lagrave<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br><br>---<p><br></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419432,
        "title": "SAINT JUST MATERIAUX",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "14:30",
        "created_at": "2022-01-17T13:59:02.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T13:59:21.000Z",
        "description": "Prénom : \nAdresse : Zone Industrielle Nord r Tailbouis\nC.P. : 60130\nVille : SAINT JUST EN CHAUSSEE\nTéléphone : 0344785907\nTél. n°2 : 0344503876\nFax : 0344784241\nMobile : \nE-mail : \nSite Internet : http://www.saintjust-materiaux.fr\nSIREN : 32642014800032\nCode Naf : 4673A",
        "html_description": "Prénom : <br>Adresse : Zone Industrielle Nord r Tailbouis<br>C.P. : 60130<br>Ville : SAINT JUST EN CHAUSSEE<br>Téléphone : 0344785907<br>Tél. n°2 : 0344503876<br>Fax : 0344784241<br>Mobile : <br>E-mail : <br>Site Internet : http://www.saintjust-materiaux.fr<br>SIREN : 32642014800032<br>Code Naf : 4673A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419359,
        "title": "Saint Gobain Verre Solutions",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-21",
        "remind_time": "11:00",
        "created_at": "2022-01-17T13:53:42.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T13:53:56.000Z",
        "description": "Prénom : \nAdresse : 10 r Thomas Edison\nC.P. : 67450\nVille : MUNDOLSHEIM\nTéléphone : 0390249090\nTél. n°2 : \nFax : 0390249091\nMobile : \nE-mail : \nSite Internet : http://agence.verre-solutions.com\nSIREN : \nCode Naf : ",
        "html_description": "Prénom : <br>Adresse : 10 r Thomas Edison<br>C.P. : 67450<br>Ville : MUNDOLSHEIM<br>Téléphone : 0390249090<br>Tél. n°2 : <br>Fax : 0390249091<br>Mobile : <br>E-mail : <br>Site Internet : http://agence.verre-solutions.com<br>SIREN : <br>Code Naf : <br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18419303,
        "title": "SAFIR INDUSTRIE",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-01",
        "remind_time": "10:45",
        "created_at": "2022-01-17T13:49:43.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T13:50:00.000Z",
        "description": "Prénom : \nAdresse : Zone Industrielle D'orvilliers 4 chem Vignettes\nC.P. : 60490\nVille : CUVILLY\nTéléphone : 0344853370\nTél. n°2 : \nFax : 0344853371\nMobile : \nE-mail : \nSite Internet : http://www.groupesafir.com\nSIREN : 38898840400033\nCode Naf : 2512Z",
        "html_description": "Prénom : <br>Adresse : Zone Industrielle D'orvilliers 4 chem Vignettes<br>C.P. : 60490<br>Ville : CUVILLY<br>Téléphone : 0344853370<br>Tél. n°2 : <br>Fax : 0344853371<br>Mobile : <br>E-mail : <br>Site Internet : http://www.groupesafir.com<br>SIREN : 38898840400033<br>Code Naf : 2512Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18348173,
        "title": "Flash Car",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-22",
        "remind_time": "09:45",
        "created_at": "2022-01-10T15:01:05.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T13:36:58.000Z",
        "description": "Prénom: Alexis\nNom: GALVANI\nTéléphone: 0247544173\nE-mail: alexis.galvani@live.fr\nFournisseur: Total energie\nECHEANCE: \nSIREN: \nAdresse: 23 Rue Georges Méliès\nC.P.: 37100\nVille: Tours\nTél. n°2: \nFax: \nMobile: 0652027625\nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: Alexis<br>Nom: GALVANI<br>Téléphone: <span>0247544173</span><br>E-mail: alexis.galvani@live.fr<br>Fournisseur: Total energie<br>ECHEANCE: <br>SIREN: <br>Adresse: <span>23 Rue Georges Méliès</span><br>C.P.: 37100<br>Ville: Tours<br>Tél. n°2: <br>Fax: <br>Mobile: <span>0652027625</span><br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18373953,
        "title": "MA SALLE",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "14:45",
        "created_at": "2022-01-12T13:12:50.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T13:29:48.000Z",
        "description": "Prénom : \nDate de création : 15 octobre 2013\nAdresse : 69 r Pierre et Marie Curie\nC.P. : 87000\nVille : LIMOGES\nTéléphone : 0555361123\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.ma-salle.fr\nSIREN : 79795968100025\nCode Naf : 9312Z",
        "html_description": "Prénom : <br>Date de création : 15 octobre 2013<br>Adresse : 69 r Pierre et Marie Curie<br>C.P. : 87000<br>Ville : LIMOGES<br>Téléphone : 0555361123<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.ma-salle.fr<br>SIREN : 79795968100025<br>Code Naf : 9312Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18389894,
        "title": "P.T.M. Sécurité",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-24",
        "remind_time": "09:15",
        "created_at": "2022-01-13T16:19:52.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T10:59:10.000Z",
        "description": "Prénom : \nAdresse : 12 av Mar Joffre\nC.P. : 68100\nVille : MULHOUSE\nTéléphone : 0389460147\nTél. n°2 : 0389456750\nFax : \nMobile : \nE-mail : \nSite Internet : http://www.ptmsecurite.fr\nSIREN : 48424955200015\nCode Naf : 9529Z",
        "html_description": "Prénom : <br>Adresse : 12 av Mar Joffre<br>C.P. : 68100<br>Ville : MULHOUSE<br>Téléphone : 0389460147<br>Tél. n°2 : 0389456750<br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.ptmsecurite.fr<br>SIREN : 48424955200015<br>Code Naf : 9529Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18416985,
        "title": "Les plants des Joannets / Gaec Des Joannets",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-25",
        "remind_time": null,
        "created_at": "2022-01-17T10:47:43.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T10:49:04.000Z",
        "description": "Prénom : \nAdresse : Chavagneux\nC.P. : 69850\nVille : SAINT MARTIN EN HAUT\nTéléphone : 0478486238\nTél. n°2 : \nFax : \nMobile : \nE-mail :lesplantsdesjoannets@gmail.com \nSite Internet : http://granvillage.com/806-gaec-des-joannets\nSIREN : 75330904600013\nCode Naf : 0119Z\nprix fixe sur 3ans signé en décembre 2020",
        "html_description": "Prénom : <br>Adresse : Chavagneux<br>C.P. : 69850<br>Ville : SAINT MARTIN EN HAUT<br>Téléphone : 0478486238<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail :lesplantsdesjoannets@gmail.com <br>Site Internet : http://granvillage.com/806-gaec-des-joannets<br>SIREN : 75330904600013<br>Code Naf : 0119Z<br><br>prix fixe sur 3ans signé en décembre 2020",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18389607,
        "title": "Norba Alsace",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-21",
        "remind_time": "10:15",
        "created_at": "2022-01-13T15:59:55.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T10:40:41.000Z",
        "description": "Prénom : \nAdresse : Za De Bosquet r Lisière\nC.P. : 67580\nVille : MERTZWILLER\nTéléphone : 0388059440\nTél. n°2 : \nFax : 0388809870\nMobile : \nE-mail : \nSite Internet : http://www.norba-menuiserie.com\nSIREN : 78964087700013\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : Za De Bosquet r Lisière<br>C.P. : 67580<br>Ville : MERTZWILLER<br>Téléphone : 0388059440<br>Tél. n°2 : <br>Fax : 0388809870<br>Mobile : <br>E-mail : <br>Site Internet : http://www.norba-menuiserie.com<br>SIREN : 78964087700013<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18398925,
        "title": "Les Ateliers Du Plantau - Fondation OVE",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-24",
        "remind_time": "10:30",
        "created_at": "2022-01-14T14:26:12.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T10:12:37.000Z",
        "description": "Prénom : \nAdresse : 130 chem Pignet\nC.P. : 38160\nVille : CHATTE\nTéléphone : 0476380640\nTél. n°2 : \nFax : 0476640770\nMobile : \nE-mail : \nSite Internet : http://www.esat-horticulteur-gresivaudan.fr\nSIREN : 80125271900886\nCode Naf : 8810C\nAude Champon ",
        "html_description": "Prénom : <br>Adresse : 130 chem Pignet<br>C.P. : 38160<br>Ville : CHATTE<br>Téléphone : 0476380640<br>Tél. n°2 : <br>Fax : 0476640770<br>Mobile : <br>E-mail : <br>Site Internet : http://www.esat-horticulteur-gresivaudan.fr<br>SIREN : 80125271900886<br>Code Naf : 8810C<br><br><br>Aude Champon ",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18416461,
        "title": "Evrard Horticulture",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-17",
        "remind_time": null,
        "created_at": "2022-01-17T10:07:47.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T10:07:59.000Z",
        "description": "Prénom : \nAdresse : 73 chem Foray\nC.P. : 73160\nVille : COGNIN\nTéléphone : 0479626156\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.producteursdesavoie.com/evrard-patrick-horticulture-maraichage.html\nSIREN : 50151830200010\nCode Naf : 0130Z",
        "html_description": "Prénom : <br>Adresse : 73 chem Foray<br>C.P. : 73160<br>Ville : COGNIN<br>Téléphone : 0479626156<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.producteursdesavoie.com/evrard-patrick-horticulture-maraichage.html<br>SIREN : 50151830200010<br>Code Naf : 0130Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18416415,
        "title": "Rémi Menuiserie",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-21",
        "remind_time": null,
        "created_at": "2022-01-17T10:03:46.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T10:04:00.000Z",
        "description": "Prénom : \nAdresse : 1 r Vaudière\nC.P. : 60490\nVille : MAREUIL LA MOTTE\nTéléphone : 0344974182\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : https://www.remimenuiserie.fr/\nSIREN : 50124346300012\nCode Naf : 4332A",
        "html_description": "Prénom : <br>Adresse : 1 r Vaudière<br>C.P. : 60490<br>Ville : MAREUIL LA MOTTE<br>Téléphone : 0344974182<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : https://www.remimenuiserie.fr/<br>SIREN : 50124346300012<br>Code Naf : 4332A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18416256,
        "title": "Scierie Mosca",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-16",
        "remind_time": null,
        "created_at": "2022-01-17T09:47:31.000Z",
        "estimated_closing_date": "2022-02-10",
        "updated_at": "2022-01-17T09:47:45.000Z",
        "description": "Prénom: Scierie Mosca\nNom: \nTéléphone: 04 76 97 61 95 \nE-mail: scieriemosca@gmail.com \nFournisseur: EDF\nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \nNo Compteur: \n---\r\nRappel Mi février",
        "html_description": "Prénom: Scierie Mosca<br>Nom: <br>Téléphone: 04 76 97 61 95 <br>E-mail: <a href=\"mailto:scieriemosca@gmail.com\">scieriemosca@gmail.com</a> <br>Fournisseur: EDF<br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>No Compteur: <br>\r\n---\r\n<p>Rappel Mi février</p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18375171,
        "title": "Scierie Dagand",
        "pipeline": null,
        "step": "Closing",
        "step_id": 450474,
        "status": "won",
        "amount": 4600.0,
        "probability": 100,
        "currency": "EUR",
        "starred": false,
        "remind_date": null,
        "remind_time": null,
        "created_at": "2022-01-12T14:37:44.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T09:06:41.000Z",
        "description": "Prénom:Delphine\nNom:Dagand\nTéléphone:04 50 77 51 33\nE-mail:scieriedagand@orange.fr \nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r\nSigné Priméo le 26/11/2021 : 01/12/2021 à 31/12/2021\nSigné chez Gazel le 26/11/2021 : 01/01/2022 à 31/12/2025",
        "html_description": "Prénom:Delphine<br>Nom:Dagand<br>Téléphone:04 50 77 51 33<br>E-mail:scieriedagand@orange.fr <br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p>Signé Priméo le 26/11/2021 : 01/12/2021 à 31/12/2021<br>Signé chez Gazel le 26/11/2021 : 01/01/2022 à 31/12/2025</p>",
        "tags": [],
        "created_from": null,
        "closed_at": "2022-01-12",
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18290915,
        "title": "Les Callunas D'Alsace SARL",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-10-05",
        "remind_time": null,
        "created_at": "2022-01-04T11:00:30.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T09:01:14.000Z",
        "description": "Prénom : \nAdresse : 4 chem Sablière\nC.P. : 67870\nVille : BISCHOFFSHEIM\nTéléphone : 0388504225\nTél. n°2 : \nFax : \nMobile : \nE-mail : callunas@orange.fr\nSite Internet : http://callunas-alsace.com\nSIREN : 50146286500020\nCode Naf : 0130Z\nContrat chez ES signé en oct 2021 => oct 2024",
        "html_description": "Prénom : <br>Adresse : 4 chem Sablière<br>C.P. : 67870<br>Ville : BISCHOFFSHEIM<br>Téléphone : 0388504225<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : callunas@orange.fr<br>Site Internet : http://callunas-alsace.com<br>SIREN : 50146286500020<br>Code Naf : 0130Z<br><br><br>Contrat chez ES signé en oct 2021 =&gt; oct 2024",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18292245,
        "title": "Boutroy-Charalambous",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-25",
        "remind_time": "10:30",
        "created_at": "2022-01-04T13:12:12.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T09:00:51.000Z",
        "description": "Prénom : \nAdresse : r Gare\nC.P. : 02800\nVille : VERSIGNY\nTéléphone : 0323563170\nTél. n°2 : \nFax : 0323565654\nMobile : \nE-mail : \nSite Internet : http://www.boutroy-charalambous-chauffage.fr\nSIREN : 42891790000014\nCode Naf : 4322B",
        "html_description": "Prénom : <br>Adresse : r Gare<br>C.P. : 02800<br>Ville : VERSIGNY<br>Téléphone : 0323563170<br>Tél. n°2 : <br>Fax : 0323565654<br>Mobile : <br>E-mail : <br>Site Internet : http://www.boutroy-charalambous-chauffage.fr<br>SIREN : 42891790000014<br>Code Naf : 4322B<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18292848,
        "title": "Carton Sté",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-10",
        "remind_time": "09:45",
        "created_at": "2022-01-04T14:00:04.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T08:57:53.000Z",
        "description": "Prénom : Yves\nNom: CARTON\nAdresse : 11 r Frénelet\nC.P. : 59650\nVille : VILLENEUVE D'ASCQ\nTéléphone : 0320569595\nTél. n°2 : \nFax : 0320479229\nMobile : \nE-mail : contact@yvescarton.fr\nSite Internet : http://stecarton.site-solocal.com\nSIREN : 33783226500025\nCode Naf : 4321A",
        "html_description": "Prénom : Yves<br>Nom: CARTON<br>Adresse : 11 r Frénelet<br>C.P. : 59650<br>Ville : VILLENEUVE D'ASCQ<br>Téléphone : 0320569595<br>Tél. n°2 : <br>Fax : 0320479229<br>Mobile : <br>E-mail : contact@yvescarton.fr<br>Site Internet : http://stecarton.site-solocal.com<br>SIREN : 33783226500025<br>Code Naf : 4321A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18396112,
        "title": "Brasserie Mira",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "cancelled",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": null,
        "remind_time": null,
        "created_at": "2022-01-14T09:30:49.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T08:50:26.000Z",
        "description": "Prénom : \nNom : Belec\nTéléphone : 0631900510\nE-mail : \nFournisseur : \nECHEANCE : \nSIREN : \nAdresse : \nC.P. : \nVille : \nTél. n°2 : \nFax : \nMobile : \nSite Internet : \nCode Naf : ",
        "html_description": "Prénom : <br>Nom : Belec<br>Téléphone : 0631900510<br>E-mail : <br>Fournisseur : <br>ECHEANCE : <br>SIREN : <br>Adresse : <br>C.P. : <br>Ville : <br>Tél. n°2 : <br>Fax : <br>Mobile : <br>Site Internet : <br>Code Naf : <br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": "2022-01-17",
        "attachment_count": 0,
        "created_by_id": 186131,
        "user_id": 185052,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18305765,
        "title": "Daché Bernard SAS",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-24",
        "remind_time": "14:15",
        "created_at": "2022-01-05T14:17:23.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T08:50:00.000Z",
        "description": "Prénom : \nAdresse : 38 r Henri Pauquet\nC.P. : 60100\nVille : CREIL\nTéléphone : 0344555232\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://bernard-dache.fr\nSIREN : 31144052300042\nCode Naf : 4321A",
        "html_description": "Prénom : <br>Adresse : 38 r Henri Pauquet<br>C.P. : 60100<br>Ville : CREIL<br>Téléphone : 0344555232<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://bernard-dache.fr<br>SIREN : 31144052300042<br>Code Naf : 4321A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18163209,
        "title": "Tourenne Florent",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "14:30",
        "created_at": "2021-12-17T15:31:49.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T08:32:46.000Z",
        "description": "Nom : M TOURENNE\nAdresse : 45 Bis bd Gambetta\nC.P. : 34800\nVille : CLERMONT L'HERAULT\nTéléphone : 0467961207\nTél. n°2 : 0975738493\nFax : 0467880376\nMobile : \nE-mail : \nSite Internet : http://www.garage-couturier-clermont.fr\nSIREN : 84185376500029\nCode Naf : 4520A",
        "html_description": "Nom : M TOURENNE<br>Adresse : 45 Bis bd Gambetta<br>C.P. : 34800<br>Ville : CLERMONT L'HERAULT<br>Téléphone : 0467961207<br>Tél. n°2 : 0975738493<br>Fax : 0467880376<br>Mobile : <br>E-mail : <br>Site Internet : http://www.garage-couturier-clermont.fr<br>SIREN : 84185376500029<br>Code Naf : 4520A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18303311,
        "title": "Etablissements Horticoles Maguy SAS",
        "pipeline": null,
        "step": "Pré-Closing",
        "step_id": 450473,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-11-23",
        "remind_time": null,
        "created_at": "2022-01-05T10:51:40.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T08:30:25.000Z",
        "description": "Prénom : Madame Dartagnan\nAdresse : 53 imp Hermitage\nC.P. : 17610\nVille : CHANIERS\nTéléphone : 0546915054\nTél. n°2 : \nFax : 0546914969\nMobile : \nE-mail : \nSite Internet : \nSIREN : 41208378400014\nCode Naf : 0119Z\nAntargaz engagé 2 ans \nfin d'engagement 5 Octobre 2023\nMail envoyé le 05/01/2022",
        "html_description": "Prénom : Madame Dartagnan<br>Adresse : 53 imp Hermitage<br>C.P. : 17610<br>Ville : CHANIERS<br>Téléphone : 0546915054<br>Tél. n°2 : <br>Fax : 0546914969<br>Mobile : <br>E-mail : <br>Site Internet : <br>SIREN : 41208378400014<br>Code Naf : 0119Z<br><br>Antargaz engagé 2 ans <br>fin d'engagement 5 Octobre 2023<br>Mail envoyé le 05/01/2022",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 1,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18147358,
        "title": "Auto Angel Martinez A.A.M",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-20",
        "remind_time": "10:45",
        "created_at": "2021-12-16T09:46:30.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T08:10:35.000Z",
        "description": "Nom : \nAdresse : 9 La Louvade\nC.P. : 34130\nVille : MAUGUIO\nTéléphone : 0467120012\nTél. n°2 : \nFax : 0467120001\nMobile : \nE-mail : amartinez@renault-mauguio.fr\nSite Internet : http://www.garage-auto-martinez.fr\nSIREN : 38076114800028\nCode Naf : 4520A",
        "html_description": "Nom : <br>Adresse : 9 La Louvade<br>C.P. : 34130<br>Ville : MAUGUIO<br>Téléphone : 0467120012<br>Tél. n°2 : <br>Fax : 0467120001<br>Mobile : <br>E-mail : amartinez@renault-mauguio.fr<br>Site Internet : http://www.garage-auto-martinez.fr<br>SIREN : 38076114800028<br>Code Naf : 4520A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18387204,
        "title": "L'oeuf Des Deux Moulins",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-17",
        "remind_time": "09:30",
        "created_at": "2022-01-13T13:45:05.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T07:30:24.000Z",
        "description": "E-mail : \nSite Internet : \nNom : \nDate Création : 1 janvier 1995\nAdresse : Le Fief Sauvin val Evre\nC.P. : 49600\nVille : MONTREVAULT SUR EVRE\nTéléphone :  0241702032\nTél. n°2 : \nFax : 0241702480\nMobile : \nSIREN : 39956003600010\nCode Naf : 4633Z",
        "html_description": "E-mail : <br>Site Internet : <br>Nom : <br>Date Création : 1 janvier 1995<br>Adresse : Le Fief Sauvin val Evre<br>C.P. : 49600<br>Ville : MONTREVAULT SUR EVRE<br>Téléphone :  0241702032<br>Tél. n°2 : <br>Fax : 0241702480<br>Mobile : <br>SIREN : 39956003600010<br>Code Naf : 4633Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185052,
        "user_id": 185052,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18395793,
        "title": "Carrosserie Michon",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-09-04",
        "remind_time": "10:00",
        "created_at": "2022-01-14T08:47:03.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T06:42:07.000Z",
        "description": "Nom : MICHON\nAdresse : 10 r Palus\nC.P. : 34110\nVille : FRONTIGNAN\nTéléphone : 0467481682\nTél. n°2 : \nFax : \nMobile : 0623794176\nE-mail : garage-michon@sfr.fr\nSite Internet : http://www.carrosserie-peinture-frontignan.fr\nSIREN : 42933697700021\nCode Naf : 4520A",
        "html_description": "Nom : MICHON<br>Adresse : 10 r Palus<br>C.P. : 34110<br>Ville : FRONTIGNAN<br>Téléphone : 0467481682<br>Tél. n°2 : <br>Fax : <br>Mobile : 0623794176<br>E-mail : garage-michon@sfr.fr<br>Site Internet : http://www.carrosserie-peinture-frontignan.fr<br>SIREN : 42933697700021<br>Code Naf : 4520A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18400539,
        "title": "Saussac Jean-Pierre",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-17",
        "remind_time": null,
        "created_at": "2022-01-14T16:01:29.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T02:05:07.000Z",
        "description": "Prénom : \nAdresse : 701 rte Etournelles\nC.P. : 01200\nVille : CHATILLON EN MICHAILLE\nTéléphone : 0450482126\nTél. n°2 : \nFax : 0450487886\nMobile : \nE-mail :jean-pierre.saussac@wanadoo.fr\nSite Internet : \nSIREN : 32298396600027\nCode Naf : 0119Z",
        "html_description": "Prénom : <br>Adresse : 701 rte Etournelles<br>C.P. : 01200<br>Ville : CHATILLON EN MICHAILLE<br>Téléphone : 0450482126<br>Tél. n°2 : <br>Fax : 0450487886<br>Mobile : <br>E-mail :<span>jean-pierre.saussac@wanadoo.fr</span><br>Site Internet : <br>SIREN : 32298396600027<br>Code Naf : 0119Z",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18387764,
        "title": "Bernard Frediere Horticulteur",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-17",
        "remind_time": null,
        "created_at": "2022-01-13T14:18:42.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T02:04:57.000Z",
        "description": "Prénom : \nAdresse : 17 r Font Rozet\nC.P. : 42400\nVille : SAINT CHAMOND\nTéléphone : 0477315439\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://meristaime.site-solocal.com\nSIREN : 85283058700014\nCode Naf : 0130Z",
        "html_description": "Prénom : <br>Adresse : 17 r Font Rozet<br>C.P. : 42400<br>Ville : SAINT CHAMOND<br>Téléphone : 0477315439<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://meristaime.site-solocal.com<br>SIREN : 85283058700014<br>Code Naf : 0130Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18376409,
        "title": "Citeflor",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "todo",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-17",
        "remind_time": null,
        "created_at": "2022-01-12T15:38:46.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-17T02:04:50.000Z",
        "description": "Prénom : \nAdresse : 1 all Jean Rostand\nC.P. : 33650\nVille : MARTILLAC\nTéléphone : 0556644445\nTél. n°2 : \nFax : 0556644449\nMobile : 0677720982\nE-mail : \nSite Internet : http://www.citeflor.fr\nSIREN : 42172701700020\nCode Naf : 4649Z",
        "html_description": "Prénom : <br>Adresse : 1 all Jean Rostand<br>C.P. : 33650<br>Ville : MARTILLAC<br>Téléphone : 0556644445<br>Tél. n°2 : <br>Fax : 0556644449<br>Mobile : 0677720982<br>E-mail : <br>Site Internet : http://www.citeflor.fr<br>SIREN : 42172701700020<br>Code Naf : 4649Z<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18397148,
        "title": "Portails & Clotures",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-24",
        "remind_time": "10:45",
        "created_at": "2022-01-14T11:09:48.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-15T15:50:39.000Z",
        "description": "Prénom : Olivier\nNom : PREVOST\nAdresse : 180 r Jean Pierre Douay\nC.P. : 80210\nVille : AIGNEVILLE\nTéléphone : 0322241210\nTél. n°2 : \nFax : \nMobile : 0643706268\nE-mail : celine.olivier1@orange.fr\nSite Internet : http://morelprevostcelineliseliliane.site-solocal.com\nSIREN : 81916095300017\nCode Naf : 4799A",
        "html_description": "Prénom : Olivier<br>Nom : PREVOST<br>Adresse : 180 r Jean Pierre Douay<br>C.P. : 80210<br>Ville : AIGNEVILLE<br>Téléphone : 0322241210<br>Tél. n°2 : <br>Fax : <br>Mobile : 0643706268<br>E-mail : celine.olivier1@orange.fr<br>Site Internet : http://morelprevostcelineliseliliane.site-solocal.com<br>SIREN : 81916095300017<br>Code Naf : 4799A",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18200107,
        "title": "Boulangerie Josse",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-08",
        "remind_time": "10:15",
        "created_at": "2021-12-22T08:28:00.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-15T14:23:14.000Z",
        "description": "Prénom: Joel\nNom: JOSSE\nTéléphone: 05 53 71 03 85\nE-mail: angelesudreau045@gmail.com\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: 10 Rue du Barry\nC.P.: 47500\nVille: Fumel\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: Boulangerie et boulangerie-pâtisserie (158C)\n---\r",
        "html_description": "Prénom: Joel<br>Nom: JOSSE<br>Téléphone: 05 53 71 03 85<br>E-mail: angelesudreau045@gmail.com<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: 10 Rue du Barry<br>C.P.: 47500<br>Ville: Fumel<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: Boulangerie et boulangerie-pâtisserie (158C)<br><br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18200911,
        "title": "Clean Pressing",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-26",
        "remind_time": "10:15",
        "created_at": "2021-12-22T09:13:42.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T19:55:37.000Z",
        "description": "Prénom: Fabian\nNom: GUICQUEL\nTéléphone: 02 40 97 71 85\nE-mail: fabpressing@gmail.com\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: 44400\nVille: Reze\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: Fabian<br>Nom: GUICQUEL<br>Téléphone: 02 40 97 71 85<br>E-mail: fabpressing@gmail.com<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: 44400<br>Ville: Reze<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18187651,
        "title": "Meca J.C.A",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-01-03",
        "remind_time": null,
        "created_at": "2021-12-21T10:09:18.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T16:46:23.000Z",
        "description": "Prénom: \nNom: THOMASSEAU\nTéléphone: 02 47 58 66 66\nE-mail: mecajca@wanadoo.fr\nFournisseur: \nECHEANCE: 2024\nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: <br>Nom: THOMASSEAU<br>Téléphone: <span>02 47 58 66 66</span><br>E-mail: mecajca@wanadoo.fr<br>Fournisseur: <br>ECHEANCE: 2024<br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18372519,
        "title": "L Orange bleue",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "11:15",
        "created_at": "2022-01-12T11:02:09.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T16:03:19.000Z",
        "description": "Prénom : Mme MANESCAU CATHERINE\nDate de création : 1 novembre 1994\nAdresse : av Alexandre Fleming\nC.P. : 64400\nVille : OLORON SAINTE MARIE\nTéléphone : 0559398159\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : http://www.e-leclerc.com\nSIREN : 39917079400016\nCode Naf : 4711F",
        "html_description": "Prénom : Mme MANESCAU CATHERINE<br>Date de création : 1 novembre 1994<br>Adresse : av Alexandre Fleming<br>C.P. : 64400<br>Ville : OLORON SAINTE MARIE<br>Téléphone : 0559398159<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : http://www.e-leclerc.com<br>SIREN : 39917079400016<br>Code Naf : 4711F<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18395998,
        "title": "Pernin Ets",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-19",
        "remind_time": "11:00",
        "created_at": "2022-01-14T09:14:49.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T15:40:34.000Z",
        "description": "Prénom : \nAdresse : RN 330 15 rte Senlis\nC.P. : 60330\nVille : LAGNY LE SEC\nTéléphone : 0344601071\nTél. n°2 : \nFax : 0344600743\nMobile : \nE-mail : Pernin.serge@wanadoo.fr\nSite Internet : http://pernin.artetfenetres.com\nSIREN : 43990043200022\nCode Naf : 4759B",
        "html_description": "Prénom : <br>Adresse : RN 330 15 rte Senlis<br>C.P. : 60330<br>Ville : LAGNY LE SEC<br>Téléphone : 0344601071<br>Tél. n°2 : <br>Fax : 0344600743<br>Mobile : <br>E-mail : Pernin.serge@wanadoo.fr<br>Site Internet : http://pernin.artetfenetres.com<br>SIREN : 43990043200022<br>Code Naf : 4759B",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18389410,
        "title": "Moos",
        "pipeline": null,
        "step": "Prospection",
        "step_id": 450471,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-01-24",
        "remind_time": "11:00",
        "created_at": "2022-01-13T15:49:21.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T15:21:24.000Z",
        "description": "Prénom : \nAdresse : 83 r Alliés\nC.P. : 67680\nVille : EPFIG\nTéléphone : 0389768929\nTél. n°2 : \nFax : \nMobile : \nE-mail : \nSite Internet : https://www.moos.fr/contact.html\nSIREN : 35391419500022\nCode Naf : 4752A",
        "html_description": "Prénom : <br>Adresse : 83 r Alliés<br>C.P. : 67680<br>Ville : EPFIG<br>Téléphone : 0389768929<br>Tél. n°2 : <br>Fax : <br>Mobile : <br>E-mail : <br>Site Internet : https://www.moos.fr/contact.html<br>SIREN : 35391419500022<br>Code Naf : 4752A<br>",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18148515,
        "title": "Garage PACTAT",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2024-01-02",
        "remind_time": "09:30",
        "created_at": "2021-12-16T11:38:38.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T15:12:18.000Z",
        "description": "Prénom: Regis\nNom: PACTAT\nTéléphone: 02 48 59 51 49\nE-mail: garage.pactat@wanadoo.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: \nVille: \nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r\nEngagé pour 2 ans",
        "html_description": "Prénom: Regis<br>Nom: PACTAT<br>Téléphone: 02 48 59 51 49<br>E-mail: garage.pactat@wanadoo.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: <br>Ville: <br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p>Engagé pour 2 ans</p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18399178,
        "title": "FLORISY EARL",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2022-02-01",
        "remind_time": null,
        "created_at": "2022-01-14T14:42:59.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T14:47:26.000Z",
        "description": "Prénom : \nAdresse : 625 lieu-dit Chantelouve\nC.P. : 26300\nVille : ALIXAN\nTéléphone : \nTél. n°2 : \nFax : \nMobile : 0630314513\nE-mail :florisyearl@gmail.com \nSite Internet : \nSIREN : 53305011800017\nCode Naf : 0130Z\nreste 3 ans de contrat",
        "html_description": "Prénom : <br>Adresse : 625 lieu-dit Chantelouve<br>C.P. : 26300<br>Ville : ALIXAN<br>Téléphone : <br>Tél. n°2 : <br>Fax : <br>Mobile : 0630314513<br>E-mail :florisyearl@gmail.com <br>Site Internet : <br>SIREN : 53305011800017<br>Code Naf : 0130Z<br><br>reste 3 ans de contrat",
        "tags": [],
        "created_from": "spreadsheet",
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185049,
        "user_id": 185049,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    },
    {
        "id": 18200862,
        "title": "Alliance automotive",
        "pipeline": null,
        "step": "Email Qualifié",
        "step_id": 450472,
        "status": "standby",
        "amount": null,
        "probability": null,
        "currency": null,
        "starred": false,
        "remind_date": "2023-01-03",
        "remind_time": null,
        "created_at": "2021-12-22T09:11:35.000Z",
        "estimated_closing_date": null,
        "updated_at": "2022-01-14T14:19:41.000Z",
        "description": "Prénom: Isabelle\nNom: BREAU\nTéléphone: 01 40 89 28 28\nE-mail: i.breau@allianceautomotive.fr\nFournisseur: \nECHEANCE: \nSIREN: \nAdresse: \nC.P.: 92300\nVille: Levallois-Perret\nTél. n°2: \nFax: \nMobile: \nSite Internet: \nCode Naf: \n---\r",
        "html_description": "Prénom: Isabelle<br>Nom: BREAU<br>Téléphone: 01 40 89 28 28<br>E-mail: i.breau@allianceautomotive.fr<br>Fournisseur: <br>ECHEANCE: <br>SIREN: <br>Adresse: <br>C.P.: 92300<br>Ville: Levallois-Perret<br>Tél. n°2: <br>Fax: <br>Mobile: <br>Site Internet: <br>Code Naf: <br>\r\n---\r\n<p></p>",
        "tags": [],
        "created_from": null,
        "closed_at": null,
        "attachment_count": 0,
        "created_by_id": 185053,
        "user_id": 185053,
        "client_folder_id": null,
        "client_folder_name": null,
        "team_id": null,
        "team_name": null
    }
]


