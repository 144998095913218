<template>
  <div>
    <div
      v-if="searchActive"
      class="tableFilters d-flex justify-content-start align-items-center my-4"
    >
      <input
        class="form-control search-input"
        type="text"
        v-model="search"
        placeholder="Rechercher..."
        @input="$emit('search', { type: 'search', key: search })"
        style="width: 300px"
      />
    </div>
    <table
      class="table table-striped table-bordered"
      style="display: block; width: 100%; overflow-x: auto"
    >
      <thead>
        <tr>
          <th
            style="text-align: center"
            v-for="column in columns"
            :key="column.name"
            v-if="column.sortable"
            @click="$emit('sort', column.name)"
            :class="
              sortKey == column.name
                ? sortOrders[column.name] > 0
                  ? 'sorting_asc'
                  : 'sorting_desc'
                : 'sorting'
            "
            :style="'width:' + column.width + ';' + 'cursor:pointer;'"
          >
            {{ column.label }}
          </th>
          <th
            style="text-align: center"
            :key="column.name"
            v-else
            :class="
              sortKey == column.name
                ? sortOrders[column.name] > 0
                  ? 'sorting_asc'
                  : 'sorting_desc'
                : 'sorting'
            "
            :style="'width:' + column.width + ';' + 'cursor:pointer;'"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <slot name="body"></slot>
    </table>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
export default {
  props: {
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    sortKey: {
      type: String,
      default: "",
    },
    sortOrders: {
      type: Object,
      default: function () {
        return {};
      },
    },
    searchActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>
<style scoped>
@media only screen and (max-width: 350px) {
  .search-input {
    width: 125px !important;
  }
}

@media only screen and (max-height: 1368px) {
  .screen-phone {
    height: 300px !important;
  }
}
</style>
<style scoped>
@media only screen and (min-width: 1400px) {
  .screen-phone {
    height: 100% !important;
  }
}
</style>
