<template>
  <div>
    <loadingComponent
      v-if="dialog == true"
      @closeDialog="dialog = $event"
      :dialogProps="dialog"
      :messageDialogeProps="messageDialogeError"
      :loadingProps="loading"
      :errorProps="error"
    />

    <v-dialog v-model="addData" max-width="850" persistent>
      <v-card>
        <v-toolbar :color="$colors[0]" dark>
          <v-card-title class="text-h6"> Informations complémentaires </v-card-title>
        </v-toolbar>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-row>
            <!-- IBAN -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="otherData[1].iban"
                label="IBAN"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                v-if="loading == false"
                :color="$colors[0]"
                class="white--text"
                @click="getBankName"
              >
                Confirmer
              </v-btn>
            </v-col>
            <!-- BIC -->
            <v-col cols="12" md="4">
              <v-text-field
                label="BIC"
                disabled
                v-model="otherData[1].bic"
                class="mt-2"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-row v-if="goodIban == true">
                <!-- Company Name -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Nom Entreprise</p>
                  <v-text-field
                    v-model="otherData[1].companyName"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Siret -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">siret</p>
                  <v-text-field
                    v-model="otherData[1].siret"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- VAT Number -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">numéro TVA</p>
                  <v-text-field
                    v-model="otherData[1].vatNumber"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Firstname -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Nom</p>
                  <v-text-field
                    v-model="otherData[1].firstname"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Lastname -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Prenom</p>
                  <v-text-field
                    v-model="otherData[1].lastname"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Email</p>
                  <v-text-field
                    v-model="otherData[1].email"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Offer Validity End Date -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Date Fin Validité Offre</p>
                  <v-text-field
                    type="date"
                    v-model="otherData[1].offerValidityEndDate"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Billing Company Name -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Nom Entreprise Facturation</p>
                  <v-text-field
                    v-model="otherData[1].billingCompanyName"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Billing Address -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Adresse Facturation</p>
                  <v-text-field
                    v-model="otherData[1].billingAddress"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Billing City -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Ville Facturation</p>
                  <v-text-field
                    v-model="otherData[1].billingCity"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Billing Postal Code -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Code Postal Facturation</p>
                  <v-text-field
                    v-model="otherData[1].billingPostalCode"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Billing Email -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Email Facturation</p>
                  <v-text-field
                    v-model="otherData[1].billingEmail"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- SEPA Company Name -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Nom Entreprise SEPA</p>
                  <v-text-field
                    v-model="otherData[1].sepaCompanyName"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- SEPA Siret -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Siret SEPA</p>
                  <v-text-field
                    v-model="otherData[1].sepaSiret"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- SEPA Address -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">adresse SEPA</p>
                  <v-text-field
                    v-model="otherData[1].sepaAddress"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- SEPA Postal Code -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Code Postal SEPA</p>
                  <v-text-field
                    v-model="otherData[1].sepaPostalCode"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- SEPA City -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">ville SEPA</p>
                  <v-text-field
                    v-model="otherData[1].sepaCity"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Delivery Points -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Numéro PDL</p>
                  <v-text-field
                    v-model="otherData[1].deliveryPoints[0].pdl.number"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Adresse PDL</p>
                  <v-text-field
                    v-model="otherData[1].deliveryPoints[0].pdl.address"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Code postal PDL</p>
                  <v-text-field
                    v-model="otherData[1].deliveryPoints[0].pdl.postalCode"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Ville PDL</p>
                  <v-text-field
                    v-model="otherData[1].deliveryPoints[0].pdl.city"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Power Subscribed -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Puissance Souscrite</p>
                  <v-select
                    v-model="otherData[1].deliveryPoints[0].powerSubscribed"
                    outlined
                    class="mt-2"
                    dense
                    :items="['3', '6', '9', '12', '15', '18', '24', '30', '36']"
                  >
                  </v-select>
                </v-col>

                <!-- Duration Start Date -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Date Début</p>
                  <v-text-field
                    v-model="otherData[1].deliveryPoints[0].duration.startDate"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Duration Months -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Durée</p>
                  <v-radio-group
                    v-model="otherData[1].deliveryPoints[0].duration.months"
                    row
                    dense
                  >
                    <v-radio label="12" :value="12"></v-radio>
                    <v-radio label="24" :value="24"></v-radio>
                    <v-radio label="36" :value="36"></v-radio>
                  </v-radio-group>
                </v-col>

                <!-- Price Structure -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Structure Tarifaire</p>
                  <v-radio-group
                    v-model="otherData[1].deliveryPoints[0].priceStructure"
                    row
                    dense
                  >
                    <v-radio label="base" value="base"></v-radio>
                    <v-radio label="HpHc" value="hpHc"></v-radio>
                  </v-radio-group>
                </v-col>

                <!-- FTA -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">FTA</p>
                  <v-radio-group v-model="otherData[1].deliveryPoints[0].fta" row dense>
                    <v-radio label="CU4" value="CU4"></v-radio>
                    <v-radio label="MU4" value="MU4"></v-radio>
                  </v-radio-group>
                </v-col>

                <!-- Pricing Margin -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">marge</p>
                  <v-text-field
                    @input="changeMargin"
                    v-model="otherData[1].deliveryPoints[0].pricing.margin"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Pricing Rate Base -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Tarif de base</p>
                  <v-text-field
                    disabled
                    v-model="otherData[1].deliveryPoints[0].pricing.rate.base"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Pricing Rate HP -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Tarif de hp</p>
                  <v-text-field
                    disabled
                    v-model="otherData[1].deliveryPoints[0].pricing.rate.hp"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Pricing Rate HC -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Tarif de hc</p>
                  <v-text-field
                    disabled
                    v-model="otherData[1].deliveryPoints[0].pricing.rate.hc"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- GRD -->
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">grd</p>
                  <v-text-field
                    v-model="otherData[1].deliveryPoints[0].grd"
                    class="mt-2"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col align="end" cols="12" md="12" sm="12" lg="12" xl="12" class="pa-0">
                  <v-btn
                    v-if="loading == false"
                    color="error"
                    text
                    @click="addData = false"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                    v-if="loading == false"
                    :color="$colors[0]"
                    class="white--text"
                    @click="createContrat"
                  >
                    Confirmer
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-btn @click="openModal" icon depressed class="ma-auto text-capitalize">
        <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import {
  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
  layoutMultilineText,
  setFillingRgbColor,
  setFontAndSize,
} from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import "jspdf-autotable";

import loadingComponent from "../../components/ui/loading-component.vue";
import axios from "axios";
export default {
  name: "JpmeContratC5",
  components: { loadingComponent },
  props: {
    donner: null,
    numCompteur: null,
    societe: null,
    donnerEnedis: null,
    optionTarifaire: null,
  },
  data() {
    return {
      goodIban: false,
      addData: false,
      dialog: false,
      error: false,
      loading: false,
      messageDialogeError: "",
      messageDialogeSucces: "",
      data: {
        dateAjourdhui:
          new Date().getDate() +
          "/" +
          (parseInt(new Date().getMonth()) + 1) +
          "/" +
          new Date().getFullYear(),
        dateDebut: "10/10/2020",
        dateFin: "10/10/2022",
        pdl: "14552022144555",
        siret: "11111111111111",
        codeNaf: "1212Z",
        adresseSociete: "199 RUE, 12554 commune d'atte",
        adresseCompteur: "199 RUE, 12554 commune d'atte",
        codePostaleCompteur: "",
        voieCompteur: "",
        communeCompteur: "",
        codePostaleSociete: "",
        voieSociete: "",
        communeSociete: "",

        codePostale: "",
        nom: "chrif",
        prenom: "slimen",
        email: "slimenc6@gmail",
        numTele: "0755448899",
        consommationAnuuelle: "377.20",
        typeCompteur: "BTINFMUDT",
        kva: "24",
        raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
        nbrMonth: "2",
        cabase: "",
        cahp: "",
        cahc: "",
      },
      infoBank: {
        bankName: "",
        bic: "",
        IBAN: "",
      },
      otherData: [
        {
          id: "",
          first_name: "Jeremy",
          last_name: "Seront",
          email: "jeremy@gaz-elec-moinscher.fr",
          emailVerified: null,
          image: null,
          hashedRefreshToken: null,
          role: "PARTNER",
          isTwoFactorEnabled: false,
          customerId: null,
          clientId: null,
          createdAt: "$D2024-09-20T08:51:51.583Z",
          updatedAt: "$D2024-09-20T08:51:51.583Z",
          deletedAt: null,
        },
        {
          contractType: "C5_PRO",
          contractId: "cm042co9y0002xpp036131ewe",
          companyName: "ENEFFIC",
          vatNexecuterJpmeumber: "FR28951979798",
          siret: "95197979800018",
          lastname: "test",
          firstname: "test",
          email: "slimenc6@gmail.com",
          phone: "0782184983",
          hasProxy: "false",
          deliveryPoints: [
            {
              pdl: {
                number: "19533285033627",
                address: "26 rue de cinsault 34970 lattes",
                postalCode: "34970",
                city: "lattes",
              },
              powerSubscribed: "6",
              duration: {
                startDate: "2024-10-01",
                months: null,
              },
              fta: "CU4",
              priceStructure: "base",
              pricing: {
                margin: "15",
                rate: {
                  base: 116.76,
                  hp: 122.4,
                  hc: 97.46,
                },
              },
              grd: "ENEDIS",
            },
          ],
          offerValidityEndDate: "2024-09-25",
          billingCompanyName: "ENEFFIC",
          billingAddress: "26 rue de cinsault",
          billingPostalCode: "34970",
          billingCity: "lattes",
          billingEmail: "slimenc6@gmail.com",
          sepaCompanyName: "eneffic",
          sepaSiret: "95197979800018",
          sepaAddress: "26 rue de sincault",
          sepaPostalCode: "34970",
          sepaCity: "lattes",
          bic: "CMCIFR2A",
          iban: "FR7610278361030001130760111",
        },
        {
          id: "cm042co9y0002xpp036131ewe",
          name: "C5 PRO",
          slug: "C5_PRO",
          version: 1,
          docusignTemplateId: "3908b6ae-e6b8-4af9-a5e1-8201deb2e62f",
          createdAt: "$D2024-09-10T13:37:16.990Z",
          updatedAt: "$D2024-09-10T15:48:49.448Z",
          deletedAt: null,
        },
      ],
      defaultBase: 0,
      defaultHp: 0,
      defaultHc: 0,
      defaultMarge: 0,
    };
  },
  methods: {
    changeMargin() {
      this.otherData[1].deliveryPoints[0].pricing.margin = this.otherData[1].deliveryPoints[0].pricing.margin.replace(
        ",",
        "."
      );
      let diff =
        Number(this.otherData[1].deliveryPoints[0].pricing.margin) -
        Number(this.defaultMarge);
      this.otherData[1].deliveryPoints[0].pricing.rate.base =
        Number(this.defaultBase) + diff;
      this.otherData[1].deliveryPoints[0].pricing.rate.hp = Number(this.defaultHp) + diff;
      this.otherData[1].deliveryPoints[0].pricing.rate.hc = Number(this.defaultHc) + diff;
    },
    openModal() {
      console.log(this.donner);
      this.addData = true;
      let adresseCompteur = "";
      let codePostalCompteur = "";
      let communCompteur = "";
      if (this.donnerEnedis["donneTechniqueInfo"][0]["result"] == "valide") {
        adresseCompteur = this.donnerEnedis["donneTechniqueInfo"][0]["data"]["adresse"];
        codePostalCompteur = this.donnerEnedis["donneTechniqueInfo"][0]["data"][
          "codePostal"
        ];
        communCompteur = this.donnerEnedis["donneTechniqueInfo"][0]["data"]["commune"];
      } else {
        adresseCompteur = this.societe.AdressePostal;
        codePostalCompteur = this.societe.codePostal;
        communCompteur = this.societe.commune;
      }

      this.otherData[0]["createdAt"] = "$D" + this.getCurrentISODate();
      this.otherData[0]["updatedAt"] = "$D" + this.getCurrentISODate();
      this.otherData[2]["createdAt"] = "$D" + this.getCurrentISODate();
      this.otherData[2]["updatedAt"] = "$D" + this.getCurrentISODate();
      this.otherData[1]["offerValidityEndDate"] = this.getCurrentISODate().split("T")[0];
      this.otherData[1]["bic"] = "";
      this.otherData[1]["iban"] = "";
      this.otherData[1]["companyName"] = this.societe.Raison;
      this.otherData[1]["vatNumber"] = this.societe.TVA;
      this.otherData[1]["siret"] = this.societe.siret;
      this.otherData[1]["lastname"] = this.societe.nom;
      this.otherData[1]["firstname"] = this.societe.prenom;
      this.otherData[1]["email"] = this.societe.AdresseEmail;
      this.otherData[1]["billingCompanyName"] = this.societe.Raison;
      this.otherData[1]["billingAddress"] = this.societe.AdressePostal;
      this.otherData[1]["billingCity"] = this.societe.commune;
      this.otherData[1]["billingPostalCode"] = this.societe.codePostal;
      this.otherData[1]["billingEmail"] = this.societe.AdresseEmail;
      this.otherData[1]["sepaCompanyName"] = this.societe.Raison;
      this.otherData[1]["sepaSiret"] = this.societe.siret;
      this.otherData[1]["sepaAddress"] = this.societe.AdressePostal;
      this.otherData[1]["sepaPostalCode"] = this.societe.codePostal;
      this.otherData[1]["sepaCity"] = this.societe.commune;

      this.otherData[1]["deliveryPoints"][0]["pdl"]["number"] = this.numCompteur;
      this.otherData[1]["deliveryPoints"][0]["pdl"]["address"] = adresseCompteur;
      this.otherData[1]["deliveryPoints"][0]["pdl"]["postalCode"] = codePostalCompteur;
      this.otherData[1]["deliveryPoints"][0]["pdl"]["city"] = communCompteur;

      this.otherData[1]["deliveryPoints"][0]["powerSubscribed"] = this.donner.kva;

      this.otherData[1]["deliveryPoints"][0]["duration"][
        "startDate"
      ] = this.donner.dateDebut;
      this.otherData[1]["deliveryPoints"][0]["duration"]["months"] = this.donner.duree;

      if (this.donner.optionTarifaire.indexOf("CU") != -1) {
        this.otherData[1]["deliveryPoints"][0]["fta"] = "CU4";
      } else {
        this.otherData[1]["deliveryPoints"][0]["fta"] = "MU4";
      }
      if (this.donner.parametreCompteur == "C5BASE") {
        this.otherData[1]["deliveryPoints"][0]["priceStructure"] = "base";
      } else if (this.donner.parametreCompteur == "C5HP") {
        this.otherData[1]["deliveryPoints"][0]["priceStructure"] = "hpHc";
      } else {
        if (this.donner.optionTarifaire.indexOf("CU") != -1) {
          this.otherData[1]["deliveryPoints"][0]["priceStructure"] = "base";
        } else {
          this.otherData[1]["deliveryPoints"][0]["priceStructure"] = "hpHc";
        }
      }
      this.otherData[1]["deliveryPoints"][0]["pricing"]["margin"] = this.donner.marge;
      this.defaultMarge = this.donner.marge;
      this.otherData[1]["deliveryPoints"][0]["pricing"]["rate"][
        "base"
      ] = this.donner.prixBase;
      this.otherData[1]["deliveryPoints"][0]["pricing"]["rate"][
        "hp"
      ] = this.donner.prixHP;
      this.otherData[1]["deliveryPoints"][0]["pricing"]["rate"][
        "hc"
      ] = this.donner.prixHC;
      this.defaultBase = this.donner.prixBase;
      this.defaultHp = this.donner.prixHP;
      this.defaultHc = this.donner.prixHC;

      this.otherData[1]["deliveryPoints"][0]["grd"] = "ENEDIS";
      this.addData = true;
    },
    getCurrentISODate() {
      let date = new Date();
      return date.toISOString();
    },
    getBankName() {
      if (this.otherData[1]["iban"] != "" || this.otherData[1]["iban"] != null) {
        var formDonne = new FormData();
        formDonne.append("iban", this.otherData[1]["iban"]);
        axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getBankName/",
          method: "POST",
          data: formDonne,
        })
          .then((res) => {
            if (res.data.result == true) {
              this.infoBank.bankName = res.data.bank_name;
              this.otherData[1]["bic"] = res.data.bic;
              this.goodIban = true;
            } else {
              this.infoBank.bankName = "";
              this.otherData[1]["bic"] = "";
              this.goodIban = false;
              alert(
                "Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît"
              );
            }
          })
          .catch((err) => {
            this.infoBank.bankName = "";
            this.otherData[1]["bic"] = "";
            this.goodIban = false;
            alert(
              "Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît"
            );
          });
      } else {
        this.infoBank.bankName = "";
        this.otherData[1]["bic"] = "";
      }
    },
    async createContrat(){
        this.addData = false;
        this.dialog = true;
        this.error = false;
        this.loading = true;
        this.messageDialogeError = "";
        this.messageDialogeSucces = "";
        var dataToSend = {
            otherData: this.otherData
        };
        await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getContartJpme/",
        method: "POST",
        data: dataToSend,
        headers: { "content-type": "application/json" },
        })
        .then(async (res) => {
            console.log("ok")
        })
        .catch((err) => {
            console.log("notok")
        });
        this.dialog = false;
        this.error = false;
        this.loading = false;
        this.messageDialogeError = "";
        this.messageDialogeSucces = "";
    },
    /*async createContrat() {
      this.addData = false;
      this.dialog = true;
      this.error = false;
      this.loading = true;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
      var dataToSend = {
        donner: this.donner,
        donnerEnedis: this.donnerEnedis,
        optionTarifaire: this.optionTarifaire,
        numCompteur: this.numCompteur,
        societe: this.societe,
      };
      await axios({
        //url: 'http://localhost:3000/JpmeContrat',
        url: "https://editioncontrat.volto.fr/JpmeContrat",
        method: "POST",
        data: dataToSend,
        headers: { "content-type": "application/json" },
      })
        .then(async (res) => {
          var doc = await PDFDocument.load(res.data);
          doc = await doc.save();
          download(
            doc,
            this.numCompteur + "_JPME_" + this.societe.Raison + "_.pdf",
            "application/pdf"
          );
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialog = false;
      this.error = false;
      this.loading = false;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
      this.$emit("readonlyExpand", false);
    },*/
  },
};
</script>
