<template>
    <v-card>
          <v-card-text class="pa-5">
            <div v-if="this.loadersProps">
              <Loader />
            </div>
            <div class='d-flex align-center' v-else>
              <span class="l-stick"></span>
              <v-avatar class="bordered">
                <v-icon> {{iconProps}} </v-icon>
              </v-avatar>
              <div class="ml-4 mr-1">
                <h5 class="subtitle-1 grey--text">{{h5Title}}</h5>
                <h2 class="font-weight-regular">
                  {{ h2Title }}
                </h2>
              </div>
            </div>
          </v-card-text>
        </v-card>
</template>
<script>
import Loader from '../../../components/ui/Loader.vue'
export default{
    name:"cardComponent",
    components:{Loader},
    props:['loadersProps','iconProps','h5Title','h2Title'],
}
</script>