<style scoped>
    .responsiveFlex{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        width:fit-content;
    }
    @media only screen and (max-width:600px) {
        .responsiveFlex{
            flex-direction: column;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
</style>
<template>
    <div class="ml-4" id="mycard">
        <h4 class="ml-8"> <span>Sélectionnez le fichier que vous souhaitez diviser </span></h4>
        <v-form id="myform" ref="formPDF">
            <v-file-input
            style="width:40rem"
            @change="uploadPDF"
            @click:clear="clearDuplication"
            placeholder="Fichier pdf"
            accept=".pdf"
            dense
            filled
            v-model="fil"
            id="myPdf"
            ></v-file-input>
            <div v-if="fil">
                <div id="parent" class=" mb-8 d-flex">
                    <div :id="n" class="ma-2" v-for="n in duplication" style="width:15rem">
                    <v-autocomplete 
                        v-model="selectedFile[n-1]" 
                        :items="resteFile"
                        @change="selectOneLabel(n)"
                        item-text="fileName" 
                        item-value="id"
                        label="Label" 
                        placeholder="choisie type de fichier" 
                        :rules="[rules.required2]"
                    >
                    </v-autocomplete>

                        <v-text-field dense  @input="splitPDF(n)" :rules="[rules.required,rules.maxPages]" type="number" class="mx-1" :id="'inputFirst'+n" v-model="beginPDF[n]" outlined></v-text-field>
                        <v-text-field dense @input="splitPDF(n)" :rules="[rules.required,rules.maxPages]"  min="1" type="number" class="mx-1" :id="'inputLast'+n" v-model="lastPDF[n]" outlined></v-text-field>
                        <v-btn  @click="removing(n)">Supprimer</v-btn>
                    </div>
                </div>
                    <v-btn  class="mb-8" @click="addRange">Ajouter un intervalle</v-btn>
                <div  id="containerPreview" class="d-flex">
                </div>
            </div>
        </v-form>
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import moment from 'moment'
    import axios from 'axios'
    import Vue from "vue"
    import $ from "jquery"
    export default {
        emits:['sendSplitPDF','removeSplitPDF','clearFileList'],
        name: "inputFilePDF",
        props:{
            numberOfDuplicate: 1,
            filePossible: []
         },
        data() {
            return {
                possibleFileCopy:[],
                resteFile: [],
                selectedFile: [],
                compteurPartiePDF:1,
                pageToRender:[],
                filePDF: [],
                duplication : 0,
                beginPDF:[],
                lastPDF:[],
                fil: null,
                maxPageOfPDF:0,

                rules: {
                    integerOnly:(v) => (/^\b(?<!\.)\d+(?!\.)\b$/).test(v) || "ce champ n'accepte que des nombres ou chiffres entiers",
                    maxPages:(v) => {
                        if(v > this.maxPageOfPDF){
                            return "la page max de ce pdf est "+this.maxPageOfPDF
                        }else{
                            return true
                        }
                    },
                    required: (v) => {
                        if(v == null){
                            return "champ obligatoire !"
                        }
                        if(v <=0){
                            return "le champ n'accepte pas de valeur négative"
                        }else{
                            return true
                        }
                    },
                    required2: (v) => {
                        if(v == null){
                            return "champ obligatoire !"
                        }
                        if(v <0){
                            return "le champ n'accepte pas de valeur négative"
                        }else{
                            return true
                        }
                    }
                }
            }
        },
        watch:{
            fil:function(newVal){
                if(newVal){
                    this.beginPDF = [];
                    this.lastPDF = [];
                }
            },
            duplication:function(newVal,oldVal){
                let lastValueInputPDF = 0;
                if(newVal > oldVal){   
                    if(this.lastPDF[oldVal] == null){
                        lastValueInputPDF = this.beginPDF[oldVal] // take last value of beginPDF array
                    }else{
                        lastValueInputPDF = this.lastPDF[oldVal]; // take last value of lastPDF array
                    }

                        (lastValueInputPDF >= this.maxPageOfPDF) ? Vue.set(this.beginPDF,newVal,this.maxPageOfPDF) : Vue.set(this.beginPDF,newVal,++lastValueInputPDF)
                    
                }
            },

            beginPDF:function(){
                if(this.beginPDF[this.beginPDF.length-1] > this.maxPageOfPDF){
                    Vue.set(this.beginPDF,this.beginPDF.length-1, this.maxPageOfPDF)
                }
            },
            lastPDF:function(){
                if(this.lastPDF[this.lastPDF.length-1] > this.maxPageOfPDF){
                    Vue.set(this.lastPDF,this.lastPDF.length-1,this.maxPageOfPDF)
                }
            },
            filePossible: function(){
                this.resteFile = []
                this.possibleFileCopy=[...this.filePossible]
                for (var i=0; i<this.possibleFileCopy.length; i++){
                    this.resteFile.push({
                        id: i,
                        fileName: this.possibleFileCopy[i],
                        disabled: false
                    })
                }
                
                this.uploadPDF()
            },
        },
        created(){
            this.possibleFileCopy=[...this.filePossible]
            for (var i=0; i<this.possibleFileCopy.length; i++){
                this.resteFile.push({
                    id: i,
                    fileName: this.possibleFileCopy[i],
                    disabled: false
                })
            }
        },
         mounted() {
            let externalScript = document.createElement('script')
            externalScript.setAttribute('src',"/js/jquery.min.js")
            document.head.appendChild(externalScript)

             let externalScript2 = document.createElement('script')
            externalScript2.setAttribute('src',"/js/pdf.js")
            document.head.appendChild(externalScript2)
        },
        methods: {
            enableAndDesable(n){
                for(var i=0; i<this.resteFile.length; i++){
                    var index = this.selectedFile.findIndex(x => x === this.resteFile[i].id)
                    if (index > -1) { 
                        this.resteFile[i]["disabled"] = true
                    }else{
                        this.resteFile[i]["disabled"] = false
                    }
                }
            },
            selectOneLabel(n){
                this.enableAndDesable(n)
                this.splitPDF(n)
            },
            clearDuplication(){
                this.duplication = 0
                this.fil = null
                this.$emit('clearFileList')
            },
            
            addRange(){
                    let numberOfDivInput = document.getElementById('parent').childElementCount;
                      if(this.beginPDF.length ==  this.lastPDF.length && this.duplication < this.numberOfDuplicate)
                         this.duplication++
                         this.compteurPartiePDF++;
                
            },
            sendPDFSplit(begin,length, n){
                let pdfDoc = null
                let uniqueArray = this.filePDF
                const reader = new FileReader();
                reader.readAsArrayBuffer(this.fil);
                reader.onload = async () => {
                    pdfDoc = await PDFDocument.load(reader.result);
                    var mergedPdf =  await PDFDocument.create();
                    (begin == 1) ? begin = 0 : begin = begin-1
                    if(begin<length){
                        for (let i = begin; i < length; i++){ 
                            var [page] = await mergedPdf.copyPages(pdfDoc, [i])
                            mergedPdf.addPage(page)
                        }
                    }else{
                        for (let i = begin; i >= length-1; i--){
                            var [page] = await mergedPdf.copyPages(pdfDoc, [i])
                            mergedPdf.addPage(page)
                        }
                    }
                    const newPdf = await mergedPdf.save()   
                    if(uniqueArray.includes(null)){
                        uniqueArray = uniqueArray.filter(el=>{return el !== null})
                        for(let i = 0;i<uniqueArray.length;i++){
                            uniqueArray[i] = new File([uniqueArray[i]],`Partie ${i+1} ${this.fil.name}`,{type:'application/pdf'})
                            this.filePDF = uniqueArray;
                        }
                    }else{
                        for(let i =0;i<this.duplication;i++){
                            this.filePDF[this.compteurPartiePDF-1] = new File([newPdf],"Partie "+this.compteurPartiePDF+" "+this.fil.name,{type:'application/pdf'})
                        }
                    }
                              
                    this.$emit('sendPDFSplit',{listFilePDF:this.filePDF, listeIndex:this.selectedFile})  
                };
            },
            uploadPDF(){
                var length =0;
                try {
                        length =document.getElementById('containerPreview').childNodes.length
                    }
                    catch(err) {
                        length = 0;
                    }
                for (var i=0; i<length; i++){
                    try {
                       this.removing(i+1)
                    }
                    catch(err) {
                        var c= 0;
                    }
                }
                this.duplication = 0
                if(this.fil){
                    this.duplication++
                     this.splitPDF(1) 
                }
            },
            removing(n){
                this.selectedFile.splice(n-1, 1)
                this.enableAndDesable(n)
                let divCanvas = document.getElementById(`n${n}`);
                let arrayDivCanvas = document.getElementById('containerPreview').childNodes
                let arrayTempBegin = []
                let arrayTempLast = []

                if(this.beginPDF[n] && this.lastPDF[n]){
                    Vue.set(this.beginPDF,n,null);
                    Vue.set(this.lastPDF,n,null); 

                    this.beginPDF = this.beginPDF.filter(e=>{return e !== null})
                    this.lastPDF = this.lastPDF.filter(e=>{return e !== null}) 

                    for(let i =0;i<this.beginPDF.length;i++){
                        arrayTempBegin[i+1] = this.beginPDF[i];
                        arrayTempLast[i+1] = this.lastPDF[i];
                    }

                    this.beginPDF = arrayTempBegin;
                    this.lastPDF = arrayTempLast;

                    if(n == 1 && n == this.duplication){
                        divCanvas.remove()
                    }else if(n < this.duplication){
                        this.duplication--
                        for(let i = 0;i< arrayDivCanvas.length;i++){
                            if(divCanvas.id === arrayDivCanvas[i].id){
                                arrayDivCanvas[i+1].remove()
                            }
                            if('n'+(i+1) != arrayDivCanvas[i].id){
                                arrayDivCanvas[i].id = 'n'+(i+1)
                            }
                        }
                       // this.previewPDF(this.beginPDF[n],this.lastPDF[n],n)
                    }
                    else if(n >1 && n == this.duplication){
                        this.duplication--
                        document.getElementById('containerPreview').childNodes[document.getElementById('containerPreview').childNodes.length - 1].remove()
                    }
                    if(n > 0){
                        this.$emit("removeSplitPDF",this.filePDF[ n-1]);
                        Vue.set(this.filePDF,n-1,null)
                    }else{
                        this.$emit("removeSplitPDF",this.filePDF[n]);
                        Vue.set(this.filePDF,n,null)
                    }
                    if(this.compteurPartiePDF > 1){
                        this.compteurPartiePDF--
                    }
                    this.filePDF = this.filePDF.filter(el=>{return el != null})
                }else{
                    Vue.set(this.beginPDF,n,null);
                    Vue.set(this.lastPDF,n,null); 
                    this.beginPDF = this.beginPDF.filter(e=>{return e !== null})
                    this.lastPDF = this.lastPDF.filter(e=>{return e !== null}) 

                    for(let i =0;i<this.beginPDF.length;i++){
                        arrayTempBegin[i+1] = this.beginPDF[i];
                        arrayTempLast[i+1] = this.lastPDF[i];
                    }

                    this.beginPDF = arrayTempBegin;
                    this.lastPDF = arrayTempLast;

                    if(n == 1 && n == this.duplication){
                    divCanvas.remove()
                   } else if(n < this.duplication){
                        this.duplication--
                        for(let i = 0;i< arrayDivCanvas.length;i++){
                            if(divCanvas.id === arrayDivCanvas[i].id){
                                arrayDivCanvas[i+1].remove()
                            }
                            if('n'+(i+1) != arrayDivCanvas[i].id){
                                arrayDivCanvas[i].id = 'n'+(i+1)
                            }
                        }
                    }else if(n >1 && n == this.duplication){
                        this.duplication--
                    }
                    if(n > 0){
                        this.$emit("removeSplitPDF",this.filePDF[ n - 1]);
                        Vue.set(this.filePDF,n-1,null)
                    }else{
                        this.$emit("removeSplitPDF",this.filePDF[n]);
                        Vue.set(this.filePDF,n,null)
                    }
                    if(this.compteurPartiePDF > 1){
                        this.compteurPartiePDF--
                    }
                    this.filePDF = this.filePDF.filter(el=>{return el != null})

                }

            },

            previewPDF(firstPage,lastPage,n){
                let listePageToRender = []
                var pdfjsLib = window['pdfjs-dist/build/pdf'];
                var file = this.fil
                var fileReader = null;
                var pdfData = null;
                var loadingTask = null;
                var div = null;
                var containerPreview = document.getElementById("containerPreview")
                if(firstPage == lastPage){
                   listePageToRender = [parseInt(firstPage)]
                }else{
                        listePageToRender.push(parseInt(firstPage),parseInt(lastPage))
                    }
                    this.pageToRender = listePageToRender
               pdfjsLib.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.js';

                if(file.type == "application/pdf"){
                    fileReader = new FileReader();  
                    fileReader.onload = function() {
                    pdfData = new Uint8Array(this.result);
                    loadingTask = pdfjsLib.getDocument({data: pdfData});
                    loadingTask.promise.then(function(pdf) {
                        
                        if(document.getElementById(`n${n}`)){
                            div = document.getElementById(`n${n}`);
                            (listePageToRender.length == 1) ? div.style.width = "15%" : div.style.width="30%"
                            let child = div.lastElementChild
                            while(child){
                                div.removeChild(child)
                                child = div.lastElementChild;
                            }
                        }else{
                                div = document.createElement("div");
                                div.style.border="1px gray dashed";
                                div.style.display="flex";
                                div.style.setProperty("margin-right","15px");
                                div.style.setProperty("justify-content","space-between");
                                div.style.setProperty("background-color",'#f6f6f8');
                                (listePageToRender.length == 1) ? div.style.width = "15%" : div.style.width="30%"
                                div.id = `n${n}`
                                containerPreview.appendChild(div)
                            }
                        for(let i =0;i<listePageToRender.length;i++){
                            pdf.getPage(listePageToRender[i]).then(function(page) {
                            
                            let scale = 0.9;
                            let viewport = page.getViewport({scale: scale});
                            
                            
                            let canvas = document.createElement('canvas');
                            canvas.style.setProperty("margin","15px")
                            canvas.height = Math.floor(viewport.height);
                            canvas.width = Math.floor(viewport.width);
                            canvas.style.width = "95%"
                            canvas.style.height="95%"
                            
                            let numberPageCanvas = document.createElement("p")
                            numberPageCanvas.innerText = listePageToRender[i]
                            numberPageCanvas.style.setProperty("font-size",".8em")

                            let divParentCanvas = document.createElement("div")
                            divParentCanvas.style.setProperty("background-color",'#f6f6f8')
                            divParentCanvas.appendChild(numberPageCanvas)
                            divParentCanvas.appendChild(canvas)
                            divParentCanvas.style.display = "flex"
                            divParentCanvas.style.setProperty("flex-direction",'column-reverse')
                            divParentCanvas.style.setProperty("justify-content","center")
                            divParentCanvas.style.setProperty("align-items",'center')
                            divParentCanvas.style.with="100%"
                            divParentCanvas.style.height="100%"
                            divParentCanvas.width = Math.floor(viewport.width);
                            divParentCanvas.height = Math.floor(viewport.height);

                            
                            for(let i =containerPreview.childNodes.length-1; i>=0;i--){
                                let child = containerPreview.childNodes[i]
                                let nextChild = containerPreview.childNodes[i+1];
                                 if(nextChild !== undefined){
                                    let childID = parseInt((child.id).substr(1),10)
                                    let nextChildID = parseInt((nextChild.id).substr(1),10)
                                            if(childID > nextChildID){
                                                containerPreview.replaceChild(nextChild,child)
                                                containerPreview.appendChild(child)
                                            }
                                    }
                            }
 
                                div.appendChild(divParentCanvas)


                            
                            var context = canvas.getContext('2d');
            
                            let renderContext = {
                            canvasContext: context,
                            viewport: viewport
                            };
                            let renderTask = page.render(renderContext);
                            renderTask.promise.then(function () {
                            });
                            });
                        }
                        }, function (reason) {
                        console.error(reason);
                        });
                    };
                    fileReader.readAsArrayBuffer(file);
                }
                    this.sendPDFSplit(firstPage,lastPage, n)
           },
            splitPDF(n){
                if(this.$refs.formPDF.validate()){
                    var pdfDoc = null
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(this.fil);
                    reader.onload = async () => {
                    pdfDoc = await PDFDocument.load(reader.result);
                    if(this.beginPDF[n] && this.lastPDF[n]){
                        var begin = parseFloat(this.beginPDF[n])
                        var length = parseFloat(this.lastPDF[n])
                    }
                    else{
                        var begin = 1;
                        Vue.set(this.beginPDF,n,begin);
                        var length = pdfDoc.getPages().length;
                        Vue.set(this.lastPDF,n,length);
                        this.maxPageOfPDF = length;
                    }
                    await this.previewPDF(begin,length,n)
 
                };
                }
            },
        }
    }
</script>
