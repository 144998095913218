<template>
  <div class="dashboard-wrapper">
    <div class="w-100 d-flex flex-row justify-content-between mb-2">
      <h4 class="title">
        <span>Dashboard</span>
      </h4>
      <div class="d-flex flex-row justify-content-between align-items-center">
        <v-autocomplete dense class="px-2" v-model="selectedYearFilter" :items="yearList" label="Année" 
        outlined background-color="white" @change="getData"></v-autocomplete>

        <v-autocomplete dense class="px-2" v-model="selectedMonthFilter" :items="monthList" item-text="label"
          item-value="value" label="Mois" outlined background-color="white" @change="getData"></v-autocomplete>

        <!-- selectionner Equipe -->
        <v-autocomplete v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'ADV'" dense class="px-2"
          v-model="selectedEquipeFilter" :items="equipeList" item-text="nomEquipe" item-value="id" label="Equipe" outlined multiple
          background-color="white" @change="filterByEquipe">
          <template v-slot:selection="data">
              <v-chip
                  v-if="selectedEquipeFilter.length < 2"
                  v-bind="data.attrs"
                  small
              >
                  {{ data.item.nomEquipe }}
              </v-chip>
              <div v-else-if="data.index === 0">
                  {{ `${selectedEquipeFilter.length} equipes selectionner` }}
              </div>
          </template>
        </v-autocomplete>

        <!-- selectionner compte -->
        <v-autocomplete v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'ADV' || whoIam == 'Chef équipe'"
          dense class="px-2" v-model="selectedCompteFilter" :items="filteredCompteList" item-text="prenom" item-value="id" multiple
          label="Compte" outlined background-color="white" @change="filterByCompte" :filter="searchByFullName">
          <template v-slot:selection="data">
              <v-chip
                  v-if="selectedCompteFilter.length < 2"
                  v-bind="data.attrs"
                  small
              >
                {{ data.item.prenom + ' ' + data.item.nom }}
              </v-chip>
              <div v-else-if="data.index === 0">
                  {{ `${selectedCompteFilter.length} comptes selectionner` }}
              </div>
          </template>
          <template v-slot:item="data">
            <v-checkbox
              v-model="data.attrs.inputValue" 
            >
              <template v-slot:label>
                <span style="font-size: 0.8125rem; font-weight: 500; line-height: 1rem;">
                  {{ data.item.prenom + ' ' + data.item.nom }}
                </span>
              </template>
            </v-checkbox>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <DashHeaderCards :chiffreAffaireGlobal="chiffreAffaireGlobal" :CotationGlobal="CotationGlobal"
      :VenteGlobal="VenteGlobal" />

    
    <!-- **************************************** -->
    <!-- ********** objectifs cards content ***** -->
    <!-- **************************************** -->
    <v-col cols="12" class="mt-8">
      <!-- Button Ajouter un objectif -->
      <v-btn v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'Chef équipe'" :color="$colors[0]" class="white--text mb-5" @click="openDialogAddObjectif">
        Ajouter un objectif <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>

      <v-row cols="12">
        <!-- Card Objectif Cotation -->
        <v-col cols="12" sm="12" lg="4">
          <v-card v-if="listObjectifCotaMensuel.length > 0" elevation="0" height="330" width="100%" class="rounded-lg pa-2">
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <div class="pa-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                  ">
                  <v-icon :color="$colors[0]">mdi-flag-checkered</v-icon>
                </div>
                <v-card-title class="font-weight-bold pt-0" style="font-size: 14px;color: #103a5e">
                  <span>Objectif Cotation&nbsp;</span>
                  <span style="font-size: 14px;">({{ transformMonth(selectedMonthFilter) || getCurrentMonthText() }})</span>
                </v-card-title>
              </div>
              <div class="d-flex flex-row">
                <div @click="modifyListeObjectifDialog('cotation')" class="pa-1 mx-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                  ">
                  <v-icon :color="$colors[0]">mdi-cog-outline</v-icon>
                </div>
                <div @click="openObjectifInfo('cotation')" class="pa-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                  ">
                  <v-icon :color="$colors[0]">mdi-dots-horizontal</v-icon>
                </div>
              </div>
            </div>
            <apexchart height="360" type="radialBar" :options="chartOptionsObjectif" :series="seriesObjectifCotation">
            </apexchart>
          </v-card>
        </v-col>

        <!-- Card Objectif Chiffre d'Affaire -->
        <v-col cols="12" sm="12" lg="4">
          <v-card v-if="listObjectifCAMensuel.length > 0" elevation="0" height="330" width="100%" class="rounded-lg pa-2">
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <div class="pa-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                  ">
                  <v-icon :color="$colors[0]">mdi-flag-checkered</v-icon>
                </div>
                <v-card-title class="font-weight-bold pt-0" style="font-size: 14px;color: #103a5e">
                  <span>Objectif Chiffre d'Affaire&nbsp;</span>
                  <span style="font-size: 14px;">({{ transformMonth(selectedMonthFilter) || getCurrentMonthText() }})</span>
                </v-card-title>
              </div>
              <div class="d-flex flex-outline">
                <div @click="modifyListeObjectifDialog('CA')" class="pa-1 mx-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                  ">
                  <v-icon :color="$colors[0]">mdi-cog-outline</v-icon>
                </div>
                <div @click="openObjectifInfo('CA')" class="pa-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                  ">
                  <v-icon :color="$colors[0]">mdi-dots-horizontal</v-icon>
                </div>
              </div>
            </div>
            <apexchart height="360" type="radialBar" :options="chartOptionsObjectif" :series="seriesObjectifCA"></apexchart>
          </v-card>
        </v-col>

        <!-- Card Objectif Vente -->
        <v-col cols="12" sm="12" lg="4">
          <v-card v-if="listObjectifVenteMensuel.length > 0" elevation="0" height="330" width="100%" class="rounded-lg pa-2">
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-row">
                <div class="pa-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                  ">
                  <v-icon :color="$colors[0]">mdi-flag-checkered</v-icon>
                </div>
                <v-card-title class="font-weight-bold pt-0" style="font-size: 14px;color: #103a5e">
                  <span>Objectif Vente&nbsp;</span>
                  <span style="font-size: 14px;">({{ transformMonth(selectedMonthFilter) || getCurrentMonthText() }})</span>
                </v-card-title>
              </div>
              <div class="d-flex flex-outline">
                <div @click="modifyListeObjectifDialog('vente')" class="pa-1 mx-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                  ">
                  <v-icon :color="$colors[0]">mdi-cog-outline</v-icon>
                </div>
                <div @click="openObjectifInfo('vente')" class="pa-1" style="
                    border: 2px solid #103a5e;
                    border-radius: 10px;
                    height: fit-content;
                    cursor: pointer;
                  ">
                  <v-icon :color="$colors[0]">mdi-dots-horizontal</v-icon>
                </div>
              </div>
            </div>
            <apexchart height="360" type="radialBar" :options="chartOptionsObjectif" :series="seriesObjectifVente">
            </apexchart>
          </v-card>
        </v-col>

      </v-row>
    </v-col>

    <!-- **************************************** -->
    <!-- ********** charts content ************** -->
    <!-- **************************************** -->
    <v-col cols="12" sm="12" md="12" lg="12">

      <!-- Cotation Prospecteur -->
      <v-card v-if="['Prospecteur'].includes(whoIam)" elevation="0" width="100%" class="rounded-lg pa-2 mt-8">
        <apexchart type="bar" height="570" :options="chartOptionsCotationProspecteur" :series="seriesChartsCotationProspecteur">
        </apexchart>
      </v-card>

      <!-- Cotation VS Ventes -->
      <v-card v-if="['admin','superadmin','ADV','Chef équipe', 'Vendeur'].includes(whoIam)" elevation="0" width="100%" class="rounded-lg pa-2 mt-8">
        <apexchart type="area" height="570" :options="chartOptionsCotationVentes" :series="seriesChartsCotationVentes">
        </apexchart>
      </v-card>
      
      <!-- Cotation VS Ventes par equipe -->
      <v-card v-if="['admin','superadmin','ADV','Chef équipe'].includes(whoIam) && selectedEquipeFilter.length > 0 || selectedCompteFilter.length > 0" elevation="0" width="100%" class="rounded-lg pa-2 mt-8">
        <apexchart type="bar" height="570" :options="chartOptionsCotationVentesByEquipe" :series="seriesChartsCotationVentesByEquipe"></apexchart>
      </v-card>

      <!-- Chiffre d'affaire VS Objectif -->
      <v-card v-if="['admin','superadmin','ADV','Chef équipe', 'Vendeur'].includes(whoIam)" elevation="0" width="100%" class="rounded-lg pa-2 mt-8">
        <apexchart type="bar" height="570" :options="chartOptionsCAObjectif" :series="seriesCAObjectif"></apexchart>
      </v-card>

      <!-- Total Chiffre d'affaire -->
      <v-card v-if="['admin','superadmin','ADV','Chef équipe', 'Vendeur'].includes(whoIam)" elevation="0" width="100%" class="rounded-lg pa-2 mt-8">
        <apexchart type="area" height="570" :options="chartOptionsTotalCA" :series="seriesChartsTotalCA">
        </apexchart>
      </v-card>
      
    </v-col>




    <!-- dialog info -->
    <DialogInfoObjectif 
      :isOpen="dialogInfoObjectifCota" 
      title="Objectif Cotations Par Mois" 
      icon='mdi-cash-multiple'
      :progress='Cota_Mensuel ? Cota_Mensuel.toLocaleString() : 0' 
      :total='valeurTotalObjectifCota ? valeurTotalObjectifCota.toLocaleString() : 0' 
      @close="closeDialogInfoObjectif" 
    />
    <DialogInfoObjectif 
      :isOpen="dialogInfoObjectifCA" 
      title="Objectif Chiffre d'Affaire Par Mois" 
      icon='mdi-finance'
      :progress="CA_Mensuel ? parseFloat(CA_Mensuel.toFixed(3)).toLocaleString() : 0 + ' €'" 
      :total="valeurTotalObjectifCA ? parseFloat(valeurTotalObjectifCA.toFixed(3)).toLocaleString() + ' €' : 0 + ' €'"
      @close="closeDialogInfoObjectif" 
    />
    <DialogInfoObjectif 
      :isOpen="dialogInfoObjectifVente" 
      title="Objectif Ventes Par Mois" 
      icon='mdi-cash-multiple'
      :progress='Vente_Mensuel ? Vente_Mensuel : 0' 
      :total="valeurTotalObjectifVente ? valeurTotalObjectifVente.toLocaleString() : 0"
      @close="closeDialogInfoObjectif" 
    />




    <!-- dialog Modal Creation et modifier objectif -->
    <DialogObjectif 
    v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'Chef équipe'" 
    :isOpen="dialogAddEditObjectif" 
    :isModify="isModifyObjectif"
    :listMembreEquipe="compteList" 
    :listObjectifVenteMensuel="listObjectifVenteMensuel" 
    :listObjectifCAMensuel="listObjectifCAMensuel" 
    :listObjectifCotaMensuel="listObjectifCotaMensuel" 
    @close="closeDialogAddEditObjectif" 
    @refreshData="getData" 
    />



    <!-- dialog Table Objectif -->
    <v-dialog v-model="dialogTableObjectif" width="1300">
      <v-card class="pa-6">
        <b-table id="table-graph" show-empty empty-text="Aucune donnée disponible pour le moment" striped bordered
          :items="listTableObjectif" class="pr-lg-4 mb-0 pb-0 px-0 text-center" :fields="columns">
          <!-- <template #cell(nom)="data">
            <span class="">{{ data.item.nom ? data.item.nom : "Aucun nom" }}</span>
          </template> -->
          <template #cell(equipe)="data">
            <span class="">{{ data.item.compte.equipe }}</span>
          </template>
          <template #cell(compte)="data">
            <span class="">{{ data.item.compte.prenom + " " + data.item.compte.nom }}</span>
          </template>
          <template #cell(mois)="data">
            <span class="">{{ transformMonth(data.item.mois) }}</span>
          </template>
          <template #cell(annee)="data">
            <span class="">{{ data.item.annee }}</span>
          </template>
          <template #cell(valeur)="data">
            <v-text-field v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'Chef équipe'" dense outlined hide-details style="background-color:white"
              v-model="data.item.valeur" :rules="[$rules.required]">
            </v-text-field>
            <span v-else class="">{{ data.item.valeur }}</span>
          </template>
          <template #cell(actions)="data">
            <v-btn v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'Chef équipe'" elevation="0" style="border:1px solid #103A5E"
              @click='editObjectif(data.item)'><v-icon :color='$colors[0]'>mdi-pencil</v-icon></v-btn>
            <v-btn v-if="whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'Chef équipe'" elevation="0" style="border:1px solid red" class="ml-4"
              @click='deleteObjectif(data.item)'><v-icon color="red">mdi-delete</v-icon></v-btn>
          </template>
        </b-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import DashHeaderCards from "./components/DashHeaderCards"
import DialogObjectif from "./components/DialogObjectif";
import DialogInfoObjectif from "./components/DialogInfoObjectif";

export default {
  components: {
    apexchart: VueApexCharts,
    DashHeaderCards,
    DialogObjectif,
    DialogInfoObjectif
  },
  data() {
    return {
      // user role
      whoIam: "",

      // dialogs
      dialogAddEditObjectif: false,
      dialogTableObjectif: false,
      dialogInfoObjectifCA: false,
      dialogInfoObjectifCota: false,
      dialogInfoObjectifVente: false,

      // states
      listMembreEquipe: [],
      isModifyObjectif: false,

      // filter states
      selectedYearFilter: null,
      selectedMonthFilter: null,
      selectedEquipeFilter: [],
      selectedCompteFilter: [],
      monthList: [
        { value: '01', label: 'Janvier' },
        { value: '02', label: 'Février' },
        { value: '03', label: 'Mars' },
        { value: '04', label: 'Avril' },
        { value: '05', label: 'Mai' },
        { value: '06', label: 'Juin' },
        { value: '07', label: 'Juillet' },
        { value: '08', label: 'Août' },
        { value: '09', label: 'Septembre' },
        { value: '10', label: 'Octobre' },
        { value: '11', label: 'Novembre' },
        { value: '12', label: 'Décembre' }
      ],
      yearList: [],
      equipeList: [],
      compteList: [],
      filteredCompteList: [],

      // header cards state
      chiffreAffaireGlobal: 0,
      CotationGlobal: 0,
      VenteGlobal: 0,

      // chart cotation Prospecteur
      seriesChartsCotationProspecteur: [],
      chartOptionsCotationProspecteur:{
        chart: {
          type: 'bar',
          height: 570,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true,
            type: 'x',
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '16px',
            colors: ["#304758"]
          },
          formatter: (val) => {
            return val;
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            // columnWidth: '100%',
            dataLabels: {
              position: 'top',
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          tickPlacement: 'on',
          categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        },
        fill: {
          opacity: 1
        },
        title: {
          text: "Total Cotation",
          align: "left",
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val;
            }
          }
        },
        tooltip: {
          theme: true,
          enabled: true,
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },

      // chart cotation vs ventes
      seriesChartsCotationVentes: [],
      chartOptionsCotationVentes: {
        chart: {
          type: "area",
          height: 340,
          zoom: {
            enabled: true,
          },
        },

        title: {
          text: "Cotations Vs Ventes",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
          tickPlacement: "on",
          labels: {
            show: true,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          theme: "apexcharts-tooltip",
          shared: true,
        },
        grid: {
          show: false, // Enlever le grillage
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
          }, // Enlever la barre des ordonnées
        },
      },

      // chart cotation vs ventes par equipe
      seriesChartsCotationVentesByEquipe: [],
      chartOptionsCotationVentesByEquipe: {
        chart: {
          type: 'bar',
          height: 570,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true,
            type: 'x',
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          style: {
            colors: ['#000'], // Replace with your desired color
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'both',
            // columnWidth: '100%',
            dataLabels: {
            }
          },
        },
        xaxis: {
          tickPlacement: 'on',
          categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        },
        fill: {
          opacity: 1
        },
        title: {
          text: "Cotation Vs Ventes Détaillé",
          align: "left",
        },
        legend: {
          position: 'right',
          horizontalAlign: 'left'
        },
        tooltip: {
          theme: true,
          enabled: true,
        },
      },

      // chart chiffre d'affaire vs objectif
      seriesCAObjectif: [],
      chartOptionsCAObjectif: {
        chart: {
          type: 'bar',
          height: 570,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true,
            type: 'x',
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          formatter: (val) => {
            if (val) {
                return val >= 1000 ? (Number(val)/1000).toFixed(2)+'k' : (Number(val)/1000).toFixed(2)+'K';
              } else {
                return val;
              }
          },
          style: {
            colors: ['#000'], // Replace with your desired color
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'both',
            // columnWidth: '100%',
            dataLabels: {
              // total: {
              //   enabled: true,
              //   style: {
              //     fontSize: '13px',
              //     fontWeight: 900
              //   }
              // }
            }
          },
        },
        xaxis: {
          tickPlacement: 'on',
          categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        },
        fill: {
          opacity: 1
        },
        title: {
          text: "Chiffres Affaires VS Objectifs",
          align: "left",
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              if (val) {
                return val >= 1000 ? (Number(val)/1000).toFixed(2)+'k' : (Number(val)/1000).toFixed(2)+'k';
              } else {
                return val;
              }
            },
            style: {
              colors: ['#000'], // Replace with your desired color
            },
          }
        },
        legend: {
          position: 'right',
          horizontalAlign: 'left'
        },
        tooltip: {
          theme: true,
          enabled: true,
          y: {
            formatter: function (val) {
              if (val) {
                return val >= 1000 ? (Number(val)/1000).toFixed(3)+'k' : (Number(val)/1000).toFixed(3)+'k';
              } else {
                return val;
              }
            },
          },
        },
      },
          
          
      // chart total chiffre affaire
      seriesChartsTotalCA: [],
      chartOptionsTotalCA: {
        chart: {
          type: "area",
          height: 340,
          zoom: {
            enabled: true,
          },
        },

        title: {
          text: "Total Chiffres Affaires",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "category",
          categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
          tickPlacement: "on",
          labels: {
            show: true,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          theme: "apexcharts-tooltip",
          shared: true,
        },
        grid: {
          show: false, // Enlever le grillage
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              if (val) {
                return Number(val) >= 1000 ? (Number(val)/1000).toFixed(3)+'k' : (Number(val)/1000).toFixed(3)+'k';
              } else {
                return val;
              }
            }
          }
        },
      },


      // table objectif columns
      columns: [
        // {
        //   thStyle: "background-color:#103A5E;color:white",
        //   label: "Nom Objectif",
        //   key: "nom",
        // },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Type",
          key: "type"
        },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Equipe",
          key: "equipe",
        },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Compte",
          key: "compte",
        },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Mois",
          key: "mois",
        },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Année",
          key: "annee",
        },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Objectif",
          key: "valeur",
        },
        {
          thStyle: "background-color:#103A5E;color:white",
          label: "Actions",
          key: "actions",
        },
      ],
      // Objectifs
      listTableObjectif: [],
      listObjectifVenteMensuel: [],
      listObjectifCAMensuel: [],
      listObjectifCotaMensuel: [],
      // percentage value of objectif chart
      seriesObjectifCA: [0],
      seriesObjectifVente: [0],
      seriesObjectifCotation: [0],
      // propgress value of objectif
      CA_Mensuel: 0,
      Cota_Mensuel: 0,
      Vente_Mensuel: 0,
      // max value of objectif
      valeurTotalObjectifCota: 0,
      valeurTotalObjectifCA: 0,
      valeurTotalObjectifVente: 0,
      // styles of objectif percentage chart
      chartOptionsObjectif: {
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,

            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                show: false,
                color: "black",
                fontSize: "15px",
                fontFamily: "Poppins",
                offsetY: -20,
              },
              value: {
                fontWeight: 600,
                color: "#103A5E",
                fontFamily: "Poppins",
                fontSize: "30px",
                show: true,
                offsetY: 0,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            gradientToColors: ["#103A5E"],
            stops: [0, 100],
          },
        },
      },
    }
  },
  watch: {
    whoIam(role) {
      if (role != 'admin' && role != 'superadmin' &&  role != "Chef équipe") {
        // Find the index of the object you want to remove
        const indexToRemove = this.columns.findIndex(column => column.key === "actions");

        if (indexToRemove !== -1) {
          // Remove the object from the columns array
          this.columns.splice(indexToRemove, 1);
        }
      }
    },
  },
  computed: {
    compteFullName() {
      return this.filteredCompteList.map(item => item.prenom + ' ' + item.nom);
    }
  },
  async mounted() {
    this.fillYearOptions();
  },
  async created() {
    // check if user connected and get the role
    this.whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      this.whoIam = data;
    });
    if (this.whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", this.whoIam);
    }
    // get data
    await this.getAllCompteAndEquipe()
    await this.getData()
  },
  methods: {
    // search compte by nom and prenom
    searchByFullName(item, queryText) {
      const fullName = `${item.prenom} ${item.nom}`;
      return fullName.toLowerCase().includes(queryText.toLowerCase());
    },
    // state functions
    openDialogAddObjectif() {
      this.dialogAddEditObjectif = true;
      this.isModifyObjectif = false;
    },
    closeDialogAddEditObjectif() {
      this.dialogAddEditObjectif = false;
      this.isModifyObjectif = false;
    },
    closeDialogInfoObjectif() {
      this.dialogInfoObjectifCA = false;
      this.dialogInfoObjectifVente = false;
      this.dialogInfoObjectifCota = false;
    },
    openObjectifInfo(objectifName) {
      switch (objectifName) {
        case "CA":
          this.dialogInfoObjectifCA = true;
          break;
        case "cotation":
          this.dialogInfoObjectifCota = true;
          break;
        case "vente":
          this.dialogInfoObjectifVente = true;
          break;
        default:
          break;
      }
    },
    filterByEquipe() {
      this.selectedCompteFilter = [];
      if (this.selectedEquipeFilter.length>0) {
        this.filteredCompteList = this.compteList.filter(compte => {
          return this.selectedEquipeFilter.includes(compte.equipe)
        })
      } else {
        this.filteredCompteList = this.compteList;
      }
      this.getData()
    },
    filterByCompte() {
      this.getData()
    },
    modifyListeObjectifDialog(objectifType) {
      this.refreshTableData(objectifType)
      this.dialogTableObjectif = true;
    },
    refreshTableData(objectType) {
      this.listTableObjectif = [];
      if (objectType == "CA") {
        this.listObjectifCAMensuel.forEach((e) => {
          const newObj = { ...e, oldValue: e.valeur }
          this.listTableObjectif.push(newObj);
        });
      }
      if (objectType == "cotation") {
        this.listObjectifCotaMensuel.forEach((e) => {
          const newObj = { ...e, oldValue: e.valeur }
          this.listTableObjectif.push(newObj);
        });
      }
      if (objectType == "vente") {
        this.listObjectifVenteMensuel.forEach((e) => {
          const newObj = { ...e, oldValue: e.valeur }
          this.listTableObjectif.push(newObj);
        });
      }
    },
    transformMonth(value) {
      switch (value) {
        case '01':
          return 'Janvier';
        case '02':
          return 'Février';
        case '03':
          return 'Mars';
        case '04':
          return 'Avril';
        case '05':
          return 'Mai';
        case '06':
          return 'Juin';
        case '07':
          return 'Juillet';
        case '08':
          return 'Août';
        case '09':
          return 'Septembre';
        case '10':
          return 'Octobre';
        case '11':
          return 'Novembre';
        case '12':
          return 'Décembre';
        default:
          return null;
      }
    },
    getCurrentMonthText() {
      const month = new Date().getMonth() + 1; // Adding 1 to get the correct month number
      const twoNumberMonth = month.toString().padStart(2, '0'); // Formatting with leading zeroes
      return this.transformMonth(twoNumberMonth);
    },
    fillYearOptions() {
      const currentYear = new Date().getFullYear();
      const startYear = 2021;
      let newList = [];
      for (let year = currentYear; year >= startYear; year--) {
        newList.push(year.toString());
      }
      this.selectedYearFilter = currentYear.toString();
      this.yearList = newList;
    },

    // consuming data functions
    generateForAll(data) {
      console.log('generateForAll')
      this.chiffreAffaireGlobal = data.metrics.CA_total;
      this.CotationGlobal = data.metrics.cotation_count;
      this.VenteGlobal = data.metrics.vente_count;

      const groups = data.groupes;

      // **********cotation prospecteur***************
      let dataCotationProspecteur = [];
      dataCotationProspecteur = Object.values(groups[0].data.vente_vs_cotation).map(item => item.dc_count)
      this.seriesChartsCotationProspecteur = [
        { name: "Total Cotation", data: dataCotationProspecteur },
      ];

      // **********cotation vs ventes***************
      let dataCotation = [];
      let dataVentes = [];
      dataCotation = Object.values(groups[0].data.vente_vs_cotation).map(item => item.dc_count)
      dataVentes = Object.values(groups[0].data.vente_vs_cotation).map(item => item.vente_count)
      this.seriesChartsCotationVentes = [
        { name: "Cotation", data: dataCotation },
        { name: "Ventes", data: dataVentes }
      ];

      // ********chiffre d'affaire vs objectif*******
      let dataCA = [];
      let dataObj = [];
      Object.keys(groups[0].data.ca_vs_objectif).sort().forEach(month => {
        dataCA.push(groups[0].data.ca_vs_objectif[month].ca);
        dataObj.push(groups[0].data.ca_vs_objectif[month].obj);
      });
      this.seriesCAObjectif = [
        { name: "Chiffre d'affaire", group: 'progress', data: dataCA },
        { name: "Objectif", group: 'objectif', data: dataObj }
      ];

      // ********Total chiffre d'affaire*******
      let dataTotalCA = [];
      let cumulativeTotal = 0;
      Object.keys(groups[0].data.ca_vs_objectif).sort().forEach(month => {
          cumulativeTotal += groups[0].data.ca_vs_objectif[month].ca;
          dataTotalCA.push(cumulativeTotal);
      });
      this.seriesChartsTotalCA = [
        { name: "Total Chiffre d'affaire", data: dataTotalCA },
      ];

      // *****************objectifs**************

      // propgress value of objectif
      this.Vente_Mensuel = groups[0].data.objectif_by_type.vente.valeur || 0;
      this.Cota_Mensuel = groups[0].data.objectif_by_type.cotation.valeur || 0;
      this.CA_Mensuel = groups[0].data.objectif_by_type.CA.valeur || 0;
      // Vente
      if (groups[0].data.objectif_by_type?.vente?.objects?.length > 0) {
        this.listObjectifVenteMensuel = groups[0].data.objectif_by_type.vente.objects;
        let count = 0;
        this.listObjectifVenteMensuel.map((item) => {
          count = count + item.valeur;
        });
        this.valeurTotalObjectifVente = count;
        const pourcentageObjectif = (Number(this.Vente_Mensuel) / Number(this.valeurTotalObjectifVente)) * 100;

        if (pourcentageObjectif % 1 == 0) {
          this.seriesObjectifVente = [pourcentageObjectif.toFixed(0)];
        } else {
          this.seriesObjectifVente = [pourcentageObjectif.toFixed(2)];
        }

      } else {
        this.listObjectifVenteMensuel = [];
        this.valeurTotalObjectifVente = 0;
        this.seriesObjectifVente = [0];
      }
      // cotation
      if (groups[0].data.objectif_by_type?.cotation?.objects?.length > 0) {
        this.listObjectifCotaMensuel = groups[0].data.objectif_by_type.cotation.objects;
        let count = 0;
        this.listObjectifCotaMensuel.map((item) => {
          count = count + item.valeur;
        })
        this.valeurTotalObjectifCota = count;
        const pourcentageObjectif = (Number(this.Cota_Mensuel) / Number(this.valeurTotalObjectifCota)) * 100;
        if (pourcentageObjectif % 1 == 0) {
          this.seriesObjectifCotation = [pourcentageObjectif.toFixed(0)];
        } else {
          this.seriesObjectifCotation = [pourcentageObjectif.toFixed(2)];
        }
      } else {
        this.listObjectifCotaMensuel = [];
        this.valeurTotalObjectifCota = 0;
        this.seriesObjectifCotation = [0];
      }
      // Chiffre affaire
      if (groups[0].data.objectif_by_type?.CA?.objects?.length > 0) {
        this.listObjectifCAMensuel = groups[0].data.objectif_by_type.CA.objects;
        let count = 0;
        this.listObjectifCAMensuel.map((item) => {
          count = count + item.valeur;
        })
        this.valeurTotalObjectifCA = count;
        const pourcentageObjectif = (Number(this.CA_Mensuel) / Number(this.valeurTotalObjectifCA)) * 100;
        if (pourcentageObjectif % 1 == 0) {
          this.seriesObjectifCA = [pourcentageObjectif.toFixed(0)];
        } else {
          this.seriesObjectifCA = [pourcentageObjectif.toFixed(2)];
        }
      } else {
        this.listObjectifCAMensuel = [];
        this.valeurTotalObjectifCA = 0;
        this.seriesObjectifCA = [0];
      }
    },
    generateByType(data) {
      console.log('generateByType')
      this.chiffreAffaireGlobal = data.metrics.CA_total;
      this.CotationGlobal = data.metrics.cotation_count;
      this.VenteGlobal = data.metrics.vente_count;

      // chart data
      const groups = data.groupes;
      const totalCotation = new Array(12).fill(0); // Assuming 12 months
      const totalVentes = new Array(12).fill(0);
      const newSeriesCAObjectif = [];
      const newSeriesCotationVentes = [];

      // vente objectif data
      let SUM_VenteValeurMensuel = 0;
      let groupedVenteObjects = [];
      this.valeurTotalObjectifVente = 0;

      // Cotation objectif data
      let SUM_CotationValeurMensuel = 0;
      let groupedCotationObjects = [];
      this.valeurTotalObjectifCota = 0;

      // Chiffre Affaire objectif data
      let SUM_CAValeurMensuel = 0;
      let groupedCAObjects = [];
      this.valeurTotalObjectifCA = 0;

      // total chiffre Affaire
      this.seriesChartsTotalCA = [];

      // Extract data for each group
      groups.forEach((group) => {
        const groupId = group.id;
        const groupName = group.name;
        const groupData = group.data;

        // **********cotation vs ventes***************
        const venteVsCotationData = group.data.vente_vs_cotation;
        let dataCotationParEquipe = [];
        let dataVentesParEquipe = [];
        Object.keys(venteVsCotationData).sort().forEach((month,index) => {
          totalCotation[index] += venteVsCotationData[month].dc_count;
          totalVentes[index] += venteVsCotationData[month].vente_count;
          dataCotationParEquipe.push(venteVsCotationData[month].dc_count);
          dataVentesParEquipe.push(venteVsCotationData[month].vente_count);
        });
        // **********cotation vs ventes par Equipe***************
        newSeriesCotationVentes.push(
          {
            name: `Cotation ${groupName}`,
            group: 'Cotation',
            data: dataCotationParEquipe
          },
          {
            name: `Ventes ${groupName}`,
            group: 'Ventes',
            data: dataVentesParEquipe
          }
        );


        // ********chiffre d'affaire vs objectif*******
        const caVsObjectifData = groupData.ca_vs_objectif;
        let dataCA = [];
        let dataObj = [];
        let dataTotalCA = [];
        let cumulativeTotal = 0;
        Object.keys(caVsObjectifData).sort().forEach(month => {
          const claimedCAValue = caVsObjectifData[month].ca
          const claimedOBJValue = caVsObjectifData[month].obj
          dataCA.push(claimedCAValue);
          dataObj.push(claimedOBJValue);
          cumulativeTotal += claimedCAValue;
          dataTotalCA.push(cumulativeTotal);
        });
        newSeriesCAObjectif.push(
          {
            name: `Chiffre d'affaire ${groupName}`,
            group: 'progress',
            data: dataCA
          },
          {
            name: `Objectif ${groupName}`,
            group: 'objectif',
            data: dataObj
          }
        );
        // ********Total chiffre d'affaire*******
        this.seriesChartsTotalCA.push({ name: group.name, data: dataTotalCA })

        // *****************objectifs**************
        // ****************************************
        // Vente
        const venteObjects = group.data.objectif_by_type.vente.objects;
        SUM_VenteValeurMensuel += group.data.objectif_by_type.vente.valeur;
        groupedVenteObjects.push(...venteObjects);
        let count = 0;
        venteObjects.map((item) => {
          count = count + item.valeur;
        });
        this.valeurTotalObjectifVente += count;

        // cotation
        const cotationObjects = group.data.objectif_by_type.cotation.objects;
        SUM_CotationValeurMensuel += group.data.objectif_by_type.cotation.valeur;
        groupedCotationObjects.push(...cotationObjects);
        let count2 = 0;
        cotationObjects.map((item) => {
          count2 = count2 + item.valeur;
        });
        this.valeurTotalObjectifCota += count2;

        // Chiffre affaire
        const CAObjects = group.data.objectif_by_type.CA.objects;
        SUM_CAValeurMensuel += group.data.objectif_by_type.CA.valeur;
        groupedCAObjects.push(...CAObjects);
        let count3 = 0;
        CAObjects.map((item) => {
          count3 = count3 + item.valeur;
        });
        this.valeurTotalObjectifCA += count3;
        

      });

      // update the state
      this.seriesCAObjectif = newSeriesCAObjectif;
      this.seriesChartsCotationVentesByEquipe = newSeriesCotationVentes;
      this.seriesChartsCotationVentes = [
        { name: "Cotation", data: totalCotation },
        { name: "Ventes", data: totalVentes },
      ]

      // objectifs states
      this.Vente_Mensuel = SUM_VenteValeurMensuel || 0;
      this.Cota_Mensuel = SUM_CotationValeurMensuel || 0;
      this.CA_Mensuel = SUM_CAValeurMensuel || 0;
      // Vente
      if (groupedVenteObjects.length > 0) {
        const pourcentageObjectif = (Number(this.Vente_Mensuel) / Number(this.valeurTotalObjectifVente)) * 100;
        if (pourcentageObjectif % 1 == 0) {
          this.seriesObjectifVente = [pourcentageObjectif.toFixed(0)];
        } else {
          this.seriesObjectifVente = [pourcentageObjectif.toFixed(2)];
        }
        this.listObjectifVenteMensuel = groupedVenteObjects;
      } else {
        this.listObjectifVenteMensuel = [];
        this.valeurTotalObjectifVente = 0;
        this.seriesObjectifVente = [0];
      }

      // cotation
      if (groupedCotationObjects.length > 0) {
        const pourcentageObjectif = (Number(this.Cota_Mensuel) / Number(this.valeurTotalObjectifCota)) * 100;
        if (pourcentageObjectif % 1 == 0) {
          this.seriesObjectifCotation = [pourcentageObjectif.toFixed(0)];
        } else {
          this.seriesObjectifCotation = [pourcentageObjectif.toFixed(2)];
        }
        this.listObjectifCotaMensuel = groupedCotationObjects;
      } else {
        this.listObjectifCotaMensuel = [];
        this.valeurTotalObjectifCota = 0;
        this.seriesObjectifCotation = [0];
      }

      // Chiffre affaire
      if (groupedCAObjects.length > 0) {
        const pourcentageObjectif = (Number(this.CA_Mensuel) / Number(this.valeurTotalObjectifCA)) * 100;
        if (pourcentageObjectif % 1 == 0) {
          this.seriesObjectifCA = [pourcentageObjectif.toFixed(0)];
        } else {
          this.seriesObjectifCA = [pourcentageObjectif.toFixed(2)];
        }
        this.listObjectifCAMensuel = groupedCAObjects;
      } else {
        this.listObjectifCAMensuel = [];
        this.valeurTotalObjectifCA = 0;
        this.seriesObjectifCA = [0];
      }
    },
    async getData() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "dashboard_view_v2/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.getToken("token"),
          year: this.selectedYearFilter,
          month: this.selectedMonthFilter,
          equipes: this.selectedEquipeFilter,
          comptes: this.selectedCompteFilter,
        }),
      }).then((res) => {
        if (res.status === 200 && res.data) {
          if (res.data.type == 'all') {
            this.generateForAll(res.data)
          } else {
            this.generateByType(res.data)
          }
        }
      });
    },
    async getAllCompteAndEquipe() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "get_all_comptes_equipes_dashboard_view/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.getToken("token"),
        }),
      }).then((res) => {
        // update the view
        if (res.status == 200 && res.data) {
          this.equipeList = res.data.equipes;
          this.compteList = res.data.comptes;
          this.filteredCompteList = res.data.comptes;
        }
      });
    },

    // actions functions
    async editObjectif(item) {
      if (window.confirm(`Êtes-vous sûr de vouloir modifier cet objectif de ${item.compte.prenom} ${item.compte.nom} dernier mois à ${item.oldValue}`)) {
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "modifierObjectif/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            token: this.getToken("token"),
            valeur: item.valeur,
            type: item.type,
            idObjectif: item.id,
            compte: item.compte.id,
          }),
        }).then(async (res) => {
          // update the view
          if (res.status == 200) {
            await this.getData()
            this.refreshTableData(item.type)
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    },
    async deleteObjectif(item) {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer cet objectif")) {
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "supprimerObjectif/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            token: this.getToken("token"),
            idObjectif: item.id,
          }),
        }).then(async (res) => {
          // update the view
          if (res.status == 200) {
            await this.getData();
            this.refreshTableData(item.type)
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
</script>

<style scoped>
.dashboard-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  padding: 40px 20px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #000;
}
</style>