<template>
    <v-container>
        <!-- modal refuser -->
        <v-dialog v-model="ouvrireDialogeAddNew" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Ajouter nouvelle notification ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="12" >
                            <v-textarea
                                filled
                                :rules="[rules.required('une notification.')]"
                                auto-grow
                                label="Notification"
                                rows="2"
                                row-height="20"
                                v-model="notification"
                                >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" >
                            <v-btn color="blue darken-1" text @click="ouvrireDialogeAddNew=false"
                                >Annuler</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="envoyerNotification"
                                >Confirmer</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-form>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- body -->
        <v-card class="mt-3 pa-2" elevation="11">
            <template>
                <v-toolbar dark color="primary" fixed>
                    <v-toolbar-title class="white--text">Controle Message Slack Bot</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                </v-toolbar>
                    <v-btn @click="ouvrireDialogeAddNew=true"
                        class="mt-2"
                        x-medium
                        color="success"
                        dark>
                    Create new notification
                </v-btn>
                <v-data-table
                    :search="search"
                    :headers="headers"
                    :items="lesMessages"
                    sort-by="id"
                    :items-per-page="10"
                   >
                    <template v-slot:item.message="{ item }">
                        <div style="max-height: 100px; overflow: auto;" >
                            <p>{{item.message}}</p>
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div style="max-width: 120px; min-width:120px;">
                            <v-row
                                align="center"
                                justify="space-around"
                            >
                                <v-btn
                                @click="supprimerMessage(item.id)"
                                class="mt-1"
                                x-small
                                color="error"
                                dark>Supprimer</v-btn>
                            </v-row>
                        </div>
                    </template>
                
                </v-data-table>
            </template>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'controleSlackBot',
        components: {
        },
        data: () => ({
            ouvrireDialogeAddNew: false,
            search: '',
            notification: '',
            headers: [
                {text: 'Id', value:'id', sortable: true,},
                { text: 'Message', value: 'message', sortable: true },
                { text: 'Action', value: 'action', sortable: true },
            ],
            lesMessages: [],
            //règles de validation forme propositions
            rules: {
                required(finPhrase) {
                return (value) => !!value || `Veuillez saisir ${finPhrase}`
                },
            },

        }),
        created() {
            this.verifyApplicationDataSecurity()
            this.getAllMessage()
        },
        methods: {
            verifyApplicationDataSecurity() {
                if (this.$cookies.get('token')) {
                    var formData = new FormData()
                    formData.append('type', localStorage.getItem('typeUser'))
                    formData.append('vueAfficher', 'admin')
                    formData.append('token', this.$cookies.get('token'))
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'verifyApplicationDataSecurity.php',
                        method: 'POST',
                        data: formData,
                        
                    })
                    .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    }
                    })
                    .catch((err) => {
                    console.log(err)
                    })
                }else{
                    this.$router.push('/login')
                }
            },
            async getAllMessage() {
                let self = this
                await axios.get('https://app.volto.fr/getAllMessage/', {})
                .then((res) => {
                    this.lesMessages  = res.data.res
                })
            },
            async supprimerMessage(id){
                let self = this
                let payload1 = {
                    id: id,
                }
                await axios.get('https://app.volto.fr/slackBotDeleteMessage/', {
                    params: payload1,
                }).then((res) => {
                    this.getAllMessage()
                })

            },
            async envoyerNotification(){
                if (!this.$refs.form.validate()) return
                this.ouvrireDialogeAddNew=false
                let self = this
                var formData = new FormData()
                
                formData.append('msg', this.notification)
                this.notification=''
                axios({
                    url:'https://app.volto.fr/slackBotSendMessage/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    this.getAllMessage()
                })
            }
        }
    }
</script>