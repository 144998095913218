<template>
    <div class="d-flex justify-content-center align-items-center" style="height:100%">
        <v-card elevation="0" class="d-flex flex-column justify-content-center align-items-center">
                <v-icon size="100">mdi-database-off</v-icon>
                <span class="text-lg-h5 text-center my-4">Aucune données disponibles pour le moment</span>
        </v-card>
    </div>
</template>
<script>
export default {
    name:'no-data-table'    
}
</script>