<template>
  <div class="d-flex justify-end">
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogPres == true">
      <loadingComponent @closeDialog="dialogPres = $event" :dialogProps="dialogPres" :loadingProps="loadingPres" />
    </v-col>
    <v-btn :disabled="isDisabled" :color="$colors[0]" class="white--text mr-2" @click="createPres"><span
        class="Poppins-Bold">Créer une présentation</span><v-icon class="ml-6" right dark>
        mdi-file-document-multiple
      </v-icon></v-btn>
  </div>
</template>

<script>
import { degrees, PDFDocument, rgb, PDFName, PDFString, StandardFonts, TextAlignment, setFillingRgbColor, setFontAndSize } from 'pdf-lib'
import download from 'downloadjs'
import jsPDF from 'jspdf'
import loadingComponent from "../../../components/ui/loading-component.vue"
import axios from 'axios'
import 'jspdf-autotable'
export default {
  name: "presentation",
  components: {
    loadingComponent
  },
  props: {
    parametreCompteur: null,
    donnerDesFournisseur: [],
    typeCompteur: null,
    fournisseurActuelle: null,
    indexes: [],
    numCompteur: null,
    dataEnedis: [],
    nbrMonthsEnedis: 0,
    turpeData: [],
    donnerCompteur: [],
    societe: [],
  },
  data() {
    return {
      dialogPres: false,
      loadingPres: false,
      listDesOffre: [],
      Offres: [],
      isDisabled: true,
    }
  },
  watch: {
    indexes:{
        immediate: true,
        deep: true,
        handler (val, oldVal) {
            if(this.donnerDesFournisseur.length>0){
                this.isDisabled = true
                for(var i=0; i<val.length; i++){
                    var isActuelle = false
                    if('resultat' in this.donnerDesFournisseur[i]){ 
                        if(this.donnerDesFournisseur[i]['resultat']['actuelle'] == true){
                            isActuelle = true
                        }
                    }
                    if(isActuelle == true){
                        if(val[i].length>1){
                            this.isDisabled = false
                        }  
                    }else{
                        if(val[i].length>0){
                            this.isDisabled = false
                        }  
                    }
                }
            }
        }
    }
  },
  methods: {
    formatDate(date) {
      return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
      ].join('/');
    },
    async getImage(link, doc) {
      const ImageLogo = await fetch(link).then((res) =>
        res.arrayBuffer()
      )
      var pngImage = await doc.embedPng(ImageLogo)
      return pngImage
    },
    async createTextFieldPers(fontsize, textColor, multiLine, form, page, x, y, width, height, txt, name, bgColor) {

      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand)
      textField.setText(txt)
      if (multiLine == true) {
        textField.enableMultiline()
      }
      textField.setAlignment(TextAlignment.Left)
      textField.addToPage(page, {
        x: x,
        y: y,
        width: width,
        height: height,
        textColor: rgb(0.062, 0.227, 0.368),
        backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
        borderWidth: 0,
      })
      textField.acroField.setDefaultAppearance(setFontAndSize('Courier', fontsize).toString() + setFillingRgbColor(textColor[0], textColor[1], textColor[2]).toString())
      var fields = form.getFields()
      fields.forEach(field => {
        field.enableReadOnly()
      })
      form.updateFieldAppearances()
    },
    async createTextField(multiLine, form, page, x, y, width, height, txt, name, bgColor) {
      var fontsize = 9
      /////////////////////
      if (width / txt.length <= 3.5) {
        if (name.indexOf("value") != -1 && name.indexOf("TypeOffre") != -1) {
          if (width / txt.length <= 2) {
            fontsize = 6.5
            height = height + 2.5
          } else {
            fontsize = 8
          }
        } else {
          fontsize = 8
        }
        multiLine = true
      }
      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand)
      textField.setText(txt)
      if (multiLine == true) {
        textField.enableMultiline()
      }
      if (name == "title8BigPourcentage") {
        textField.enableMultiline()
        textField.setAlignment(TextAlignment.Center)
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height + 20,
          textColor: rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        })

      } else {
        textField.setAlignment(TextAlignment.Center)
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height,
          textColor: rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        })

      }
      textField.acroField.setDefaultAppearance(setFontAndSize('Courier', fontsize).toString() + setFillingRgbColor(0.062, 0.227, 0.368).toString())
      var fields = form.getFields()
      fields.forEach(field => {
        field.enableReadOnly()
      })
      form.updateFieldAppearances()
    },
    async infoTable(form, pdfDoc, page, donner) {

      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      page.drawText("RESULTATS DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()), {
        x: 100,
        y: 560,
        font: HelveticaBold,
        size: 25,
        color: rgb(0.062, 0.227, 0.368),
      })
      page.drawText("Vos informations", {
        x: 50,
        y: 520,
        font: HelveticaBold,
        size: 20,
        color: rgb(0.062, 0.227, 0.368),
      })
      var image = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var typeCompteur = donner['typeCompteur']
      var xBegin = donner['xBegin']
      var yBegin = donner['yBegin']
      for (var i = 0; i < donner['data'].length; i++) {
        var data = donner['data']
        var width = data[i]['width']
        var title = data[i]['title']
        var lesTexts = data[i]['text']
        var fieldName = data[i]['fieldName']

        page.drawImage(image, { x: xBegin, y: yBegin, width: width, height: 32, })
        await this.createTextField(false, form, page, xBegin, yBegin + 49, width, 15, title, "title" + fieldName, [1, 1, 1])
        var xForm = xBegin + 10
        for (var j = 0; j < lesTexts.length; j++) {
          var text = lesTexts[j]
          var subTitle = text['title']
          var value = text['value']
          var fieldName = text['fieldName']
          if (lesTexts.length > 2) {
            var widthForm = (width / lesTexts.length) - 8
          } else if (lesTexts.length == 2) {
            var widthForm = (width / lesTexts.length) - 12
          } else {
            var widthForm = width - 15
          }
          await this.createTextField(false, form, page, xForm, yBegin + 30, widthForm, 15, subTitle, "subTitle" + fieldName, [1, 1, 1])

          try {
            if (subTitle == "Compteur" || (value.indexOf('/') != -1) || isNaN(parseFloat(value).toFixed(2))) {
              value = value
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value
          }
          await this.createTextField(false, form, page, xForm, yBegin + 5, widthForm, 22, value, fieldName, [1, 0.796, 0.207])
          xForm = xForm + widthForm + 5
        }
        if (typeCompteur == "C5HP") {
          xBegin = xBegin + 20
        }
        if (typeCompteur == "C5BASE") {
          xBegin = xBegin + 40
        }
        xBegin = xBegin + width
      }
      return pdfDoc
    },
    async drawOffreActuelle(form, pdfDoc, page, donner) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      var y = 400
      page.drawText("Offre de renouvellement / actuelle", {
        x: 50,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      })

      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc)

      var xBegin = donner['xBegin']
      var yBegin = 320
      var width = 515
      var logo = await this.getImage(donner['logo'], pdfDoc)
      page.drawImage(logo, { x: xBegin - 110, y: yBegin + 2, width: 110, height: 40 })
      page.drawImage(tableLine, { x: xBegin, y: yBegin + 2, width: width, height: 40, })
      var smallWidth = 80
      page.drawImage(yellow, { x: xBegin + width, y: yBegin + 2, width: smallWidth, height: 40, })
      var xForm = xBegin
      var widthForm = 68
      var value = ""
      var title = ""
      var subtitle = ""
      var fieldName = ""
      for (var i = 0; i < donner['data'].length; i++) {
        var data = donner['data'][i]
        xForm = xBegin + data['pas']
        widthForm = data['width']
        value = data['value']
        title = data['title']
        subtitle = data['subTitle']
        fieldName = data['fieldName']
        await this.createTextField(false, form, page, xForm, yBegin + 56, widthForm, 15, title, "title" + fieldName, [1, 1, 1])
        await this.createTextField(false, form, page, xForm, yBegin + 40, widthForm, 15, subtitle, "subtitle" + fieldName, [1, 1, 1])
        xForm = xBegin + data['pas']
        try {
          if (title == "Durée" || isNaN(parseFloat(value).toFixed(2))) {
            value = value
          } else {
            value = parseFloat(value).toFixed(2);
          }
        } catch (exception) {
          value = value
        }
        await this.createTextField(false, form, page, xForm, yBegin + 12, widthForm, 21, value, "value" + fieldName, [1, 0.796, 0.207])
        xBegin = xBegin + widthForm + data['pas']
      }

      return pdfDoc
    },
    filterArray(inputArr) {
      var found = {};
      var out = inputArr.filter(function (element) {
        return found.hasOwnProperty(element) ? false : (found[element] = true);
      });
      return out;
    },
    async drawOffres(form, pdfDoc, page, donner, fournisseurActuelle) {
      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc)
      var green = await this.getImage("/imagePres/green.PNG", pdfDoc)
      var greenLine = await this.getImage("/imagePres/tableGreenRecap.PNG", pdfDoc)
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      var y = (fournisseurActuelle) ? 290 : 350
      page.drawText("Nos meilleures offres", {
        x: 50,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      })
      var xBegin = donner['xBegin']
      var yBegin = (fournisseurActuelle) ? 210 : 270
      var actuelle = donner['actuelle']
      var width = 515

      var xForm = xBegin
      var widthForm = 68
      var value = ""
      var title = ""
      var subtitle = ""
      var fieldName = ""
      for (var i = 0; i < donner['titles'].length; i++) {
        var data = donner['titles'][i]
        xForm = xBegin + data['pas']
        widthForm = data['width']
        value = data['value']
        title = data['title']
        subtitle = data['subTitle']
        fieldName = data['fieldName']
        await this.createTextField(false, form, page, xForm, yBegin + 56, widthForm, 15, title, "title" + i + fieldName, [1, 1, 1])
        await this.createTextField(false, form, page, xForm, yBegin + 40, widthForm, 15, subtitle, "subtitle" + i + fieldName, [1, 1, 1])
        xBegin = xBegin + widthForm + data['pas']
      }
      var xForm = xBegin
      var widthForm = 68
      var value = ""
      var fieldName = ""
      for (var i = 0; i < donner['data'].length; i++) {
        xBegin = donner['xBegin']
        if (i == 0) {
          page.drawImage(greenLine, { x: xBegin, y: yBegin + 2, width: width, height: 40, })
          var smallWidth = 80
          page.drawImage(green, { x: xBegin + width, y: yBegin + 2, width: smallWidth, height: 40, })
          if (actuelle == true) {
            page.drawImage(green, { x: xBegin + smallWidth + width + 2, y: yBegin + 2, width: smallWidth + 15, height: 40, })
          }
          var colorBackGroundTextField = [0.662, 0.941, 0.819];
        } else {
          page.drawImage(tableLine, { x: xBegin, y: yBegin + 2, width: width, height: 40, })
          var smallWidth = 80
          page.drawImage(yellow, { x: xBegin + width, y: yBegin + 2, width: smallWidth, height: 40, })
          if (actuelle == true) {
            page.drawImage(yellow, { x: xBegin + smallWidth + width + 2, y: yBegin, width: smallWidth + 15, height: 40, })
          }
          var colorBackGroundTextField = [1, 0.796, 0.207];
        }
        var itemData = donner['data'][i]
        var logo = await this.getImage(itemData[0]['value'], pdfDoc)
        switch (itemData[0]['value']) {
          case "/logoParticulier/OHM_120X35.png":
            page.drawImage(logo, { x: xBegin - 110, y: yBegin + 2, width: 110, height: 30, })
            break;
          case "/logoParticulier/Ekwateur_120X35.png":
            page.drawImage(logo, { x: xBegin - 110, y: yBegin + 2, width: 110, height: 30, })
            break;
          default:
            page.drawImage(logo, { x: xBegin - 110, y: yBegin + 2, width: 110, height: 40, })
            break;
        }
        for (var j = 1; j < itemData.length; j++) {
          var data = itemData[j]
          xForm = xBegin + data['pas']
          widthForm = data['width']
          value = data['value']
          fieldName = data['fieldName']
          xForm = xBegin + data['pas']
          try {
            if (value.indexOf("MOIS") != -1 || isNaN(parseFloat(value).toFixed(2))) {
              value = value
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value
          }
          await this.createTextField(false, form, page, xForm, yBegin + 12, widthForm, 21, value, "value" + i + j + fieldName, colorBackGroundTextField)
          xBegin = xBegin + widthForm + data['pas']
        }

        yBegin = yBegin - 45

        if (yBegin < 45 && i != donner['data'].length - 1) {
          page = pdfDoc.addPage([842, 595])
          yBegin = 450
        }

        var imageBorder = await this.getImage("/imagePres/border-presentation.jpg", pdfDoc)
        page.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 })
      }
      return [pdfDoc, page, yBegin]
    },
    async drawPricingTbaleC3(form, pdfDoc, page, donner, fournisseur, fournisseurActuelle) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      var typeCompteur = donner['typeCompteur']
      page.drawText("RECAPITULATIF DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()), {
        x: 100,
        y: 530,
        font: HelveticaBold,
        size: 25,
        color: rgb(0.062, 0.227, 0.368),
      })
      var imageBorder = await this.getImage("/imagePres/border-presentation.jpg", pdfDoc)
      page.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 })

      var c3Pricing = await this.getImage(donner['UrlPricing'], pdfDoc)
      var green = await this.getImage(donner['UrlGreen'], pdfDoc)
      var c3PriceYellow = await this.getImage(donner['UrlPricingYellow'], pdfDoc)
      var yellow = await this.getImage(donner['yellow'], pdfDoc)
      var yBegin = donner['yBegin']
      var xBegin = donner['xBegin']
      var width = ""
      var height = ""
      var titles = donner['titre']
      var xf = xBegin
      var xf2 = xBegin + 4
      for (var j = 0; j < titles.length; j++) {
        var v = titles[j]['value']
        var w = titles[j]['width']
        var st = titles[j]['subTitle']
        var fd = titles[j]['fieldName']
        if (titles[j]['twoLine'] == true) {
          var h = 23
        } else {
          var h = 18
        }
        await this.createTextField(titles[j]['twoLine'], form, page, xf, yBegin + 55, w, h, v, 'title' + fd + j, [1, 1, 1])
        for (var i = 0; i < st.length; i++) {
          var width2 = (w / st.length) - 4
          await this.createTextField(titles[j]['twoLine'], form, page, xf2, yBegin + 36, width2, 18, st[i], 'subtitle' + fd + i, [1, 1, 1])
          xf2 = xf2 + width2 + 4
        }
        xf2 = xf2 + 4
        xf = xf + w + 4
      }
      var nbrPage = 0
      for (var j = 0; j < donner['data'].length; j++) {
        var line = donner['data'][j]
        try {
          var logo = await this.getImage(line[0]['logo'], pdfDoc)
          if (line[0]['logo'] == "/logoParticulier/Ekwateur_120X35.png") {
            page.drawImage(logo, { x: xBegin - 90, y: yBegin + 3, width: 85, height: 25, })
          } else {
            page.drawImage(logo, { x: xBegin - 90, y: yBegin + 3, width: 85, height: 28, })
          }
        } catch (error) {
          page.drawText(line[0]['logo'], {
            x: xBegin - 70,
            y: yBegin + 12,
            font: Helvetica,
            size: 14,
            color: rgb(0.062, 0.227, 0.368),
          })
        }
        for (var posData = 1; posData < line.length; posData++) {
          var item = line[posData]
          width = item['image']['width']
          height = item['image']['height']
          var IndexMoinscher = 0
          if (fournisseurActuelle == true) {
            if (fournisseur["resultat"]["result"].length > 1) {
              if (parseFloat("0" + fournisseur["resultat"]["result"][0]['budget']) >= parseFloat("0" + fournisseur["resultat"]["result"][1]['budget'])) {
                IndexMoinscher = 1
              }
            }
          }
          if (j == IndexMoinscher) {
            //firstImage for pricing
            if (item['image']['name'] == "pricing") {
              page.drawImage(c3Pricing, { x: xBegin, y: yBegin, width: width, height: height, })

            } else {
              page.drawImage(green, { x: xBegin, y: yBegin - 4, width: width, height: height + 6, })
            }
            var xForm = xBegin
            var widthForm = 0
            for (var x = 0; x < item['items'].length; x++) {
              var lesValeurs = item['items'][x]
              xForm = xForm + widthForm + lesValeurs['pas']
              var widthForm = lesValeurs['width']
              var fieldName = lesValeurs['fieldName']
              var value = lesValeurs['value']
              try {
                if (isNaN(parseFloat(value).toFixed(2))) {
                  value = value
                } else {
                  value = parseFloat(value).toFixed(2);
                }
              } catch (exception) {
                value = value
              }
              await this.createTextField(false, form, page, xForm, yBegin + 5, widthForm, 25, value, fieldName + j + x + nbrPage, [0.662, 0.941, 0.819])
            }
            xBegin = xBegin + width + 4
          } else {
            //firstImage for pricing
            if (item['image']['name'] == "pricing") {
              page.drawImage(c3PriceYellow, { x: xBegin, y: yBegin, width: width, height: height, })

            } else {
              page.drawImage(yellow, { x: xBegin, y: yBegin, width: width, height: height, })
            }
            var xForm = xBegin
            var widthForm = 0
            for (var x = 0; x < item['items'].length; x++) {
              var lesValeurs = item['items'][x]
              xForm = xForm + widthForm + lesValeurs['pas']
              var widthForm = lesValeurs['width']
              var fieldName = lesValeurs['fieldName']
              var value = lesValeurs['value']
              try {
                if (isNaN(parseFloat(value).toFixed(2))) {
                  value = value
                } else {
                  value = parseFloat(value).toFixed(2);
                }
              } catch (exception) {
                value = value
              }
              await this.createTextField(false, form, page, xForm, yBegin + 5, widthForm, 25, value, fieldName + j + x + nbrPage, [1, 0.796, 0, 207])
            }
            xBegin = xBegin + width + 4

          }
        }
        yBegin = yBegin - 38
        xBegin = donner['xBegin']
        if (yBegin <= 77 && (donner['data'].length - 1) - j >= 1) {
          nbrPage = nbrPage + 1
          page = pdfDoc.addPage([842, 595])
          page.drawText("RECAPITULATIF DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()), {
            x: 100,
            y: 530,
            font: HelveticaBold,
            size: 25,
            color: rgb(0.062, 0.227, 0.368),
          })
          var titles = donner['titre']
          var yBegin = donner['yBegin']
          var xBegin = donner['xBegin']
          var width = ""
          var height = ""
          var titles = donner['titre']
          var xf = xBegin
          var xf2 = xBegin + 4
          for (var index = 0; index < titles.length; index++) {
            var v = titles[index]['value']
            var w = titles[index]['width']
            var st = titles[index]['subTitle']
            var fd = titles[index]['fieldName']
            if (titles[index]['twoLine'] == true) {
              var h = 23
            } else {
              var h = 18
            }
            await this.createTextField(titles[index]['twoLine'], form, page, xf, yBegin + 55, w, h, v, 'title' + fd + index + nbrPage, [1, 1, 1])
            for (var iindex = 0; iindex < st.length; iindex++) {
              var width2 = (w / st.length) - 4
              await this.createTextField(titles[index]['twoLine'], form, page, xf2, yBegin + 36, width2, 18, st[iindex], 'subtitle' + fd + iindex + nbrPage, [1, 1, 1])
              xf2 = xf2 + width2 + 4
            }
            xf2 = xf2 + 4
            xf = xf + w + 4
          }
        }
      }
      if (typeCompteur == "C5BASE" || typeCompteur == "C5HP") {
        let returnForLogo = false;
        for (let i = 0; i < donner.data.length; i++) {
          if (!donner.data[i][0].logo.startsWith("/")) {
            var imageAmpoule = await this.getImage("/imagePres/ampoule.png", pdfDoc)
            page.drawImage(imageAmpoule, { x: 730, y: 250, width: 100, height: 110 })
            returnForLogo = true;
            break;
          }
        }
        if (!returnForLogo) {

          switch (this.listDesOffre.length) {
            case 2:
              var imageAmpoule = await this.getImage("/imagePres/ampoule.png", pdfDoc)
              page.drawImage(imageAmpoule, { x: 750, y: 360, width: 70, height: 80 })
              break;

            case 3:
              var imageAmpoule = await this.getImage("/imagePres/ampoule.png", pdfDoc)
              page.drawImage(imageAmpoule, { x: 730, y: 350, width: 75, height: 85 })
              break;
            case 4:
              var imageAmpoule = await this.getImage("/imagePres/ampoule.png", pdfDoc)
              page.drawImage(imageAmpoule, { x: 730, y: 350, width: 75, height: 85 })
              break;

            default:
              var imageAmpoule = await this.getImage("/imagePres/ampoule.png", pdfDoc)
              page.drawImage(imageAmpoule, { x: 730, y: 370, width: 40, height: 50 })
              break;
          }
        }
        ///////////////////////////////////
      }
      return pdfDoc;
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(
        donorPdf,
        donorPdf.getPageIndices()
      )
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page)
      })
      return mergedPdf
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    async getConsomationTotaleGraphe(page, doc, dataEnedis, typeCompteur, donnerCompteur) {
      var result_Enedis_Horaire = false
      var errGraph1 = false
      let payload1 = {
        //jsonData est la reponse de enedis qui contient la consommation par mois
        data: dataEnedis,
        typeCompteur: typeCompteur,
        donnerCompteur: donnerCompteur,
      }
      if (dataEnedis.mesuresInfo[0].nbrMonth >= 8) {
        errGraph1 = true
        await axios.post(process.env.VUE_APP_URL_API_CLIENT + 'getBarGraphGEMC/', {
          ...payload1,
        })
          .then(async (res) => {
            var Tmpimg = res.data.some;
            if (this.typeCompteur == "C5BASE") {
              try {
                var imgg = await this.getImage(Tmpimg, doc)
                page.drawImage(imgg, { x: 380, y: 110, width: 450, height: 200 })
              } catch (erro) {
                errGraph1 = false
              }
            } else {
              try {
                var imgg = await this.getImage(Tmpimg, doc)
                page.drawImage(imgg, { x: 380, y: 110, width: 450, height: 200 })
              } catch (erro) {
                errGraph1 = false
              }
            }
          }).catch((err) => {
            errGraph1 = false
          })
      }
      await axios.post(process.env.VUE_APP_URL_API_CLIENT + 'getSommeConsoGraphGEMC/',
        payload1
      )
        .then(async (res) => {
          this.img = res.data.some;
          try {
            var imgg = await this.getImage(this.img, doc)
            if (errGraph1 == true) {
              page.drawImage(imgg, { x: 20, y: 80, width: 350, height: 295 })
            } else {
              page.drawImage(imgg, { x: 200, y: 70, width: 350, height: 295 })
            }
          } catch (erro) {
          }
        }).catch((err) => {
        })
    },
    async createPres() {
      this.loadingPres = true;
      this.dialogPres = true;
      if (this.indexes.length > 0) {
        var pdfDoc = await PDFDocument.create()
        const presentationURLPartBegin = '/pdf/Presentation-3-5.pdf'
        const presentationPartBeginBytes = await fetch(presentationURLPartBegin).then((res) =>
          res.arrayBuffer()
        )
        var presentationPagePartBeginDoc = await PDFDocument.load(presentationPartBeginBytes)
        const HelveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
        const TimesRomanFont = await presentationPagePartBeginDoc.embedFont(StandardFonts.TimesRomanBold)
        pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartBeginDoc)

        let fournisseurList = []
        // loop through all compteurs and create pages 
        for (let [k, fournisseur] of this.donnerDesFournisseur.entries()) {

          /// les information pour chaque compteur
          let typeCompteur = fournisseur["resultat"]["typeCompteur"];
          let numCompteur = fournisseur["resultat"]["numCompteur"];
          let donnerCompteur = fournisseur["resultat"]["donnerCompteur"];
          let fournisseurActuelle = fournisseur["resultat"]["actuelle"];
          let dataEnedis = fournisseur["resultat"]["dataEnedis"];

          // filter les offre sélectionnée pour ce compteur
          let listDesOffre = fournisseur["resultat"]["result"].filter((item, ind) => {
            return this.indexes[k]?.includes(ind);
          });

          if (listDesOffre.length != 0) {
            let pageBlanche = pdfDoc.addPage([842, 595])
            await this.getConsomationTotaleGraphe(pageBlanche, pdfDoc, dataEnedis, typeCompteur, donnerCompteur)
            var frm = pdfDoc.getForm()
            var imageBorder = await this.getImage("/imagePres/border-presentation.jpg", pdfDoc)
            pageBlanche.drawImage(imageBorder, { x: 742, y: 0, width: 100, height: 100 })
            for (var i = 0; i < this.indexes.length; i++) {
              pageBlanche.drawText('VOTRE SOCIETE', {
                x: 50,
                y: 530,
                size: 25,
                font: HelveticaBoldFont,
                color: rgb(0.10, 0.17, 0.35),
              })
              if (i == 0) {
                await this.createTextFieldPers(15, [0.10, 0.17, 0.35], true, frm, pageBlanche, 80, 470, 750, 40, `Raison Sociale : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['Raison']}`, "raisonConsommation", [1, 1, 1])
                await this.createTextFieldPers(15, [0.10, 0.17, 0.35], true, frm, pageBlanche, 80, 450, 750, 40, `SIRET : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['siret']}`, "siretConsommation", [1, 1, 1])
                if (dataEnedis.donneTechniqueInfo[0].result == "valide"){
                await this.createTextFieldPers(15, [0.10, 0.17, 0.35], true, frm, pageBlanche, 80, 430, 750, 40, `Adresse de consommation : ${dataEnedis.donneTechniqueInfo[0].data.adresse}`, "adresseConsommation", [1, 1, 1])
                }else{
                await this.createTextFieldPers(15, [0.10, 0.17, 0.35], true, frm, pageBlanche, 80, 430, 750, 40, `Adresse de consommation : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['AdressePostal']}`, "adresseConsommation", [1, 1, 1])
                }
              }

              pageBlanche.drawText(`VOTRE HISTORIQUE DE CONSOMMATION (MWH)`, {
                x: 50,
                y: 380,
                size: 25,
                font: HelveticaBoldFont,
                color: rgb(0.10, 0.17, 0.35),
              })
            }
            var page = pdfDoc.addPage([842, 595]);
            const form = pdfDoc.getForm();
            if (typeCompteur == "C5BASE") {
              var enteteInfoCompteur = {
                typeCompteur: 'C5BASE',
                xBegin: 80,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: 'pdl',
                        title: 'Compteur',
                        value: numCompteur + "",
                      },
                      {
                        fieldName: 'dateDebut',
                        title: 'Déb.contrat ',
                        value: donnerCompteur['dateDebut'],
                      }
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: 'kvaBASE',
                        title: 'BASE',
                        value: donnerCompteur['kvaBASE'] + "",
                      },
                    ],
                    width: 110,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: 'carBASE',
                        title: 'BASE',
                        value: donnerCompteur['CABASE'] + "",
                      },
                    ],
                    width: 110,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: 'Total',
                        title: 'MWh/an',
                        value: donnerCompteur['CAR'] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: 'Total',
                    fieldName: "titleTotal",
                  }
                ]
              }
            } else if (typeCompteur == "C5HP") {
              var enteteInfoCompteur = {
                typeCompteur: 'C5HP',
                xBegin: 70,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: 'pdl',
                        title: 'Compteur',
                        value: numCompteur + "",
                      },
                      {
                        fieldName: 'dateDebut',
                        title: 'Déb.contrat ',
                        value: donnerCompteur['dateDebut'],
                      }
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: 'kvaHP',
                        title: 'HP',
                        value: donnerCompteur['kvaHP'] + "",
                      },
                      {
                        fieldName: 'kvaHC',
                        title: 'HC',
                        value: donnerCompteur['kvaHC'] + "",
                      },
                    ],
                    width: 150,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: 'carHP',
                        title: 'HP',
                        value: donnerCompteur['CAHP'] + "",
                      },
                      {
                        fieldName: 'carHC',
                        title: 'HC',
                        value: donnerCompteur['CAHC'] + "",
                      },
                    ],
                    width: 150,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: 'Total',
                        title: 'MWh/an',
                        value: donnerCompteur['CAR'] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: 'Total',
                    fieldName: "titleTotal",
                  }
                ]
              }
            } else if (typeCompteur == "C4") {
              var enteteInfoCompteur = {
                typeCompteur: 'C4',
                xBegin: 20,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: 'pdl',
                        title: 'Compteur',
                        value: numCompteur + "",
                      },
                      {
                        fieldName: 'dateDebut',
                        title: 'Déb.contrat ',
                        value: donnerCompteur['dateDebut'],
                      }
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: 'kvaHPH',
                        title: 'HPH',
                        value: donnerCompteur['kvaHPH'] + "",
                      },
                      {
                        fieldName: 'kvaHCH',
                        title: 'HCH',
                        value: donnerCompteur['kvaHCH'] + "",
                      },
                      {
                        fieldName: 'kvaHPE',
                        title: 'HPE',
                        value: donnerCompteur['kvaHPE'] + "",
                      },
                      {
                        fieldName: 'kvaHCE',
                        title: 'HCE',
                        value: donnerCompteur['kvaHCE'] + "",
                      }
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: 'carHPH',
                        title: 'HPH',
                        value: donnerCompteur['CAHPH'] + "",
                      },
                      {
                        fieldName: 'carHCH',
                        title: 'HCH',
                        value: donnerCompteur['CAHCH'] + "",
                      },
                      {
                        fieldName: 'carHPE',
                        title: 'HPE',
                        value: donnerCompteur['CAHPE'] + "",
                      },
                      {
                        fieldName: 'carHCE',
                        title: 'HCE',
                        value: donnerCompteur['CAHCE'] + "",
                      }
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: 'Total',
                        title: 'MWh/an',
                        value: donnerCompteur['CAR'] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: 'Total',
                    fieldName: "titleTotal",
                  }
                ]
              }
            } else if (typeCompteur == "C5C4") {
              var enteteInfoCompteur = {
                typeCompteur: 'C4',
                xBegin: 20,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: 'pdl',
                        title: 'Compteur',
                        value: numCompteur + "",
                      },
                      {
                        fieldName: 'dateDebut',
                        title: 'Déb.contrat ',
                        value: donnerCompteur['dateDebut'],
                      }
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: 'kvaHPH',
                        title: 'HPH',
                        value: donnerCompteur['kvaHPH'] + "",
                      },
                      {
                        fieldName: 'kvaHCH',
                        title: 'HCH',
                        value: donnerCompteur['kvaHCH'] + "",
                      },
                      {
                        fieldName: 'kvaHPE',
                        title: 'HPE',
                        value: donnerCompteur['kvaHPE'] + "",
                      },
                      {
                        fieldName: 'kvaHCE',
                        title: 'HCE',
                        value: donnerCompteur['kvaHCE'] + "",
                      }
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: 'carHPH',
                        title: 'HPH',
                        value: donnerCompteur['CAHPH'] + "",
                      },
                      {
                        fieldName: 'carHCH',
                        title: 'HCH',
                        value: donnerCompteur['CAHCH'] + "",
                      },
                      {
                        fieldName: 'carHPE',
                        title: 'HPE',
                        value: donnerCompteur['CAHPE'] + "",
                      },
                      {
                        fieldName: 'carHCE',
                        title: 'HCE',
                        value: donnerCompteur['CAHCE'] + "",
                      }
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: 'Total',
                        title: 'MWh/an',
                        value: donnerCompteur['CAR'] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: 'Total',
                    fieldName: "titleTotal",
                  }
                ]
              }
            }
            else if (typeCompteur == "C3") {
              var enteteInfoCompteur = {
                typeCompteur: 'C3',
                xBegin: 20,
                yBegin: 430,
                data: [
                  {
                    text: [
                      {
                        fieldName: 'pdl',
                        title: 'Compteur',
                        value: numCompteur + "",
                      },
                      {
                        fieldName: 'dateDebut',
                        title: 'Déb.contrat ',
                        value: donnerCompteur['dateDebut'],
                      }
                    ],
                    width: 200,
                    height: 32,
                    title: "",
                    fieldName: "titleInfo",
                  },
                  {
                    text: [
                      {
                        fieldName: 'kvaPTE',
                        title: 'PTE',
                        value: donnerCompteur['kvaPTE'] + "",
                      },
                      {
                        fieldName: 'kvaHPH',
                        title: 'HPH',
                        value: donnerCompteur['kvaHPH'] + "",
                      },
                      {
                        fieldName: 'kvaHCH',
                        title: 'HCH',
                        value: donnerCompteur['kvaHCH'] + "",
                      },
                      {
                        fieldName: 'kvaHPE',
                        title: 'HPE',
                        value: donnerCompteur['kvaHPE'] + "",
                      },
                      {
                        fieldName: 'kvaHCE',
                        title: 'HCE',
                        value: donnerCompteur['kvaHCE'] + "",
                      }
                    ],
                    width: 245,
                    height: 32,
                    title: "Puissances souscrites KVA",
                    fieldName: "titleKva",
                  },
                  {
                    text: [
                      {
                        fieldName: 'carPTE',
                        title: 'PTE',
                        value: donnerCompteur['CAP'] + "",
                      },
                      {
                        fieldName: 'carHPH',
                        title: 'HPH',
                        value: donnerCompteur['CAHPH'] + "",
                      },
                      {
                        fieldName: 'carHCH',
                        title: 'HCH',
                        value: donnerCompteur['CAHCH'] + "",
                      },
                      {
                        fieldName: 'carHPE',
                        title: 'HPE',
                        value: donnerCompteur['CAHPE'] + "",
                      },
                      {
                        fieldName: 'carHCE',
                        title: 'HCE',
                        value: donnerCompteur['CAHCE'] + "",
                      }
                    ],
                    width: 245,
                    height: 32,
                    title: "Consommation MWh",
                    fieldName: "titleCar",
                  },
                  {
                    text: [
                      {
                        fieldName: 'Total',
                        title: 'MWh/an',
                        value: donnerCompteur['CAR'] + "",
                      },
                    ],
                    width: 90,
                    height: 32,
                    title: 'Total',
                    fieldName: "titleTotal",
                  }
                ]
              }
            }
            pdfDoc = await this.infoTable(form, pdfDoc, page, enteteInfoCompteur)
            this.Offres = {
              actuelle: fournisseurActuelle,
              xBegin: 140,
              yBegin: 240,
              titles: [
                {
                  title: "Durée",
                  subTitle: "",
                  width: 62,
                  pas: 7,
                  fieldName: "BigDurre",
                },
                {
                  title: "Type d'offre",
                  subTitle: "",
                  width: 64,
                  pas: 8,
                  fieldName: "BigTypeOffre",
                },
                {
                  title: "Budget énergie",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 8,
                  fieldName: "BigAchem",
                },
                {
                  title: "Acheminement",
                  subTitle: "en €/an HT",
                  width: 64,
                  pas: 12,
                  fieldName: "BigAchem",
                },
                {
                  title: "Taxes",
                  subTitle: "en €/an",
                  width: 60,
                  pas: 15,
                  fieldName: "BigTaxe",
                },
                {
                  title: "Total annuel",
                  subTitle: "en €/an",
                  width: 60,
                  pas: 10,
                  fieldName: "TotalAnnuel",
                },
                {
                  title: "TVA",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 7,
                  fieldName: "tva",
                },
                {
                  title: "Total TTC",
                  subTitle: "en €/an",
                  width: 64,
                  pas: 20,
                  fieldName: "BigTTc",
                },
              ],
              data: [
              ]
            }
            if (fournisseurActuelle == true) {
              var OffreActuelle = {
                xBegin: 130,
                yBegin: 330,
                logo: "/logoParticulier/" + fournisseur["resultat"]["result"][0]['logo'],
                data: [
                  {
                    title: "Durée",
                    subTitle: "",
                    value: fournisseur["resultat"]["result"][0]['nbrMonth'] + " MOIS",
                    fieldName: "ActuDuree",
                    width: 62,
                    pas: 7,
                  },
                  {
                    title: "Type  d'offre",
                    subTitle: "",
                    value: fournisseur["resultat"]["result"][0]['type'] + "",
                    fieldName: "ActuTypeOffre",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Budget Energie",
                    subTitle: "en €/an HT",
                    value: fournisseur["resultat"]["result"][0]['budgetAbo'] + "",
                    fieldName: "ActuBudget",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Acheminement",
                    subTitle: "en €/an HT",
                    value: fournisseur["resultat"]["result"][0]['TURPE'] + "",
                    fieldName: "ActuAchm",
                    width: 64,
                    pas: 12,
                  },
                  {
                    title: "Taxes",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]['budgetTaxe'] + "",
                    fieldName: "ActuTaxes",
                    width: 60,
                    pas: 15,
                  },
                  {
                    title: "Total Annuel",
                    subTitle: "en €/an HT",
                    value: fournisseur["resultat"]["result"][0]['budget'] + "",
                    fieldName: "ActuTotalAnnuel",
                    width: 60,
                    pas: 13,
                  },
                  {
                    title: "TVA",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]['TVA'] + "",
                    fieldName: "ActuTva",
                    width: 64,
                    pas: 7,
                  },
                  {
                    title: "Total TTC",
                    subTitle: "en €/an",
                    value: fournisseur["resultat"]["result"][0]['TotaleanuelleTTC'] + "",
                    fieldName: "ActuTotalTTC",
                    width: 64,
                    pas: 15,
                  },

                ]
              }
              pdfDoc = await this.drawOffreActuelle(form, pdfDoc, page, OffreActuelle)
              this.Offres.titles.push(
                {
                  title: "Economies par rapport à votre offre de renouvellement",
                  subTitle: "en %/an",
                  width: 90,
                  pas: 10,
                  fieldName: "BigPourcentage",
                }
              )
            }
            /////////////////////////////////////////////////////////////
            var pos_debut = 0
            if (fournisseurActuelle == true) {
              pos_debut = 1
            }
            for (var index = pos_debut; index < listDesOffre.length; index++) {
              try {
                var aboAn = listDesOffre[index]['mensuel'] * 12
                aboAn = aboAn.toFixed(2)
              } catch (error) {
                var aboAn = 0.0
              }
              if (listDesOffre[index]['typeYears'] == "avecSpot") {
                var item = [
                  {
                    value: "/logoParticulier/" + listDesOffre[index]['moyenne']['logo'],
                    fieldName: "logo",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['nbrMonth'] + " MOIS",
                    fieldName: "ActuDuree",
                    width: 62,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['type'] + "",
                    fieldName: "ActuTypeOffre",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Budget Energie",
                    subTitle: "en €/an HT",
                    value: listDesOffre[index]['moyenne']['budgetAbo'] + "",
                    fieldName: "ActuBudget",
                    width: 62,
                    pas: 8,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['TURPE'] + "",
                    fieldName: "ActuAchm",
                    width: 60,
                    pas: 15,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['budgetTaxe'] + "",
                    fieldName: "ActuTaxes",
                    width: 60,
                    pas: 20,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['budget'] + "",
                    fieldName: "ActuTotalAnnuel",
                    width: 60,
                    pas: 13,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['TVA'] + "",
                    fieldName: "ActuTva",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]['moyenne']['TotaleanuelleTTC'] + "",
                    fieldName: "ActuTotalTTC",
                    width: 64,
                    pas: 15,
                  },

                ]
              } else {

                var item = [
                  {
                    value: "/logoParticulier/" + listDesOffre[index]['logo'],
                    fieldName: "logo",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]['nbrMonth'] + " MOIS",
                    fieldName: "ActuDuree",
                    width: 62,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]['type'] + "",
                    fieldName: "ActuTypeOffre",
                    width: 64,
                    pas: 8,
                  },
                  {
                    title: "Budget Energie",
                    subTitle: "en €/an HT",
                    value: listDesOffre[index]['budgetAbo'] + "",
                    fieldName: "ActuBudget",
                    width: 62,
                    pas: 8,
                  },
                  {
                    value: listDesOffre[index]['TURPE'] + "",
                    fieldName: "ActuAchm",
                    width: 60,
                    pas: 15,
                  },
                  {
                    value: listDesOffre[index]['budgetTaxe'] + "",
                    fieldName: "ActuTaxes",
                    width: 60,
                    pas: 20,
                  },
                  {
                    value: listDesOffre[index]['budget'] + "",
                    fieldName: "ActuTotalAnnuel",
                    width: 60,
                    pas: 13,
                  },
                  {
                    value: listDesOffre[index]['TVA'] + "",
                    fieldName: "ActuTva",
                    width: 64,
                    pas: 7,
                  },
                  {
                    value: listDesOffre[index]['TotaleanuelleTTC'] + "",
                    fieldName: "ActuTotalTTC",
                    width: 64,
                    pas: 15,
                  },

                ]
              }
              if (fournisseurActuelle == true) {
                try {
                  if (listDesOffre[index]['typeYears'] == "avecSpot") {
                    var poucentage = ((fournisseur["resultat"]["result"][0]['TotaleanuelleTTC'] - listDesOffre[index]['moyenne']['TotaleanuelleTTC']) / fournisseur["resultat"]["result"][0]['TotaleanuelleTTC']) * 100
                    poucentage = poucentage.toFixed(2)
                  } else {
                    var poucentage = ((fournisseur["resultat"]["result"][0]['TotaleanuelleTTC'] - listDesOffre[index]['TotaleanuelleTTC']) / fournisseur["resultat"]["result"][0]['TotaleanuelleTTC']) * 100
                    poucentage = poucentage.toFixed(2)
                  }
                } catch (error) {
                  var poucentage = 0.0
                }
                item.push(
                  {
                    value: poucentage < 0 ? "--" : poucentage + "",
                    fieldName: "pourcentage",
                    width: 75,
                    pas: 14,
                  }
                )
              }
              this.Offres.data.push(item)
            }
            var res = await this.drawOffres(form, pdfDoc, page, this.Offres, fournisseurActuelle)
            pdfDoc = res[0]
            page = res[1]
            var yBegin = res[2]

            var page = pdfDoc.addPage([842, 595])
            var pricing = {
            }
            var posY = 290 + ((listDesOffre.length / 2) * 40)
            if (typeCompteur == "C5BASE") {
              pricing['typeCompteur'] = "C5BASE"
              pricing['titre'] = [
                {
                  twoLine: true,
                  fieldName: 'prixUnitaire',
                  value: "Prix unitaires en €/MWh",
                  width: 80,
                  subTitle: [
                    "BASE",
                  ]
                },
                {
                  twoLine: true,
                  fieldName: 'coutCapacite',
                  value: "Coût capacité en €/MWh",
                  width: 80,
                  subTitle: [
                    "BASE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'CEE',
                  value: "CEE",
                  width: 80,
                  subTitle: [
                    "€/MWh",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'Abo',
                  value: "Abo",
                  width: 80,
                  subTitle: [
                    "€/an",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'totaleEnergie',
                  value: "Budget Energie",
                  width: 90,
                  subTitle: [
                    "€/an",
                  ]
                },
              ]
              pricing['yBegin'] = posY
              pricing['xBegin'] = 230
              pricing['UrlPricing'] = "/imagePres/green.PNG"
              pricing['UrlGreen'] = "/imagePres/green.PNG"
              pricing['UrlPricingYellow'] = "/imagePres/Yellow1.PNG"
              pricing['yellow'] = "/imagePres/Yellow1.PNG"
              pricing['data'] = []
              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null

                if (listDesOffre[index]['typeYears'] == "many") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitaire'] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_base'] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["premierAnnee"]['unitaire'] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["premierAnnee"]['capa_base'] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["premierAnnee"]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Reste D'année",
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["secondeAnnee"]['unitaire'] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["secondeAnnee"]['capa_base'] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["secondeAnnee"]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)

                } else if (listDesOffre[index]['typeYears'] == "precie") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitaire'] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_base'] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['2022', '2023', '2024', '2025', '2026', '2027']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitaire'] + "",
                              fieldName: "prixBase",
                              width: 70,
                              pas: 7,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_base'] + "",
                              fieldName: "capaBase",
                              width: 70,
                              pas: 7,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                } else if (listDesOffre[index]['typeYears'] == "single") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitaire'] + "",
                          fieldName: "prixBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_base'] + "",
                          fieldName: "capaBase",
                          width: 70,
                          pas: 7,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                }
              }
            } else if (typeCompteur == "C5HP") {
              pricing['typeCompteur'] = "C5HP"
              pricing['titre'] = [
                {
                  twoLine: false,
                  fieldName: 'prixUnitaire',
                  value: "Prix unitaires en €/MWh",
                  width: 150,
                  subTitle: [
                    "HP",
                    "HC"
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'coutCapacite',
                  value: "Coût capacité en €/MWh",
                  width: 150,
                  subTitle: [
                    "HP",
                    "HC"
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'CEE',
                  value: "CEE",
                  width: 80,
                  subTitle: [
                    "€/MWh",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'Abo',
                  value: "Abo",
                  width: 80,
                  subTitle: [
                    "€/an",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'totaleEnergie',
                  value: "Budget Energie",
                  width: 90,
                  subTitle: [
                    "€/an",
                  ]
                },
              ]
              pricing['yBegin'] = posY
              pricing['xBegin'] = 150
              pricing['UrlPricing'] = "/imagePres/c5HpPrice.PNG"
              pricing['UrlGreen'] = "/imagePres/green.PNG"
              pricing['UrlPricingYellow'] = "/imagePres/c5HpPriceYellow.PNG"
              pricing['yellow'] = "/imagePres/Yellow1.PNG"
              pricing['data'] = []
              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null
                if (listDesOffre[index]['typeYears'] == "many") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehp'] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]['unitairehc'] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hp'] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]['capa_hc'] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["premierAnnee"]['unitairehp'] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["premierAnnee"]['unitairehc'] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["premierAnnee"]['capa_hp'] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["premierAnnee"]['capa_hc'] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]["premierAnnee"]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["premierAnnee"]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Reste D'années",
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["secondeAnnee"]['unitairehp'] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["secondeAnnee"]['unitairehc'] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["secondeAnnee"]['capa_hp'] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]["secondeAnnee"]['capa_hc'] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]["secondeAnnee"]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]["secondeAnnee"]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)

                } else if (listDesOffre[index]['typeYears'] == "precie") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehp'] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]['unitairehc'] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hp'] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]['capa_hc'] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['2022', '2023', '2024', '2025', '2026', '2027']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 150,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairehp'] + "",
                              fieldName: "prixHp",
                              width: 60,
                              pas: 10,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehc'] + "",
                              fieldName: "prixHc",
                              width: 60,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 150,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_hp'] + "",
                              fieldName: "capaHp",
                              width: 60,
                              pas: 10,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hc'] + "",
                              fieldName: "capaHc",
                              width: 60,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                } else if (listDesOffre[index]['typeYears'] == "single") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehp'] + "",
                          fieldName: "prixHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]['unitairehc'] + "",
                          fieldName: "prixHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 150,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hp'] + "",
                          fieldName: "capaHp",
                          width: 60,
                          pas: 10,
                        },
                        {
                          value: listDesOffre[index]['capa_hc'] + "",
                          fieldName: "capaHc",
                          width: 60,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                }
              }
            } else if (typeCompteur == "C4") {
              pricing['typeCompteur'] = "C4"
              pricing['titre'] = [
                {
                  twoLine: false,
                  fieldName: 'prixUnitaire',
                  value: "Prix unitaires en €/MWh",
                  width: 220,
                  subTitle: [
                    "HPH",
                    "HCH",
                    "HPE",
                    "HCE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'coutCapacite',
                  value: "Coût capacité en €/MWh",
                  width: 220,
                  subTitle: [
                    "HPH",
                    "HCH",
                    "HPE",
                    "HCE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'CEE',
                  value: "CEE",
                  width: 80,
                  subTitle: [
                    "€/MWh",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'Abo',
                  value: "Abo",
                  width: 80,
                  subTitle: [
                    "€/an",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'totaleEnergie',
                  value: "Budget Energie",
                  width: 90,
                  subTitle: [
                    "€/an",
                  ]
                },
              ]
              pricing['yBegin'] = posY
              pricing['xBegin'] = 110
              pricing['UrlPricing'] = "/imagePres/c4Price.PNG"
              pricing['UrlGreen'] = "/imagePres/green.PNG"
              pricing['UrlPricingYellow'] = "/imagePres/c4PriceYellow.PNG"
              pricing['yellow'] = "/imagePres/Yellow1.PNG"
              pricing['data'] = []
              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;
                if (listDesOffre[index]['typeYears'] == "many") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]["premierAnnee"]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Reste d'annees",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]["secondeAnnee"]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                } if (listDesOffre[index]['typeYears'] == "precie") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['2022', '2023', '2024', '2025', '2026', '2027']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairehph'] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehch'] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehpe'] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehce'] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_hph'] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hch'] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hpe'] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hce'] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                } else if (listDesOffre[index]['typeYears'] == "single") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                } else if (listDesOffre[index]['typeYears'] == "avecSpot") {

                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['moyenne']['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['moyenne']['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['moyenne']['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['spot', 'grille']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    var logo = ""
                    if (year == 'spot') {
                      logo = "8% Spot"
                    } else {
                      logo = "92% Grille"
                    }
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: logo,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairehph'] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehch'] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehpe'] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehce'] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_hph'] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hch'] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hpe'] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hce'] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                }
              }
            } 
            else if (typeCompteur == "C5C4") {
              pricing['typeCompteur'] = "C4"
              pricing['titre'] = [
                {
                  twoLine: false,
                  fieldName: 'prixUnitaire',
                  value: "Prix unitaires en €/MWh",
                  width: 220,
                  subTitle: [
                    "HPH",
                    "HCH",
                    "HPE",
                    "HCE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'coutCapacite',
                  value: "Coût capacité en €/MWh",
                  width: 220,
                  subTitle: [
                    "HPH",
                    "HCH",
                    "HPE",
                    "HCE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'CEE',
                  value: "CEE",
                  width: 80,
                  subTitle: [
                    "€/MWh",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'Abo',
                  value: "Abo",
                  width: 80,
                  subTitle: [
                    "€/an",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'totaleEnergie',
                  value: "Budget Energie",
                  width: 90,
                  subTitle: [
                    "€/an",
                  ]
                },
              ]
              pricing['yBegin'] = posY
              pricing['xBegin'] = 110
              pricing['UrlPricing'] = "/imagePres/c4Price.PNG"
              pricing['UrlGreen'] = "/imagePres/green.PNG"
              pricing['UrlPricingYellow'] = "/imagePres/c4PriceYellow.PNG"
              pricing['yellow'] = "/imagePres/Yellow1.PNG"
              pricing['data'] = []
              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null;
                if (listDesOffre[index]['typeYears'] == "many") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Première année",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]["premierAnnee"]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Reste d'annees",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]["secondeAnnee"]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                } if (listDesOffre[index]['typeYears'] == "precie") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['2022', '2023', '2024', '2025', '2026', '2027']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairehph'] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehch'] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehpe'] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehce'] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_hph'] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hch'] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hpe'] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hce'] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                } else if (listDesOffre[index]['typeYears'] == "single") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                } else if (listDesOffre[index]['typeYears'] == "avecSpot") {

                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['moyenne']['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 48,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 48,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 48,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 48,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['moyenne']['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['moyenne']['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['spot', 'grille']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    var logo = ""
                    if (year == 'spot') {
                      logo = "8% Spot"
                    } else {
                      logo = "92% Grille"
                    }
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: logo,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairehph'] + "",
                              fieldName: "prixHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehch'] + "",
                              fieldName: "prixHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehpe'] + "",
                              fieldName: "prixHpe",
                              width: 48,
                              pas: 6,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehce'] + "",
                              fieldName: "prixHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_hph'] + "",
                              fieldName: "capaHph",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hch'] + "",
                              fieldName: "capaHch",
                              width: 48,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hpe'] + "",
                              fieldName: "capaHpe",
                              width: 48,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hce'] + "",
                              fieldName: "capaHce",
                              width: 48,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                }
              }
            }
            else if (typeCompteur == "C3") {
              pricing['typeCompteur'] = "C3"
              pricing['titre'] = [
                {
                  twoLine: false,
                  fieldName: 'prixUnitaire',
                  value: "Prix unitaires en €/MWh",
                  width: 220,
                  subTitle: [
                    "PTE",
                    "HPH",
                    "HCH",
                    "HPE",
                    "HCE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'coutCapacite',
                  value: "Coût capacité en €/MWh",
                  width: 220,
                  subTitle: [
                    "PTE",
                    "HPH",
                    "HCH",
                    "HPE",
                    "HCE",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'CEE',
                  value: "CEE",
                  width: 80,
                  subTitle: [
                    "€/MWh",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'Abo',
                  value: "Abo",
                  width: 80,
                  subTitle: [
                    "€/an",
                  ]
                },
                {
                  twoLine: false,
                  fieldName: 'totaleEnergie',
                  value: "Budget Energie",
                  width: 90,
                  subTitle: [
                    "€/an",
                  ]
                },
              ]
              pricing['yBegin'] = posY
              pricing['xBegin'] = 110
              pricing['UrlPricing'] = "/imagePres/c3Price.PNG"
              pricing['UrlGreen'] = "/imagePres/green.PNG"
              pricing['UrlPricingYellow'] = "/imagePres/c3PriceYellow.PNG"
              pricing['yellow'] = "/imagePres/Yellow1.PNG"
              pricing['data'] = []
              for (var index = 0; index < listDesOffre.length; index++) {
                var et = null
                if (listDesOffre[index]['typeYears'] == "many") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairepointe'] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_pointe'] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: 'Première année',
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairepointe'] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_pointe'] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['premierAnnee']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['premierAnnee']['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['premierAnnee']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  et = [
                    {
                      logo: "Reste d'annees",
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairepointe'] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_pointe'] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['secondeAnnee']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['secondeAnnee']['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['secondeAnnee']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                } else if (listDesOffre[index]['typeYears'] == "precie") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairepointe'] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_pointe'] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['2022', '2023', '2024', '2025', '2026', '2027']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: year,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairepointe'] + "",
                              fieldName: "prixPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehph'] + "",
                              fieldName: "prixHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehch'] + "",
                              fieldName: "prixHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehpe'] + "",
                              fieldName: "prixHpe",
                              width: 38,
                              pas: 6,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehce'] + "",
                              fieldName: "prixHce",
                              width: 38,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_pointe'] + "",
                              fieldName: "capaPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hph'] + "",
                              fieldName: "capaHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hch'] + "",
                              fieldName: "capaHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hpe'] + "",
                              fieldName: "capaHpe",
                              width: 38,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hce'] + "",
                              fieldName: "capaHce",
                              width: 38,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [

                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                } else if (listDesOffre[index]['typeYears'] == "single") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['unitairepointe'] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['capa_pointe'] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                } else if (listDesOffre[index]['typeYears'] == "avecSpot") {
                  et = [
                    {
                      logo: "/logoParticulier/" + listDesOffre[index]['moyenne']['logo'],
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['unitairepointe'] + "",
                          fieldName: "prixPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehph'] + "",
                          fieldName: "prixHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehch'] + "",
                          fieldName: "prixHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehpe'] + "",
                          fieldName: "prixHpe",
                          width: 38,
                          pas: 6,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['unitairehce'] + "",
                          fieldName: "prixHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 220,
                        height: 36,
                        name: "pricing",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['capa_pointe'] + "",
                          fieldName: "capaPte",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hph'] + "",
                          fieldName: "capaHph",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hch'] + "",
                          fieldName: "capaHch",
                          width: 38,
                          pas: 5,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hpe'] + "",
                          fieldName: "capaHpe",
                          width: 38,
                          pas: 7,
                        },
                        {
                          value: listDesOffre[index]['moyenne']['capa_hce'] + "",
                          fieldName: "capaHce",
                          width: 38,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['moyenne']['cee'] + "",
                          fieldName: "CEE",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 80,
                        height: 36,
                        name: "color",
                      },
                      items: [

                        {
                          value: listDesOffre[index]['moyenne']['aboAns'] + "",
                          fieldName: "Abo",
                          width: 70,
                          pas: 5,
                        },
                      ]
                    },
                    {
                      image: {
                        width: 90,
                        height: 36,
                        name: "color",
                      },
                      items: [
                        {
                          value: listDesOffre[index]['moyenne']['budgetAbo'] + "",
                          fieldName: "totalEnergie",
                          width: 80,
                          pas: 5,
                        },
                      ]
                    },
                  ]
                  pricing['data'].push(et)
                  var lesAnnee = ['spot', 'grille']
                  for (var years = 0; years < lesAnnee.length; years++) {
                    var year = lesAnnee[years]
                    var logo = ""
                    if (year == 'spot') {
                      logo = "8% Spot"
                    } else {
                      logo = "92% Grille"
                    }
                    if (year in listDesOffre[index]) {
                      et = [
                        {
                          logo: logo,
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['unitairepointe'] + "",
                              fieldName: "prixPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehph'] + "",
                              fieldName: "prixHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehch'] + "",
                              fieldName: "prixHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehpe'] + "",
                              fieldName: "prixHpe",
                              width: 38,
                              pas: 6,
                            },
                            {
                              value: listDesOffre[index][year]['unitairehce'] + "",
                              fieldName: "prixHce",
                              width: 38,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 220,
                            height: 36,
                            name: "pricing",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['capa_pointe'] + "",
                              fieldName: "capaPte",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hph'] + "",
                              fieldName: "capaHph",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hch'] + "",
                              fieldName: "capaHch",
                              width: 38,
                              pas: 5,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hpe'] + "",
                              fieldName: "capaHpe",
                              width: 38,
                              pas: 7,
                            },
                            {
                              value: listDesOffre[index][year]['capa_hce'] + "",
                              fieldName: "capaHce",
                              width: 38,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index]['cee'] + "",
                              fieldName: "CEE",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 80,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['aboAns'] + "",
                              fieldName: "Abo",
                              width: 70,
                              pas: 5,
                            },
                          ]
                        },
                        {
                          image: {
                            width: 90,
                            height: 36,
                            name: "color",
                          },
                          items: [
                            {
                              value: listDesOffre[index][year]['budgetAbo'] + "",
                              fieldName: "totalEnergie",
                              width: 80,
                              pas: 5,
                            },
                          ]
                        },
                      ]
                      pricing['data'].push(et)
                    }
                  }
                }
              }
            }
            pdfDoc = await this.drawPricingTbaleC3(form, pdfDoc, page, pricing, fournisseur, fournisseurActuelle)

            for (var i = 0; i < listDesOffre.length; i++) {
              fournisseurList[i] = listDesOffre[i]['fournisseur'].toLowerCase() + '.pdf'
            }
            if (fournisseurActuelle == true) {
              fournisseurList.push(fournisseur["resultat"]["result"][0]['fournisseur'].toLowerCase() + '.pdf')
            }

          }
        }
        fournisseurList = this.filterArray(fournisseurList)
        for (var i = 0; i < fournisseurList.length; i++) {
          var name = fournisseurList[i]
          try {
            const url4 = '/newFournisseurs/' + name
            const fournisseurPdfBytes = await fetch(url4).then((res) =>
              res.arrayBuffer()
            )

            const fournisseurPdfDoc = await PDFDocument.load(fournisseurPdfBytes)

            pdfDoc = await this.addPagesToPdf(pdfDoc, fournisseurPdfDoc)
          } catch (error) {
            console.log("Presentation Fournisseur introuvable " + name)
          }
        }

        const presentationURLPartFinal = '/pdf/Presentation-11-13-modif.pdf'
        const presentationPartFinalBytes = await fetch(presentationURLPartFinal).then((res) =>
          res.arrayBuffer()
        )
        var presentationPagePartFinalDoc = await PDFDocument.load(presentationPartFinalBytes)

        var getPages = presentationPagePartFinalDoc.getPages()
        const timesRomanFont = await presentationPagePartFinalDoc.embedFont(StandardFonts.HelveticaBold)
        const contactPage = getPages[2]
        var username = localStorage.getItem('email')
        var nomPrenom = localStorage.getItem('vendeurName')
        var Telephone = localStorage.getItem('Telephone')
        const Helvetica = await presentationPagePartFinalDoc.embedFont(StandardFonts.Helvetica)

        contactPage.drawText(nomPrenom, {
          x: 305,
          y: 270,
          size: 30,
          font: timesRomanFont,
          color: rgb(1, 1, 1),
        })
        if (Telephone != null && Telephone != "null") {
          contactPage.drawText(Telephone, {
            x: 305,
            y: 230,
            size: 25,
            font: Helvetica,
            color: rgb(1, 1, 1),
          })

        }
        const createPageLinkAnnotation = (PDFPage, string) => {
          PDFPage.doc.context.register(
            page.doc.context.obj({
              Type: 'Annot',
              Subtype: 'Link',
              Rect: [0, 30, 40, 230],
              Border: [0, 0, 2],
              C: [0, 0, 1],
              A: {
                Type: 'Action',
                S: 'URI',
                URI: PDFString.of(`mailto:${string}`),
              },
            }),
          );
        }
        contactPage.drawText(username, {
          x: 305,
          y: 190,
          size: 20,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });

        const link = createPageLinkAnnotation(contactPage, username);
        contactPage.node.set(PDFName.of('Annots'), presentationPagePartFinalDoc.context.obj([link]));

        pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartFinalDoc)
        const pdfBytes = await pdfDoc.save()
        this.generatedPdf = pdfBytes
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        download(pdfBytes, 'Presentation_' + this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['siret'] + '_' + today + '.pdf', 'application/pdf')
      }
      this.loadingPres = false;
      this.dialogPres = false;
    }
  }

}
</script>
