<template>
    <div style="background-color: #eee;">
    <!-- pop up calculette -->
        <v-dialog
            v-if="openModalCalculette==true"
            v-model="openModalCalculette"
            persistent
            max-width="70%" 
            >
            <v-card style="overflow: hidden;">
                 <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">Creation Calculette Gazel</span>
                </v-toolbar>
                <v-card-text class="my-6">
                    <v-form ref="formCalculette"  lazy-validation>
                        <v-row>
                            <v-col cols="11" sm="11" md="11" lg="11" xl='11'>
                                <v-row>
                                    <v-col cols="4" sm="4" md="4" lg="4" xl='4'>
                                        <v-autocomplete 
                                            v-model="objectCalculette.leCompteurSelectionner" 
                                            :items="listCompteur" 
                                            :loading="isLoading"
                                            hide-no-data 
                                            hide-selected
                                            :rules="[rules.requiredString]"
                                            label="N° Compteur" 
                                            placeholder="Ajouter un compteur" 
                                            chips
                                            item-text="numCompteur"
                                            return-object
                                            deletable-chips
                                            >
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="11" sm="3" md="3" lg="3" xl='3' v-if="objectCalculette.leCompteurSelectionner!=null">
                                        <v-autocomplete 
                                            v-if="''+objectCalculette.leCompteurSelectionner.typeDemande=='ELEC'"
                                            v-model="objectCalculette.offre" 
                                            :items="lesOffreGazel" 
                                            :loading="isLoading"
                                            hide-no-data 
                                            hide-selected
                                            :rules="[rules.requiredString]"
                                            label="Type d'offre" 
                                            placeholder="Selectionné un offre" 
                                            chips
                                            deletable-chips
                                            >
                                        </v-autocomplete>
                                        <v-autocomplete 
                                            v-if="''+objectCalculette.leCompteurSelectionner.typeDemande=='GAZ'"
                                            v-model="objectCalculette.tarif" 
                                            :items="['T1', 'T2', 'T3', 'T4']" 
                                            :loading="isLoading"
                                            hide-no-data 
                                            hide-selected
                                            :rules="[rules.required]"
                                            label="Tarif" 
                                            placeholder="Selectionné une tarif"
                                            chips
                                            deletable-chips
                                            >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="11" sm="3" md="3" lg="3" xl='3' v-if="objectCalculette.leCompteurSelectionner!=null">
                                        <v-autocomplete 
                                            v-if="''+objectCalculette.leCompteurSelectionner.typeDemande=='GAZ'"
                                            v-model="objectCalculette.profil" 
                                            :items="['P11', 'P12', 'P13', 'P14', 'P15', 'P16', 'P17', 'P18', 'P19']" 
                                            :loading="isLoading"
                                            hide-no-data 
                                            hide-selected
                                            :rules="[rules.required]"
                                            label="Profile" 
                                            placeholder="Selectionné un profil" 
                                            chips
                                            deletable-chips
                                            >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- La date de début -->
                            <v-col cols="11" sm="4" md="3" lg="3" xl='3'>
                                <v-text-field 
                                    type="date" 
                                    outlined
                                    :rules="[required('une date de début du contrat valide'), 
                                            isValidDate('DD/MM/YYYY', minDebutContrat, maxDateDebutContrat),isRangeDateValide('Début')]"
                                    label="Début de Contrat"
                                    v-model="objectCalculette.dateDebut"
                                    :min="minDebutContrat"
                                    :max="maxDateDebutContrat"/>
                            </v-col>

                            <!-- La date de fin -->
                            <v-col cols="11" sm="4" md="3" lg="3" xl='3'>
                                <v-text-field 
                                    type="date" 
                                    outlined
                                    :rules="[required('une date de fin du contrat valide'), 
                                            isValidDate('DD/MM/YYYY', minDebutFinContrat, maxDateFinContrat), isRangeDateValide('Fin')]"
                                    label="Fin de Contrat"
                                    v-model="objectCalculette.dateFin"
                                    :min="minDebutFinContrat"
                                    :max="maxDateFinContrat"/>
                            </v-col>
                            <!-- capital -->
                            <v-col cols="11" sm="4" md="3" lg="3" xl='3'>
                                <v-text-field 
                                    outlined
                                    :rules="[rules.required]"
                                    label="Capitaux Propres"
                                    v-model="objectCalculette.capital"/>
                            </v-col>
                            <!-- resultatExploitation -->
                            <v-col cols="11" sm="4" md="3" lg="3" xl='3'>
                                <v-text-field 
                                    outlined
                                    :rules="[rules.required]"
                                    label="Résultat de exploitation"
                                    v-model="objectCalculette.resultatExploitation"/>
                            </v-col>
                            <!-- produitExploitation-->
                            <v-col cols="11" sm="4" md="3" lg="3" xl='3'>
                                <v-text-field 
                                    outlined
                                    :rules="[rules.required]"
                                    label="Produit de exploitation"
                                    v-model="objectCalculette.produitExploitation"/>
                            </v-col>
                        </v-row>
                        <div class="d-flex justify-content-end"> 
                            <v-btn
                                text
                                color="error"
                                class="mt-8 mr-4"
                                @click="openModalCalculette=false"
                                >
                                Fermer
                            </v-btn>
                            <v-btn
                                :color="$colors[0]"
                                class="mt-8 mr-4 text-white"
                                @click="creationCalculette"
                                >
                                Valider
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- pop up demande offre -->
        <!-- <v-dialog
            v-model="openModalAcdEngie"
            persistent
            max-width="70%" 
            >
            <v-card style="overflow: hidden;">
                 <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">Creation ACD Engie</span>
                </v-toolbar>
                <v-card-text class="my-6">
                    <v-form ref="formAcd"  lazy-validation>
                        <v-autocomplete 
                            v-model="selectedCompteur" 
                            :items="listCompteur" 
                            :loading="isLoading"
                            hide-no-data 
                            hide-selected
                            :rules="[rules.required]"
                            label="Autre compteur" 
                            placeholder="Ajouter un compteur" 
                            prepend-icon="mdi-account-search-outline"
                            chips
                            item-text="numCompteur"
                            deletable-chips
                            multiple
                            return-object
                            >
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                    <v-list-item-content>
                                        <v-list-item-title>Select All</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-autocomplete>
                        <div class="d-flex justify-content-end"> 
                            <v-btn
                                text
                                color="error"
                                class="mt-8 mr-4"
                                @click="openModalAcdEngie=false"
                                >
                                Fermer
                            </v-btn>
                            <v-btn
                                :color="$colors[0]"
                                class="mt-8 mr-4 text-white"
                                @click="creationAcdEngie"
                                >
                                Valider
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog> -->
        <!-- pop up demande offre -->
        <v-dialog
            v-model="ajouterVenteElecModal"
            persistent
            max-width="70%" 
            >
            <v-card style="overflow: hidden;">
                 <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">  Ajouter une vente {{venteTypeEnergie}}</span>
                </v-toolbar>
                <v-card-text class="my-6">
                    <ajouterVenteElecComposent :dataVente="dataPourVente" :societe="societe" :typeVente="venteTypeEnergie" :pourcentage="pourcentage" :listCompteur="listeCompteurVente" @closeDialog="ajouterVenteElecModalClose">
                    </ajouterVenteElecComposent>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- pop up demande offre -->
        <v-dialog
            v-model="dialogDemandeOffre"
            persistent
            max-width="90%" 
            >
            <v-card style="overflow: hidden;">
                <v-toolbar
                    class="black--text"
                    :color="$colors[0]"
                    dark>
                    <span class="text-h6 text-white">Demander une nouvelle offre {{typeDeOffre}}</span>
                </v-toolbar>
                <v-card-text class="mt-8">
                    <v-form ref="formNouveauOffre">
                        <v-row>
                            <v-col class="pb-0" cols='12' sm="10" md="6" lg="6" xl="6">
                                <v-text-field :rules="[rules.required]" type="date" v-model="nouveauOffre.dateDebut" label="Date Debut" outlined/>
                            </v-col>
                            <v-col  class="pb-0" cols='12' sm="10" md="6" lg="6" xl="6">
                                <v-select
                                    v-if="typeDeOffre=='GAZ'"
                                    required
                                    outlined
                                    v-model="profilGaz"
                                    :items="['P011', 'P012', 'P013', 'P014', 'P015', 'P016', 'P017', 'P018', 'P019']"
                                    label="Profil du compteur"
                                    :rules="[v => !!v || 'Champ obligatoire!']"
                                ></v-select>
                            </v-col>
                            <v-col cols='12' sm="10" md="6" lg="6" xl="6">
                                <v-select
                                    :items="lesCompteurPossible"
                                    :rules="[v => !!v || 'Champ obligatoire!']"
                                    v-model="nouveauOffre.numCompteur"
                                    label="Compteur"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="px-0 mx-4" cols='6' sm="2" md="2" lg="2" xl="2" v-for="(item,index) in listFournisseur" :key="index">
                                <v-row justify="start">
                                    <v-checkbox
                                        v-model="nouveauOffre.lesFournisseurPossible"
                                        :rules="[v => v?.length>0 || 'Champ obligatoire!']"
                                        :label="item"
                                        color="secondary"
                                        :value="item"
                                        hide-details
                                    ></v-checkbox>
                                </v-row>
                            </v-col>
                        </v-row>
                       <div class="d-flex justify-content-end"> 
                            <v-btn
                            text
                            color="error"
                            class="mt-8 mr-4"
                            @click="fermerChoisirOffre"
                            >
                            Fermer
                            </v-btn>
                        <v-btn
                            :color="$colors[0]"
                            class="mt-8 mr-4 text-white"
                            @click="ajouterNouveauOffre"
                            >
                            Valider
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- pop up delete courbe de charge-->
        <v-dialog persistent v-model="ouvrireDialogeSupprimerCourbeDeCharge" max-width="620px">
            <v-card style="overflow: hidden;">
            <v-toolbar :color="$colors[0]" dark>
                <v-card-title class="d-flex justify-content-center align-items-center">
                Suppression de la courbe de charge
                </v-card-title>
            </v-toolbar>
            <v-card-text style="display:block" class="text-h6 text-center font-weight-light my-8">
                Êtes-vous sûr de bien vouloir supprimer cette courbe de charge ?
            </v-card-text>
            <div class="d-flex justify-content-end mb-2">
                <v-btn text class="mx-2" color="error" @click="fermerDialogeSupprimerCourbeDeCharge">
                Annuler
                </v-btn>
                <v-btn class="text-white mr-2" :color="$colors[0]" @click="confirmerSupprimerCourbeDeCharge">
                Supprimer
                </v-btn>
            </div>
            </v-card>
        </v-dialog>
         <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
                 <loadingComponent @closeDialog="dialog = $event" :messageDialogeProps="messageDialogeError" :dialogProps="dialog" :loadingProps="loading" :errorProps="error" />
            </v-col>
        </v-row>
        <section style="background-color: #eee;" v-if="societe!= null"> 
            <div class="container-fluid py-5" style="height: 100%;">
                <div class="row">
                    <div class="col-lg-4 pb-0 px-2">
                        <div class="card mb-0">
                        <div class="card-body text-center">
                            <h5 class="my-3">{{societe.Raison}}</h5>
                            <img src="../../assets/companies-icon.png" alt="avatar"
                            class="rounded-circle img-fluid" style="width: 150px;">
                            <p class="text-muted mb-1">{{societe.siret}}</p>
                            <small class="text-muted mb-4">{{societe.AdressePostal}}</small>
                            <div class="d-flex justify-content-center mb-2 mt-2">
                                <v-btn :color="$colors[0]" v-if="urlStatus!=''">
                                    <a style="text-decoration:none" :href="urlStatus" target="_blank"><span class="white--text font-weight-bold">EXTRAIT PAPPERS<v-icon class="ml-1" dense>mdi-download</v-icon></span></a>
                                </v-btn>
                            </div>
                        </div>
                        </div>
                        <div class="card mb-4 mb-lg-0 mt-2">
                           <div class="card-body p-0">
                                <ul class="list-group list-group-flush rounded-3">
                                    <li @click="afficherInfoPersonnelle" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[2]'>mdi-account-outline</v-icon>
                                        <p class="mb-0 ml-5">Information du client</p>
                                    </li>
                                    <li @click="DemandeDeCotation" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[2]'>mdi-clipboard-text-clock-outline</v-icon>
                                        <p class="mb-0 ml-5">Historique de cotation</p>
                                    </li>
                                    <li @click="getAllVenteProBySociete" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[2]'>mdi-clipboard-list-outline</v-icon>
                                        <p class="mb-0 ml-5">Liste des ventes</p>
                                    </li>
                                    <li @click="GenerationPDF" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[2]'>mdi-download-outline</v-icon>
                                        <p class="mb-0 ml-5">Génération Document(s)</p>
                                    </li>
                                    <li @click="getAllHistoriqueCourbesDesCharges" class="btn list-group-item d-flex align-items-center p-3">
                                        <v-icon :color='$colors[2]'>mdi-clipboard-list-outline</v-icon>
                                        <p class="mb-0 ml-5">Historique Courbes des charges</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card mb-4 mb-lg-0 mt-2">
                            <div class="card-body p-0">
                                <ul class="list-group list-group-flush rounded-3">
                                    <li class="list-group-item d-flex align-items-center p-3">
                                        <div>
                                            <v-card-title class="justify-start">
                                                <v-avatar class="mr-2" size="36" rounded>
                                                <img
                                                    alt="credit safe score"
                                                    src="../../assets/images/logos/logo-credit-safe.png"
                                                />
                                                </v-avatar>
                                                creditsafe
                                            </v-card-title>

                                            <v-card-text class=" text-left text-body-1  pr-6"
                                                >Score:
                                                <span class="ml-2 font-weight-black">{{
                                                societe.scoreCreditSafe
                                                }}</span>
                                                / 100
                                            </v-card-text>
                                        </div>
                                    </li>
                                    <li class="list-group-item d-flex align-items-center p-3">
                                        <div>
                                            <v-card-title class="justify-start">
                                                <v-avatar class="mr-2" size="36" rounded>
                                                <img
                                                    alt="credit safe score"
                                                    src="../../assets/logoElliPro.png"
                                                />
                                                </v-avatar>
                                                Elli Pro
                                            </v-card-title>

                                            <v-card-text class=" text-left text-body-1 pr-6"
                                                >Score:
                                                <span class="ml-2 font-weight-black">
                                                {{societe.scoreEllipro}}
                                                </span> / 10
                                            </v-card-text>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="typeUser =='admin' || typeUser =='superadmin'" class="card p-4 mb-4 mb-lg-0 mt-2">
                            <v-form ref="formScores">
                                <label>Score Credit Safe:</label>
                                <v-text-field 
                                v-model="newScore.scoreCreditSafe" 
                                label="Score Credit Safe" 
                                outlined dense 
                                :rules="[required('une Score Credit Safe'),rules.isNumber]"
                                ></v-text-field>
                                <label>Score Elli Pro:</label>
                                <v-text-field 
                                v-model="newScore.scoreEllipro" 
                                label="Score Elli Pro" 
                                outlined dense 
                                :rules="[required('une Score Credit Safe'),rules.isNumber]"
                                ></v-text-field>
                            </v-form>
                            <div>
                                <v-btn @click="saveScore" style="background-color:rgb(16, 58, 94); color: #fff;">
                                    <v-icon>mdi-check-all</v-icon> Enregistrer 
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 pl-lg-0 px-4" v-if="vueAfficher=='client'">
                        <div class="card mb-2">
                            <div class="card-body">
                                <p class="mb-8"><b><span style="color:#1E6CA4" class="font-italic me-1">Informations</span></b> personnelles de client</p>
                                    <div class="d-flex justify-content-end mb-4">    
                                        <div v-if='editMode==false && typeUser !== "comptable" '>
                                            <v-btn :color="$colors[0]" class="white--text font-weight-bold" @click="activeEdit"><v-icon>mdi-pencil-outline</v-icon></v-btn>
                                        </div>
                                        <div v-if='editMode==true'>
                                            <v-btn :color="$colors[0]" class="white--text font-weight-bold mr-4" @click="cancelEdit"><v-icon>mdi-pencil-off-outline</v-icon></v-btn>
                                            <v-btn :color="$colors[0]" class="white--text font-weight-bold"  @click="saveChange"><v-icon>mdi-check-all</v-icon></v-btn>
                                        </div>
                                    </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Nom</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'> {{societe.nom}} </p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.nom"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Prénom</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'>{{societe.prenom}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.prenom"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Email</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'> {{societe.AdresseEmail}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.AdresseEmail"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Numéro de téléphone</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'>{{formatPhoneNumber(societe.numTele)}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.numTele"
                                            />
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="mb-0">Fonction</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p class="text-muted mb-0" v-if='editMode==false'>{{societe.fonction}}</p>
                                        <input type="text " v-if='editMode==true'
                                                class="form-control form-control-sm"
                                                v-model="societe.fonction"
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6 pr-lg-0 px-4">
                            <div style="height:100%" class="card mb-lg-4 mb-md-0">
                                <div class="card-body">
                                    <p class="mb-4"><b><span style="color:#1E6CA4" class="font-italic me-1">Informations</span></b> Societe</p>
                                    
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Raison</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.Raison}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Siren</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.siren}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Siret</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.siret}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Adresse Complete</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.AdressePostal}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Code Postale</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.codePostal}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Commune</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.commune}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Voie</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.Voie}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pl-lg-2 pl-4 pl-sm-0">
                            <div class="card mb-4 mb-md-0">
                                <div class="card-body">
                                    <p class="mb-4"><span style="color:#1E6CA4" class="font-italic me-1"><b>Informations</b></span> Societe</p>
                                    
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Capitaux propres</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{isNaN(societe.capi)  ? societe.capi : Number(societe.capi).toLocaleString()+" €" }}</em></small>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Code Naf</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.codeNaf}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Code insee</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.codeInsee}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Capital social</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.capitalSocial}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Status</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.statusSociete}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">Type</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.typeEtablissement}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">n° RCS</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.RCS}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <small class="mb-0">n° TVA</small>
                                        </div>
                                        <div class="col-sm-8">
                                            <small class="text-muted mb-0"><em> {{societe.TVA}}</em></small>
                                        </div>
                                    </div>
                                    <hr>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-8 pl-lg-0 pl-2 pr-2" v-if="vueAfficher=='historiqueDeCotation'">
                        <div class="card mb-3">
                            <div class="card-body pb-0">
                                <p class="mb-8"><b><span style="color:#1E6CA4" class="font-italic me-1">Les Demandes de cotations </span></b>associées à l'entreprise</p>
                                <!-- <div class="d-flex justify-content-left mb-2 mt-2" v-if="typeUser=='superadmin' || typeUser=='admin' || typeUser=='ADV'">
                                    <v-row justify="space-between">
                                        <v-col cols="7">
                                        <div> 
                                            <v-btn :color="$colors[0]" @click="ACDEngie">
                                                <span class="white--text font-weight-bold">ACD ENGIE<v-icon class="ml-1" dense>mdi-download</v-icon></span>
                                            </v-btn>
                                        </v-btn>
                                        <v-btn class="ml-4" :color="$colors[0]" @click="openResiliation">
                                            <span class="white--text font-weight-bold">Lettre de résiliation<v-icon class="ml-1" dense>mdi-download</v-icon></span>
                                        </v-btn>
                                        <lettreDeRisiliation v-if="openResiliationModal" :societe="societe" :listCompteur="listCompteur" @closeResiliationModal="openResiliationModal=false"/>
                                        </div>
                                        </v-col>
                                        <v-col class="text-end" cols="11" md="5" sm="5" xl="5">
                                            <v-btn :color="$colors[0]" @click="openModalGazel">
                                                <span class="white--text font-weight-bold">Calculette Gazel<v-icon class="ml-1" dense>mdi-download</v-icon></span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div> -->
                                 <b-table :busy="loadingTableCotation" responsive show-empty bordered :fields="fieldDemandeDeCotation"  hover :items="demandeDeCotationItems"
                                    :per-page="perPageDC"
                                    :current-page="currentPageDC"
                                    id="demandeDeCotation">
                                    <template #empty="scope">
                                        <noDataTable/>
                                    </template>
                                    <template #table-busy>
                                                    <div style="color:#1E6CA4" class="text-center my-2">
                                                        <b-spinner class="align-middle"></b-spinner>
                                                        <strong class="ml-2">Chargement...</strong>
                                                    </div>
                                    </template>
                                    <template #cell(date)="data">
                                        {{ data.item.date}}
                                    </template>
                                    <template #cell(typeDemande)="data">
                                        {{ data.item.typeDemande}}
                                    </template>                               
                                    <template #cell(numCompteur)="data">
                                        <div style="max-width:200px">
                                                    
                                                        <small>{{spliteList(data.item.numCompteur)[0]}}</small>
                                                        <v-icon
                                                            v-if="spliteListSlice(data.item.numCompteur)?.length>0"
                                                            large
                                                            data-toggle="collapse" :data-target="'#multiCollapseCompteur'" aria-expanded="false" :aria-controls="'multiCollapseCompteur'"
                                                            right
                                                            >
                                                            mdi-menu-down
                                                        </v-icon>
                                                        <div class="collapse multi-collapse" :id="'multiCollapseCompteur'">
                                                            <div >
                                                                <small v-for="c in spliteListSlice(data.item.numCompteur)">
                                                                    {{c}}
                                                                </small>    
                                                            </div>
                                                        </div>
                                                    </div>
                                    </template>
                                    <template #cell(Echeance)="data">
                                        {{ data.item.Echeance}}
                                    </template>
                                    <template #cell(finContrat)="data">
                                        {{ data.item.finContrat}}
                                    </template>
                                    <template id="status" #cell(statut)="data">
                                        <small v-if="data.item.valide == '2'"><v-icon  style="color: red;">mdi-close-circle</v-icon></small>
                                        <small v-if="data.item.valide == '1'"><v-icon  style="color:#00E676;">mdi-check-circle</v-icon></small> 
                                        <small v-if="data.item.valide == '0'"><v-icon  style="color: gray;">mdi-timer-refresh-outline</v-icon></small>

                                    </template>     
                                    <template #cell(lienFournisseur)="data">
                                        <v-btn icon :color="$colors[0]" @click="choisirOffre(data.item.id, data.item.typeDemande)">
                                                       <v-icon>mdi-tag</v-icon>
                                                    </v-btn>
                                    </template>                                                                                                                                                           
                                </b-table>
                                        
                                <b-pagination
                                    v-if="demandeDeCotationItems?.length > 0"
                                    v-model="currentPageDC"
                                    :total-rows="demandeDeCotationItems?.length"
                                    :per-page="perPageDC"
                                    aria-controls="demandeDeCotation"
                                    >
                                </b-pagination>
                            </div>
                            <div class="card-body" v-if="lesOffresDemander?.length>0">
                                <p class="mb-8"><b><span class="text-primary font-italic me-1">Les Offres </span>Externe demander</b></p>
                                <table v-if="vueAfficher=='historiqueDeCotation'" id="nouveauOffre" class="table table-striped table-bordered" width="100%">
                                    <thead>
                                        <tr>
                                            <th style="display: none;">Id</th>
                                            <th  style="text-align: center; width:10%;">Date</th>
                                            <th  style="text-align: center; width:15%;">N° Compteur</th>
                                            <th  style="text-align: center; width:7%;">Type Compteur</th>
                                            <th  style="text-align: center; width:7%;">Energie</th>
                                            <th  style="text-align: center; width:20%;">Lien</th>
                                            <th  style="text-align: center; width:7%;">Etat</th>
                                            <th  style="text-align: center; width:15%;">Fournisseurs</th>
                                            <th  style="text-align: center; width:10%;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in lesOffresDemander" :key="item.id">
                                            <td style="display: none;">
                                                <small>{{item.id}}</small>
                                            </td>
                                            <td>
                                                <small>{{item.date}}</small>
                                            </td>
                                            <td>
                                                <small>
                                                    {{item.numCompteur}}
                                                </small>
                                            </td>
                                            <td>
                                                <small>{{item.compteurProfile}}</small>
                                            </td>
                                            <td>
                                                <small>{{item.typeDemande}}</small>
                                            </td>
                                            <td>
                                                <small class="td-table">
                                                        <router-link :to="{path: '/ajouterNouvelleOffre', query: { id: item.id }}">Cliquez ici</router-link> 
                                                </small>
                                            </td>
                                            <td>
                                                <p v-if="item.etat == 'activer'">
                                                    Actif
                                                </p>
                                                <p v-if="item.etat == 'Désactiver'">
                                                    Désactiver
                                                </p>
                                            </td>
                                            <td>
                                                <small>{{item.fournisseurPossible}}</small>
                                            </td>
                                            <td>
                                                <div class="btn-group">
                                                    <v-icon
                                                        dense
                                                        class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                                                        right
                                                        >
                                                        mdi-dots-vertical
                                                    </v-icon>
                                                    <div class="dropdown-menu text-center" style="width:1px;">
                                                        <v-btn
                                                            v-if="item.etat=='Désactiver'"
                                                            @click="activerLink(item.id)"
                                                            class="dropdown-project mt-4"
                                                            color='grey lighten-1'
                                                            small
                                                            dark>
                                                            <v-icon
                                                            color='grey darken-4'>
                                                                mdi-check-outline
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            v-if="item.etat=='activer'"
                                                            @click="desactiverLink(item.id)"
                                                            class="dropdown-project mt-4"
                                                            color='grey lighten-1'
                                                            small
                                                            dark>
                                                            <v-icon
                                                                color='grey darken-4'>
                                                                mdi-cancel
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            @click="deleteLink(item.id)"
                                                            class="dropdown-project mt-4"
                                                            color='grey lighten-1'
                                                            small
                                                            dark>
                                                            <v-icon
                                                                color='grey darken-4'>
                                                                mdi-trash-can-outline
                                                            </v-icon>
                                                        </v-btn>
                                                        
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- historique gaz -->
                        
                        <div class="row">
                            <div class="col-md-12 pt-2 pb-2">
                                <div class="card mb-md-0">
                                    <div class="card-body pb-6">
                                        <p class="mb-4"><span style="color:#1E6CA4" class="font-italic me-1"><b>Historique de cotation GAZ</b></span> par compteur</p>
                                        <div v-if="typeUser !== 'comptable'" class="col-md-12 text-right pr-0">
                                            <router-link :to="{path: '/demande-cotation-gaz-pro', query: { siret: this.societe.siret }}">
                                                <!--<v-btn :color="$colors[0]">Nouvelle cotation </v-btn>-->
                                                <v-btn class="white--text font-weight-bold" :color="$colors[0]">Nouvelle cotation GAZ</v-btn>
                                            </router-link> 
                                        </div>
                                        <b-table :busy="loadingTableHistoriqueGaz" show-empty :fields="fieldhistoriqueDeCotationGaz" :items="historiqueCotationGazItems" responsive bordered
                                            :per-page="perPageHisoriqueGaz"
                                            :current-page="currentPageHistoriqueGaz"
                                            id="historiqueCotationGaz">
                                            <template #empty="scope">
                                                    <noDataTable/>
                                                </template>
                                                <template #table-busy>
                                                    <div style="color:#1E6CA4" class="text-center  my-2">
                                                        <b-spinner color="yellow darken-2" class="align-middle"></b-spinner>
                                                        <strong class="ml-2">Chargement...</strong>
                                                    </div>
                                                </template>
                                            <template #cell(Action)='data'>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <v-btn :loading="loadingButtonHistoriqueCotaGAZ" :color="$colors[2]" icon plain @click="ajouterVenteGazModalOpen(data.item)">
                                                        <v-icon>mdi-plus-box</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <template #cell(date)='data'>
                                                <small>{{data.item.date}}</small>
                                            </template>

                                            <template #cell(numCompteur)='data'>
                                                <router-link :to="{path: '/new-historiqueCotationGaz', query: { id: data.item.id }}" target='_blank' v-if="data.item.status == 'new'" ><small>Historique de Cotation {{ data.item.id}}</small></router-link> 
                                                
                                                <router-link :to="{path: '/historiqueCotationGaz', query: { id: data.item.id }}" target='_blank'  v-else ><small>{{data.item.numCompteur}}</small></router-link> 
                                            </template>
                                            <template #cell(dateDebut)='data'>
                                                <small>{{data.item.dateDebut}}</small>
                                            </template>

                                            <template #cell(dateFin)="data">
                                                <small>{{data.item.dateFin}}</small>
                                            </template>

                                            <template #cell(marge)='data'>
                                                <small>{{data.item.marge}}</small>
                                            </template>

                                        </b-table>
                                        <b-pagination
                                            v-model="currentPageHistoriqueGaz"
                                            :total-rows="historiqueCotationGazItems?.length"
                                            :per-page="perPageHisoriqueGaz"
                                            aria-controls="historiqueCotationGaz"
                                            >
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--historique elec-->
                        <div class="row">
                            <div class="pt-0 col-md-12">
                                <div class="card mb-md-0">
                                    <div class="card-body">
                                        <p class="mb-4"><span style="color:#1E6CA4" class="font-italic me-1"><b>Historique de cotation ELEC</b></span> par compteur</p>
                                        <div v-if="typeUser !== 'comptable'" class="col-md-12 text-right pr-0">
                                            <router-link :to="{path: '/demande-cotation-elec-pro', query: { siret: this.societe.siret }}">
                                                <!--<v-btn :color="$colors[0]">Nouvelle cotation </v-btn>-->
                                                <v-btn class="white--text font-weight-bold" :color="$colors[0]">Nouvelle cotation ELEC</v-btn>
                                            </router-link> 
                                        </div>
                                        <b-table :busy="loadingTableHistorique" show-empty :fields="fieldhistoriqueDeCotation" :items="historiqueCotationItems" responsive bordered
                                            :per-page="perPageHistorique"
                                            :current-page="currentPageHistorique"
                                            id="historiqueCotation">
                                                <template #empty="scope">
                                                    <noDataTable/>
                                                </template>
                                                <template #table-busy>
                                                    <div style="color:#1E6CA4" class="text-center my-2">
                                                        <b-spinner class="align-middle"></b-spinner>
                                                        <strong>Chargement...</strong>
                                                    </div>
                                                </template>
                                            <template #cell(Action)='data'>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <v-btn :color="$colors[2]" icon plain @click="ajouterVenteElecModalOpen(data.item)">
                                                        <v-icon>mdi-plus-box</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <template #cell(date)='data'>
                                                <small>{{data.item.date}}</small>
                                            </template>

                                            <template #cell(numCompteur)='data'>

                                                <router-link :to="{path: '/new-historiqueCotation', query: { id: data.item.id }}" target='_blank'  v-if="data.item.status == 'new'" ><small>Historique de Cotation {{ data.item.id}}</small></router-link> 
                                                <router-link :to="{path: '/historiqueCotation', query: { id: data.item.id }}" target='_blank' v-else ><small>{{data.item.numCompteur}}</small></router-link> 
                                            </template>

                                            <template #cell(optionTarifaire)='data'>
                                                <small>{{data.item.optionTarifaire}}</small>
                                            </template>

                                            <template #cell(dateDebut)='data'>
                                                <small>{{data.item.dateDebut}}</small>
                                            </template>

                                            <template #cell(dateFin)="data">
                                                <small>{{data.item.dateFin}}</small>
                                            </template>

                                            <template #cell(marge)='data'>
                                                <small>{{data.item.marge}}</small>
                                            </template>

                                        </b-table>
                                        <b-pagination
                                            v-model="currentPageHistorique"
                                            :total-rows="historiqueCotationItems?.length"
                                            :per-page="perPageHistorique"
                                            aria-controls="historiqueCotation"
                                            >
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-8 pl-lg-0 px-2" v-if="vueAfficher=='listeDesVentePro'">
                        <div class="card mb-4">
                            <div class="card-body">
                                <p class="mb-4"><b><span style="color:#1E6CA4" class="font-italic me-1">Les ventes professionnelles </span></b></p>
                                <!--<div class="col-md-12 bg-light text-right">
                                        <button @click="ajouterUneVentePro" type="button" class="btn btn-primary">Nouvelle Vente</button>
                                </div>-->
                               <b-table :busy="loadingTableVentePro" show-empty :fields="fieldVentePro" :items="venteProSocieteItems" responsive bordered
                                    :per-page="perPageVentePro"
                                    :current-page="currentPageVentePro"
                                    id="venteProSociete">
                                            <template #empty="scope">
                                                <noDataTable/>
                                            </template>
                                            <template #table-busy>
                                                 <div style="color:#1E6CA4" class="text-center my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong class="ml-2">Chargement...</strong>
                                                </div>
                                            </template>
                                        <template #cell(date)='data'>
                                        <small>{{data.item.date}}</small>
                                        </template>

                                        <template #cell(Vendeur)='data'>
                                           <small>{{data.item.Vendeur}}</small>
                                        </template>

                                        <template #cell(numCompteur)='data'>
                                             <small>{{data.item.numCompteur}}</small>
                                        </template>

                                        <template #cell(type)='data'>
                                            <small>{{data.item.type}}</small>
                                        </template>

                                        <template #cell(valeur)="data">
                                             <small>{{data.item.valeur}}</small>
                                        </template>

                                        <template #cell(status)='data'>
                                            <div class="btn-group">
                                                <small v-if="data.item.status == 'Refuser'"><v-icon dense style="color:red">mdi-close-circle</v-icon><b class="ml-2">refusée</b></small>
                                                    <small v-if="data.item.status == 'Accepter'"><v-icon dense style="color:#00E676">mdi-check-circle</v-icon><b class="ml-2">acceptée</b></small>
                                                    <small v-if="data.item.status == 'en Attend'"><v-icon>mdi-timer-refresh-outline</v-icon dense style="color:gray"><b class="ml-2">en attente</b></small>
                                                    <small v-if="data.item.status == 'en attente de validation'"><v-icon dense style="color:#0D47A1">mdi-send-check</v-icon><b>Phase de validation</b></small>
                                            </div>
                                        </template>

                                        </b-table>
                                        <b-pagination
                                            v-if="venteProSocieteItems?.length > 0"
                                            v-model="currentPageVentePro"
                                            :total-rows="venteProSocieteItems?.length"
                                            :per-page="perPageVentePro"
                                            aria-controls="venteProSociete"
                                            >
                                        </b-pagination>
                            </div>
                        </div>
                       
                    </div>
                    <div class="col-lg-8 pl-lg-0 pl-2 pr-2" v-if="vueAfficher=='GenerationPDF'">
                        <div class="card mb-3">
                            <div class="card-body pb-9">
                                <p class="mb-8"><b><span style="color:#1E6CA4" class="font-italic me-1">Les Documents </span></b>disponibles</p>
                                 <div class="d-flex justify-content-left mb-2 mt-2">
                                     <v-select
                                        required
                                        outlined
                                        v-model="listCompteurSelected"
                                        :items="customItems"
                                        @change="changeClick"
                                        label="Document"
                                        item-value="value"
                                        item-text="text"
                                        return-object
                                        hide-details

                                        >
                                       
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <lettreDeRisiliation v-if="openResiliationModal" :societe="societe" :listCompteur="listCompteur" @closeResiliationModal="openResiliationModal=false"/>
                            <CreationAcdEngie  v-if="openAcdEngie" :societe="societe" :listCompteur="listCompteur" @closeAcdEngieModal="openAcdEngie=false"/>
                            <acdPowerSmart  v-if="openacdPowerSmartModal" :societe="societe" :listCompteur="listCompteur" :typeFournisseur="listCompteurSelected" @closeacdPowerSmartModal="openacdPowerSmartModal=false"/>
                            <GenererCourbleChage  v-if="opengenerationCourbeDeCharge" :societe="societe" :listCompteur="listCompteur.filter(compteur => compteur.typeDemande === 'ELEC')" />
                        </div>
                    </div>
                    <div class="col-lg-8 pl-lg-0 px-2" v-if="vueAfficher=='courbesDesCharge'">
                        <div class="card mb-4">
                            <div class="card-body">
                                <p class="mb-4"><b><span style="color:#1E6CA4" class="font-italic me-1">Les courbes des charges </span></b></p>
                                <!--<div class="col-md-12 bg-light text-right">
                                        <button @click="ajouterUneVentePro" type="button" class="btn btn-primary">Nouvelle Vente</button>
                                </div>-->
                               <b-table :busy="loadingTableVentePro" show-empty :fields="fieldCourbesDesCharges" :items="courbesDesChargesItems" responsive bordered
                                    :per-page="perPageCourbesDesCharges"
                                    :current-page="currentPageCourbesDesCharges"
                                    id="venteProSociete">
                                            <template #empty="scope">
                                                <noDataTable/>
                                            </template>
                                            <template #table-busy>
                                                 <div style="color:#1E6CA4" class="text-center my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong class="ml-2">Chargement...</strong>
                                                </div>
                                            </template>
                                            <template #cell(date)='data'>
                                        <small>{{data.item.date}}</small>
                                        </template>
                                        <template #cell(finCdc)='data'>
                                            <small  v-if="data.item.status == '1' || data.item.status=='11'" >{{data.item.debutCDC}} - {{data.item.finCdc}}</small>
                                            <small v-else > - </small>
                                        </template>
                                        <template #cell(numCompteur)='data'>
                                            <small>{{data.item.numCompteur}}</small>
                                        <!--<router-link  v-if="data.item.status == '1' || data.item.status=='11'" :to="{path: '/compteur-data', query: { numCompteur: data.item.numCompteur,societeId:societe.id }}"><small>{{data.item.numCompteur}}</small></router-link> -->
                                        <!--<small v-else > - </small>-->

                                        </template>
                                        <template #cell(status)="data">
                                            <span :style="getStatusStyle(data.item.status)">
                                                {{ statusFactory[data.item.status]?.text || 'Inconnu' }}
                                            </span>
                                        </template>
                                        <template #cell(fichierCDCUrl)="data">
                                            <a v-if="data.item.status == '1' || data.item.status=='11'" :href="data.item.fichierCDCUrl" target="_blank" rel="noopener noreferrer">télécharger</a>
                                            <small v-else> - </small>
                                            
                                        </template>
                                        <template #cell(fichierCDCHoursUrl)="data">
                                            <a v-if="data.item.status == '1' || data.item.status=='11'" :href="data.item.fichierCDCHoursUrl" target="_blank" rel="noopener noreferrer">télécharger</a>
                                            <small v-else> - </small>
                                            
                                        </template>
                                        <template #cell(Supprimer)='data'>
                                                <div class="d-flex flex-row justify-content-center">
                                                    <v-btn icon plain color="red" @click="deleteCourbeDeChargeItem(data.item.numCompteur)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </b-table>
                                        <b-pagination
                                            v-if="courbesDesChargesItems?.length > 0"
                                            v-model="currentPageCourbesDesCharges"
                                            :total-rows="courbesDesChargesItems?.length"
                                            :per-page="perPageCourbesDesCharges"
                                            aria-controls="CourbesDesCharges"
                                            >
                                        </b-pagination>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
            </div>
        </section>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import axios from 'axios'
    import lettreDeRisiliation from "./componentDocs/resiliation/resiliation.vue";
    import acdPowerSmart from "./componentDocs/acdPowerSmart/acdPowerSmart.vue";
    import CreationAcdEngie from "./componentDocs/acdEngie/CreationAcdEngie.vue";
    import loadingComponent from "../../components/ui/loading-component.vue"
    import ajouterVenteElecComposent from "./ajouterVenteElecComposent.vue"
    import noDataTable from "../../components/no-data-table.vue"
    import 'jspdf-autotable'

    import moment from 'moment'
    import GenererCourbleChage from "./componentDocs/genererCourbeDeCharge/genererCourbleChage.vue";
    export default{
        components: {
            loadingComponent,
            ajouterVenteElecComposent,
            noDataTable,
            lettreDeRisiliation,
            CreationAcdEngie,
            acdPowerSmart,
            GenererCourbleChage
          
        },
        name: 'profileSociete',
        data() {
            return {
                statusFactory : {
                        "1": { text: "Acceptée", style: { backgroundColor: "#d1fae5", color: "#065f46" } },
                        "0": { text: "En attente", style: { backgroundColor: "#fef3c7", color: "#92400e" } },
                        "5": { text: "Résilié", style: { backgroundColor: "#fee2e2", color: "#991b1b" } },
                        "10": { text: "Erreur Technique", style: { backgroundColor: "#ffedd5", color: "#9a3412" } },
                        "3": { text: "Erreur Technique", style: { backgroundColor: "#ffedd5", color: "#9a3412" } },
                        "8": { text: "Le compteur n'est actuellement pas téléopérable", style: { backgroundColor: "#dbeafe", color: "#1e40af" } },
                        "9": { text: "Aucune mesure trouvée sur ce point", style: { backgroundColor: "#f3e8ff", color: "#6b21a8" } },
                        "11": { text: "Incomplet", style: { backgroundColor: "#f3f4f6", color: "#1f2937" } }
                        },
                ouvrireDialogeSupprimerCourbeDeCharge: false,
                selectedNumCompteur: null,
                listCompteurSelected: "",
                newScore: {
                    scoreCreditSafe: 0,
                    scoreEllipro: 0,
                },
                baseCustomItems: [
        {
          text: ' Creation ACD Engie',
          value: 'acdEngie',
          color: 'primary',
          icon: 'mdi-download',
          action: this.ACDEngie
        },
        {
          text: 'Lettre de résiliation',
          value: 'resiliation',
          color: 'primary',
          icon: 'mdi-download',
          action: this.openResiliation
        },
        {
          text: 'ACD Power Conseils',
          value: 'acdPowerConseil',
          color: 'primary',
          icon: 'mdi-download',
          action: this.openacdPowerSmart
        },
        {
          text: 'ACD Smart Electricité',
          value: 'acdSmartElec',
          color: 'primary',
          icon: 'mdi-download',
          action: this.openacdPowerSmart
        },
        {
          text: 'ACD GEMC',
          value: 'acdGEMC',
          color: 'primary',
          icon: 'mdi-download',
          action: this.openacdPowerSmart
        }
      ],

                              openResiliationModal: false,
                openAcdEngie:false,
                opengenerationCourbeDeCharge: false,
                messageDialogeError: '',

                error: false,
                //calculette
                openModalCalculette: false,
                lesOffreGazel: ["Offre Marché", "Offre ARENH", "Offre ARENH - CAPA reduite", "Offre ARENH - sans plafond"],
                objectCalculette:{
                    leCompteurSelectionner: null,
                    offre: "",
                    tarif: "",
                    profil: "",
                    siret: "",
                    dateDebut: "",
                    dateFin: "",
                    capital: "",
                    resultatExploitation: "",
                    produitExploitation: "",
                },
                
                minDebutContrat: new Date().toISOString().substr(0, 10),
                minDebutFinContrat:new Date().toISOString().substr(0, 10),
                maxDateFinContrat: new Date(new Date().getFullYear() + 5+'-'+12+'-'+31).toISOString().substr(0, 10),
                maxDateDebutContrat: new Date(new Date().getFullYear() + 5+'-'+ 11+'-30').toISOString().substr(0, 10),
                
                //open modal
                typeUser: '',
                openModalAcdEngie: false,
                listeDesCompteurPourEngie: [],
                isLoading: false,
                listCompteur: [],
                selectedCompteur: [],
                //
                loadingButtonHistoriqueCotaGAZ : false,
                pourcentage: 0,
                listeCompteurVente: [],
                dataPourVente: null,
                ajouterVenteElecModal: false,
                currentPageHistorique:1,
                perPageHistorique:4,
                currentPageHistoriqueGaz:1,
                perPageHisoriqueGaz: 4,
                currentPageVentePro:1,
                currentPageCourbesDesCharges:1,
                perPageVentePro: 10,
                perPageCourbesDesCharges: 10,
                currentPageDC:1,
                perPageDC: 4,
                urlStatus: "",
                historiqueCotationItems:[],
                historiqueCotationGazItems: [],
                demandeDeCotationItems:[],
                venteProSocieteItems:[],                
                courbesDesChargesItems:[],                

                loadingTableHistorique : false,
                loadingTableHistoriqueGaz : false,
                loadingTableCotation:false,
                loadingTableVentePro:false,
                loadingTableCourbesDesCharges:false,
                fieldhistoriqueDeCotationGaz:[
                    {
                        key:'Action',
                        label:"Action",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {   
                        key:'date', 
                        label:"Date d'ajout",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {   
                        key:"numCompteur",
                        label:"Compteur",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {   
                        key:"dateDebut", 
                        label:"Début de contrat",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"dateFin", 
                        label:"Fin de contrat",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"marge",
                        label:"Marge",
                        thClass: "text-center",
                        tdClass: "text-center",
                    }
                ],
                fieldDemandeDeCotation:[
                    {
                        key: 'date',
                        label:"Date d'ajout",
                    },
                    {
                        key: 'typeDemande', 
                        label:"Énergie"
                    },
                    {
                        key:"numCompteur",
                        label:"Compteur"
                    },
                    {
                        key:"Echeance", 
                        label:"Début de contrat"
                    },
                    {
                        key:"finContrat", 
                        label:"Fin de contrat"
                    },
                    {
                        key:"statut",
                        label:"Statut",
                    },
                    {
                        key:"lienFournisseur", 
                        label:"Offre"
                    }
                ],


                
                fieldhistoriqueDeCotation:[
                    {
                        key:'Action', 
                        label:"Action",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:'date', 
                        label:"Date d'ajout",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"numCompteur", 
                        label:"Compteur",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "optionTarifaire", 
                        label:"Option tarifaire",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"dateDebut", 
                        label:"Début de contrat",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"dateFin",
                        label:"Fin de contrat",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"marge",
                        label:"Marge",
                        thClass: "text-center",
                        tdClass: "text-center",
                    }
                ],

                fieldVentePro:[
                    {
                        key:'date', 
                        label:"Date d'ajout",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"Vendeur", 
                        label:"Commercial",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "type", 
                        label:"Énergie",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"valeur", 
                        label:"Valeur",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"status", 
                        label:"Statut",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                ],
                fieldCourbesDesCharges:[
                {
                        key:'date', 
                        label:"Date d'ajout",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:'finCdc', 
                        label:"période cdc",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:'numCompteur', 
                        label:"Num Compteur",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key:"status", 
                        label:"Status",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "fichierCDCUrl", 
                        label:"fichier CDC",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "fichierCDCHoursUrl", 
                        label:"fichier CDC hours",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    
                    {
                        key: "Supprimer", 
                        label:"Supprimer",
                        thClass: "text-center",
                        tdClass: "text-center",
                    }
                ],
                openacdPowerSmartModal:false,
                dialog: false,
                loading: false,
                vueAfficher: 'client',
                societe: null,
                historiqueDeCotation: null,
                lesOffresDemander: null,
                demandeDeCotationDeSociete: null,
                venteProSociete: null,
                compteVentePro: null,
                editMode: false,
                nouveauOffre:{
                    lesFournisseurPossible: [],
                    numCompteur: null,
                    dateDebut: null,
                },
                dialogDemandeOffre:false,
                idDemandeDeOffre: null,
                typeDeOffre: "ELEC",
                venteTypeEnergie: 'ELEC',
                profilGaz: null,
                listFournisseur: [
                    'Alpiq',
                    'ALSEN',
                    'Alterna',
                    'Antargaz',
                    'Axpo',
                    'BARRY',
                    'BULB',
                    'BUTAGAZ',
                    'CDISCOUNT',
                    'Dyneff',
                    'Edenkia',
                    'EDF',
                    'EDS',
                    'EDSB',
                    'Ekwateur',
                    'Ekivolt',
                    'Elecocite',
                    'Electricite_de_provence',
                    'Electricite_de_savoie',
                    'Electricite_de_Strasbourg',
                    'ELMY',
                    'Enalp',
                    'Endesa',
                    'Enercoop',
                    'Energem',
                    'ENERGIE_DICI',
                    'Engie',
                    'ENI',
                    'Enovos',
                    'Gaz_de_Bordeaux',
                    'GazelEnergie',
                    'Gazprom',
                    'GEDIA',
                    'GEG',
                    'GREEN_YELLOW',
                    'HAPPE',
                    'IBERDROLA',
                    'ILEK',
                    'JPME',
                    'la_bellenergie',
                    'LECLERCQ',
                    'LLUM',
                    'LUCIA',
                    'MEGA',
                    'MET-France',
                    'mint-energie',
                    'NATGAS',
                    "octopus-energy",
                    'OHM',
                    'OVO',
                    'Picoty',
                    'PLUM',
                    'Primeo',
                    'Proxelia',
                    'Redeéo',
                    'SaveEnergies',
                    'Selia',
                    'SEFE',
                    'SOWEE',
                    'Synelva',
                    'TDE',
                    'Urban_Solar',
                    'Vattenfall',
                    'Volterres',
                    'Wekiwi',
                    'yeli-xs',
                ],
                lesCompteurPossible: [],
                
                rules: {
                    required: value => (value!=null && (""+value).length>0)|| 'Ce champ est obligatoire.',
                    requiredString: value => (""+value).length>8|| 'Ce champ est obligatoire.',
                    siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
                    isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                    isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                    phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                    phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
                    
                    emailRules: [
                    v => !!v || 'E-mail obligatoire',
                    v => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
                    ],
                },
                required(fieldName) {
                    return (value) => ((!!value || value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
            }
        },
        computed: {
    customItems() {
      const items = [...this.baseCustomItems];
      if (this.hasElecCompteur) {
        items.push({
          text: 'Générer Courbe de Charge',
          value: 'genererCourbeDeCharge',
          color: 'primary',
          icon: 'mdi-download',
          action: this.opengenererCourbeDeCharge
        });
      }
      return items;
    },
    hasElecCompteur() {
      return this.listCompteur.some(compteur => compteur.typeDemande === 'ELEC');
    }
  },

        watch: {
            async vueAfficher(newVal){
                if(newVal == "GenerationPDF"){
                    this.listCompteurSelected = this.customItems[0]
                    await this.listCompteurSelected.action()
                }
            },
            listCompteur: {
      handler() {
        // This will trigger a re-evaluation of the computed properties
      },
      deep: true
    }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
                this.typeUser = localStorage.getItem('typeUser')
            }
            await this.getSocieteById();
            await this.getAllHistoryCotationBySociete();
            await this.getAllHistoryCotationGazBySociete();
        },
        methods: {
            async changeClick(){
                await this.listCompteurSelected.action()
            },
             async GenerationPDF(){
                await this.DemandeDeCotation()
                this.vueAfficher = "GenerationPDF"
            },
            async openResiliation(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openResiliationModal = true
                this.openAcdEngie = false;
                this.openacdPowerSmartModal = false;
                this.opengenerationCourbeDeCharge = false;

            },
            async openMandat(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openResiliationModal = false
                this.openAcdEngie = false;
                this.openacdPowerSmartModal = false;
                this.opengenerationCourbeDeCharge = false;

            },
            async opengenererCourbeDeCharge(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openResiliationModal = false
                this.openAcdEngie = false;
                this.openacdPowerSmartModal = false;
                this.opengenerationCourbeDeCharge = true;

            },
            deleteCourbeDeChargeItem(numCompteur) {
                this.selectedNumCompteur = numCompteur;
                this.ouvrireDialogeSupprimerCourbeDeCharge = true;
                },
            fermerDialogeSupprimerCourbeDeCharge() {
                this.ouvrireDialogeSupprimerCourbeDeCharge = false;
                this.selectedNumCompteur = null;
            },
            async confirmerSupprimerCourbeDeCharge() {
                // Your API call goes here
                await axios({
                  url: process.env.VUE_APP_URL_API_CLIENT + 'deleteCdc/',
                  method: 'POST',
                  data: { token: this.getToken('token'), numCompteur: this.selectedNumCompteur, societeId: this.societe.id },
                })
                .then((res) => {
                    if(res.data.result == "notok" || res.data.result == "permission"){
                        alert("une erreur est survenue")
                    }
                  console.log("success delete");
                  this.getAllHistoriqueCourbesDesCharges()
                }).catch((err) => {
                    console.error(err)
                })

                this.fermerDialogeSupprimerCourbeDeCharge();
            },
            getStatusStyle(status) {
                const defaultStyle = { backgroundColor: "#f3f4f6", color: "#1f2937" };
                const statusStyle = this.statusFactory[status]?.style || defaultStyle;
            return {
                ...statusStyle,
                display: "inline-block",
                padding: "0.25rem 0.5rem",
                fontSize: "0.75rem",
                fontWeight: "500",
                borderRadius: "9999px"
            };
    },
            formatPhoneNumber(phoneNumber) {
                // Supprimer tous les caractères non numériques
                phoneNumber = phoneNumber.replace(/\D/g, "");

                // Insérer un espace après chaque paire de chiffres
                phoneNumber = phoneNumber.replace(/(\d{2})(?=\d)/g, "$1 ");

                return phoneNumber;
            },
            isRangeDateValide(date){
                if(this.objectCalculette.dateFin != "" && this.objectCalculette.dateDebut!=""&& this.objectCalculette.dateFin<this.objectCalculette.dateDebut){
                    switch(date){
                        case "Début":
                            return "la date de début doit etre inférieure à la date de fin";
                            break;
                        case "Fin":
                            return "la date de fin doit etre supérieur à la date de début";
                            break;

                        default:
                            break;
                    }
               }
            },
            
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            isValidDate(formatDate, minDate, maxDate) {
                return (date) => {
                    var tmpdate = this.dateFormatMinus(date)
                    if (!date) {
                        return 'Veuillez saisir une date valide'
                    }
                    if (moment(tmpdate).isBefore(minDate)) {
                        return `La date doit être supérieure  au ${this.dateFormatSlach(minDate)}.`
                    }
                    if (moment(tmpdate).isAfter(maxDate)) {
                        return `La date limite de fin de contrat est: ${this.dateFormatSlach(maxDate)}.`
                    }
                    return true
                }
            },
            async saveScore(){
                this.$refs.formScores.validate()
                if (this.$refs.formScores.validate()) {
                    this.dialog = true
                    this.loading = true  
                    var dataSend = {
                        id: this.societe.id,
                        scoreEliPro: this.newScore.scoreEllipro,
                        scoreCreditSafe: this.newScore.scoreCreditSafe,
                        token: this.getToken('token'),
                    }
                    await axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'updateSocieteScore/',
                        method: 'POST',
                        data: dataSend,
                    })
                    .then((res) => {
                        if(res.status == 200){
                            this.getSocieteById()
                        }
                    }).catch((err)=>{
                        this.loading = false
                        this.error = true
                        this.messageDialogeError = "Une erreur s'est produite"
                    })
                }
            },
            async creationCalculette(){
                this.$refs.formCalculette.validate()
                if(this.$refs.formCalculette.validate()){
                    
                    this.dialog = true
                    this.loading = true
                    this.objectCalculette.siret = this.societe.siret
                    var dataSend = {
                        objectCalculette: this.objectCalculette,
                        token: this.getToken('token'),
                    }
                    await axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'createCalculette/',
                        method: 'POST',
                        data: dataSend,
                    })
                    .then((res) => {
                        if(res.data.result == true){
                            this.loading = false
                            this.dialog = false
                            const link = document.createElement('a')
                            link.href = res.data.link
                            link.download = "file.xlsx"
                            link.click()
                        }else{
                            this.loading = false
                            this.error = true
                            this.messageDialogeError = "Une erreur s'est produite"
                        }
                    }).catch((err)=>{
                        this.loading = false
                        this.error = true
                        this.messageDialogeError = "Une erreur s'est produite"
                    })
                }
            },
        
            toggle(){
                this.selectedCompteur = [...this.listCompteur]
            },
            async openModalGazel(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openModalCalculette = true
            },
            async openacdPowerSmart(){
                this.listCompteur = []
                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        var compteurIndex = this.listCompteur.findIndex(item => item.numCompteur === lesNumCompteur[j]);
                        if(compteurIndex !== -1 && this.demandeDeCotationItems[i].valide == 1){
                            // here i want to replace this compteur by the new one
                            this.listCompteur[compteurIndex] = {
                                numCompteur: lesNumCompteur[j],
                                typeDemande: typeDemande
                            };
                        }else{
                            this.listCompteur.push({
                                numCompteur: lesNumCompteur[j],
                                typeDemande: typeDemande
                            })
                        }
                    }
                }
                this.openResiliationModal = false;
                this.openAcdEngie = false;
                this.openacdPowerSmartModal = true;
                this.opengenerationCourbeDeCharge = false;

            },
            async ACDEngie(){
                this.listCompteur = []

                for (var i =0; i<this.demandeDeCotationItems.length; i++){
                    var lesNumCompteur = this.demandeDeCotationItems[i].numCompteur.split(',')
                    var typeDemande = this.demandeDeCotationItems[i].typeDemande
                    for (var j=0; j<lesNumCompteur.length; j++){
                        this.listCompteur.push({
                            numCompteur: lesNumCompteur[j],
                            typeDemande: typeDemande
                        })
                    }
                }
                this.openResiliationModal = false;
                this.openAcdEngie = true;
                this.openacdPowerSmartModal = false;
                this.opengenerationCourbeDeCharge = false;

               // this.openModalAcdEngie = true
            },
           
            async getPourcentage(numCompteur){
                var formData = new FormData()
                formData.append('numCompteur', numCompteur)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getPourcentageCompte/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    this.pourcentage = res.data.result
                }).catch((err)=>{
                    this.pourcentage = 0
                })
            },
            async ajouterVenteElecModalOpen(item){
                await this.getPourcentage(item.numCompteur)
                await this.getListeCompteurByDC(item.numCompteur)
                this.dataPourVente = item
                this.venteTypeEnergie= "ELEC"
                this.ajouterVenteElecModal = true;
            },
            async getListeCompteurByDC(numCompteur){
                var formData = new FormData()
                formData.append('numCompteur', numCompteur)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllCompteurParDC/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    this.listeCompteurVente = res.data.result
                }).catch((err)=>{
                    this.listeCompteurVente = []
                })

            },
            async ajouterVenteGazModalOpen(item){
                this.loadingButtonHistoriqueCotaGAZ = true;
                await this.getPourcentage(item.numCompteur)
                await this.getListeCompteurByDC(item.numCompteur)
                this.dataPourVente = item
                this.venteTypeEnergie= "GAZ"
                this.ajouterVenteElecModal = true;
                this.loadingButtonHistoriqueCotaGAZ = false;
            },
            async ajouterVenteElecModalClose(){
                this.ajouterVenteElecModal = false;
            },
            async DemandeDeCotation(){
                this.loadingTableHistorique= true;
                this.loadingTableCotation = true;
                this.loadingTableHistoriqueGaz = true;
                await this.getAllHistoryCotationBySociete();
                await this.getAllHistoryCotationGazBySociete();
                this.loadingTableHistorique= false;
                this.loadingTableCotation = false;
                this.loadingTableHistoriqueGaz = false;
            },            
            async deleteLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'deleteLink/',
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien supprimé")
                        await this.getLink()
                    }else if(res.data.result == false){
                        alert("Impossible de supprimer ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })

            },
            async desactiverLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'disactiverLink/',
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien désactivé")
                        await this.getLink()
                    }else if(res.data.result == false){
                        alert("Impossible de désactiver ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })

            },
            async activerLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'activerLink/',
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien activé")
                        await this.getLink()
                    }else if(res.data.result == false){
                        alert("Impossible d'activer ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async ajouterNouveauOffre(){
                this.$refs.formNouveauOffre.validate()
                if(this.$refs.formNouveauOffre.validate()){
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('id', this.idDemandeDeOffre)
                    formData.append('lesFournisseurPossible', this.nouveauOffre.lesFournisseurPossible)
                    formData.append('numCompteur', this.nouveauOffre.numCompteur)
                    formData.append('dateDebut', this.nouveauOffre.dateDebut)
                    formData.append('typeDeOffre', this.typeDeOffre)
                    formData.append('profile', this.profilGaz)
                    await axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'createLinkToGetNewOffre/',
                        method: 'POST',
                        data: formData,
                    })
                    .then(async (res) => {
                        if(res.data.result == true){
                            alert("La demande a été ajouté avec succès !")
                            this.fermerChoisirOffre()
                            await this.getLink()
                        }else if(res.data.result == false){
                            alert("Aucunes informations enedis pour ce compteur pour le moment")
                        }else if(res.data.result == "permission"){
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser') 
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                    }).catch((err)=>{
                        console.log(err)
                    })
                    
                    
                }
            },
            fermerChoisirOffre(){
                this.dialogDemandeOffre=false
                this.idDemandeDeOffre=null
                this.typeDeOffre = null
                this.profilGaz = null
                this.nouveauOffre.lesFournisseurPossible=[]
                this.numCompteur=null
                this.$refs.formNouveauOffre.resetValidation();
            },
            async getLink(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getLinkDesOffresBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.lesOffresDemander = res.data.liens
                    }else if(res.data.result==false){
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            choisirOffre(id, type){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getDemandeDeCotationById/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        this.dialogDemandeOffre=true
                        this.typeDeOffre = type
                        this.idDemandeDeOffre=id
                        this.lesCompteurPossible = res.data.listCompteur

                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }else{
                        alert("la demande n'existe pas pour le moment !")
                    }
                }).catch((err)=>{
                    alert("la demande n'existe pas pour le moment !")
                    console.log(err)
                })
                
            },
            activeEdit(){
                this.editMode = true;    
            },
            
            cancelEdit(){
                this.editMode = false;    
            },
            saveChange(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', this.societe.id)
                formData.append('nom', this.societe.nom) 
                formData.append('prenom', this.societe.prenom)
                formData.append('AdresseEmail', this.societe.AdresseEmail)
                formData.append('numTele', this.societe.numTele)
                formData.append('fonction', this.societe.fonction)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'saveChangeSociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        alert('données mises à jour')
                        this.editMode = false;   
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                        this.editMode = false;   
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            afficherInfoPersonnelle(){
                this.vueAfficher = 'client'  
                this.demandeDeCotationDeSociete=null
                this.historiqueDeCotation=null
                this.venteProSociete=null
                this.compteVentePro=null
            },
            spliteList(list){
                return list.split(',')
            },
            spliteListSlice(list){
                return list.split(',').slice(1,)
            },
            
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            async getAllVenteProBySociete(){
                this.loadingTableVentePro = true;
                this.demandeDeCotationItems=[]
                this.historiqueCotationItems=[]
                this.historiqueCotationGazItems=[]
                this.vueAfficher="listeDesVentePro"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
               await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllVenteProBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.venteProSocieteItems = res.data.ventes
                        this.compteVentePro = res.data.comptes
                        this.loadingTableVentePro = false;
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        this.loadingTableVentePro = false;
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
                this.loadingTableVentePro = false;
            },
            async getAllHistoriqueCourbesDesCharges(){
                this.loadingTableCourbesDesCharges=true;
                this.vueAfficher="courbesDesCharge"
                //call api then 
                
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('societeId', this.$route.query.id)
                
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllCdcBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.vueAfficher="courbesDesCharge"
                        this.courbesDesChargesItems = res.data.dataCdc
                        
                        console.log(this.courbesDesChargesItems)
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
                this.loadingTableCourbesDesCharges=false;

            },
            async getAllHistoryCotationBySociete(){
                await this.getLink();
                this.venteProSociete=null
                this.compteVentePro=null
                this.vueAfficher="historiqueDeCotation"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllHistoryCotationBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.vueAfficher="historiqueDeCotation"
                        this.historiqueCotationItems = res.data.histoDeCotation
                        this.demandeDeCotationItems = res.data.DC
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue111")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            async getAllHistoryCotationGazBySociete(){
                await this.getLink()
                this.venteProSociete=null
                this.compteVentePro=null
                this.vueAfficher="historiqueDeCotation"
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idSociete', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllHistoryCotationGazBySociete/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.vueAfficher="historiqueDeCotation"
                        this.historiqueCotationGazItems = res.data.histoDeCotation
                        this.demandeDeCotationItems = res.data.DC
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            getSocieteById(){
                
                this.dialog = true
                this.loading = true
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', this.$route.query.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getSocieteById/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    this.dialog = false
                    this.loading = false
                    if(res.data.result==true){
                        this.societe = res.data.societe
                        this.objectCalculette.capital = this.societe.capitalSocial
                        this.objectCalculette.resultatExploitation = this.societe.resultatExploatation
                        this.objectCalculette.produitExploitation = this.societe.produitExploatation
                        this.urlStatus = res.data.status
                        this.newScore.scoreEllipro = this.societe.scoreEllipro;
                        this.newScore.scoreCreditSafe = this.societe.scoreCreditSafe;
                    }else if(res.data.result==false){
                        alert("Une erreur est survenue")
                    }else if(res.data.result=="permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    this.urlStatus = ""
                    this.dialog = false
                    this.loading = false
                    alert("une erreur est survenue");
                })
            }
        }
    }

</script>
<style>
    #demandeDeCotation th, #demandeDeCotation td{
        text-align:center;
    }
</style>

