<template lang="">
  <div class="text-center" style="height: 100%">
    <v-progress-circular
      indeterminate
      color="primary"
      :size="this.size"
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  props: { size: null },
}
</script>
<style lang=""></style>
