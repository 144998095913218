<template>
  <div class="ma-4">
    <v-card class="mt-6 pa-4" elevation="10">

      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="$colors[0]"
            dark
            class="mb-2 text-white"
            v-bind="attrs"
            v-on="on"
          >
            Ajouter un vendeur
          </v-btn>
        </template>

        <v-card >
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
            
          </v-card-title>

          <v-card-text>
          
            <p style="font-size: 14px; color:red;" v-if="compteExist">Ce compte existe déjà</p>
            <v-container class="ml-0">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-row class="mr-0">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nom"
                      :rules="[rules.required,rules.isString]"
                      label="Nom"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.prenom"
                      :rules="[rules.required,rules.isString]"
                      label="Prénom"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-if="editedItemId > -1"
                      disabled
                      v-model="editedItem.email"
                      :rules="[rules.emailRules]"
                      label="Email"
                    ></v-text-field>
                    <v-text-field v-else
                      v-model="editedItem.email"
                      :rules="[rules.emailRules]"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="editedItem.telephone"
                      :rules="[rules.required,rules.phoneCorrect]"
                      label="Numéro de téléphone"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="societes"
                      :rules="[rules.required]"
                      v-model="editedItem.societeGroupe"
                      label="Societe Groupe"
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-if="typeUser=='admin'"
                      :items="typeVendeurAdmin"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                    <v-select
                      v-if="typeUser=='ADV'"
                      :items="typeVendeur"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                    <v-select
                      v-if="typeUser=='superadmin'"
                      :items="typeVendeurAdmin"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6"  v-if="this.editedItemId < 0">
                    <v-text-field
                      v-model="editedItem.password"
                      :append-icon="showpassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showpassword1 ? 'text' : 'password'"
                      :rules="[rules.required, rules.minPasswordLength]"
                      @click:append="showpassword1 = !showpassword1"
                      label="Mot de passe"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" v-if="editedItem.type!='ADV'">
                    <v-autocomplete
                        :rules="[required('une équipe')]"
                        :items="listeEquipe"
                        item-text="nomEquipe"
                        item-value="id"
                        v-model="editedItem.EquipeSelected"
                        label="équipe"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" v-else>
                    <v-autocomplete 
                        v-model="editedItem.ADVEquipeSelected" 
                        :items="listeEquipe" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="nomEquipe" 
                        item-value="id" 
                        label="Les équipes" 
                        placeholder="Trouver des équipe" 
                        prepend-icon="mdi-account-search-outline"
                        chips
                        deletable-chips
                        multiple
                        :rules="[requiredSelect()]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.nomEquipe"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.chefEquipe.userName"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEdit" max-width="600px">
        <v-card >
          <v-card-title>
            <span class="text-h5">Modifer Compte</span>
          </v-card-title>
          <v-card-text>
            <v-container class="ml-0">
              <v-form
                ref="formEdit"
                lazy-validation
              >
                <v-row class="mr-0">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nom"
                      :rules="[rules.required,rules.isString]"
                      label="Nom"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.prenom"
                      :rules="[rules.required,rules.isString]"
                      label="Prénom"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      disabled
                      v-model="editedItem.email"
                      :rules="[rules.emailRules]"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="editedItem.telephone"
                      :rules="[rules.required,rules.phoneCorrect]"
                      label="Numéro de téléphone"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="societes"
                      :rules="[rules.required]"
                      v-model="editedItem.societeGroupe"
                      label="Societe Groupe"
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-if="beforEditedItem.type=='ADV'"
                      :items="typeRolesADVEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type=='Chef équipe'"
                      :items="typeRolesChefEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type=='Vendeur'"
                      :items="typeRolesVendeurEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type=='Prospecteur'"
                      :items="typeRolesProspecteurEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Vendeur"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" v-if="((beforEditedItem.type=='Chef équipe'&&beforEditedItem.EquipeSelected!=-1)||
                     (beforEditedItem.type=='Chef équipe' && beforEditedItem.EquipeSelected !== editedItem.EquipeSelected)||
                     (beforEditedItem.type=='ADV' && beforEditedItem.ADVEquipeSelected !== editedItem.ADVEquipeSelected))&&beforEditedItem.EquipeSelected!=-1"
                     >
                    <v-autocomplete
                        v-if="(editedItem.type=='ADV' && beforEditedItem.type=='Chef équipe') || (beforEditedItem.EquipeSelected !== editedItem.EquipeSelected)"
                        :rules="[required('un chef d\'équipe')]"
                        :items="listeChef"
                        item-text="userName"
                        item-value="id"
                        v-model="newManager"
                        label="Remplaçant de chef d'équipe"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12">
                    <v-autocomplete
                        :items="listeEquipeUpdate"
                        item-text="nomEquipe"
                        item-value="id"
                        v-model="editedItem.EquipeSelected"
                        label="équipe"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="edit">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="640">
        <v-card>
          <v-card-title class="text-h5"
            >Êtes-vous sûr de bien vouloir supprimer cette Compte ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Confirmer</v-btn
            >
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Annuler</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogUpdatePassword" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Modifier Password</span>
          </v-card-title>
          <v-card-text>
            <p style="font-size: 14px; color:red;" v-if="compteExist">Modifier Password</p>
          </v-card-text>
            <v-container>
              <v-form
                ref="form1"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="newPassword"
                      :rules="[rules.required]"
                      label="nouveau mot de passe"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="updatePassword(idComptePasswordUpdate)">
                  Enregistrer
                </v-btn>
                <v-btn color="blue darken-1" text @click="closeDialogUpdatePassword">
                  Annuler
                </v-btn>
              </v-card-actions>
            </v-container>
        
        </v-card>
      </v-dialog>
      <template>
        <b-form-input
              style='width:50%'
              class="my-2"
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Rechercher..."
            ></b-form-input>
        <b-table
        :items="compteVendeur"
        :fields="fieldsTable"
        responsive
        :current-page="currentPage"
        :per-page="perPage" 
        :filter="filter"
        striped hover bordered
        show-empty
        empty-text="Aucune données disponible pour le moment"
        empty-filtered-text="Aucune données disponible correspondant à votre recherche"
        >
        <template #empty="scope">
          <h6>{{ scope.emptyText }}</h6>
        </template>
        <template #emptyfiltered="scope">
          <h6>{{ scope.emptyFilteredText }}</h6>
        </template>
        <template #cell(actions)='data'>
          <v-icon medium class="mr-2" @click="editItem(data.item)">mdi-pencil</v-icon>
        </template>
        </b-table>
        <v-row class=" mx-0 my-4">
        <v-row class="align-items-center">
        <p class="text-subtitle-2 ma-4">Éléments affichés</p>
          <b-form-select
            style="width:250px"
            id="per-page-select"
            v-model="perPage"
            @change="updateItemsPerPage(perPage)"
            :options="itemsPerPageArray"
          ></b-form-select>
        </v-row>
          <b-pagination
            class="ma-4"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>
        </v-row>
      </template>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'VendeursAvd',

  components: {
  },
  data: () => ({
    totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fieldsTable:[
        {key:'nom',label:'nom',sortable:"yes"},
        {key:'prenom',label:'prénom',sortable:"yes"},
        {key:'date',label:"date d'embauche",sortable:"yes"},
        {key:'email',label:'adresse mail',sortable:"yes"},
        // {key:'societeGroupe',label:'société',sortable:"yes"},
        {key:'type',label:'poste',sortable:"yes"},
        {key:'telephone',label:'numéro de téléphone',sortable:"yes"},
        {key:'hasEquipe',label:'Etat de compte',sortable:"yes"},
        {key:'actions',label:""}
    ],
    itemsPerPageArray: [10, 15, 20, 30],
    search: '',
    filter: "",
    sortDesc: true,
    page: 1,
    itemsPerPage: 10,
    sortBy: '',
    compteVendeur: [],
    dialogUpdatePassword: false,
    newPassword:null,
    idComptePasswordUpdate: null,
    search: '',
    compteExist: false,
    editedItemId: -1,
    societes: [
      'Gaz De Bordeaux',
      'Gaz Elec Moinscher',
      'Global marketing Services',
      'Power Conseils',
      'SkyBlue',
      "Ekopro energy",
      "Groupe he",
      "Lab energies",
      "PACAENERGIE",
      "I finance energies",
      "ZOUGGARI",
    ],
    editedItem: {
      nom: null,
      prenom: null,
      email: null,
      // societeGroupe: null,
      telephone: null,
      type: 'Vendeur',
      password: null,
      ADVEquipeSelected: [],
      EquipeSelected: null,
    },
    beforEditedItem: {
      nom: null,
      prenom: null,
      email: null,
      // societeGroupe: null,
      telephone: null,
      type: 'Vendeur',
      password: null,
      ADVEquipeSelected: [],
      EquipeSelected: null,
    },
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    showpassword1: false,
    required(fieldName) {
        return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
    },
    requiredSelect(){
        return (value) => (value.length>0 || "Sélectionner au moins un utilisateur.")
    },
    rules: {
        isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
        required: value => !!value || 'Ce champ est obligatoire.',
        minPasswordLength: v => v.length >= 8 || 'Un mot de passe doit contenir au minimum 8 caractères',
        isNumber: v => v.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre',
        phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
        phoneCorrect: v => (/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/).test(v) || 'Le numéro de téléphone est invalide',
        emailRules:v => !!v && (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || 'E-mail invalide',
    },
    
    keys: [
      'nom',
      'prenom',
      'type',
      'email',
      'telephone',
      'Action',
    ],
    defaultItem: {
      nom: null,
      prenom: null,
      email: null,
      telephone: null,
      type: 'Vendeur',
      password: null,
      ADVEquipeSelected: null,
      EquipeSelected: null,
    },
    typeVendeur: ['Chef équipe','Vendeur','Prospecteur'],
    typeVendeurAdmin: ['Chef équipe','Vendeur','Prospecteur'],
    typeRolesProspecteurEdit: ['Chef équipe','Vendeur','Prospecteur'],
    typeRolesVendeurEdit: ['Chef équipe','Vendeur'],
    typeRolesChefEdit: ['Chef équipe'],
    listeEquipe: [],
    listeEquipeUpdate: [],
    indivEquipe: {
      id: -1,
      nomEquipe: 'Solo'
    },
    listeChef:[],
    newManager: null,
  }),

  computed: {
    formTitle() {
      return this.editedItemId >= 0
        ? 'Profil Vendeur'
        : 'Nouveau Vendeur'
    },
    numberOfPages () {
      return Math.ceil(this.compteVendeur.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys
    },
    filteredKeysSorted () {
      return this.keys.filter(key => key !== 'Action')
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    'editedItem.type': function(newVal, oldVal) {
      if (newVal === 'ADV') {
        // Check if ADVEquipeSelected is a single value and convert it to an array
        if (!Array.isArray(this.editedItem.ADVEquipeSelected)) {
          if (this.editedItem.ADVEquipeSelected !== null && this.editedItem.ADVEquipeSelected !== undefined) {
            this.editedItem.ADVEquipeSelected = [this.editedItem.ADVEquipeSelected];
          } else {
            this.editedItem.ADVEquipeSelected = [];
          }
        }
      }
    },
  },

  created() {
    this.typeUser = localStorage.getItem('typeUser')
    //this.verifyApplicationDataSecurity()
    this.getAllCompte()
    this.getAllEquipe()
    this.getAllChef()
  },

  methods: {
    async getAllEquipe(){
        var formData = new FormData()
        formData.append('token', this.getToken('token'))
        await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + 'getAllEquipeByADV/',
            method: 'POST',
            data: formData,
        }).then((res)=>{
            if(res.data.error == false){
                this.listeEquipe = res.data.result
                this.listeEquipeUpdate = [...this.listeEquipe]
                this.listeEquipeUpdate.unshift(this.indivEquipe)
            }else if(res.data.error == "permission"){
                alert(res.data.message)
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser')
                localStorage.removeItem('token')
                this.$router.push('/login')
            }
        }).catch((err)=>{
            console.log(err)
        })
    },
    async getAllChef(){
        var formData = new FormData()
        formData.append('token', this.getToken('token'))
        await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefEquipe/',
            method: 'POST',
            data: formData,
        }).then((res)=>{
            if(res.data.error == false){
                this.listeChef = res.data.result
            }else if(res.data.error == "permission"){
                alert(res.data.message)
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser')
                localStorage.removeItem('token')
                this.$router.push('/login')
            }
        }).catch((err)=>{
            console.log(err)
        })
    },
    closeDialogUpdatePassword(){
      this.dialogUpdatePassword= false
      this.newPassword=null
      this.idComptePasswordUpdate=null
    },
    openDialogUpdatePassword(id){
      this.dialogUpdatePassword=true
      this.idComptePasswordUpdate=id
    },
    updatePassword(idComptePasswordUpdate){
      let self = this
      var formData = new FormData()
      formData.append('id', idComptePasswordUpdate)
      formData.append('password', this.newPassword)
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'modifierPassword/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getAllCompte()
            this.closeDialogUpdatePassword()
            alert('Mot de passe modifié avec succès')
          } if (res.data.result == false) {
            this.closeDialogUpdatePassword()
            alert('Erreur ! Impossible de modifier le mot de passe actuel')
          }else if (res.data.result == 'permission'){
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    editItem(item) {
      this.editedItemId = item.id
      this.editedItem = Object.assign({}, item) //copie le contenu d'item dans editedItem
      this.beforEditedItem = Object.assign({}, item)
      this.dialogEdit = true
    },

    deleteItem(item) {
      this.editedItemId = item.id
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.supprimerCompte(this.editedItemId)
      this.closeDelete()
    },

    close() {
      this.compteExist = false;
      this.dialog = false
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemId = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemId = -1
      })
    },

    save() {
      this.$refs.form.validate()
      if(this.$refs.form.validate()){
          this.ajouterCompte(this.editedItem)
      }
    },
    edit() {
        this.$refs.formEdit.validate()
        if(this.$refs.formEdit.validate()){
            this.modifierCompte(this.editedItem)
        }
    },
    supprimerCompte(compteVendeurId) {
      let self = this
      var formData = new FormData()
      formData.append('id', compteVendeurId)
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'deleteCompte/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getAllCompte()
            this.close()
          } if (res.data.result == false) {
            alert('compte déjà supprimé')
          }else if (res.data.result == 'permission'){
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    modifierCompte(compteVendeur) {
      let self = this
      var formData = new FormData()
      formData.append('idCompte', compteVendeur.id)
      formData.append('email', compteVendeur.email)
      formData.append('nom', compteVendeur.nom)
      formData.append('prenom', compteVendeur.prenom)
      formData.append('type', compteVendeur.type)
      // formData.append('societeGroupe', compteVendeur.societeGroupe)
      formData.append('telephone', compteVendeur.telephone)
      formData.append('equipeId', compteVendeur.EquipeSelected)
      formData.append('advEquipeId', compteVendeur.ADVEquipeSelected)
      formData.append('newManager', this.newManager)
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'updateCompte/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getAllCompte()
            this.close()
          } 
          if (res.data.result == false) {
            alert("Vous ne pouvez pas modifier ce compte vérifiez que vous avez l'autorisation d'effectuer cette modification")
          }
          if (res.data.result == 'permission'){
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    ajouterCompte(compteVendeur) {
      //let self = this
      var formData = new FormData()
      formData.append('email', compteVendeur.email)
      formData.append('nom', compteVendeur.nom)
      formData.append('prenom', compteVendeur.prenom)
      formData.append('type', compteVendeur.type)
      // formData.append('societeGroupe', compteVendeur.societeGroupe)
      formData.append('telephone', compteVendeur.telephone)
      formData.append('password', compteVendeur.password)
      formData.append('equipeId', compteVendeur.EquipeSelected)
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'createCompte/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          
          if (res.data.exist == true) {
            this.compteExist = true
            this.getAllCompte()
          } else if (res.data.exist == false) {
            this.getAllCompte()
            this.close()
          } else {
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAllCompte() {
      let self = this
      self.societes = []
      var formData = new FormData()
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'getAllCompte/',
        method: 'POST',
        data: formData,
      }).then((res) => {
        this.totalRows = res.data.data.length
        self.compteVendeur = res.data.data
        // for (var i=0; i<self.compteVendeur.length; i++){
        //   if(self.societes.indexOf(self.compteVendeur[i]['societeGroupe'])==-1 && self.compteVendeur[i]['societeGroupe']!= null ){

        //     self.societes.push(self.compteVendeur[i]['societeGroupe'])
        //   }
        // }
      })
    },
  },
}
</script>
<style>
  .v-application .blue.darken-3{
    margin-bottom:25px !important
  }
</style>
