<template>
  <div style="background-color: #eee">
    <v-card style="overflow: hidden">
      <v-toolbar :color="$colors[0]" dark>
        <span class="text-h6 text-white">Création d'une lettre de résiliation</span>
      </v-toolbar>
      <v-card-text class="my-4">
        <v-form ref="formAcd" lazy-validation>
          <v-row>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.Raison"
                label="Raison Sociale"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.AdressePostal"
                label="Adresse Societe"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.nom"
                label="Nom client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="societe.prenom"
                label="Prénom client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                type="date"
                v-model="dataFournisseur.dateResiliation"
                label="Date Résiliation"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.nomFournisseur"
                label="Nom Fournisseur"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.adresseFournisseur"
                label="Adresse Fournisseur"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.compteFacturation"
                :rules="[rules.required]"
                label="Compte de facturation"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                dense
                v-model="dataFournisseur.referenceClient"
                label="Référence client"
                :rules="[rules.required]"
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-autocomplete
                @input="ChangeInput(selectedCompteur)"
                dense
                v-model="selectedCompteur"
                :items="listCompteur"
                :loading="isLoading"
                hide-no-data
                :rules="[rules.required]"
                label="Compteur(s)"
                placeholder="Ajouter un compteur"
                prepend-icon="mdi-account-search-outline"
                chips
                item-text="numCompteur"
                deletable-chips
                multiple
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.numCompteur }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedCompteur.length - 1 }}
                    {{ selectedCompteur.length - 1 == 1 ? "compteur" : "compteurs" }})
                  </span>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.numCompteur }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.typeDemande
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-content>
                      <v-list-item-title
                        >Séléctionner tous les compteurs</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <div class="d-flex justify-content-end">
            <v-btn
              :color="$colors[0]"
              class="mt-8 mr-4 text-white"
              @click="createLettreResiliation()"
            >
              Valider
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
  TextAlignment,
  setFillingRgbColor,
  setFontAndSize,
  layoutMultilineText,
} from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
export default {
  name: "lettreDeRisiliation",
  props: { societe: "", listCompteur: [] },
  data() {
    return {
      openModalAcdEngie: true,
      isLoading: false,
      dataFournisseur: {
        nomFournisseur: "",
        adresseFournisseur: "",
        dateResiliation: "",
        compteFacturation: "",
        referenceClient: "",
      },
      selectedCompteur: [],
      selectedCompteursELEC: [],
      selectedCompteursGAZ: [],
      rules: {
        required: (value) =>
          (value != null && ("" + value).length > 0) || "Ce champ est obligatoire.",
        requiredString: (value) => ("" + value).length > 8 || "Ce champ est obligatoire.",
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

        emailRules: [
          (v) => !!v || "E-mail obligatoire",
          (v) => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
        ],
      },
      required(fieldName) {
        return (value) =>
          !!value ||
          value >= 0 ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
    };
  },
  methods: {
    ChangeInput(selectedCompteurs) {
      selectedCompteurs.forEach((element) => {
        console.log(element);
        if (element.typeDemande == "ELEC") {
          this.selectedCompteursELEC.push(element);
        } else {
          this.selectedCompteursGAZ.push(element);
        }
      });
      console.log(this.selectedCompteursELEC, this.selectedCompteursGAZ);
    },
    closeModal() {
      this.$emit("closeResiliationModal");
    },
    toggle() {
      console.log(this.listCompteur);
      this.selectedCompteur = [...this.listCompteur];
      console.log(this.selectedCompteur);
      for (let i = 0; i < this.selectedCompteur.length; i++) {
        console.log(this.selectedCompteur[i].typeDemande);
        if (this.selectedCompteur[i].typeDemande == "ELEC") {
          this.selectedCompteursELEC.push(this.selectedCompteur[i]);
        } else {
          this.selectedCompteursGAZ.push(this.selectedCompteur[i]);
        }
      }
      console.log(this.selectedCompteursELEC, this.selectedCompteursGAZ);
    },
    formatDate(dateStr) {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(dateStr)) {
        throw new Error('Le format de la date doit être "année-mois-jour"');
      }

      const [year, month, day] = dateStr.split("-");

      return `${day}/${month}/${year}`;
    },
    async createLettreResiliationELECGAZ(typeDemande, listCompteurs) {
      /////////////// page 1 ///////////////////////////////////////
      var url0 = "/pdf/resiliation/resiliation_gaz_elec.pdf";
      var pageZero = await fetch(url0).then((res) => res.arrayBuffer());
      // Load a PDFDocument from each of the existing PDFs
      var pageZeroDoc = await PDFDocument.load(pageZero);

      var getPages = pageZeroDoc.getPages();
      var firstPage = getPages[0];
      var form = pageZeroDoc.getForm();
      const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold);
      const lineHeight = 20; // Adjust this value to set the line spacing
      const pageWidth = firstPage.getWidth();
      const pageHeight = firstPage.getHeight();
      let startingPositon = pageHeight - 50; // Adjust the starting Y coordinate
      var field = form.getTextField("textarea_adresse_1");
      var txt = this.societe.Raison + " \n" + this.societe.AdressePostal;
      //   field.setText(txt);
      var multiTextAdresse1 = layoutMultilineText(txt, {
        alignment: "left",
        fontSize: 10,
        font: timesRomanFont,
        lineHeight: lineHeight,
        bounds: { width: 250, height: pageHeight },
      });
      for (let i = 0; i < multiTextAdresse1.lines.length; i++) {
        if (startingPositon - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
          var newPageFirstTextY = pageHeight - 50;
          startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiTextAdresse1.lines[i].text}`, {
            x: pageWidth - 250,
            y: startingPositon,
            size: 10,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          const textWidth = timesRomanFont.widthOfTextAtSize(
            multiTextAdresse1.lines[i].text,
            10
          );
          firstPage.drawText(`${multiTextAdresse1.lines[i].text}`, {
            x: 50,
            y: startingPositon,
            size: 10,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        }
        // move position down
        startingPositon = startingPositon - lineHeight;
      }
      startingPositon = pageHeight - 170;
      var field = form.getTextField("textarea_adresse_2");

      var txt =
        this.dataFournisseur.nomFournisseur +
        " \n" +
        this.dataFournisseur.adresseFournisseur;
      //   field.setText(txt);

      var multiTextAdresse2 = layoutMultilineText(txt, {
        alignment: "right",
        fontSize: 10,
        font: timesRomanFont,
        lineHeight: lineHeight,
        bounds: { width: 250, height: pageHeight },
      });
      for (let i = 0; i < multiTextAdresse2.lines.length; i++) {
        if (startingPositon - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
          var newPageFirstTextY = pageHeight - 50;
          startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiTextAdresse2.lines[i].text}`, {
            x: pageWidth - 250,
            y: startingPositon,
            size: 10,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          const textWidth = timesRomanFont.widthOfTextAtSize(
            multiTextAdresse2.lines[i].text,
            10
          );
          firstPage.drawText(`${multiTextAdresse2.lines[i].text}`, {
            x: pageWidth - 50 - textWidth,
            y: startingPositon,
            size: 10,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        }
        // move position down
        startingPositon = startingPositon - lineHeight;
      }
      startingPositon = startingPositon - 30;
      var DateAujourdhui = new Date();
      DateAujourdhui =
        DateAujourdhui.getDate() +
        "/" +
        (DateAujourdhui.getMonth() + 1) +
        "/" +
        DateAujourdhui.getFullYear();

      var field = form.getTextField("text_objet");
      //   startingPositon = startingPositon - 150;
      var txt = `OBJET: RESILIATION DU CONTRAT DE FOURNITURE ${
        typeDemande == "GAZ" ? "DE GAZ" : "D'ÉLÉCTRICITÉ"
      }`;
      field.setText(txt);

      var txt = `FAIT le ${DateAujourdhui}`;
      var multiTextFAIT = layoutMultilineText(txt, {
        alignment: "left",
        fontSize: 10,
        font: timesRomanFont,
        lineHeight: lineHeight,
        bounds: { width: pageWidth - 80, height: pageHeight },
      });
      for (let i = 0; i < multiTextFAIT.lines.length; i++) {
        if (startingPositon - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
          var newPageFirstTextY = pageHeight - 50;
          startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiTextFAIT.lines[i].text}`, {
            x: pageWidth - 80,
            y: startingPositon,
            size: 10,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          const textWidth = timesRomanFont.widthOfTextAtSize(
            multiTextFAIT.lines[i].text,
            10
          );
          firstPage.drawText(`${multiTextFAIT.lines[i].text}`, {
            x: pageWidth - 50 - textWidth,
            y: startingPositon,
            size: 10,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        }
        // move position down
        startingPositon = startingPositon - lineHeight;
      }

      //   field.setText(txt);
      var text =
        `Je soussigné M. ${this.societe.prenom} ${this.societe.nom} gérant de la société ${this.societe.Raison},` +
        ` Siret : ${this.societe.siret}, souhaite ne pas reconduire mon contrat conformément aux` +
        ` conditions générales en date du ${this.formatDate(
          this.dataFournisseur.dateResiliation
        )} .`;

      startingPositon = pageHeight - 420; // Adjust the starting Y coordinate

      var multiText = layoutMultilineText(text, {
        alignment: "left",
        fontSize: 11,
        font: timesRomanFont,
        lineHeight: lineHeight,
        bounds: { width: pageWidth - 80, height: pageHeight },
      });
      for (let i = 0; i < multiText.lines.length; i++) {
        if (startingPositon - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
          var newPageFirstTextY = pageHeight - 50;
          startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiText.lines[i].text}`, {
            x: 50,
            y: startingPositon,
            size: 11,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          firstPage.drawText(`${multiText.lines[i].text}`, {
            x: 58,
            y: startingPositon,
            size: 11,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        }
        // move position down
        startingPositon = startingPositon - lineHeight;
      }
      var nPointLivraison = "";
      for (var i = 0; i < listCompteurs.length; i++) {
        nPointLivraison = nPointLivraison + listCompteurs[i]["numCompteur"] + ", ";
      }
      var field = form.getTextField("textarea_reference");
      var textReference = `Ma référence client est ${this.dataFournisseur.referenceClient} et le numéro de compteur est le suivant : ${nPointLivraison}\n`;

      startingPositon = startingPositon - 10; // Adjust the starting Y coordinate
      var multiTextReference = layoutMultilineText(textReference, {
        alignment: "left",
        fontSize: 11,
        font: timesRomanFont,
        lineHeight: lineHeight,
        bounds: { width: pageWidth - 80, height: pageHeight },
      });
      for (let i = 0; i < multiTextReference.lines.length; i++) {
        if (startingPositon - lineHeight < 30) {
          var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
          var newPageFirstTextY = pageHeight - 50;
          startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
          firstPage.drawText(`${multiTextReference.lines[i].text}`, {
            x: 50,
            y: startingPositon,
            size: 11,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        } else {
          firstPage.drawText(`${multiTextReference.lines[i].text}`, {
            x: 58,
            y: startingPositon,
            size: 11,
            maxWidth: pageWidth - 80,
            color: rgb(0, 0, 0),
          });
        }
        // move position down
        startingPositon = startingPositon - lineHeight;
      }
      var fields = form.getFields();
      fields.forEach((field) => {
        field.enableReadOnly();
      });
      form.updateFieldAppearances();
      let mergedPdf = await PDFDocument.create();
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc);

      const pdfBytes = await mergedPdf.save();
      this.generatedPdf = pdfBytes;
      if (typeDemande == "GAZ") {
        download(pdfBytes, "Lettre_de_résiliation_GAZ.pdf", "application/pdf");
      } else {
        download(pdfBytes, "Lettre_de_résiliation_ELEC.pdf", "application/pdf");
      }
    },
    async createLettreResiliation() {
      if (this.$refs.formAcd.validate()) {
        if (this.selectedCompteursGAZ.length > 0) {
          await this.createLettreResiliationELECGAZ("GAZ", this.selectedCompteursGAZ);
        }
        if (this.selectedCompteursELEC.length > 0) {
          await this.createLettreResiliationELECGAZ("ELEC", this.selectedCompteursELEC);
        }
      }
    },
    dateFormatSlach(date) {
      try {
        return date.split("-").reverse().join("/");
      } catch (err) {
        return date;
      }
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(donorPdf, donorPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
  },
};
</script>
