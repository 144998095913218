<template>
  <div>
    <datatable
      id="table-externes-admin"
      class="ma-8"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
    >
      <template v-slot:body>
        <tbody v-if="projects.length > 0 && loadingTable == false">
          <tr v-for="(project, index) in projects" :key="project.id">
            <td>
              <div class="td-table">
                {{ project.date }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ project.typeDemande }}
              </div>
            </td>
            <td>
              <v-tooltip v-if="project.Raison.length >= 20" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <small class="td-table" v-bind="attrs" v-on="on">
                    {{ project.Raison.substring(0, 20) + "..." }}
                  </small>
                </template>
                <span>{{ project.Raison }}</span>
              </v-tooltip>
              <small class="td-table" v-else>
                {{ project.Raison }}
              </small>
            </td>
            <td>
              <div class="td-table">
                {{ project.siret }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ project.numCompteur }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ project.compteurProfile }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ project.fournisseurPossible }}
              </div>
            </td>
            <td>
              <small class="td-table">
                <router-link
                  :to="{
                    path: '/ajouterNouvelleOffre',
                    query: { id: project.id },
                  }"
                  >Cliquez ici</router-link
                >
              </small>
            </td>
            <td>
              <div class="td-table">
                <p v-if="project.etat == 'activer'">Actif</p>
                <p v-else>Désactiver</p>
              </div>
            </td>
            <td>
              <div class="d-flex flex-row justify-content-center">
                <v-btn
                  v-if="project.etat == 'Désactiver'"
                  @click="activerLink(project.id)"
                  class="dropdown-project mx-2"
                  icon
                  small
                  dark
                >
                  <v-icon color="green darken-4"> mdi-check-outline </v-icon>
                </v-btn>
                <v-btn
                  v-if="project.etat == 'activer'"
                  @click="desactiverLink(project.id)"
                  class="dropdown-project mx-2"
                  icon
                  small
                  dark
                >
                  <v-icon color="red"> mdi-cancel </v-icon>
                </v-btn>
                <v-btn
                  v-if="typeUser == 'admin'"
                  @click="deleteLink(project.id)"
                  class="dropdown-project"
                  icon
                  small
                  dark
                >
                  <v-icon color="deep-orange accent-3"> mdi-delete </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
        <noDataTable
          class="ma-4"
          v-if="projects.length == 0 && loadingTable == false"
        />
        <progressCircular class="ma-8" v-if="loadingTable == true" />
      </template>
    </datatable>
    <v-row>
      <v-col>
        <pagination
          v-if="projects.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import jQuery from "jquery";

import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/no-data-table.vue";
import progressCircular from "../../components/progress-circular.vue";

import axios from "axios";
export default {
  name: "toutLesOffreAdmin",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "10vw", label: "Date d'ajout", name: "date", sortable: true },
      { width: "10vw", label: "Énergie", name: "typeDemande", sortable: true },
      {
        width: "10vw",
        label: "Raison sociale",
        name: "Raison",
        sortable: true,
      },
      { width: "10vw", label: "Siret", name: "siret", sortable: true },
      { width: "10vw", label: "PDL/PCE", name: "numCompteur", sortable: true },
      { width: "10vw", label: "Type", name: "compteurProfile", sortable: true },
      { width: "10vw", label: "Fournisseur", name: "link", sortable: true },
      {
        width: "10vw",
        label: "Accès",
        name: "fournisseurPossible",
        sortable: true,
      },
      { width: "10vw", label: "Statut", name: "etat", sortable: true },
      { width: "10vw", label: "Actions", name: "action", sortable: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      loadingTable: false,
      projects: [],
      typeUser: localStorage.getItem("typeUser"),
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "15", "30"],
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 0,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  async created() {
    this.loadingTable = true;
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else if (whoIam == "fournisseur") {
      this.$router.push("/mesOffre");
    } else if (whoIam != "admin" && whoIam != "superadmin") {
      this.$router.push("/OldDashBoard");
    }
    await this.getData();
    this.loadingTable = false;
  },
  methods: {
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      var pageNumber = 0;
      this.tableData.search = key;
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getLienParFournisseur/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getData(pageNumber = 0) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getLienParFournisseur/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteLink(id) {
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("id", id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteLink/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            alert("Lien supprimé");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == false) {
            alert("Impossible de supprimer ce lien");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    desactiverLink(id) {
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("id", id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "disactiverLink/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            alert("Lien désactivé");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == false) {
            alert("Impossible de désactiver ce lien");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    activerLink(id) {
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("id", id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "activerLink/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            alert("Lien activé");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == false) {
            alert("Impossible d'activer ce lien");
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == "permission") {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
#table-externes-admin th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
.td-table {
  display: flex;
  justify-content: flex-start;
}
</style>
