var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('datatable',{staticClass:"ma-8",attrs:{"id":"table-mes-societes","columns":_vm.columns,"sortKey":_vm.sortKey,"sortOrders":_vm.sortOrders},on:{"sort":_vm.sortBy,"search":_vm.rechercheData},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.projects.length > 0 && _vm.loadingTable == false)?_c('tbody',_vm._l((_vm.projects),function(project){return _c('tr',{key:project.id,staticClass:"text-center"},[_c('td',[(project.Raison.length >= 20)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('small',_vm._g(_vm._b({staticClass:"td-table"},'small',attrs,false),on),[_c('router-link',{attrs:{"to":{
                      path: '/mes-profils-societes',
                      query: { id: project.idSociete },
                    }}},[_vm._v(_vm._s(project.Raison.substring(0, 20) + "..."))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.Raison))])]):_c('small',{staticClass:"td-table"},[_c('router-link',{attrs:{"to":{
                  path: '/mes-profils-societes',
                  query: { id: project.idSociete },
                }}},[_vm._v(_vm._s(project.Raison))])],1)],1),_c('td',[_vm._v(" "+_vm._s(project.siret)+" ")]),_c('td',[_vm._v(" "+_vm._s(project.codeNaf)+" ")]),_c('td',[_vm._v(" "+_vm._s(project.codeInsee ? project.codeInsee : "MANQUANT")+" ")]),_c('td',[_c('div',{staticClass:"responsive-phone"},[(project.AdressePostal.length > 20)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(project.AdressePostal.substring(0, 20) + "...")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(project.AdressePostal))])]):_c('span',[_vm._v(_vm._s(project.AdressePostal))])],1)]),_c('td',[_c('v-btn',{attrs:{"small":"","color":_vm.$colors[0]}},[_c('router-link',{staticClass:"text-white text-decoration-none",attrs:{"to":{
                  path: '/demande-cotation-gaz-pro',
                  query: { siret: project.siret },
                }}},[_c('span',{staticClass:"Poppins-Bold"},[_vm._v("Cotation Gaz"),_c('v-icon',{staticClass:"ml-2",attrs:{"dense":"","icon":""}},[_vm._v("mdi-fire-circle")])],1)])],1)],1),_c('td',[_c('v-btn',{staticClass:"text-white",attrs:{"small":"","color":_vm.$colors[0]}},[_c('router-link',{staticClass:"text-white text-decoration-none",attrs:{"to":{
                  path: '/demande-cotation-elec-pro',
                  query: { siret: project.siret },
                }}},[_c('span',{staticClass:"Poppins-Bold"},[_vm._v("Cotation Elec"),_c('v-icon',{staticClass:"ml-2",attrs:{"dense":""}},[_vm._v("mdi-lightning-bolt-circle")])],1)])],1)],1),_c('td',[_c('v-btn',{staticClass:"text-white",attrs:{"icon":"","small":""}},[_c('router-link',{staticClass:"text-white text-decoration-none",attrs:{"to":{ path: '/ajouterVenteProMulti', query: { siret: project.siret } }}},[_c('v-icon',{attrs:{"color":_vm.$colors[0]}},[_vm._v("mdi-shopping-outline")])],1)],1)],1)])}),0):(_vm.projects?.length == 0 && _vm.loadingTable == false)?_c('noDataTable',{staticClass:"ma-4"}):(_vm.loadingTable == true)?_c('progressCircular',{staticClass:"ma-8"}):_vm._e()]},proxy:true}])}),_c('v-row',[_c('v-col',[(_vm.projects.length > 0)?_c('pagination',{attrs:{"pagination":_vm.pagination},on:{"selectNumberOfRecords":_vm.rechercheData,"first":function($event){return _vm.getData(_vm.pagination.firstPage)},"last":function($event){return _vm.getData(_vm.pagination.lastPage)},"prev":function($event){return _vm.getData(_vm.pagination.prevPageUrl)},"current":function($event){return _vm.getData(_vm.pagination.currentPage)},"next":function($event){return _vm.getData(_vm.pagination.nextPageUrl)}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }