<template>
    <div class="mt-8">
        <div v-if="dialogAjouterOffre">
            <dialogeModal
                        :error="errorAjouterOffre"
                        :dialog="dialogAjouterOffre"
                        :loading="loading"
                        @closeDialog="closeDialogAjouterOffre"
                        titre="Chargement de la page en cours"
                        messageDialogeSucces="Le chargement a chargé avec succès"
                        messageDialogeError="Une erreur est survenue durant le chargement"
                        :typeDemande="typeDemande"
                        @modifierAnyway="modifierOffreAnyWay(offreModifier)" />
        </div>
        <div>
            <v-dialog
                :retain-focus="false"
                v-model="dialogRemove"
                width="500"
                >
                <!--<template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="red"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                </template>-->

                <v-card>
                    <v-toolbar
                    :color="$colors[0]"
                    class="text-white"
                    dark>
                        <span class="Poppins-Bold pl-0">Supression de l'offre</span>
                    </v-toolbar>

                    <v-card-text class="text-center mt-4">
                        Etes vous sur de vouloir supprimer cette offre ?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="dialogRemove = false"
                    >
                        <span class="Poppins-Bold">Fermer</span>
                    </v-btn>
                    <v-btn
                        :color="$colors[0]"
                        @click="removeOffer()"
                    >
                        <span class="Poppins-Bold white--text">Supprimer</span>
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <b-alert  v-if="linkFound==false" variant="danger" show>cette offre n'existe plus</b-alert>
        <b-alert  v-if="etat!='activer'" variant="danger" show>cette offre n'est pas activé pour l'instant</b-alert>
        <v-btn class="ml-4 text-white" :color="$colors[0]"  v-if="(typeUser=='admin' || typeUser=='superadmin') && etat=='activer'" @click="updateLink($route.query.id,'disactiverLink/')"><span class="Poppins-Bold">Désactiver</span></v-btn>
        <v-btn v-if="(typeUser=='admin' || typeUser=='superadmin')  && etat!='activer'" @click="updateLink($route.query.id,'activerLink/')"><span class="Poppins-Bold">Activer</span></v-btn>
        <div v-if="linkFound==true">
        <CardInformations :object="societe"/>
            <v-card class="ma-4 d-flex flex-column">
                <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="Poppins-Bold pl-0">Informations du Compteur</span>
                </v-toolbar>
                <v-card-text class="ma-6 d-flex flex-row">
                    <v-row align="center" justify="start">
                        <span  class="d-flex my-1 mx-2 text-subtitle-1"><p class="font-weight-bold black--text">Numéro de Compteur:</p> <p class="ma-0 px-2 black--text">{{numCompteur}}</p></span>
                        <span  class="d-flex my-1 mx-2 text-subtitle-1"><p class="font-weight-bold black--text">FTA:</p> <p class="ma-0 px-2 black--text">{{optionTarifaire}}</p></span>
                        <v-radio-group  v-if="optionTarifaire == 'BTINFMU4' || optionTarifaire == 'BTINFCU4'"
                            @change="changeOptionCalculeBtinf(parametreCompteur, parametreCompteur)" v-model="parametreCompteur" 
                            row
                            class="smallradio"
                            >
                            <v-radio label="C4" value="C5C4"></v-radio>
                            <v-radio label="HP/HC" value="C5HP"></v-radio>
                            <v-radio label="Base" value="C5BASE"></v-radio>
                        </v-radio-group>
                    </v-row>
                </v-card-text>
            </v-card>
            
            <v-card class="d-flex flex-column justify-content-center  mt-12 ma-4">
                <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="Poppins-Bold pl-0">Liste d'offres</span>
                </v-toolbar>
                <b-table thead-class="head-Btable" tbody-tr-class="textCenter"  show-empty :busy="loadingTable" class="pa-4" 
                    :items="listDesOffreFiltred" :fields="headersDataTable"  bordered  responsive >
                    <template #empty="scope">
                        <noDataTable/>
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="pl-4">Chargement des offres en cours...</strong>
                        </div>
                    </template>
                    <template #cell(buttonDetails)="row">
                        <v-btn icon size="sm" :color="$colors[0]" @click="openEdit(row)" class="mr-2">
                            <v-icon>{{row.detailsShowing ? "mdi-minus" : "mdi-plus"}}</v-icon>
                        </v-btn>
                        <v-btn icon size="sm" color="error" @click="openDialogItem(row.item)" class="mr-2">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </template>
                    <template #cell(dateDebut)="row">
                        <p>{{dateFormatSlash(row.item.dateDebut)}}</p>
                    </template>
                    <template #cell(dateFin)="row">
                        <p>{{dateFormatSlash(row.item.dateFin)}}</p>
                    </template>
                    <!-- detailes -->
                    <template #row-details="row">
                        <b-card v-if="row.item.typeDemande == 'ELEC'">
                            <v-form ref="formUpdateElec">
                                <b-row class="pl-2 my-4">
                                    <b-col cols="12" sm="12" md="7" lg="7" xl="7">
                                        <b-row class="text-center" align-v="center">
                                            <v-radio-group v-model="row.item.turpeInclus" row>
                                                <v-radio class="pl-4" :color="$colors[2]" value="oui" label="Turpe Inclus"></v-radio>
                                                <v-radio class="pr-4" :color="$colors[2]" value="non" label="Turpe non Inclus"></v-radio>
                                            </v-radio-group>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class='pl-4 mt-8 mb-2'>
                                    <b class="mr-4">Prix du MWh : </b>
                                </b-row>
                                <v-col class="mb-8 mt-1">
                                <v-row>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4' || row.item.compteurType == 'C5C4'" 
                                        outlined dense class="mt-4 mx-2" v-model="row.item.HPH" label="(HPH)"
                                        suffix="€"
                                            :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4'"
                                        outlined dense class="mt-4 mx-2" v-model="row.item.HCH" label="(HCH)"
                                        suffix="€"
                                            :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4' " 
                                        outlined dense class="mt-4 mx-2" v-model="row.item.HPE" label="(HPE)"
                                        suffix="€"
                                            :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4' " 
                                        outlined dense class="mt-4 mx-2" v-model="row.item.HCE" label="(HCE)"
                                        suffix="€"
                                            :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C3' " class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense v-model="row.item.POINTE"  label="(P)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C5BASE'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense v-model="row.item.BASE"  label="(BASE)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense v-model="row.item.HP"  label="(HP)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div>
                                    
                                    <v-text-field v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense v-model="row.item.HC"  label="(HC)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>                                        
                                </div>
                                <div>
                                    
                                    <v-text-field class="mt-4 mx-2" v-model="row.item.CEE"
                                        suffix="€"
                                        outlined dense label="CEE" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>   
                                </div>
                                <div>
                                    
                                    <v-text-field class="mt-4 mx-2" v-model="row.item.abo"
                                        suffix="€"
                                        outlined dense label="Abonnement mensuel" :rules="[rules.required]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                </v-row>
                                </v-col>
                                <b-row class="pl-4 mt-4 mb-2">
                                    <b class="mr-4">Informations de l'offre :</b>
                                </b-row>
                                <b-row class="my-4 pl-4">
                                    <v-radio-group v-model="row.item.typeCapa" row>
                                        <v-radio class="pr-4" :color="$colors[2]" value="CAPA" label="Cout Capacité €/MWh"></v-radio>
                                        <v-radio class="pr-4" :color="$colors[2]" value="COEF" label="Coefficient Capacité €/MWh"></v-radio>
                                        <v-radio class="pr-4" :color="$colors[2]" value="CAPAINCLUS" label="CAPA Inclus"></v-radio>
                                    </v-radio-group>
                                </b-row>
                                <div v-if="row.item.typeCapa != 'CAPAINCLUS'" class="mb-8 mt-1 d-flex flex-row">
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_HPH"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HPH)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_HCH"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HCH)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_HPE"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HPE)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_HCE"  v-if="row.item.compteurType == 'C3' || row.item.compteurType == 'C4'|| row.item.compteurType == 'C5C4'"
                                        suffix="€"
                                        outlined dense class="mt-4 mx-2" style="width:15%"  label="(HCE)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_POINTE"   v-if="row.item.compteurType == 'C3'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(P)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_BASE"  v-if="row.item.compteurType == 'C5BASE'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(BASE)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_HP"  v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(HP)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">
                                    
                                    <v-text-field v-model="row.item.CAPA_HC"  v-if="row.item.compteurType == 'C5HP'" class="mt-4 mx-2"
                                        suffix="€"
                                        outlined dense style="width:15%"  label="(HC)" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </div>
                                </div>

                                <v-row justify="end" class="pa-4">
                                    <v-btn class="white--text" @click="modifyData(row.item, 'Elec')" :color='$colors[0]'><span class="Poppins-Bold">Envoyer</span></v-btn>
                                </v-row>
                            </v-form>
                        </b-card>
                        <b-card v-if="row.item.typeDemande == 'GAZ'">
                            <v-form ref="formUpdateGaz">
                                <b-row class='pl-4 mt-8 mb-2'>
                                    <b class="mr-4">Prix du MWh : </b>
                                </b-row>
                                <b-row class="mb-8 mt-1">
                                    <v-text-field v-model="row.item.prixMolecule"  class="ml-4 mt-4 mr-2" style="width:20%"
                                        outlined dense label="Prix de la molécule" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.abo" class="mt-4 mr-2" style="width:20%"
                                        outlined dense label="Abonnement mensuel" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                    <v-text-field v-model="row.item.CEE" class="mt-4" style="width:20%"
                                        outlined dense label="CEE" :rules="[rules.required, ]" step="0.001" type="number">
                                    </v-text-field>
                                </b-row>
                                <b-row class="pa-4">
                                    <b-button @click="modifyData(row.item, 'Gaz')" variant="primary">Envoyer</b-button>
                                </b-row>
                            </v-form>
                        </b-card>
                    </template>
                </b-table>
            </v-card>
            
            <v-divider class="ma-0 pa-0"></v-divider>
            <v-btn  class="ma-4 text-white" v-if="showForm == false" @click="showForm=true,selectTypeOffre()" :color="$colors[0]">
                <b>Ajouter Nouvelle Offre</b>
            </v-btn>
            <v-card  class="ma-4 d-flex flex-column" v-if="typeDemande == 'ELEC' && showForm == true">
                <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <span class="Poppins-Bold pl-0">Nouvelle offre ELEC</span>
                </v-toolbar>
                <v-form class="pa-4" ref="formActuelle">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <p class="ml-4"><b>Informations sur l'offre:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                            <v-row class="ma-1 pa-0">
                            
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                                    <v-select
                                        required
                                        outlined
                                        @change="selectTypeOffre"
                                        :rules="[required('un Fournisseur valide')]"
                                        v-model="nouvelleOffreElec.fournisseur"
                                        :items="optionsFournisseurs"
                                        label="Fournisseur"
                                    ></v-select>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3' >
                                    <v-select required outlined 
                                        @change="selectTypeOffre" :rules="[required(`un type d'offre valide`), rules.Length]"
                                    v-model="nouvelleOffreElec.typeOffre" :items="['Arenh', 'Fixe', 'pré-écrêté']"
                                    label="type d'offre"></v-select>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3' v-if="nouvelleOffreElec.typeOffre == 'pré-écrêté'">
                                    <v-checkbox v-model="nouvelleOffreElec.Classique" label="Classique" @change="selectTypeOffre" value="Classique"></v-checkbox>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3' v-if="nouvelleOffreElec.Classique.indexOf('Classique') == -1">
                                    <v-text-field  required outlined type="number"
                                        :rules="[
                                            required(`un valeur de pré-écrêté valide`),
                                            rules.Length,
                                            rules.pourcentageEcrete,
                                        ]" v-model="nouvelleOffreElec.preEcrete" @change="selectTypeOffre" label="Valeur pré-écrêté" suffix="%">
                                    </v-text-field>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                                    <v-text-field
                                        required
                                        type="date"
                                        outlined
                                        :rules="[required('une date de début de contrat valide')]"
                                        v-model="nouvelleOffreElec.dateDebut"
                                        disabled
                                        label="Début de contrat"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                                    <v-text-field
                                        required
                                        type="date"
                                        outlined
                                        :rules="[dateFinValide(nouvelleOffreElec.dateDebut)]"
                                        v-model="nouvelleOffreElec.dateFin"
                                        :items="listeDesAnnee"
                                        label="Fin de contrat"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-radio-group
                                        v-model="nouvelleOffreElec.turpeInclus"
                                        :disabled="isDisabledTurpe"
                                        row
                                        >
                                        <v-radio
                                            :color="$colors[2]"
                                            label="Turpe Inclus"
                                            value="oui"
                                        ></v-radio>
                                        <v-radio
                                            :color='$colors[2]'
                                            label="TURPE non Inclus"
                                            value="non"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- Prix -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <p class="ml-4"><b>Prix du MWh:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                v-if="parametreCompteur == 'C5BASE'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixBASE"
                                label="(BASE)"
                                :rules="[required('un PRIX (BASE) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                min="0"
                                suffix="€"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixHPH"
                                label="(HPH)"
                                :rules="[required('un PRIX (HPH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixHP"
                                label="(HP)"
                                :rules="[required('un PRIX (HP) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3'|| parametreCompteur == 'C4'
                        || parametreCompteur == 'C5C4' || parametreCompteur == 'C5HP'">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixHCH"
                                label="(HCH)"
                                :rules="[required('un PRIX (HCH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixHC"
                                label="(HC)"
                                :rules="[required('un PRIX (HC) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col  cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4'">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixHPE"
                                label="(HPE)"
                                :rules="[required('un PRIX (HPE) valide')]"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col  cols="11" sm="3" md="2" lg="2" xl='2' v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixHCE"
                                label="(HCE)"
                                :rules="[required('un PRIX (HCE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col  cols="11" sm="3" md="2" lg="2" xl='2' v-if="parametreCompteur == 'C3'">
                            <v-text-field
                                v-if="parametreCompteur == 'C3'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.prixPOINTE"
                                label="(P)"
                                :rules="[required('un PRIX (P) valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                suffix="€"
                                outlined
                                v-model="nouvelleOffreElec.CEE"
                                label="CEE"
                                :rules="[required('un CEE valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                suffix="€"
                                outlined
                                v-model="nouvelleOffreElec.ABO"
                                label="Abonnement mensuel"
                                :rules="[required('une valeur valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <!-- coef capa -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <p class="ml-4"><b>Informations sur la Capacité:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        
                        <v-col cols='12' sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <v-radio-group v-model="nouvelleOffreElec.typeCapa" row class="ma-0 pa-0">
                                <v-radio
                                    :color="$colors[2]"
                                    label="Coût Capacité €/MWh"
                                    value="CAPA"
                                ></v-radio>
                                <v-radio
                                    :color="$colors[2]"
                                    label="Coefficient Capacité €/MWh"
                                    value="COEF"
                                ></v-radio>
                                <v-radio
                                    :color="$colors[2]"
                                    label="CAPA Inclus"
                                    value="CAPAINCLUS"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS'">
                            <v-text-field
                                v-if="parametreCompteur == 'C5BASE'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaBASE"
                                label="(BASE)"
                                :rules="[required('un CAPA (BASE) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaHPH"
                                label="(HPH)"
                                :rules="[required('un CAPA (HPH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaHP"
                                label="(HP)"
                                :rules="[required('un CAPA (HP) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && 
                                (parametreCompteur == 'C3' || parametreCompteur == 'C5HP'|| parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaHCH"
                                label="(HCH)"
                                :rules="[required('un CAPA (HCH) valide')]"
                            ></v-text-field>
                            <v-text-field
                                v-if="parametreCompteur == 'C5HP'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaHC"
                                label="(HC)"
                                :rules="[required('un CAPA (HC) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && (parametreCompteur == 'C3' || 
                            parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaHPE"
                                label="(HPE)"
                                :rules="[required('un CAPA (HPE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && ((parametreCompteur == 'C3' ||
                                 parametreCompteur == 'C4'|| parametreCompteur == 'C5C4'))">
                            <v-text-field
                                v-if="(parametreCompteur == 'C3' || parametreCompteur == 'C4'|| parametreCompteur == 'C5C4')"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaHCE"
                                label="(HCE)"
                                :rules="[required('un CAPA (HCE) valide')]"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="11" sm="3" md="2" lg="2" xl='2' v-if="nouvelleOffreElec.typeCapa != 'CAPAINCLUS' && parametreCompteur == 'C3'">
                            <v-text-field
                                v-if="parametreCompteur == 'C3'"
                                type="number"
                                step="0.001"
                                suffix="€"
                                min="0"
                                required
                                outlined
                                v-model="nouvelleOffreElec.capaPOINTE"
                                label="(P)"
                                :rules="[required('un CAPA (P) valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-row>
                        <v-col align="end">
                            <v-btn
                                color="error"
                                class='ml-5'
                                text
                                @click="fermer"
                            >
                                Fermer
                            </v-btn>
                            <v-btn
                                :color="$colors[0]"
                                class='ml-5 text-white'
                                @click="AjouterOffre(nouvelleOffreElec)"
                            >
                                <span class="Poppins-Bold">Ajouter</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-card  class="ma-4 d-flex flex-column" v-else-if="typeDemande == 'GAZ' && showForm == true">
                <v-card-title id="card-title" class="text-h4 text--primary"> Nouvelle offre GAZ</v-card-title>
                <v-form ref="formActuelle">
                    <v-row class="ma-1 pa-0">
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <p class="ml-4"><b>Informations de l'offre:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                            <v-select
                                dense
                                required
                                outlined dense
                                @change="selectTypeOffre"
                                :rules="[required('Fournisseur')]"
                                v-model="nouvelleOffreGaz.fournisseur"
                                :items="optionsFournisseurs"
                                label="Fournisseur"
                            ></v-select>
                        </v-col>
                        <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                        <v-select
                            required
                            outlined dense
                            dense
                            @change="selectTypeOffre"
                            :rules="[required('type de offre')]"
                            v-model="nouvelleOffreGaz.typeOffre"
                            :items="['Variable', 'Fixe']"
                            label="type d'offre"
                        ></v-select>
                    </v-col>
                    <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                        <v-text-field
                            required
                            type="date"
                            outlined dense
                            :rules="[required('date Debut')]"
                            v-model="nouvelleOffreGaz.dateDebut"
                            disabled
                            label="Début de contrat"
                        ></v-text-field>
                    </v-col>
                    <v-col cols='11' sm="4" md="3" lg="3" xl='3'>
                        <v-text-field
                            required
                            type="date"
                            outlined dense
                            :rules="[required('date Fin'), dateFinValide(nouvelleOffreGaz.dateDebut)]"
                            v-model="nouvelleOffreGaz.dateFin"
                            :min="nouvelleOffreGaz.dateDebut"
                            :items="listeDesAnnee"
                            label="Date Fin"
                        ></v-text-field>
                    </v-col>
                    </v-row>
                    
                    <!-- Prix -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <p class="ml-4"><b>Prix du MWh:</b></p>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                dense
                                suffix="€"
                                outlined dense
                                v-model="nouvelleOffreGaz.prixMolecule"
                                label="Prix de la molécule"
                                :rules="[required('un prix de la molécule valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                suffix="€"
                                dense
                                outlined dense
                                v-model="nouvelleOffreGaz.ABO"
                                label="Abonnement mensuel"
                                :rules="[required('une valeur valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols='11' sm="3" md="2" lg="2" xl='2' >
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                suffix="€"
                                dense
                                outlined dense
                                v-model="nouvelleOffreGaz.CEE"
                                label="CEE"
                                :rules="[required('un CEE valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-row>
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                            <v-btn
                                :color="$colors[0]"
                                class='ml-5 text-white'
                                @click="AjouterOffre(nouvelleOffreGaz)"
                            >
                                Ajouter
                            </v-btn>
                            <v-btn
                                color="error"
                                class='ml-5'
                                text
                                @click="fermer"
                            >
                                Fermer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import moment from 'moment'
    import dialogeModal from '../../components/ui/dialogeModal'
    import CardInformations from './components/CardInformations'
    import noDataTable from "../../components/no-data-table.vue"
    import axios from 'axios'
    import './css/main.css'
    export default{
        name: "ajouterNouvelleOffre",
        components:{dialogeModal,CardInformations,noDataTable},
        data(){
            return{
                dialogRemove:false,
                editedItem: null,
                isDisabledTurpe:false,
                errorDateDeFin : false,
                dialogAjouterOffre: false,
                messageAjouter: "",
                errorAjouterOffre: false,
                loading: false,
                dialog: false,
                loadingTable:false,
                offreModifier: null,
                headersDataTable:[
                    {key:"date",label:"Date d'ajout",sortable:true},
                    {key:"dateDebut",label:"Début de contrat",sortable:true},
                    {key:"dateFin",label:"Fin de contrat",sortable:true},
                    {key:"fournisseur",label:"Fournisseur",sortable:true},
                    {key:"compteurNumber",label:"Numéro de compteur",sortable:true},
                    {key:"compteurType",label:"Profil",sortable:true},
                    {key:"typeDemande",label:"Type d'énergie",sortable:true},
                    {key:"typeOffre",label:"Offre",sortable:true},
                    {key:"buttonDetails",label:"Actions"}
                ],
                defaultDateDebut: '',
                //////////////////////////////////////////
                showForm: false,
                linkFound: false,
                listDesOffre: [],
                listDesOffreFiltred: [],
                societe: null,
                typeUser: localStorage.getItem('typeUser'),
                typeDemande: null,
                parametreCompteur: null,
                optionTarifaire: null,
                numCompteur: null,
                etat: null,
                listeDesAnnee:['Tout', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'],
                nouvelleOffreElec:{
                    fournisseur: null,
                    turpeInclus: 'non',
                    dateDebut: '',
                    dateFin: '',
                    annee: null,
                    typeOffre: null,
                    prixHPH: null,
                    prixHCH: null,
                    prixHPE: null,
                    prixHCE: null,
                    prixHP: null,
                    prixHC: null,
                    prixBASE: null, 
                    prixPOINTE: null, 
                    typeCapa: 'CAPA',
                    capaHPH: null,
                    capaHCH: null,
                    capaHPE: null,
                    capaHCE: null,
                    capaHP: null,
                    capaHC: null,
                    capaBASE: null, 
                    capaPOINTE: null, 
                    CEE: null,
                    ABO: null,
                    Classique: ["Classique"],
                    preEcrete: "0",        
                },
                nouvelleOffreGaz:{
                    dateDebut: '',
                    dateFin: '',
                    fournisseur: null,
                    annee: null,
                    typeOffre: null,
                    prixMolecule: null,
                    CEE: null,
                    ABO: null,           
                },
                
                optionsFournisseurs: [
                    'Alpiq',
                    'ALSEN',
                    'Alterna',
                    'Antargaz',
                    'Axpo',
                    'BARRY',
                    'BULB',
                    'BUTAGAZ',
                    'CDISCOUNT',
                    'Dyneff',
                    'Edenkia',
                    'EDF',
                    'EDS',
                    'EDSB',
                    'Ekwateur',
                    'Elecocite',
                    'Electricite_de_provence',
                    'Electricite_de_savoie',
                    'Electricite_de_Strasbourg',
                    'ELMY',
                    'Enalp',
                    'Endesa',
                    'Enercoop',
                    'Energem',
                    'ENERGIE_DICI',
                    'Engie',
                    'ENI',
                    'Enovos',
                    'Gaz_de_Bordeaux',
                    'GazelEnergie',
                    'Gazprom',
                    'GEDIA',
                    'GEG',
                    'GREEN_YELLOW',
                    'HAPPE',
                    'IBERDROLA',
                    'ILEK',
                    'JPME',
                    'la_bellenergie',
                    'LECLERCQ',
                    'LLUM',
                    'LUCIA',
                    'MEGA',
                    'MET-France',
                    'mint-energie',
                    'OHM',
                    'OVO',
                    'Picoty',
                    'PLUM',
                    'Primeo',
                    'Proxelia',
                    'Redeéo',
                    'SaveEnergies',
                    'Selia',
                    'SEFE',
                    'SOWEE',
                    'Synelva',
                    'TDE',
                    'Urban_Solar',
                    'Vattenfall',
                    'Volterres',
                    'Wekiwi',
                    'yeli-xs',
                ],
                rules: {
                    required: value => !!value || 'Ce champ est obligatoire.',
                    valeurMin: (v) => (v >= 0) || "Ce champ n'accepte qu'une valeur positive.",
                },

                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                dateFinValide(dateDebut){
                    return (value) =>new Date(dateDebut).getTime() < new Date(value).getTime() || `Veuillez saisir une date de fin de contrat supérieur au `+dayjs(dateDebut).format('DD/MM/YYYY')
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.verifierLaccessEtGetData()
        },
        methods: {
            async filterByCompteurType(type) {
                this.listDesOffreFiltred = this.listDesOffre.filter(item => item.compteurType === type);
            },
            async changeOptionCalculeBtinf(obj, newParam) {
               await this.filterByCompteurType(newParam)
            },
            openEdit(row){
                /*var fournisseurToUpdate = row.item
                if (fournisseurToUpdate["typeOffre"].indexOf("pré-écrêté") != -1) {
                    if (fournisseurToUpdate["typeOffre"].indexOf("%") != -1) {
                    fournisseurToUpdate["Classique"] = [""];
                    fournisseurToUpdate["preEcrete"] = fournisseurToUpdate["typeOffre"]
                        .split("pré-écrêté")[1]
                        .split("%")[0]
                        .replace(" ", "");
                    } else {
                    fournisseurToUpdate["Classique"] = ["Classique"];
                    fournisseurToUpdate["preEcrete"] = "0";
                    }
                    fournisseurToUpdate["typeOffre"] = "pré-écrêté";
                }
                row.item = fournisseurToUpdate
                console.log(fournisseurToUpdate)*/
                row.toggleDetails()
            },
            closeDialogAjouterOffre(){
                this.dialogAjouterOffre = false
                this.messageAjouter = ""
                this.errorAjouterOffre = false
                this.loading = false
            },
            async modifierOffreAnyWay(offre){
                this.dialogAjouterOffre = true
                this.loading = true
                this.messageAjouter = ""
                this.errorAjouterOffre = false
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT +
                    'modifierOffreAnyWay/',
                    method: 'POST',
                    data: {
                        token: this.getToken('token'),
                        item: offre,
                        typeDemande: this.typeDemande,
                    },
                })
                .then(async (res) => {
                    if(res.data.result==false){
                        this.messageAjouter = res.data.message
                        this.errorAjouterOffre = true
                    }else if(res.data.result=="year"){
                        this.messageAjouter = res.data.message
                        this.errorAjouterOffre = "year"
                    }else{
                        alert('permission')
                    }
                    await this.verifierLaccessEtGetData()
                    this.fermer()
                })
                .catch((err)=>{
                    console.log(err)
                    this.fermer()
                })
                    this.closeDialogAjouterOffre()
                 
            },
            selectTypeOffre(){
                this.isDisabledTurpe = false
                var trouver = false;
                var typeOffre;
                var fournisseur;
                var dateFin = new Date('1990-01-01');
                if(this.typeDemande == "GAZ"){
                    fournisseur = this.nouvelleOffreGaz.fournisseur
                    typeOffre = this.nouvelleOffreGaz.typeOffre
                }else{
                    fournisseur = this.nouvelleOffreElec.fournisseur
                    typeOffre = this.nouvelleOffreElec.typeOffre
                    if (this.nouvelleOffreElec.typeOffre == "pré-écrêté") {
                        if (this.nouvelleOffreElec.Classique.indexOf("Classique") != -1) {
                            typeOffre = "pré-écrêté Classique";
                        } else {
                            typeOffre =
                                this.nouvelleOffreElec.typeOffre +
                                " " +
                                this.nouvelleOffreElec.preEcrete +
                                "%";
                            }
                    } else {
                        typeOffre = this.nouvelleOffreElec.typeOffre;
                    }
                }
                for (var i=0; i<this.listDesOffreFiltred.length; i++){
                    if(this.listDesOffreFiltred[i]['typeOffre'] == typeOffre && this.listDesOffreFiltred[i]['fournisseur'] == fournisseur){
                        
                        var currentDateFin = new Date(this.listDesOffreFiltred[i]['dateFin'])
                        if (currentDateFin > dateFin){
                            trouver = true
                            dateFin = currentDateFin;
                            if(this.typeDemande == "GAZ"){
                                this.nouvelleOffreGaz.dateDebut = moment(dateFin.setDate(dateFin.getDate() + 1)).format('YYYY-MM-DD');
                            }else{
                                this.nouvelleOffreElec.dateDebut = moment(dateFin.setDate(dateFin.getDate() + 1)).format('YYYY-MM-DD');
                                if (this.listDesOffreFiltred[i]['turpeInclus'] == "oui"){
                                    this.nouvelleOffreElec.turpeInclus = 'oui'
                                    this.isDisabledTurpe = true
                                }else{
                                    this.nouvelleOffreElec.turpeInclus = 'non'
                                    this.isDisabledTurpe = true
                                }
                            }
                        }
                    }
                }
                if(trouver == false){ 
                    if(this.typeDemande == "GAZ"){
                        this.nouvelleOffreGaz.dateDebut =this.defaultDateDebut;
                    }else{
                        this.nouvelleOffreElec.dateDebut = this.defaultDateDebut;
                    }
                }
            },
            dateFormatSlash(date){
                return date.split('-').reverse().join('/')
            },
            fermer(){
                this.loadingTable = false;
                this.showForm = false
                for(let [key,value] of Object.entries(this.nouvelleOffreElec)){key = null}
                this.nouvelleOffreElec.turpeInclus= 'non'
                this.nouvelleOffreElec.dateDebut= ''
                this.nouvelleOffreElec.dateFin= ''
                this.nouvelleOffreElec.typeCapa= 'CAPA'
                for(let [key,value] of Object.entries(this.nouvelleOffreGaz)){key = null}
                this.nouvelleOffreGaz.dateDebut= ''
                this.nouvelleOffreGaz.dateFin= ''
            },
            async verifierLaccessEtGetData(){
                this.dialogAjouterOffre = true
                this.loading = true
                this.loadingTable = true
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idLink', this.$route.query.id)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT +
                    'getToutLesOffreById/',
                    method: 'POST',
                    data: formData,
                })
                .then( async (res) => {
                    if(res.data.result == true){
                        this.linkFound = true
                        this.listDesOffre = res.data.resulatFinal.listsDesOffres
                        this.numCompteur= res.data.resulatFinal.numCompteur
                        this.etat= res.data.resulatFinal.etat
                        this.societe = res.data.resulatFinal.societe
                        this.typeDemande = res.data.resulatFinal.typeDemande
                        if(this.typeDemande=='ELEC'){
                            this.loadingTable = false
                            this.nouvelleOffreElec.dateDebut = res.data.resulatFinal.dateDebut
                            this.defaultDateDebut = res.data.resulatFinal.dateDebut
                        }else{
                            this.loadingTable = false
                            this.nouvelleOffreGaz.dateDebut = res.data.resulatFinal.dateDebut
                            this.defaultDateDebut = res.data.resulatFinal.dateDebut
                        }
                        
                        if(this.parametreCompteur!='C5HP' && this.parametreCompteur!='C5BASE' && 
                            this.parametreCompteur!='C5C4' && this.parametreCompteur!='C4' && 
                            this.parametreCompteur!='C3' ){
                            this.parametreCompteur = res.data.resulatFinal.parametreCompteur
                        }
                        if(this.typeDemande=='ELEC'){
                            await this.filterByCompteurType(this.parametreCompteur)
                        }else{
                            this.listDesOffreFiltred = this.listDesOffre
                        }
                        this.optionTarifaire = res.data.resulatFinal.optionTarifaire
                    }else if(res.data.result == false){
                        this.loadingTable = false
                        this.linkFound = false
                        this.listDesOffre = []
                        this.numCompteur= null
                        this.etat= null
                        this.societe = null
                        this.typeDemande = null
                        this.parametreCompteur = null
                        this.optionTarifaire =null
                    }else{
                        this.loadingTable = false
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    this.dialogAjouterOffre = false;    
                    this.loadingTable = false
                    this.errorAjouterOffre = false
                }).catch((err)=>{
                    console.log(err)
                    this.loadingTable = false
                })
                    this.loadingTable = false
                    this.errorAjouterOffre = false
                    
            },
            async modifyData(item, typeDemande){
                if(this.$refs.formUpdateElec.validate() || this.$refs.formUpdateGaz.validate())
                    this.offreModifier = item
                    this.dialogAjouterOffre = true
                    this.messageAjouter = ""
                    this.errorAjouterOffre = false
                    this.loading = true
                        var data = {
                            token: this.getToken('token'),
                            item: item,
                            typeDemande: typeDemande,
                        }
                        await axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + "modifierOffreExtern/",
                            method: "POST",
                            data: data,
                        })
                            .then(async (res) => {
                            if (res.data.result === "permission") {
                                localStorage.removeItem("vendeurName");
                                localStorage.removeItem("typeUser");
                                localStorage.removeItem("token");
                                this.$router.push("/login");
                            }
                            if (res.data.result == true) {
                                this.messageAjouter = "Offre modifiée avec succès !"
                                this.errorAjouterOffre = false
                                await this.verifierLaccessEtGetData()

                            }else if(res.data.result=="capa" && typeDemande=="Elec" ){
                                this.messageAjouter = "Modification valeur TURPE pour cette offre et toutes offres ayant le même type d'énergie et le même fournisseur"
                                this.errorAjouterOffre = true
                                this.loadingTable = false
                            }
                            this.dialogAjouterOffre = true
                            this.loading = false
                        }).catch((err)=>{
                            
                                this.dialogAjouterOffre = true
                                this.messageAjouter = "Une errreur est survenue ! Veuillez réessayer"
                                this.errorAjouterOffre = false
                                this.loading = false
                        })
                    
                
            },
            openDialogItem(item){
                this.dialogRemove= true
                this.editedItem = item
            },
            async removeOffer(){
                this.selectTypeOffre();
                var data = {
                    token: this.getToken('token'),
                    item: this.editedItem,
                }
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "deleteOffreExtern/",
                    method: "POST",
                    data: data,
                })
                .then(async (res) => {
                    if (res.data.result === "permission") {
                        localStorage.removeItem("vendeurName");
                        localStorage.removeItem("typeUser");
                        localStorage.removeItem("token");
                        this.$router.push("/login");
                    }
                    if (res.data.result == true) {
                       await this.verifierLaccessEtGetData()
                    }
                })
                this.dialogRemove = false;
            },
           async AjouterOffre(offre){
                if(this.$refs.formActuelle.validate()){
                    console.log(offre.preEcrete)

                    
                    if (offre.typeOffre == "pré-écrêté") {
                        if (offre.Classique.indexOf("Classique") != -1) {
                        offre["typeOffre"] = "pré-écrêté Classique";
                        } else {
                        offre["typeOffre"] =
                            offre.typeOffre +
                            " " +
                            offre.preEcrete +
                            "%";
                        }
                    } else {
                        offre["typeOffre"] = offre.typeOffre;
                    }
                    this.dialogAjouterOffre = true
                    this.loadingTable = true
                    this.messageAjouter = ""
                    this.errorAjouterOffre = false
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT +
                        'AjouterOffre/',
                        method: 'POST',
                        data: {
                            token: this.getToken('token'),
                            idLink: this.$route.query.id,
                            offre: offre,
                            typeDemande: this.typeDemande,
                            parametreCompteur: this.parametreCompteur,
                            societePart:"GEMC",
                        },
                    })
                    .then((res) => {
                        if(res.data.result==true){
                            this.messageAjouter = "Offre ajouté avec succès"
                            this.$refs.formActuelle.reset();
                        }else if(res.data.result==false){
                            this.messageAjouter = res.data.message
                            this.errorAjouterOffre = true
                        }else{
                            alert("permission")
                        } 
                    })
                    .catch((err)=>{
                        console.log(err)
                        this.messageAjouter = "Une erreur est survenue !"
                    })
                        this.dialogAjouterOffre = false
                        this.loadingTable = false
                        this.errorAjouterOffre = false 
                        await this.verifierLaccessEtGetData()
                        this.fermer()

                }
            },
           async updateLink(id,urlAPI){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    urlAPI,
                    method: 'POST',
                    data: formData,
                })
                .then(async (res) => {
                    if(res.data.result == true){
                        alert("Lien désactivé")
                    }else if(res.data.result == false){
                        alert("Impossible de désactiver ce lien")
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                    await this.verifierLaccessEtGetData()
                }).catch((err)=>{
                    console.log(err)
                })

            },
        }
    }
</script>
<style>
    .head-Btable{
        background-color:#103A5E;
        color:white;
    }
</style>
