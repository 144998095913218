<template>
  <v-dialog v-model="isOpen" width="700" @click:outside="closeDialog()">
    <v-card>
      <v-toolbar :color="$colors[0]" class="text-white" dark>
        <p class="text-h6 mb-0">{{ isModify ? 'modifier objectif' : 'Creation objectif' }}</p>
      </v-toolbar>
      <v-form ref="formObjectif">
        <div class="d-flex flex-column pa-4">
          <v-select :rules="[$rules.required]" :disabled="isModify ? true : false" v-model="objectifSelected"
            :items="objectifTypes" outlined label="Type Objectif"></v-select>
          <div v-if="objectifSelected != ''">
            <v-autocomplete label="Personne concerné" outlined v-model="membre" item-text="displayName" item-value="id"
              :items="listMembreEquipe" :rules="[$rules.required]">
              <template v-slot:selection="data">
                {{ data.item.prenom + ' ' + data.item.nom }}
              </template>
              <template v-slot:item="data">
                {{ data.item.prenom + ' ' + data.item.nom }}
              </template>
            </v-autocomplete>

            <v-autocomplete v-model="monthAdd" :items="monthList" :rules="[$rules.required]" item-text="label"
              item-value="value" label="Mois" outlined background-color="white"></v-autocomplete>

            <v-autocomplete v-model="yearAdd" :items="yearList" :rules="[$rules.required]" label="Année" 
              outlined background-color="white"></v-autocomplete>

            <v-text-field type="number" :rules="[$rules.isPositiveNumb]" outlined :label="getValeurObjectifPrefix"
              v-model="valeurObjectif">{{ valeurObjectif }}</v-text-field>
          </div>
          <div class="d-flex flex-row justify-end">
            <v-btn text color="error" @click="closeDialog()">Fermer</v-btn>
            <v-btn :color="$colors[0]" class="white--text ml-2" @click="addObjectif">{{ isModify ? "Modifier" : "Ajouter"
            }}</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: ['isOpen', 'isModify', 'listMembreEquipe', 'listObjectifVenteMensuel', 'listObjectifCAMensuel', 'listObjectifCotaMensuel'],
  data() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const listOfMonths = [
      { value: '01', label: 'Janvier' },
      { value: '02', label: 'Février' },
      { value: '03', label: 'Mars' },
      { value: '04', label: 'Avril' },
      { value: '05', label: 'Mai' },
      { value: '06', label: 'Juin' },
      { value: '07', label: 'Juillet' },
      { value: '08', label: 'Août' },
      { value: '09', label: 'Septembre' },
      { value: '10', label: 'Octobre' },
      { value: '11', label: 'Novembre' },
      { value: '12', label: 'Décembre' }
    ];
    const objectifTypes = ["CA", "cotation", "vente"];

    return {
      objectifSelected: "",
      objectifTypes: objectifTypes,
      membre: null,
      monthAdd: "",
      monthList: listOfMonths,
      yearAdd: "",
      yearList: [],
      valeurObjectif: 0,
    }
  },
  mounted() {
    this.fillYearOptions();
  },
  computed: {
    getValeurObjectifPrefix() {
      switch (this.objectifSelected) {
        case 'CA':
          return 'Objectif en Euro';
        case 'cotation':
          return 'Objectif en Quantité';
        case 'vente':
          return 'Objectif en Quantité';
      
        default:
          return '';
      }
    }
  },
  methods: {
    async addObjectif() {
      if (this.$refs.formObjectif.validate()) {
        // verify if there is an objectif exist in the selected month with the specified user or not
        let objectif = null;
        if (this.objectifSelected == 'CA') {
          objectif = this.listObjectifCAMensuel.find((item) => item.compte.id == this.membre && item.mois == this.monthAdd && item.annee == this.yearAdd)
        }
        if (this.objectifSelected == 'cotation') {
          objectif = this.listObjectifCotaMensuel.find((item) => item.compte.id == this.membre && item.mois == this.monthAdd && item.annee == this.yearAdd)
        }
        if (this.objectifSelected == 'vente') {
          objectif = this.listObjectifVenteMensuel.find((item) => item.compte.id == this.membre && item.mois == this.monthAdd && item.annee == this.yearAdd)
        }
        // verifying and saving
        if (objectif && objectif.valeur != this.valeurObjectif) {
          const res = window.confirm(`Êtes-vous sûr de vouloir ajouter un nouveau objectif dans ce mois à ce compte, cet compte à deja un objectif ${objectif.valeur}`);
          if (res) {
            await this.saveObjectif();
          }
        } else {
          await this.saveObjectif();
        }

      }
    },
    async saveObjectif() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "ajouterObjectif/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.getToken("token"),
          valeur: Number(this.valeurObjectif),
          type: this.objectifSelected,
          compte: this.membre || null,
          month: this.monthAdd,
          year: this.yearAdd,
        }),
      }).then((res) => {
        if (res.status == 200) {
          // emit refresh data
          this.$emit('refreshData');
        }
      })
        .catch((err) => {
          console.log(err);
        });
      // close dialog
      this.closeDialog()
    },
    freeValues() {
      this.objectifSelected = "";
      this.membre = null;
      this.monthAdd = "";
      this.valeurObjectif = 0;
    },
    closeDialog() {
      this.freeValues();
      // emit close dialog
      this.$emit('close');
    },
    fillYearOptions() {
      const currentYear = new Date().getFullYear();
      const startYear = 2021;
      let newList = [];
      for (let year = currentYear; year >= startYear; year--) {
        newList.push(year.toString());
      }
      this.yearAdd = currentYear.toString();
      this.yearList = newList;
    },
  },
}
</script>